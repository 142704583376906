import React, { useState } from 'react';
import styled from 'styled-components';

import { FileSize, Spinner } from '../ui';
import { deleteTouchpoint } from '../svc/brand';
import SettingsFooter from './editor/SettingsFooter';

const Wrap = styled.div`
  display: flex;
  position: relative;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 64px;

  p {
    margin: 0;
    margin-bottom: 2px;
  }

  .title {
    font-size: 15px;
  }
  .sub {
    opacity: 1;
    font-size: 12px;
  }
  .mute {
    color: #444444;
  }

  & > div:not(:last-child) {
    margin-right: 24px;
  }

  & > .f1 {
    flex: 1;
  }

  & > .f2 {
    flex: 2;
  }
  & > .f3 {
    flex: 3;
  }

  & > .thumb {
    background-color: #f0f0f0;
    height: 80px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 32px;
      height: 32px;
      fill: rgba(0, 0, 0, 0.3);
    }
    object {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Error = styled.p`
  position: absolute;
  bottom: -28px;
  left: 0;
  color: red;
`;

export default ({ touchpoint, onCancel = () => {}, onDelete = () => {} }) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const remove = async () => {
    setLoading(true);
    setError(undefined);

    const { error: err } = await deleteTouchpoint(touchpoint.id);
    if (err) {
      setError(err);
    } else {
      onDelete(touchpoint.id);
    }
    setLoading(false);
  };

  if (!touchpoint) return <></>;
  const file = touchpoint.thumbnails[0];
  if (!file) return <></>;

  return (
    <Spinner spin={loading}>
      <h3 style={{ fontWeight: 'normal' }}>Delete '{touchpoint.name}'?</h3>
      <p style={{ fontWeight: '300', fontSize: 12, color: '#444444' }}>
        Deleting this touchpoint remove it completely from BrandBox. Do you want
        to continue?
      </p>
      <Wrap>
        <div className="f1 thumb">
          {(() => {
            return <img src={file.urls.original} alt={file.name} />;
          })()}
        </div>
        <div className="f2">
          <p className="sub">
            <span className="mute">File type:</span> ZIP
          </p>
          <p className="sub">
            <span className="mute">File size:</span>{' '}
            <FileSize
              size={(() => {
                return [
                  ...touchpoint.source_files,
                  ...touchpoint.linked_files,
                ].reduce((s, n) => {
                  s += n.file_size;
                  return s;
                }, 0);
              })()}
            />
          </p>
        </div>
      </Wrap>
      <SettingsFooter
        showCancel
        bordered
        showConfirm
        onCancel={onCancel}
        onConfirm={remove}
      />
      {error && (
        <Error className="error">Update failed! Please try again.</Error>
      )}
    </Spinner>
  );
};
