import React, { useState } from 'react';

import { addFileToCollections } from '../svc/brand';

import { Spinner } from '../ui';
import MediaBrowser from './MediaBrowser';

export default ({
  title,
  brandId,
  collectionId,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const confirm = async (files) => {
    setLoading(true);
    await Promise.all(
      files.map((f) => addFileToCollections(brandId, f.id, [collectionId]))
    );
    setLoading(false);
    onConfirm(files);
  };

  return (
    <Spinner spin={loading}>
      <MediaBrowser
        title={title}
        multi
        onCancel={onCancel}
        onConfirm={confirm}
        collectionId={collectionId}
      />
    </Spinner>
  );
};
