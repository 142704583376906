import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal } from '../../ui';
import Placeholder from './PlaceHolder';
import Text from './Text';
import MediaBrowser from '../MediaBrowser';

const Wrap = styled.div`
  div {
    margin-bottom: 0 !important;
    & > div > p {
      font-weight: 400;
      ${({ position }) =>
        position
          ? 'margin-bottom: 12px !important;'
          : 'margin-top: 12px !important;'}
    }
  }
`;

const Image = styled.div`
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 80px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef !important;
  ${({ selected }) =>
    selected &&
    `
  &:hover {
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:before {
      content: '+';
      display: block;
      font-size: 32px;
      color: white;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }`}
  ${({ url }) =>
    !url &&
    `
    background: linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 0.8px),#d2d2d2 50%,rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%);
    border: 1px solid #d2d2d2;
    min-height: 312px;
  `}

  .img {
    ${({ imageSizeOption }) =>
      !imageSizeOption &&
      `
      position: relative;
      &::before {
        content: '▶';
        color: white;
        position: absolute;
        left: -13px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &::after {
        content: '◀';
        color: white;
        position: absolute;
        right: -13px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
      }
    `}
  }

  img,
  object {
    position: relative;
    pointer-events: none;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    ${({ imageSizeOption, height }) =>
      imageSizeOption &&
      `
      height: ${height}px;
      border-top: 2px solid white !important;
      border-bottom: 2px solid white !important;
    `}
    ${({ imageSizeOption, width }) =>
      !imageSizeOption &&
      `
      width: ${width}px;
      border-left: 2px solid white !important;
      border-right: 2px solid white !important;
      &::after {
        content: '&#9658;';
      }
    `}
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  span {
    color: #000000;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: #d2d2d2;
  }
`;
const Label = styled.span`
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.1px;
  color: ${({ symbol }) => (symbol ? '#999999' : '#333333')};
  display: inline-block;
`;

const Content = styled.div`
  display: flex;
  margin-top: 10px;
  flex-grow: 0 !important;
`;

const Item = styled.div`
  margin-right: 30px;
`;

export default ({
  url = '',
  edit,
  selected,
  placeholder,
  onClickHolder,
  position = false,
  size = 0,
  title = 'Minimum size',
  height = 200,
  width = 200,
  pHeight = 20,
  pWidth = 20,
  imageSizeOption,
  changeHandler = () => {},
}) => {
  const [open, setOpen] = useState(false);

  if (!edit && !url && placeholder)
    return (
      <Placeholder
        placeholder={placeholder}
        height={368}
        onClick={onClickHolder}
      />
    );

  const setImage = (sel) => {
    changeHandler({
      url: sel.urls.original,
    });
    setOpen(false);
  };

  return (
    <Wrap position={position}>
      {position && size !== 0 && (
        <Text
          head
          edit={edit}
          type="text"
          text={title}
          selected={selected}
          size={(() => {
            if (size === 2) return 22;
            if (size === 3) return 28;
            return 15;
          })()}
          changeHandler={({ text }) => {
            changeHandler({
              title: text,
            });
          }}
        />
      )}
      <Image
        width={width}
        height={height}
        imageSizeOption={imageSizeOption}
        edit={edit}
        selected={selected}
        url={url}
        onClick={() => selected && setOpen(!open)}
      >
        {url ? (
          <span className="img">
            <img src={`/api/v1/file?url=${url}`} alt="logo-min" />
          </span>
        ) : (
          <span />
        )}
      </Image>
      {!position && size !== 0 && (
        <Text
          head
          edit={edit}
          type="text"
          as={`h${[3, 2, 1][size - 1] + 2}`}
          text={title}
          selected={selected}
          size={(() => {
            if (size === 2) return 22;
            if (size === 3) return 28;
            return 15;
          })()}
          changeHandler={({ text }) => {
            changeHandler({
              title: text,
            });
          }}
        />
      )}
      {!imageSizeOption ? (
        <Content>
          <Item>
            <Label>Digital min: &nbsp;</Label>
            <Label symbol>{`${width}px wide`}</Label>
          </Item>
          <Item>
            <Label>Printed min: &nbsp;</Label>
            <Label symbol>{`${pWidth}mm wide`}</Label>
          </Item>
        </Content>
      ) : (
        <Content>
          <Item>
            <Label>Digital min: &nbsp;</Label>
            <Label symbol>{`${height}px high`}</Label>
          </Item>
          <Item>
            <Label>Printed min: &nbsp;</Label>
            <Label symbol>{`${pHeight}mm high`}</Label>
          </Item>
        </Content>
      )}

      {selected && (
        <Modal visible={open}>
          <MediaBrowser
            type="image"
            category="basics"
            pub
            onCancel={() => setOpen(false)}
            onConfirm={setImage}
          />
        </Modal>
      )}
    </Wrap>
  );
};
