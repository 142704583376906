import React from 'react';

export default ({ size }) => (
  <>
    {(() => {
      if (size >= 1000000000) {
        return `${parseFloat(
          (size / 1000 / 1000 / 1000).toFixed(1),
          10
        ).toLocaleString()} GB`;
      }

      if (size >= 1000000) {
        return `${parseFloat(
          (size / 1000 / 1000).toFixed(1),
          10
        ).toLocaleString()} MB`;
      }

      return `${parseFloat((size / 1000).toFixed(1), 10).toLocaleString()} KB`;
    })()}
  </>
);
