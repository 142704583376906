import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TextCtl from './TextCtl';
import ColorCtl from './ColorCtl';
import SectionCtl from './SectionCtl';
import TypographyCtl from './TypographyCtl';
import DividerCtl from './DividerCtl';
import GradiantCtl from './GradiantCtl';
import WelcomeCtl from './WelcomeCtl';
import ImageCtl from './ImageCtl';
import RowCtl from './RowCtl';
import LogoSpacingCtl from './LogoSpacingCtl';
import LogoMinSizeCtl from './LogoMinSizeCtl';
import VideoCtl from './VideoCtl';
import AudioCtl from './AudioCtl';
import { BlockButton, Icon, Modal } from '../../ui';

const SideNav = styled.div`
  position: fixed;
  overflow: scroll;
  padding-bottom: 111px;
  top: 111px;
  left: 0;
  z-index: 20;
  box-sizing: border-box;
  height: 100%;
  width: 270px;
  background-color: #f8f8f8;
  border: 1px solid #d2d2d2;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);

  section {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    h3 {
      color: #000000;
      font-size: 12px;
      margin-bottom: 6px;
    }

    & > p {
      color: #444444;
      font-size: 12px;
      line-height: 1.5em;
      margin-bottom: 1em;
    }
  }
`;

const Collapse = styled.div`
  &:not(:first-child) {
    margin-top: 32px;
    margin-bottom: 111px;
  }

  header {
    display: flex;
    align-items: center;
    padding: 0 12px !important;
    margin: 0 !important;
    text-transform: capitalize;
    height: 40px;
    color: #000000;
    background-color: #f0f0f0;
    ${({ inverted }) =>
      inverted &&
      `
    color: #ffffff;
    background-color: #000000;
    `}

    svg {
      fill: #ffffff;
      margin-right: 6px;
      width: 18px;
      height: 18px;
    }
  }

  main {
    padding: 12px;
  }
`;

const selector = ({ user, page, global }) => ({
  ubrand: user.user.brand,
  page,
  gbrand: global.brand,
});

export default () => {
  const { page, ubrand, gbrand } = useSelector(selector);
  const { selected = {}, sections = [] } = page;
  const { section, row, column, type, item } = selected;

  const brand = ubrand || gbrand;

  const componentType = {
    color: 'color',
    text: 'text',
    typography: 'typography',
    divider: 'divider',
    gradient: 'gradient',
    welcomeintro: 'Welcome Intro',
    image: 'image',
    exclusionzone: 'Logo spacing',
    minimumsize: 'Minimum size',
  };

  return (
    <SideNav
      onClick={(e) => {
        if (e.type.match(/click/i) && e.target.nodeName.match(/(label|input)/i))
          return;
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {type && (
        <Collapse inverted>
          <header>
            <Icon name={type} /> {componentType[type]}
          </header>
          <main>
            {(() => {
              // row
              if (type === 'section') {
                return (
                  <Modal visible>
                    <SectionCtl
                      key="section-1"
                      props={sections[section]}
                      {...{ section, row, column, type, item }}
                    />
                  </Modal>
                );
              }

              if (type === 'row') {
                return (
                  <Modal visible>
                    <RowCtl
                      key="row-1"
                      props={sections[section].rows[row]}
                      {...{ section, row, column, type, item }}
                    />
                  </Modal>
                );
              }

              // item
              const selectedItem =
                sections[section].rows[row].columns[column].items[item];
              switch (selectedItem?.type) {
                case 'color':
                  return (
                    <ColorCtl
                      props={(() => {
                        const pr = brand?.colors.find(
                          (c) =>
                            c.id === selectedItem.id ||
                            c.color === selectedItem.color
                        );
                        if (pr) return { ...selectedItem, ...pr };
                        if (!selectedItem.id) return selectedItem;
                        return {};
                      })()}
                      {...{ section, row, column, type, item }}
                    />
                  );

                case 'text':
                  return (
                    <TextCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );

                case 'typography':
                  return (
                    <TypographyCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'divider':
                  return (
                    <DividerCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'gradient':
                  return (
                    <GradiantCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'welcomeintro':
                  return (
                    <WelcomeCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'image':
                  return (
                    <ImageCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'exclusionzone':
                  return (
                    <LogoSpacingCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'minimumsize':
                  return (
                    <LogoMinSizeCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'video':
                  return (
                    <VideoCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );
                case 'audio':
                  return (
                    <AudioCtl
                      props={selectedItem}
                      {...{ section, row, column, type, item }}
                    />
                  );

                default:
                  return <span />;
              }
            })()}
          </main>
        </Collapse>
      )}
      <Collapse>
        <header>Toolkit</header>
        <main>
          <section>
            <h3>General content blocks</h3>
            <p>
              Drag & drop the components below into the layout to create new
              content.
            </p>
            <BlockButton
              icon="welcomeintro"
              text="welcome intro"
              block
              draggable
            />
            <BlockButton icon="text" text="text" block draggable />
            <BlockButton icon="image" text="image" block draggable />
            <BlockButton icon="audio" text="audio" block draggable />
            <BlockButton icon="divider" text="divider" block draggable />
            <BlockButton icon="video" text="video" block draggable />
          </section>
        </main>
        <main>
          <section>
            <h3>Advanced content blocks</h3>
            <p>
              Drag & drop the components below into the layout to automate
              useful brand details.
            </p>
            <BlockButton icon="color" text="color" block draggable />
            <BlockButton icon="typography" text="typography" block draggable />
            <BlockButton icon="gradient" text="gradient" block draggable />
            <BlockButton
              icon="minimumsize"
              text="Logo min. size"
              block
              draggable
            />
            <BlockButton
              icon="exclusionzone"
              text="Logo spacing"
              block
              draggable
            />
          </section>
        </main>
      </Collapse>
    </SideNav>
  );
};
