import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const FontTitle = styled.div`
  width: ${({ block }) => (block ? '100%' : '460px')};
  height: 80px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  ${({ block }) =>
    !block &&
    `
  width: 444px;
  &:not(:nth-child(2n)) {
    margin-right: 14px !important;
  }
  float: left;
  `}
  margin-bottom: 32px;

  & > p {
    font-size: 25px;
    font-stretch: condensed;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: center;
    color: #999999;
    margin: 0;
    ${({ url, fontName, fstyle, weight }) =>
      url &&
      `
        @font-face {
          font-family: ${fontName};
          src: url(/api/v1/file?url=${window.encodeURIComponent(url)});
        }

        font-family: ${fontName} !important;
        font-style: ${fstyle} !important;
        font-weight: ${weight} !important;
      `};
  }

  button,
  button:active {
    display: none;
    padding: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0 !important;
    span {
      margin: 0 !important;
    }
  }

  &:hover {
    button {
      display: inline-block;
    }
  }
`;

const TypographyBlock = ({
  id,
  font: { name, url, variant } = {},
  block,
  index,
  onDelete = () => {},
}) => {
  let weight = 'normal';
  let style = 'normal';

  // extract numeric char
  const w = variant.toString().replace(/[^0-9]/g, '');
  if (w.match(/[0-9]{2,}/)) weight = w;
  // extract alpha char
  const s = variant.toString().replace(/[^a-z]/gi, '');
  if (s.match(/[a-z]{2,}/i)) style = s.replace(/regular/i, 'normal');

  const handleDelete = () => {
    onDelete(index);
  };

  const fn = (n) => {
    return n.split('.')[0].replace('-', ' ');
  };

  return (
    <>
      <FontTitle
        url={url.replace('http:', 'https:')}
        block={block}
        fontName={!name ? 'Roboto' : `${fn(name)}-${id}`}
        fstyle={style}
        weight={weight}
      >
        <Button icon="delete" text onClick={handleDelete} />
        <p>{name}</p>
      </FontTitle>
    </>
  );
};

export default TypographyBlock;
