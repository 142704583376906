export default {
  colors: {
    none: 'transparent',
    head: '#f0f0f0',
    body: '#f8f8f8',
    info: '#4b9aff',
    white: '#ffffff',
    black: '#000000',
    danger: '#ff2419',
    default: '#d2d2d2',
    primary: '#000000',
    success: '#00c000',
    warning: '#ff7c00',
  },

  // color suggestions for the select color box
  selectColors: [
    { label: 'Red', value: '#e80000' },
    { label: 'Pink', value: '#f500a6' },
    { label: 'Blue', value: '#007cf8' },
    { label: 'Aqua', value: '#00cdae' },
    { label: 'Green', value: '#00c000' },
    { label: 'Purple', value: '#950bce' },
    { label: 'Orange', value: '#ff7c00' },
    { label: 'Yellow', value: '#f7c900' },
  ],
};
