import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { setDropTarget, updateItem, setSelected } from '../../store/page';

import { Modal } from '../../ui';

import AddItemBlock from '../AddItemBlock';

const Column = styled.div`
  flex: ${({ span = 1 }) => span} !important;
  box-sizing: border-box;
  &:not(:last-child) {
    padding-right: 24px;
  }
  ${({ ccount = 0, isWelcome, edit }) =>
    ccount === 1 &&
    !edit &&
    !isWelcome &&
    `
  padding: 24px;
  `}
  ${({ count, isDropTarget }) =>
    count === 0 &&
    `
  .add {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 300;
    width: 100%;
    height: 145px;
    outline: 1px solid #000000;
    color: #000000;
    border: none;
    background-color: transparent;
    &:hover {
      background-color: #f0f0f0;
    }
    ${
      isDropTarget &&
      `
      outline: 1px dashed #000000;
      background-color: rgba(0,0,0,0.1);
    `
    }
  }
  `}

  h1,
  h2,
  h3,
  h4,
  p, div {
    margin-bottom: 1em;
    line-height: 1.5em;
  }
`;

export default ({
  row,
  type,
  edit,
  span,
  count,
  ccount,
  column,
  section,
  newItem,
  children,
  isWelcome,
  dragTarget,
  dropTarget,
}) => {
  const dispatch = useDispatch();
  const colRef = useRef();

  const [to, setTo] = useState();
  const [open, setOpen] = useState(false);

  const isDropTarget = (() => {
    if (!dropTarget) return false;
    return (
      dropTarget.section === section &&
      dropTarget.row === row &&
      dropTarget.column === column &&
      dropTarget.item === count - 1
    );
  })();

  const dragenter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDropTarget) return;
    if (to) clearTimeout(to);

    e.target.addEventListener('dragleave', function dl(x) {
      e.target.removeEventListener('dragleave', dl);
      if (!e.target.contains(x.relatedTarget))
        dispatch(setDropTarget(undefined));
    });

    setTo(
      setTimeout(
        () =>
          dispatch(
            setDropTarget({
              row,
              item: count - 1,
              position: 1,
              type,
              column,
              section,
            })
          ),
        100
      )
    );
  };

  useEffect(() => {
    if (colRef.current && edit) {
      const refCopy = colRef.current;
      refCopy.addEventListener('dragenter', dragenter);

      return () => {
        refCopy.removeEventListener('dragenter', dragenter);
      };
    }
  });

  const cancel = () => {
    setOpen(false);
  };

  const confirm = (selected) => {
    dispatch(
      updateItem({
        row,
        item: 0,
        column,
        section,
        props:
          typeof selected === 'object'
            ? selected
            : {
                type: selected,
                new: selected === 'welcomeintro' ? true : undefined,
              },
      })
    );

    dispatch(setSelected({ type: selected, section, row, column, item: 0 }));

    cancel();
  };

  return (
    <>
      <Column
        ref={colRef}
        span={span}
        edit={edit}
        count={count}
        ccount={ccount}
        isWelcome={isWelcome}
        isDropTarget={isDropTarget}
        dragTarget={dragTarget || newItem}
      >
        {edit && count === 0 && (
          <button className="add" type="button" onClick={() => setOpen(true)}>
            +
          </button>
        )}
        {children}
      </Column>
      <Modal visible={open} width={666}>
        <AddItemBlock onCancel={cancel} onConfirm={confirm} />
      </Modal>
    </>
  );
};
