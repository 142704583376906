import React from 'react';
import styled from 'styled-components';

const Insert = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 12px;
  height: 12px;

  div {
    margin: 0;
    padding: 0;
    visibility: hidden;
  }

  &:hover div {
    visibility: visible;
  }

  .label {
    padding: 8px 32px;
    margin: 0;
    border: 1px solid #000000;
    font-size: 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    flex: 1;

    svg {
      fill: #000000;
      width: 12px;
      height: 12px;
      margin-right: 12px;
    }

    button {
      display: flex;
      align-items: center;
      margin-left: 12px;
      border: 0;
      outline: none;
      color: white;
      background-color: #d2d2d2;
      &:hover {
        background-color: #000000;
      }

      span {
        margin-right: 12px;
      }
      img {
        height: 20px;
      }
    }
  }

  .line {
    position: relative;
    flex: 2;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 100%;
      background-color: #000000;
      position: relative;
      height: 1px;
    }
  }
`;

export default ({ label, onClick }) => {
  const hover = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Insert
      className="insert"
      onMouseOver={hover}
      onFocus={hover}
      onBlur={hover}
    >
      <div className="line" />
      <div className="label">
        <span style={{ width: 100 }}>{label} </span>
        <button type="button" onClick={() => onClick(0)}>
          <span style={{ width: 50, padding: '5px 2px' }}>1 Col</span>{' '}
          <img src="/1col.jpg" alt="1col" />
        </button>
        <button type="button" onClick={() => onClick(1)}>
          <span style={{ width: 50, padding: '5px 2px' }}>2 Col.A</span>{' '}
          <img src="/2cola.jpg" alt="2cola" />
        </button>
        <button type="button" onClick={() => onClick(2)}>
          <span style={{ width: 50, padding: '5px 2px' }}>2 Col.B</span>{' '}
          <img src="/2colc.jpg" alt="2colc" />
        </button>
        <button type="button" onClick={() => onClick(3)}>
          <span style={{ width: 50, padding: '5px 2px' }}>2 Col.C</span>{' '}
          <img src="/2colb.jpg" alt="2colb" />
        </button>
        <button type="button" onClick={() => onClick(4)}>
          <span style={{ width: 50, padding: '5px 2px' }}>3 Col</span>{' '}
          <img src="/3col.jpg" alt="3col" />
        </button>
      </div>
      <div className="line" />
    </Insert>
  );
};
