import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { darken } from 'polished';

import Icon from './Icon';
import { getColorFromProps } from '../config';

const File = styled.div`
  width: 256px;
  height: ${({ size }) => (size === 'small' ? '30px' : '46px')};
  display: inline-block;

  ${({ block }) =>
    block &&
    `
    width: 100%;
    display: block;
  `};

  border-radius: 0;
  font-size: ${({ size }) => (size === 'small' ? '12px' : '15px')};
  position: relative;
  box-sizing: border-box;
  font-weight: 300;
  font-family: Roboto;
  margin-bottom: 12px;
  margin-right: ${({ block: b }) => (b ? '0px' : '12px')};
  letter-spacing: 0.3px;
  background-color: transparent;

  border-bottom: 1px solid
    ${(p) => {
      if (p.error) return '#ff2419 !important';
      return getColorFromProps({ props: p, input: true });
    }};
  &:hover {
    border-color: ${(p) => {
      let color = getColorFromProps({ props: p, input: true });
      if (p.error) {
        color = '#ff2419 !important';
        return color;
      }
      if (p.disabled || (p.progress && p.progress !== 100)) return color;
      return darken(0.2, color);
    }};
  }

  & input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: .7;
      & select {
        cursor: not-allowed;
      }
      & svg {
        opacity: 0.4;
      }
  `}

  & span.placeholder {
    position: absolute;
    color: ${({ progress }) => (progress > 0 ? '#000000' : '#999999')};
    display: inline-block;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    & svg {
      position: relative;
      top: 2px;
      margin-right: 3px;
      fill: ${({ progress }) => (progress === 100 ? '#000000' : '#999999')};
    }
  }

  & label {
    cursor: ${({ disabled, progress }) =>
      disabled || (progress > 0 && progress !== 100) ? 'default' : 'pointer'};
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 45px;
    & svg {
      position: absolute;
      top: ${({ size }) => (size === 'small' ? '35%' : '50%')};
      right: ${({ size }) => (size === 'small' ? '12px' : '10px')};
      width: 13px;
      height: 13px;

      transform: translateY(-50%);
      fill: #000000;
      ${({ error }) =>
        error &&
        `
      fill: red !important;
      `}
    }
  }
`;

export default (p) => {
  const props = { ...p };
  const {
    size = 'large',
    name = 'file',
    accept = '*/*',
    endpoint,
    disabled,
    onUpload = () => {},
    placeholder = 'Select file to upload',
  } = props;

  // remove so styled comp won't complain
  delete props.onUpload; // eslint-disable-line

  const [file, setFile] = useState(undefined);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);

  const upload = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // reset
    setProgress(0);
    setError(false);
    setFile(undefined);

    onUpload({});

    const { files = [] } = e.target;
    const [item] = files;

    e.target.value = '';

    if (!item || !endpoint) return;
    setFile(item);

    try {
      // create new form data instance
      const payload = new FormData();
      // append file
      payload.append('file', item, item.name.replace(' ', '-'));

      // send query
      const { data, status = 0 } = await axios.post(endpoint, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },

        onUploadProgress: (evt) => {
          const percent = Math.ceil((evt.loaded * 100) / evt.total);
          if (percent < 99) setProgress(percent);
        },
      });

      if (data && status === 200 && typeof onUpload === 'function') {
        setProgress(100);
        onUpload({
          data: {
            ...data,
            name: item.name,
            variant: 'regular',
          },
          status,
        });
      }
    } catch (err) {
      // reset progress back to 0
      setProgress(0);
      setError(true);

      if (typeof onUpload === 'function') {
        onUpload({ error: err.message });
      }
    }
  };

  return (
    <>
      <File {...props} progress={progress} error={error} size={size}>
        <span className="placeholder">
          {(() => {
            // in progress
            if (progress > 0 && progress < 100)
              return `Uploading file ${progress}%`;
            // 100 %
            if (progress === 100)
              return (
                <>
                  <Icon name="tick" /> {file.name}
                </>
              );
            // error
            if (error) return 'Upload failed!';
            return placeholder;
          })()}
        </span>

        <input
          id={name}
          name={name}
          type="file"
          disabled={disabled || (progress > 0 && progress < 100)}
          accept={accept}
          onChange={upload}
        />

        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        <label htmlFor={name} disabled={disabled}>
          <Icon name="add" />
        </label>
      </File>
    </>
  );
};
