import React, { useState } from 'react';
import styled from 'styled-components';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { getData } from 'country-list';

import { DropDown, Input as IC } from '../ui';

const countries = getData().map((d) => ({ label: d.name, value: d.code }));

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '12px',
      '::placeholder': {
        color: '#aaaaaa',
      },
    },
    invalid: {
      color: '#ff2419',
      iconColor: '#ff2419',
    },
  },
};

const Card = styled(CardElement)`
  border-bottom: 1px solid #e9e9e9;
  padding: 12px 0;
  margin-bottom: 32px;
  width: ${({ w = 315 }) => w}px;
`;

const Title = styled.p`
  font-size: ${({ fs = 15 }) => fs}px;
  font-weight: ${({ fw = 'normal' }) => fw};
  margin-bottom: ${({ mb = 0 }) => mb}px;
  color: ${({ color = '#000000' }) => color};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.06px;
`;

const Input = styled(IC)`
  width: ${({ w = 315 }) => w}px;
`;

export default ({ onToken = () => {}, payment }) => {
  const [details, setDetails] = useState({
    name: payment.billing_details?.name,
    address_zip: payment.billing_details?.address?.postal_code, // eslint-disable-line
    address_line1: payment.billing_details?.address?.line1,
    address_line2: payment.billing_details?.address?.line2,
    address_country: payment.billing_details?.address?.country || 'SG',
  });

  const stripe = useStripe();
  const elements = useElements();

  const tokenize = async (e) => {
    if (e && (e.error || !e.complete)) {
      onToken(undefined);
      return;
    }
    const cardel = elements.getElement(CardElement);
    const t = await stripe.createToken(cardel, details);
    onToken(t);
  };

  const changeHandler = (key) => (e) => {
    const { value } = e.target;
    if (!value.trim()) return;
    setDetails({
      ...details,
      [key]: value.trim(),
    });
    if (key !== 'name') tokenize();
  };

  return (
    <>
      <Title mb={16}>Card details</Title>
      <Input
        name="name"
        placeholder="Name on card"
        size="small"
        defaultValue={payment.billing_details?.name || ''}
        onChange={changeHandler('name')}
      />

      <Card
        onChange={tokenize}
        options={{ ...cardStyle, disabled: !details || !details.name }}
      />

      <Title mb={16}>Billing address</Title>
      <div style={{ marginBottom: 10 }}>
        <DropDown
          items={countries}
          defaultValue={payment.billing_details?.address?.country || 'SG'}
          onSelect={(country) => {
            setDetails({
              ...details,
              address_country: country,
            });
          }}
          size="small"
          placeholder="Country"
          width={315}
        />
      </div>
      <Input
        name="address"
        placeholder="Address line 1 (street address)"
        size="small"
        defaultValue={payment.billing_details?.address?.line1 || ''}
        onChange={changeHandler('address_line1')}
      />
      <Input
        name="address2"
        placeholder="Address line 2 (apartment, unit)"
        size="small"
        defaultValue={payment.billing_details?.address?.line2 || ''}
        onChange={changeHandler('address_line2')}
      />
      <Input
        name="zip"
        placeholder="Postal code"
        size="small"
        w={90}
        defaultValue={(() => {
          return payment.billing_details?.address?.postal_code || ''; // eslint-disable-line
        })()}
        onChange={changeHandler('address_zip')}
      />
    </>
  );
};
