import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '../ui';
import { verify } from '../svc/auth';

export default () => {
  const { token } = useParams();
  if (!token) window.location.href = '/';

  useEffect(() => {
    (async () => {
      const { error } = await verify({ token });
      if (!error) {
        localStorage.setItem(
          'flash',
          JSON.stringify({
            title: 'Account verified',
            description:
              'You can now add users, customize the interface, and more via Settings (click Settings icon, top-right)',
          })
        );
      }

      window.location.href = '/';
    })();
  }, []); // eslint-disable-line

  return <Spinner spin />;
};
