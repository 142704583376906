import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DropDown } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const selectPaddingSize = [
  { label: 'Regular Padding', value: 0 },
  { label: 'More Padding', value: 1 },
  { label: 'Less Padding', value: 2 },
];

export default (attrs) => {
  const { props } = attrs;
  const { size } = props;
  const dispatch = useDispatch();

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onSelectPaddingSize = (value) => {
    update({
      size: value,
    });
  };

  return (
    <Ctl>
      <section>
        <h3>Padding</h3>
        <p>Increase or decrease the padding above or below the divider.</p>
      </section>

      <DropDown
        items={selectPaddingSize}
        onSelect={onSelectPaddingSize}
        defaultValue={selectPaddingSize[size]?.value || 0}
        dropDownType="text"
        disabled={false}
        block
        size="small"
      />
    </Ctl>
  );
};
