import React from 'react';
import styled from 'styled-components';

import TouchpointHeader from './TouchpointHeader';

const Wrap = styled.div`
  position: relative;
  background-color: white;
  ${({ edit }) =>
    edit &&
    `
  border: 1px solid #d2d2d2;
  padding: 24px;
  `}
  box-sizing: border-box;
  margin: 0 auto;
  width: 960px;
  margin-bottom: 64px;

  p {
    font-family: Roboto !important;
  }

  & section {
    padding: 12px;
    margin-bottom: 32px;
  }

  p.description {
    width: 484px;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    line-height: 22px;
  }

  h5 {
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #000000;
    margin-bottom: 12px;
  }
`;

export default ({ edit, onEdit, children, onCancel, onConfirm }) => {
  return (
    <>
      <TouchpointHeader
        edit={edit}
        fixed={edit}
        onEdit={onEdit}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <Wrap edit={edit}>{children}</Wrap>
    </>
  );
};
