import React from 'react';
import styled from 'styled-components';

import Icon from '../ui/Icon';

const Empty = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px dotted #000000;
  min-height: 394px;

  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `}

  * {
    margin-bottom: 10px !important;
  }

  .title {
    margin-top: 12px;
    font-size: 24px;
    color: black;
    font-weight: normal;
  }

  .sub {
    font-size: 12px;
    color: black;
  }

  svg {
    fill: black;
    width: 25px;
    height: 25px;
  }
`;

export default ({
  sub = 'sub',
  icon = 'add',
  title = 'Title',
  onClick,
  disabled,
}) => {
  return (
    <Empty onClick={onClick}>
      {!disabled && <Icon name={icon} />}
      <p className="title">{title}</p>
      <p className="sub">{sub}</p>
    </Empty>
  );
};
