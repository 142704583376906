import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Input, Spinner, TinySwitch } from '../ui';
import TagBox from './TagBox';
import SettingsFooter from './editor/SettingsFooter';

import CollectionMediaBrowser from './CollectionMediaBrowser';
import CollectionTouchpointBrowser from './CollectionTouchpointBrowser';

import {
  createCollection,
  createTouchpointsCollection,
  updateCollection,
  deleteCollection,
  updateTouchpointsCollection,
  deleteTouchpointsCollection,
} from '../svc/brand';

const Wrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  p {
    margin: 0;
    margin-bottom: 2px;
  }

  .title {
    font-size: 15px;
  }
  .sub {
    opacity: 1;
    font-size: 12px;
  }
  .mute {
    color: #828282;
    line-height: 16px;
    margin-bottom: 12px;
  }

  & > div:not(:last-child) {
    margin-right: 24px;
  }

  & > .f1 {
    flex: 1;
  }

  & > .f2 {
    flex: 2;
  }
  & > .f3 {
    flex: 3;
  }

  & > .thumb {
    background: #000000 url(${({ thumbnail }) => thumbnail}) center center /
      cover no-repeat;
    position: relative;
    overflow: hidden;
  }

  .delete {
    button {
      padding: 0;
      font-weight: 300;
    }
  }
`;

const Error = styled.p`
  position: absolute;
  bottom: -28px;
  left: 0;
  color: red;
`;

export default ({
  bid,
  showDelete,
  onCancel = () => {},
  onUpdate = () => {},
  onCreate = () => {},
  onDelete = () => {},
  touchpoint,
  collection: col = { tags: [], hidden: false },
}) => {
  const [collection, setCollection] = useState(col);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [deletion, setDeletion] = useState(showDelete);
  const [cid, setCid] = useState();

  const del = touchpoint ? deleteTouchpointsCollection : deleteCollection;
  const update = touchpoint ? updateTouchpointsCollection : updateCollection;
  const create = touchpoint ? createTouchpointsCollection : createCollection;

  const confirm = async () => {
    setLoading(true);

    // new
    if (col.id && deletion) {
      const { error: err } = await del(bid, col.id);
      if (err) setError(err);
      else onDelete();
    } else if (col.id) {
      const { error: err } = await update(bid, col.id, collection);
      if (err) setError(err);
      else onUpdate();
    } else {
      const { error: err, data } = await create(bid, collection);
      if (err) setError(err);
      else setCid(data.id);
    }

    setLoading(false);
  };

  if (cid) {
    return (
      <Spinner spin={loading}>
        {touchpoint ? (
          <CollectionTouchpointBrowser
            brandId={bid}
            title="Add touchpoints to the collection"
            collectionId={cid}
            onConfirm={() => onCreate(cid)}
          />
        ) : (
          <CollectionMediaBrowser
            brandId={bid}
            title="Add files to the collection"
            collectionId={cid}
            onConfirm={() => onCreate(cid)}
          />
        )}
      </Spinner>
    );
  }

  return (
    <Spinner spin={loading}>
      {deletion ? (
        <Wrap>
          <div className="f3">
            <p>Delete this collection?</p>
            <p>&nbsp;</p>
            <p className="sub mute">
              Removing the collection will not delete the original files.
            </p>
          </div>
          <div className="f1">&nbsp;</div>
        </Wrap>
      ) : (
        <>
          <h3 style={{ fontWeight: 'normal' }}>
            {col.id ? 'Collection settings' : 'New collection'}
          </h3>
          <Wrap>
            <div className="f3">
              <p>Name</p>
              <Input
                block
                size="small"
                placeholder="Enter collection name"
                defaultValue={collection.name ? collection.name : ''}
                onChange={(e) => {
                  setCollection({
                    ...collection,
                    name: e.target.value.trim(),
                  });
                }}
              />
              <p>&nbsp;</p>
              <p>Tags</p>
              <p className="sub mute">
                Add tags to your collection to easily search for similar files.
                Enter the tag name and click Add.
              </p>
              <TagBox
                tags={[...collection.tags]}
                onChange={(t) => {
                  setCollection({
                    ...collection,
                    tags: t,
                  });
                }}
              />
              <p>&nbsp;</p>
              <p>Hide collection</p>
              <p className="sub mute">
                Hide this collection from non-admin level users.
              </p>
              <p>
                <TinySwitch
                  checked={collection.hidden}
                  label="Hide"
                  onChange={(v) => {
                    setCollection({
                      ...collection,
                      hidden: v,
                    });
                  }}
                />
              </p>
              {col.id && (
                <>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <p className="delete">
                    <Button
                      icon="delete"
                      label="Hide"
                      text
                      size="small"
                      onClick={() => setDeletion(true)}
                    >
                      Delete collection
                    </Button>
                  </p>
                </>
              )}
            </div>
            <div className="f1">&nbsp;</div>
          </Wrap>
        </>
      )}
      <SettingsFooter
        showCancel
        bordered={!deletion}
        showConfirm
        confirmText={
          collection?.id
            ? 'CONFIRM'
            : `ADD ${touchpoint ? 'TOUCHPOINTS' : 'FILES'}`
        }
        onCancel={
          deletion && !showDelete
            ? () => {
                setDeletion(false);
                setError(undefined);
              }
            : onCancel
        }
        onConfirm={collection.name ? confirm : undefined}
      />
      {error && (
        <Error className="error">An error occured! Please try again.</Error>
      )}
    </Spinner>
  );
};
