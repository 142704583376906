import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  redo,
  undo,
  reset,
  initPage,
  setSelected,
  doUpdatePage,
  insertSection,
  toggleEditMode,
} from '../store/page';
import { doGetUser } from '../store/user';

import { Button, Spinner, Modal } from '../ui';
import Component from './editor/Component'; // eslint-disable-line
import NavCtl from './editor/NavCtl';
import PlaceHolder from './editor/PlaceHolder';
import { toast } from '../ui/Toast';

const Background = styled.div`
  background-color: #ffffff;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const MainWrap = styled.div`
  height: 100%;
  background-color: #ffffff;
  overflow: auto;

  & > div > header {
    padding: 0;
    height: 60px;
    box-sizing: border-box;
    position: fixed;
    top: 111px;
    width: inherit;
    z-index: 10000;
    display: flex;
    align-items: center;
    background-color: #ffffff;

    .flex {
      flex: 1;
    }

    .right {
      float: right;
    }
  }

  & > div > main {
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-top: ${({ isAdmin }) => (isAdmin ? 60 : 0)}px;
  }

  ${({ editMode }) =>
    editMode &&
    `
    @media only screen and (max-width: 1496px) {
      padding-left: ${270 - Math.abs(window.innerWidth - 960 - 270) + 32}px;
    }

    background-color: #f0f0f0;

    & > div > header {
      background-color: #f0f0f0;
      width: 983px;
      padding-right: 23px;
    }

    & > div > main {
      box-sizing: border-box;
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      padding: 12px;
      margin-bottom: 32px;

      & > div {
        flex-grow: 1;
      }
    }
  `}
`;

const Content = styled.div`
  width: 960px;
  margin: 0 auto;
`;

const Sep = styled.div`
  display: block;
  width: 980px;
  height: 1px;
  background-color: #d2d2d2;
  position: absolute;
  bottom: -1px;
  left: -10px;
`;

const Split = styled.span`
  display: inline-block;
  height: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
  margin-right: 12px;
  top: 5px;
`;

const selector = ({ user, page, global: { loading, brand } }) => ({
  ...user,
  page,
  brand,
  loading,
});

export default ({ slug }) => {
  const {
    page,
    user: { brand: bbrand, id } = {},
    loading,
    brand: gbrand,
  } = useSelector(selector);

  const brand = bbrand || gbrand || {};

  const [confirm, setConfirm] = useState(false);

  const dispatch = useDispatch();

  const deselect = (e) => {
    if (!page.selected) return;
    if (!e.target.nodeName.match(/(label|input)/i))
      dispatch(setSelected(undefined));
  };

  const onUpdate = (error) => {
    if (error) toast.error('Update failed!', 'Please try again later.');
    else {
      dispatch(doGetUser(id));
      toast('Success', 'Page updated successfully.');
    }
  };

  const insert = (e) => {
    e.preventDefault();

    if (!page?.editMode) {
      dispatch(toggleEditMode(true));
    }

    dispatch(
      insertSection({
        section: 0,
      })
    );
  };

  const handleCancel = () => {
    if (page.past.length === 0) {
      dispatch(toggleEditMode());
      return;
    }
    setConfirm(true);
  };

  useEffect(() => {
    dispatch(initPage({ brand, slug }));
    return () => dispatch(reset());
  }, []); // eslint-disable-line

  return (
    <Spinner spin={loading}>
      <MainWrap
        editMode={page?.editMode}
        onClick={deselect}
        loading={loading ? 'true' : ''}
        isAdmin={brand.role?.match(/(admin|owner)/i) && true}
      >
        {page?.editMode && <NavCtl />}
        <Content>
          {brand.role?.match(/(admin|owner)/i) && (
            <header>
              {!page?.editMode && <Background className="bg" />}
              {page?.editMode ? (
                <>
                  <div className="flex">
                    <Button
                      disabled={page.past.length === 0}
                      icon="undo-sml"
                      text
                      onClick={() => dispatch(undo())}
                    >
                      Undo
                    </Button>
                    <Split />
                    <Button
                      onClick={() => dispatch(redo())}
                      iconRight="redo-sml"
                      text
                      disabled={page.future.length === 0}
                    >
                      Redo
                    </Button>
                  </div>
                  <div className="flex">
                    <Button
                      disabled={
                        loading ||
                        JSON.stringify(page?.sections) ===
                          JSON.stringify(page?.sectionsRef)
                      }
                      icon="tick"
                      onClick={() =>
                        dispatch(
                          doUpdatePage(
                            {
                              brandId: brand?.id,
                              slug: page?.slug,
                              sections: page?.sections,
                            },
                            onUpdate
                          )
                        )
                      } // eslint-disable-line
                      className="right"
                    >
                      {loading ? 'SAVING...' : 'SAVE'}
                    </Button>
                    <span className="right"> &nbsp;</span>
                    <Button
                      text
                      icon="cross-sml"
                      disabled={loading}
                      onClick={handleCancel}
                      className="right"
                    >
                      CANCEL
                    </Button>
                  </div>
                  <Sep />
                </>
              ) : (
                <div className="flex">
                  <Button
                    onClick={() => {
                      localStorage.removeItem(`insider-${id}`, true);
                      dispatch(toggleEditMode());
                    }}
                    className="right"
                  >
                    EDIT PAGE
                  </Button>
                </div>
              )}
            </header>
          )}

          <main>
            {(() => {
              if (!page.sections || page.sections.length === 0)
                return (
                  <PlaceHolder
                    onClick={insert}
                    height={128}
                    placeholder="Add New Section"
                  />
                );
              return page.sections.map((s, i) => (
                <Component
                  key={`section-${i}`}
                  props={s}
                  section={i}
                  readOnly={!brand.role?.match(/(admin|owner)/i)}
                />
              ));
            })()}
          </main>
        </Content>
        {page?.editMode && confirm && (
          <Modal
            width={512}
            visible={confirm}
            onCancel={() => setConfirm(false)}
            onConfirm={() => {
              dispatch(toggleEditMode());
              setConfirm(false);
            }}
            cancelText="CANCEL"
            confirmText="LEAVE PAGE"
            showCancel
            showConfirm
          >
            <h4>Leaving BrandBox?</h4>
            <p style={{ marginBottom: 0 }}>
              If you leave the page now, your changes will not be saved.
            </p>
          </Modal>
        )}
      </MainWrap>
    </Spinner>
  );
};
