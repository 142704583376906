import React from 'react';
import styled from 'styled-components';

const Ctl = styled.div`
  box-sizing: border-box;
`;

export default () => {
  return (
    <Ctl>
      <section>
        <p>Options not available.</p>
      </section>
    </Ctl>
  );
};
