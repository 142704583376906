import React from 'react';
import styled from 'styled-components';

import { AiOutlineFilePdf, AiOutlineFileText } from 'react-icons/ai';

import { Icon, Button, FileSize } from '../ui';

const Box = styled.div`
  box-sizing: border-box;
  width: 225px;
  height: 180px;
  font-size: 12px;
`;

const MediaBox = styled(Box)`
  border: 1px solid #d2d2d2;
  background-color: #ffffff;

  img {
    height: 100%;
  }
  object {
    width: 100%;
    height: inherit;
    object-fit: contain;
    box-sizing: border-box;
    padding: 12px;
  }
  video {
    width: 100%;
    height: 100%;
  }

  audio {
    position: absolute;
    bottom: 0;
  }

  .main {
    position: relative;
    box-sizing: border-box;
    height: 135px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      fill: #ffffff;
      width: 64px;
      height: 64px;
    }

    .icon {
      fill: rgba(0, 0, 0, 0.3);
      width: 40px;
      height: 40px;
    }

    .toggle {
      overflow: auto;

      button {
        position: absolute;
        right: -2px;
        top: 4px;
        padding: 2px 2px !important;
        margin: 0 !important;
        & > svg {
          width: 32px;
          height: 16px;
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      & > .menu {
        display: none;
        position: absolute;
        box-sizing: border-box;
        top: 24px;
        right: 10px;
        width: 130px;
        padding: 12px;
        padding-bottom: 24px;
        background-color: #ffffff;
        border: 1px solid #d2d2d2;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);

        button {
          position: relative;
          font-size: 12px;
          margin: 0 !important;
          padding: 0 !important;
          font-weight: 300;
          &:not(:last-child) {
            margin-bottom: 16px !important;
          }
          text-align: left;
        }

        svg {
          fill: #000000;
          background-color: transparent;
          width: 12px;
          height: 12px;
          border-radius: 0;
        }
      }

      &:hover {
        .menu {
          display: block;
        }
      }
    }
  }

  ${({ small }) =>
    small &&
    `
    width: 190px;
    height: 150px;
    .main { height: 105px; }
  `}

  footer {
    position: relative;
    font-size: 12px;
    padding: 2px 15px;

    p {
      margin-bottom: -8px;
    }

    .mute {
      color: #999999;
    }

    .download {
      position: absolute;
      top: 9px;
      right: 12px;
      padding: 0;
      margin: 0;
      &:hover {
        text-decoration: none;
        border: 0;
      }
    }
  }
`;

const Triangle = styled.span`
  display: inline-block;
  position: absolute;
  top: -5px;
  right: 8px;
  width: 10px;
  height: 5px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px dotted transparent;
    border-right: 5px dotted transparent;
    border-bottom: 5px solid #d2d2d2;
    position: absolute;
  }
  &:after {
    border-bottom: 5px solid #ffffff;
    top: 2px; /* offset sets width of border */
  }
`;

export default ({
  file: f,
  small,
  onEdit = () => {},
  download,
  readOnly,
  onDelete = () => {},
  onCollection = () => {},
  showCollection,
}) => {
  // eslint-disable-next-line
  let mediaSource = f.urls.original;
  const ctype = (() => {
    if (f.file_name.match(/svg/i)) return 'svg';
    if (f.file_type.match(/image/i)) return 'image';
    if (f.file_type.match(/video/i)) return 'video';
    if (f.file_type.match(/audio/i)) return 'audio';
    if (f.file_type.match(/font/i) || f.file_name.match(/(otf|ttf|woff)/i))
      return 'font';
    if (
      f.file_type.match(/octet/i) &&
      f.file_name.match(/(mov|mp4|avi|flv|webm|ogg)/i)
    )
      return 'video';
    if (f.file_type.match(/txt/i) || f.file_name.match(/\.txt/i)) return 'txt';
    if (f.file_type.match(/pdf/i)) return 'pdf';
    return '';
  })();

  if (ctype.match(/image|svg/i)) {
    mediaSource = f.urls.thumbnail || f.urls.original;
  }

  return (
    <>
      <MediaBox small={small ? 'small' : ''} key={f.id}>
        <div className="main">
          {(() => {
            if (ctype === 'svg')
              return <object data={mediaSource}>{f.file_name}</object>;
            if (ctype === 'image')
              return <img src={mediaSource} alt={f.file_name} />;
            if (ctype === 'pdf') return <AiOutlineFilePdf className="icon" />;
            if (ctype === 'font')
              return <Icon className="icon" name="typography" />;
            if (ctype === 'video')
              return (
                <video controls>
                  <source src={`${mediaSource}#t=10`} />
                </video>
              );
            if (ctype === 'audio')
              return (
                <audio controls>
                  <source src={mediaSource} />
                </audio>
              );
            return <AiOutlineFileText className="icon" />;
          })()}
          {!readOnly && (
            <div className="toggle">
              <Button icon="more" text />
              <div className="menu">
                <Triangle />
                <Button
                  block
                  size="small"
                  text
                  icon="edit"
                  onClick={() => onEdit(f)}
                >
                  Edit
                </Button>
                {showCollection && (
                  <Button
                    block
                    size="small"
                    text
                    icon="add-to-collection"
                    onClick={() => onCollection(f)}
                  >
                    Collections
                  </Button>
                )}
                <Button
                  block
                  size="small"
                  text
                  icon="delete"
                  onClick={() => onDelete(f)}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
        <footer>
          <p>
            {f.file_name.slice(0, 23)}
            {f.file_name.length > 23 && '...'}
          </p>
          <p className="mute">
            {f.file_name.split('.').pop().toUpperCase()} -{' '}
            <FileSize size={f.file_size} />
          </p>
          {download && (
            <a
              href={`/api/v1/file?url=${window.encodeURIComponent(
                f.urls.original
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="download brand-color"
              download={f.file_name}
            >
              <Icon name="download" />
            </a>
          )}
        </footer>
      </MediaBox>
    </>
  );
};
