import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { DropDown, Switch } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const ColorSelect = styled(DropDown)`
  margin: 5px 0;
`;

const selector = ({ user }) => user;

const selectSizing = [
  { label: 'Disable heading', value: 0 },
  { label: 'Small heading', value: 1 },
  { label: 'Regular heading', value: 2 },
  { label: 'Large heading', value: 3 },
];

export default (attrs) => {
  const { props } = attrs;
  const { id, secondaryId, position = false, size = 0 } = props;
  const dispatch = useDispatch();

  const { user } = useSelector(selector);
  const coreColors = [
    ...user.brand.colors.map((c) => ({
      label: c.name,
      value: c.id,
      color: c.color,
    })),
  ];

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onPositionChange = (v) => {
    update({ position: v });
  };

  const onSizeChange = (v) => {
    update({ size: v });
  };

  const onColor = (v) => {
    const p = user.brand.colors.find((c) => c.id === v);
    if (!p) return;
    update(p);
  };

  const onSecondaryColor = (v) => {
    update({ secondaryColor: v });
    const p = user.brand.colors.find((c) => c.id === v);
    if (!p) return;
    update({
      secondaryColor: p.color,
      secondaryId: p.id,
      secondaryName: p.name,
      secondaryCmyk: p.cmyk,
    });
  };

  return (
    <Ctl>
      <section>
        <h3>Define gradient</h3>
        <p>Create a gradient using two existing colours.</p>
        <ColorSelect
          onSelect={onColor}
          items={coreColors}
          defaultValue={id}
          dropDownType="color"
          disabled={false}
          block
          size="small"
        />
        <ColorSelect
          onSelect={onSecondaryColor}
          items={coreColors}
          defaultValue={secondaryId}
          dropDownType="color"
          disabled={false}
          block
          size="small"
        />
      </section>

      <section>
        <h3>Heading Options</h3>
        <p>Enable heading, choose its size and specify its placement.</p>
        <DropDown
          items={selectSizing}
          onSelect={onSizeChange}
          defaultValue={size}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        {size !== 0 && (
          <Switch
            size="small"
            block
            defaultValue={position}
            onChange={onPositionChange}
            leftText="Below"
            rightText="Above"
          />
        )}
      </section>
    </Ctl>
  );
};
