///////////////////////////////////////////////////////
//
// Card
//
// Brandbox specific card component
//
///////////////////////////////////////////////////////
import React from 'react';
import styled, { css } from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';

import { colors } from '../config';

const Card = styled.div`
  overflow: visible;
  position: relative;
  width: ${({ width: w }) => (w && `${parseInt(w, 10)}px`) || '100%'};
  height: ${({ height: h }) => (h && `${parseInt(h, 10)}px`) || 'auto'};

  background-color: ${colors.card.backgroundColor};
  border: 1px solid ${colors.card.borderColor};

  box-sizing: border-box;
  margin-bottom: 32px;

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        border-color: ${colors.card.borderHoverColor};
      }
    `}

  ${({ nobg }) =>
    nobg &&
    css`
      & {
        background-color: transparent;
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        z-index: 3;
        margin: -16px 0 0 -16px;
        animation: spin 0.5s linear infinite;
        opacity: 0.75;
        font-weight: bold;
      }

      &::before {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        background-color: white;
        color: #aaa;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        z-index: 2;
        align-items: center;
        justify-content: center;
      }
    `}
`;

const CardHeader = styled.div`
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid ${colors.card.borderColor};
`;

const CardBody = styled.div`
  padding: 32px;
`;

export default (props) => {
  const { title, children, loading } = props;

  let loadingText = '';
  if (loading) loadingText = 'loading';

  return (
    <Card {...props} loading={loadingText}>
      {loading && <AiOutlineLoading />}
      {title && <CardHeader>{title}</CardHeader>}
      <CardBody>{children}</CardBody>
    </Card>
  );
};
