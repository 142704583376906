import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DropDown, Switch, Input } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const selectSizing = [
  { label: 'Disable heading', value: 0 },
  { label: 'Small heading', value: 1 },
  { label: 'Regular heading', value: 2 },
  { label: 'Large heading', value: 3 },
];

export default (attrs) => {
  const { props } = attrs;
  const {
    imageSizeOption = 0,
    position = false,
    size = 0,
    width = 200,
    height = 200,
    pHeight = 20,
    pWidth = 20,
  } = props;
  const dispatch = useDispatch();

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onPositionChange = (v) => {
    update({ position: v });
  };

  const onSizeChange = (v) => {
    update({ size: v });
  };

  const onSizeOptionChange = (v) => {
    update({ imageSizeOption: v, height, width });
  };

  const onDimensionChange = (e) => {
    const { value } = e.target;
    if (value.match(/[^0-9]/g)) {
      return;
    }

    if (!imageSizeOption) {
      update({ width: value });
      return;
    }
    update({ height: value });
  };

  const onPrintChangeChange = (e) => {
    const { value } = e.target;
    if (value.match(/[^0-9]/g)) {
      return;
    }

    if (!imageSizeOption) {
      update({ pWidth: value });
      return;
    }
    update({ pHeight: value });
  };

  return (
    <Ctl>
      <section>
        <h3>Minimum size options</h3>
        <p>
          Define the minimum size for your logo using digital and print
          dimensions
        </p>
      </section>

      <Switch
        size="small"
        block
        defaultValue={imageSizeOption}
        onChange={onSizeOptionChange}
        leftText="Width"
        rightText="Height"
      />
      <Input
        block
        size="small"
        value={!imageSizeOption ? width : height}
        onChange={onDimensionChange}
        placeholder="Digital"
      />
      <Input
        block
        size="small"
        value={!imageSizeOption ? pWidth : pHeight}
        onChange={onPrintChangeChange}
        placeholder="Print"
      />

      <section>
        <h3>Heading Options</h3>
        <p>Enable heading, choose its size and specify its placement</p>
        <DropDown
          items={selectSizing}
          onSelect={onSizeChange}
          defaultValue={size}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        <br />
        {size !== 0 && (
          <Switch
            size="small"
            block
            defaultValue={position}
            onChange={onPositionChange}
            leftText="Below"
            rightText="Above"
          />
        )}
      </section>
    </Ctl>
  );
};
