import { get, post, put } from './client';

export const getPlans = () => {
  return get('/plans');
};

export const addCard = (bid, payload) => {
  return post(`/brands/${bid}/cards`, payload);
};

export const subscribe = (bid, payload, coupon = '') => {
  return post(`/brands/${bid}/subscriptions?coupon=${coupon}`, payload);
};

export const updateSubscription = (bid, sid, payload) => {
  return put(`/brands/${bid}/subscriptions/${sid}`, payload);
};

export default {};
