import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
`;
const ModalCard = styled.div`
  position: relative;
  z-index: 10;
  width: 657px;
  height: 560px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  padding: 39px 87px 42px;
  box-sizing: border-box;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  background-color: rgba(248, 248, 248, 0.85);
`;

const Portal = ({ children }) => {
  const element = document.getElementById('root');
  const el = document.createElement('div');
  const elementParent = element.parentNode;
  el.setAttribute('id', 'modal');

  useEffect(() => {
    elementParent.insertBefore(el, element.nextSibling);

    return () => {
      elementParent.removeChild(el);
    };
  }, [el, element, elementParent]);

  return createPortal(children, el);
};

export default ({ children, open = false }) => {
  return (
    <Portal>
      {open && (
        <ModalWrapper>
          <ModalCard>{children}</ModalCard>
          <Background />
        </ModalWrapper>
      )}
    </Portal>
  );
};
