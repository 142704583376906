import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DropDown, Button, Input, Modal, Tooltip } from '../../ui';
import { updateItem } from '../../store/page';
import MediaBrowser from '../MediaBrowser';

const Ctl = styled.div`
  box-sizing: border-box;

  .add {
    padding: 0;
    margin-top: 12px;
    font-size: 12px;
    font-weight: normal;
  }
`;

const TextSelect = styled(DropDown)`
  margin-bottom 12px;
`;

const DlButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  align-items: center;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  svg {
    right: 13px;
    fill: #6d6d6d;
  }
`;

const selectTextSize = [
  { label: 'Large body text', value: 15 },
  { label: 'Regular body text', value: 12 },
];

const selectHeadingSize = [
  { label: 'Disable heading', value: 0 },
  { label: 'Large heading', value: 3 },
  { label: 'Regular heading', value: 4 },
  { label: 'Small heading', value: 5 },
  { label: 'Smallest heading', value: 6 },
];

const headingSize = [0, 28, 22, 15, 12];

const selectButtonType = [
  { label: 'Button disabled', value: 0 },
  { label: 'Go to page', value: 'arrow-right' },
  { label: 'Asset download', value: 'download' },
];

const selector = ({ user }) => user;

export default (attrs) => {
  const { props } = attrs;
  const { size, heading, button } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(selector);

  const [open, setOpen] = useState();
  const [assetTooltip, setAssetooltip] = useState(
    localStorage.getItem(`tooltip-asset-${user.id}`)
  );

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onTextSize = (value) => {
    update({
      size: value,
    });
  };

  const onHeadingSize = (value) => {
    if (value === 0)
      return update({
        heading: undefined,
      });

    update({
      heading: {
        text: 'Title', // placeholder
        ...heading,
        level: value,
        size: headingSize[value],
      },
    });
  };

  const onSelectButtonType = (value) => {
    if (value === 0)
      return update({
        button: undefined,
      });

    update({
      button: {
        type: value,
        text: '',
        url: '',
      },
    });
  };

  const onButtonChange = (value, key) => {
    update({
      button: {
        ...button,
        [key]: value,
      },
    });
  };
  return (
    <Ctl>
      <section>
        <h3>Heading Options</h3>
        <p>Enable heading and choose its size</p>
        <TextSelect
          items={selectHeadingSize}
          onSelect={onHeadingSize}
          defaultValue={heading?.level || 0}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
      </section>

      <section>
        <h3>Body Text Size</h3>
        <TextSelect
          items={selectTextSize}
          onSelect={onTextSize}
          defaultValue={size}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
      </section>

      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <h3>Button</h3>
        </div>
        <p>Enable action button and add a link if necessary</p>
        <TextSelect
          items={selectButtonType}
          onSelect={onSelectButtonType}
          defaultValue={button?.type || 0}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        {button && button.type === 'arrow-right' && (
          <Input
            block
            placeholder="E.g https://example.com"
            error={
              button.url &&
              !button.url.match(
                /^((ftp|http|https):\/\/)?([A-z]+)\.([A-z]{2,})/
              )
            }
            size="small"
            value={button.url}
            onChange={(e) => onButtonChange(e.target.value, 'url')}
          />
        )}
        {button && button.url && button.type === 'download' && (
          <DlButton
            className="add"
            onClick={() => setOpen(true)}
            text
            block
            size="small"
            iconRight="add"
          >
            <span
              style={{
                textOverflow: 'ellipsis',
                display: 'inline-block',
                width: '90%',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {button.url.split('/').pop()}
            </span>
          </DlButton>
        )}
        {button && !button.url && button.type === 'download' && (
          <Button
            className="add"
            onClick={() => setOpen(true)}
            text
            size="small"
            icon="add"
          >
            Select file
          </Button>
        )}
        {assetTooltip && (
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Tooltip
              text="Switch to 'Asset download'"
              onDismiss={() => {
                setAssetooltip(undefined);
                localStorage.removeItem(`tooltip-asset-${user.id}`, true);
              }}
            />
          </div>
        )}
        <Modal visible={open}>
          <MediaBrowser
            category="basics"
            onCancel={() => setOpen(false)}
            onConfirm={(f) => {
              setOpen(false);
              onButtonChange(f.urls.original, 'url');
              setAssetooltip(undefined);
              localStorage.removeItem(`tooltip-asset-${user.id}`, true);
            }}
          />
        </Modal>
      </section>
    </Ctl>
  );
};
