import React from 'react';
import styled from 'styled-components';

import Component from './Component'; // eslint-disable-line
import Column from './Column';

const Row = styled.div`
  display: flex;
  min-height: auto;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default ({
  row,
  edit,
  section,
  columns,
  newItem,
  readOnly,
  dragTarget,
  dropTarget,
}) => {
  return (
    <Row edit={edit}>
      {columns.map((column, columnIndex) => (
        <Column
          row={row}
          key={`column-${section}-${row}-${columnIndex}`}
          edit={edit}
          span={column.span}
          count={column.items.length}
          ccount={columns.length}
          column={columnIndex}
          newItem={newItem}
          section={section}
          dragTarget={dragTarget}
          dropTarget={dropTarget}
          isWelcome={(() => {
            if (column.items[0]?.type === 'welcomeintro') return true;
            return false;
          })()}
          readOnly={readOnly}
        >
          {column.items.map((item, itemIndex) => (
            <Component
              readOnly={readOnly}
              ccount={columns.length}
              key={`item-${section}-${row}-${columnIndex}-${itemIndex}`}
              props={item}
              columnCount={column.items.length}
              {...{
                section,
                row,
                column: columnIndex,
                item: itemIndex,
              }}
            />
          ))}
        </Column>
      ))}
    </Row>
  );
};
