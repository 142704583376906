import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Page from './pages/Page';
import NotFound from './pages/NotFound';
import Media from './pages/Media';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Verify from './pages/Verify';
import Signup from './pages/Signup';
import Request from './pages/Request';
import Password from './pages/Password';
import Settings from './pages/Settings';
import Touchpoint from './pages/Touchpoint';
import PageLayout from './components/layouts/PageLayout';
import BrandLayout from './components/layouts/BrandLayout';
import Touchpoints from './pages/Touchpoints';
import ResetPassword from './pages/ResetPassword';
import DefaultLayout from './components/layouts/DefaultLayout';

import Listener from './Listener';

const App = () => {
  return (
    <Router>
      <Listener>
        <Switch>
          <Route path="/settings/:page?">
            <PageLayout>
              <Settings />
            </PageLayout>
          </Route>

          <Route path="/media/:collection?">
            <PageLayout>
              <Media />
            </PageLayout>
          </Route>

          <Route path="/touchpoints/e/:touchpoint?">
            <PageLayout>
              <Touchpoint />
            </PageLayout>
          </Route>

          <Route path="/touchpoints/:page?/:collection?">
            <PageLayout>
              <Touchpoints />
            </PageLayout>
          </Route>

          <Route exact path="/signup">
            <DefaultLayout>
              <Signup />
            </DefaultLayout>
          </Route>

          <Route exact path="/verify/:token">
            <Verify />
          </Route>

          <Route exact path="/login">
            <BrandLayout>
              <Login />
            </BrandLayout>
          </Route>

          <Route exact path="/request">
            <BrandLayout>
              <Request />
            </BrandLayout>
          </Route>

          <Route exact path="/password">
            <BrandLayout>
              <Password />
            </BrandLayout>
          </Route>

          <Route exact path="/forgot">
            <BrandLayout>
              <Forgot />
            </BrandLayout>
          </Route>

          <Route exact path="/reset-password">
            <DefaultLayout>
              <ResetPassword />
            </DefaultLayout>
          </Route>

          <Route exact path="/notfound">
            <NotFound />
          </Route>

          <Route exact path="/:page">
            <PageLayout>
              <Page />
            </PageLayout>
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Listener>
    </Router>
  );
};

export default App;
