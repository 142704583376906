import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';

import useIntersection from './UseIntersection';

const LazyLoadWrap = styled.div`
  clear: both;
  width: 100%;
  text-align: center;
  & > svg {
    animation: spin 0.5s linear infinite;
  }
`;

const LazyLoad = ({ onView = () => {} }) => {
  const ref = useRef();
  const inView = useIntersection(ref, '-150px');
  const [fetching, setFetching] = React.useState(false);
  let isCancelled = false; // This flag will help us to handle cancellation

  useEffect(() => {
    if (inView && !fetching) {
      setFetching(true);

      // Ensure onView returns a promise

      onView(() => {
        // Only set state if the component is still mounted
        if (!isCancelled) {
          setFetching(false);
        }
      });
    }

    // Cleanup function
    return () => {
      isCancelled = true; // On unmount, set cancellation flag
    };
  }, [inView, fetching, onView]); // Don't forget dependencies

  return (
    <LazyLoadWrap ref={ref}>
      <AiOutlineLoading />
    </LazyLoadWrap>
  );
};

export default LazyLoad;
