import React, { useState } from 'react';
import styled from 'styled-components';

import { Spinner, TinySwitch } from '../ui';
import SettingsFooter from './editor/SettingsFooter';

const Desc = styled.textarea`
  width: 100%;
  border: 0;
  font-size: 12px;
  font-family: Roboto;
  color: #444;
  box-sizing: border-box;
  padding: 10px;
  min-height: 100px;
  margin-top: -20px;
  border: 1px solid #e9e9e9;
  line-height: 1.5em;
`;

export default ({
  description = {
    show: true,
    text: 'description...',
  },
  title = 'Description Bo',
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const [loading] = useState(false);
  const [dsc, setDsc] = useState({ ...description });

  return (
    <Spinner spin={loading}>
      <h3 style={{ fontWeight: 'normal', overflowWrap: 'break-word' }}>
        {title}
      </h3>
      <p>Description</p>
      <p>
        <Desc
          placeholder="Enter description"
          onChange={(e) => {
            setDsc({
              ...dsc,
              text: e.target.value,
            });
          }}
          style={{ fontWeight: '300', fontSize: 12, color: '#444444' }}
          defaultValue={dsc.text}
        />
        <br />
        <TinySwitch
          checked={!dsc.show}
          label="Hide description"
          onChange={(v) => {
            setDsc({
              ...dsc,
              show: !v,
            });
          }}
        />
      </p>
      <SettingsFooter
        showCancel
        bordered
        showConfirm
        onCancel={onCancel}
        onConfirm={() => {
          onConfirm(dsc);
        }}
      />
    </Spinner>
  );
};
