import { useState, useEffect } from 'react';

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (element.current) observer.observe(element.current);

    return () => observer.unobserve(element.current); // eslint-disable-line
  }, []); // eslint-disable-line

  return isVisible;
};

export default useIntersection;
