import React from 'react';
import styled from 'styled-components';

import Icon from './Icon';
import Button from './Button';

const Tooltip = styled.div`
  border-radius: 11.5px;
  background-color: #4b9aff;
  height: 17px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  z-index: 1;

  button {
    padding: 0 15px 0 5px;
    width: 15px;
    height: 15px;
    svg {
      fill: #ffffff;
      width: 15px;
      width: 15px;
    }
  }
`;

export default ({
  text = '',
  onDismiss,
  icon = '',
  className = '',
  style = {},
}) => {
  return (
    <Tooltip className={className} style={style}>
      {icon && (
        <Icon
          name="search"
          color="#ffffff"
          size={15}
          style={{ padding: '0 8px 0 2px' }}
        />
      )}
      <span>{text}</span>
      {onDismiss && <Button text icon="cross-sml" onClick={onDismiss} />}
    </Tooltip>
  );
};
