import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useParams, Link, useHistory } from 'react-router-dom';

import { setBackground } from '../store/page';
import { setBrandProps } from '../store/user';
import { updateBrand as ub } from '../svc/brand';
import {
  getAll,
  setTouchpoints,
  getCollections,
  setCollections,
  deleteTouchpoint,
} from '../store/touchpoint';

import { Modal, Button, Spinner, Icon } from '../ui';
import { toast } from '../ui/Toast';
import Empty from '../components/Empty';
import Sorter from '../ui/Sorter';
import TouchpointBox from '../components/TouchpointBox';
import EditFile from '../components/EditFile';
import DeleteTouchpoint from '../components/DeleteTouchpoint';
import MediaSearch from '../components/MediaSearch';
import NewCollection from '../components/NewCollection';
import CollectionBox from '../components/CollectionBox';
import CollectionTouchpointBrowser from '../components/CollectionTouchpointBrowser';
import FileCollections from '../components/FileCollections';
import TouchpointBrowser from '../components/TouchpointBrowser';
import DescriptionBox from '../components/DescriptionBox';

const Spin = styled.div`
  height: ${window.innerHeight - 224}px;
  width: 100%;
  position: relative;
  margin-top: -32px;
`;

const SubNav = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 32px 0;

  .left {
    width: 50%;
    flex: 1;
  }
  .right {
    width: 50%;
    flex: 1;
    margin-bottom: 0 !important;
    .uploader {
      width: 173px;
      height: 30px;
      border-radius: 3px;
      float: right;
      background-color: black;
      span {
        color: white !important;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }

  a,
  a:active {
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-right: 18px;
    color: #888;
    outline: none;

    &.active {
      color: #000000;
      font-weight: bold;
    }
  }
`;

const Content = styled.div`
  width: 960px;
  margin: 0 auto;
  clear: both;
  position: relative;

  .clear {
    clear: both;
  }

  h2 {
    button {
      padding: 0;
      margin-left: 6px;
    }
    svg {
      fill: #000000;
    }
  }

  h4 {
    position: relative;
    font-weight: normal;
    clear: both;

    .count {
      font-family: 'Roboto';
      display: inline-block;
      position: absolute;
      bottom: -6px;
      font-size: 12px;
      font-weight: normal;
      right: 0;
      letter-spacing: 0.5px;
      .mute {
        color: #b3b3b3;
      }
      svg {
        fill: #000000;
        position: relative;
        top: 3px;
      }
    }
  }

  &:not(:first-child) {
    padding-top: 32px;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    font-weight: normal;
  }

  .right {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 32px;
  }

  .media {
    position: relative;
    h3 {
      margin-bottom: 32px;
      font-size: 23px;

      button {
        position: relative;
        top: -2px;
        margin-left: 16px;
        svg {
          fill: #222;
        }
      }
    }
  }

  .media > div {
    float: left;
    &:not(:nth-child(4n)) {
      margin-right: 20px;
    }
    margin-bottom: 20px;
  }

  a,
  a:hover {
    text-decoration: none;
    border-bottom: none;
  }

  .back {
    margin: 0;
    font-size: 12px;
    svg {
      margin-right: 6px;
    }
    a,
    a:hover {
      color: #000000;
    }
    span {
      position: relative;
      top: -3px;
    }
  }
`;

const ContentHead = styled.div`
  padding: 32px 0;
  background-color: #ffffff;
  margin-bottom: 32px;
  border-bottom: 1px solid #f0f0f0;
`;

const Wrap = styled.div`
  padding-bottom: 32px;
  overflow: auto;
`;

const selector = ({ user, touchpoint, global }) => ({
  ...user,
  ...touchpoint,
  gbrand: global.brand,
});

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collection, page } = useParams();

  const { user, touchpoints, collections, gbrand } = useSelector(selector);
  const brand = user?.brand || gbrand;
  const currentUser = brand?.users?.find((u) => u?.id === user?.id);

  const [search, setSearch] = useState(undefined);
  const [action, setAction] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [downloading, setDownloading] = useState(false);
  const [sortField, setSortField] = useState('created_at');
  const [ascending, setAscending] = useState(false);

  const desc = brand?.meta?.touchpoints_description || {
    show: true,
    text: 'Add description',
  };

  const sortValues = ['created_at'];
  const sortItems = ['Date added'];
  if (page === 'collections' && !collection) {
    sortValues.push(...['name', 'total_items']);
    sortItems.push(...['Name', 'Total items']);
  } else {
    sortValues.push('name');
    sortItems.push('Name');
  }

  const cancel = () => {
    setSelected(undefined);
    setAction(undefined);
  };

  const done = () => {
    cancel();
    dispatch(getAll(brand.id, collection, search));
  };

  useEffect(() => {
    dispatch(setBackground('#f8f8f8'));

    if (brand) {
      if (!search) {
        dispatch(setTouchpoints(undefined));
        dispatch(setCollections(undefined));
      }
      if (page === 'collections' && !collection) {
        dispatch(
          getCollections(brand.id, search, undefined, sortField, ascending)
        );
      } else {
        dispatch(
          getAll(brand.id, collection, search, undefined, sortField, ascending)
        );
      }
    }

    return () => {
      dispatch(setBackground(undefined));
      dispatch(setTouchpoints(undefined));
      dispatch(setCollections(undefined));
    };
  }, [
    brand,
    dispatch,
    page,
    collection,
    search,
    history,
    sortField,
    ascending,
  ]);

  if (!user && brand?.access === 0) {
    return <></>;
  }

  return (
    <>
      <MediaSearch onSearch={setSearch} />
      <ContentHead>
        <Content>
          {(() => {
            let label = 'Touchpoints';

            if ((page && page !== 'collections' && !collections) || search) {
              label = search ? 'searching...' : 'Touchpoints';
            }

            if (search) {
              if (collections)
                label = `${collections.length} ${
                  collections.length > 1 ? 'items' : 'item'
                } found for "${search}"`;
              if (touchpoints)
                label = `${touchpoints?.touchpoints?.length} ${
                  touchpoints?.touchpoints?.length > 1 ? 'items' : 'item'
                } found for "${search}"`;
            }

            return (
              <>
                <h2 className="brand-font-large brand-color-large">{label} </h2>
                <pre
                  style={{
                    color: '#444444',
                    fontSize: 12,
                    marginTop: 12,
                    lineHeight: 1.42,
                    fontWeight: 300,
                    width: 'fit-content',
                    maxWidth: 400,
                    position: 'relative',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {!search && desc.show && desc.text}
                  {!search && user && currentUser?.role === 'owner' && (
                    <Button
                      onClick={() => setAction(8)}
                      icon="edit"
                      text
                      size="small"
                      style={{
                        position: 'absolute',
                        top: -6,
                        right: -30,
                      }}
                    />
                  )}
                </pre>
              </>
            );
          })()}
        </Content>
      </ContentHead>
      {(() => {
        if (
          (!page && !touchpoints) ||
          (page && !collection && !collections) ||
          (page && collection && !touchpoints)
        ) {
          return (
            <Spin>
              <Spinner spin />
            </Spin>
          );
        }

        return (
          <Wrap>
            <Content>
              {!search && (
                <SubNav>
                  <div className="left">
                    {!touchpoints?.meta && (
                      <Link
                        className={!page || page === '' ? 'active' : ''}
                        to="/touchpoints"
                      >
                        All touchpoints
                      </Link>
                    )}
                    <Link
                      className={page && page?.match(/coll/) ? 'active' : ''}
                      to="/touchpoints/collections"
                    >
                      {touchpoints?.meta && (
                        <Icon
                          name="next-sml"
                          style={{
                            transform: 'scaleX(-100%)',
                            WebkitTransform: 'scaleX(-100%)',
                            position: 'relative',
                            top: 3,
                          }}
                        />
                      )}{' '}
                      Collections
                    </Link>
                    <span
                      style={{
                        position: 'relative',
                        marginRight: 20,
                        opacity: 0.3,
                      }}
                    >
                      |
                    </span>
                    <Sorter
                      items={sortItems}
                      onChange={(v) => setSortField(sortValues[v])}
                      defaultValue={sortValues.indexOf(sortField)}
                    />
                    <span
                      style={{
                        position: 'relative',
                        marginRight: 10,
                        opacity: 0.3,
                      }}
                    >
                      :
                    </span>
                    <Sorter
                      label=""
                      items={['Ascending', 'Descending']}
                      onChange={(v) => setAscending([true, false][v])}
                      defaultValue={[true, false].indexOf(ascending)}
                    />
                  </div>
                  {user &&
                    currentUser?.role.match(/admin|owner/i) &&
                    page &&
                    page === 'collections' &&
                    !collection &&
                    collections?.length > 0 && (
                      <div className="right">
                        <Button
                          onClick={() => setAction(5)}
                          icon="add-to-collection"
                          style={{ fontWeight: 'normal' }}
                        >
                          ADD NEW COLLECTION
                        </Button>
                      </div>
                    )}
                  {user &&
                    currentUser?.role.match(/admin|owner/i) &&
                    !page &&
                    !collection &&
                    touchpoints?.touchpoints?.length > 0 && (
                      <div className="right">
                        <Button
                          icon="add"
                          type="button"
                          onClick={() => history.push('/touchpoints/e/new')}
                        >
                          CREATE NEW TOUCHPOINT
                        </Button>
                      </div>
                    )}

                  {user &&
                    currentUser?.role.match(/admin|owner/i) &&
                    page?.match(/coll/) &&
                    collection &&
                    touchpoints?.touchpoints?.length > 0 && (
                      <div className="right">
                        <Button
                          icon="add"
                          type="button"
                          onClick={() => {
                            setSelected(touchpoints.meta);
                            setAction(4);
                          }}
                        >
                          ADD TOUCHPOINT TO COLLECTION
                        </Button>
                      </div>
                    )}
                </SubNav>
              )}
              <div className="media">
                {page?.match(/coll/) && collection && (
                  <h3 className="brand-font-large brand-color-large">
                    {touchpoints?.meta?.name}
                    {!search &&
                      brand?.role?.match(/(admin|owner)/i) &&
                      collection &&
                      collection !== 'collections' &&
                      collections && (
                        <Button
                          icon="edit"
                          text
                          onClick={() => {
                            setSelected(touchpoints.meta);
                            setAction(3);
                          }}
                        />
                      )}
                  </h3>
                )}
              </div>
              {(() => {
                if (page?.match(/coll/) && collections?.length === 0) {
                  if (
                    (!user && brand.access === 1) ||
                    !currentUser?.role.match(/admin|owner/i) ||
                    search
                  )
                    return (
                      <Empty
                        disabled
                        onClick={undefined}
                        title="Collection is empty"
                        sub="Collection doesn't have any item yet"
                      />
                    );
                  return (
                    <Empty
                      onClick={() => setAction(5)}
                      title="Ready to add some collections?"
                      icon="add-to-collection"
                      sub="Click to add new collection"
                    />
                  );
                }

                if (
                  collection &&
                  page?.match(/coll/) &&
                  touchpoints?.touchpoints?.length === 0
                ) {
                  if (
                    (!user && brand.access === 1) ||
                    !currentUser?.role.match(/admin|owner/i) ||
                    search
                  )
                    return (
                      <Empty
                        disabled
                        onClick={undefined}
                        title="Collection is empty"
                        sub="Collection doesn't have any touchpoint yet"
                      />
                    );

                  return (
                    <Empty
                      onClick={() => {
                        setSelected(touchpoints.meta);
                        setAction(4);
                      }}
                      title="Ready to add touchpoints?"
                      sub="Click to add touchpoints"
                    />
                  );
                }

                if (
                  !page?.match(/coll/) &&
                  touchpoints?.touchpoints?.length === 0
                ) {
                  if (
                    (!user && brand.access === 1) ||
                    !currentUser?.role.match(/admin|owner/i) ||
                    search
                  )
                    return (
                      <Empty
                        disabled
                        onClick={undefined}
                        title="Touchpoint is empty"
                        sub="No touchpoint has been added yet"
                      />
                    );

                  return (
                    <Empty
                      onClick={() => history.push('/touchpoints/e/new')}
                      title="Ready to add touchpoints?"
                      sub="Click to add new touchpoints"
                    />
                  );
                }

                if (page?.match(/fav/)) {
                  return (
                    <Empty
                      title="No favourites yet!"
                      icon="super"
                      sub="Click to add favourite"
                    />
                  );
                }
              })()}
              <div className="media">
                {(!page || (page.match(/coll/) && collection)) &&
                  touchpoints &&
                  touchpoints.touchpoints.map((f) => (
                    <TouchpointBox
                      download
                      readOnly={!brand?.role?.match(/(admin|owner)/i)}
                      touchpoint={f}
                      key={f.id}
                      onEdit={() => {
                        history.push(`/touchpoints/e/${f.id}`);
                      }}
                      onDownloading={setDownloading}
                      showCollection={collections?.length > 0}
                      onCollection={(s) => {
                        setSelected(s);
                        setAction(1);
                      }}
                      onDelete={(s) => {
                        setSelected(s);
                        setAction(2);
                      }}
                    />
                  ))}
              </div>
              {page?.match(/coll/) &&
                !collection &&
                collections &&
                collections.length > 0 && (
                  <div className="media">
                    {collections.map((c) => (
                      <CollectionBox
                        readOnly={!brand?.role?.match(/(admin|owner)/i)}
                        touchpoint
                        onDownloading={setDownloading}
                        count={c.total_items}
                        files={c.touchpoints.reduce((t, n) => {
                          if (n.thumbnails.length > 0)
                            return [...t, n.thumbnails[0]];
                          return t;
                        }, [])}
                        name={c.name}
                        cid={c.id}
                        bid={c.brand_id}
                        key={c.id}
                        onClick={() => {
                          history.push(`/touchpoints/collections/${c.id}`);
                        }}
                        onDelete={() => {
                          setSelected(c);
                          setAction(7);
                        }}
                        onEdit={() => {
                          setSelected(c);
                          setAction(3);
                        }}
                        download
                      />
                    ))}
                  </div>
                )}
            </Content>
            <Modal width={666} visible={action !== undefined || downloading}>
              {downloading && (
                <p
                  style={{
                    textAlign: 'center',
                    position: 'relative',
                    top: 17,
                  }}
                >
                  Downloading Please Wait...
                </p>
              )}
              {selected && action === 0 && (
                <EditFile
                  file={selected}
                  onUpdate={() => {
                    done();
                  }}
                  onCancel={cancel}
                />
              )}

              {selected && action === 1 && (
                <FileCollections
                  onCancel={cancel}
                  onCreate={() => {
                    done();
                  }}
                  file={selected}
                  onComplete={() => {
                    done();
                    toast('Success', 'File added to collection');
                  }}
                  collections={
                    (touchpoints && touchpoints.collections) ||
                    (collections && collections.collections) ||
                    []
                  }
                />
              )}

              {selected && action === 2 && (
                <DeleteTouchpoint
                  touchpoint={selected}
                  onDelete={(id) => {
                    cancel();
                    const p = {};
                    if (collection) p.cid = id;
                    else p.id = id;
                    dispatch(deleteTouchpoint(p));
                  }}
                  onCancel={cancel}
                />
              )}

              {selected && action === 3 && (
                <NewCollection
                  touchpoint
                  onCancel={cancel}
                  bid={brand.id}
                  collection={selected}
                  onDelete={() => {
                    cancel();
                    window.location.href = '/touchpoints/collections';
                  }}
                  onUpdate={() => {
                    done();
                  }}
                />
              )}

              {selected && action === 7 && (
                <NewCollection
                  touchpoint
                  onCancel={cancel}
                  bid={brand.id}
                  collection={selected}
                  showDelete
                  onDelete={() => {
                    cancel();
                    window.location.href = '/touchpoints/collections';
                  }}
                  onUpdate={() => {
                    done();
                  }}
                />
              )}

              {selected && collection && action === 4 && (
                <CollectionTouchpointBrowser
                  brandId={brand.id}
                  collectionId={collection}
                  onCancel={cancel}
                  onConfirm={() => {
                    done();
                  }}
                />
              )}

              {action === 5 && (
                <NewCollection
                  onCancel={cancel}
                  bid={brand.id}
                  touchpoint
                  onCreate={(id) => {
                    done();
                    history.push(`/touchpoints/collections/${id}`);
                  }}
                />
              )}

              {action === 6 && (
                <TouchpointBrowser
                  category="media"
                  onCancel={() => setAction()}
                  uploadOnly
                  title="Select a file to upload"
                  onConfirm={() => {
                    setAction();
                    dispatch(getAll(brand.id, collection, search));
                  }}
                />
              )}

              {action === 8 && (
                <DescriptionBox
                  onCancel={() => setAction()}
                  title="Touchpoints"
                  description={desc}
                  onConfirm={(dsc) => {
                    const payload = {
                      online: brand?.online,
                      access: brand?.access,
                      meta: { ...brand?.meta, touchpoints_description: dsc },
                    };
                    dispatch(setBrandProps(payload));
                    ub(brand.id, payload);
                    setAction();
                  }}
                />
              )}
            </Modal>
          </Wrap>
        );
      })()}
    </>
  );
};
