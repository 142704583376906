import { createSlice } from '@reduxjs/toolkit';
import {
  signup,
  resend,
  login,
  verify,
  reset,
  update,
  checkEmail,
} from '../svc/auth';

import { setStyles as st } from './brand';
import { getUser, updatePassword, passwordReset } from '../svc/user';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    step: 1,
    credential: {},
    errorMessage: null,
    brandName: '',
    brandProperties: {
      colors: [],
      fonts: [],
    },
  },

  reducers: {
    setBrandName: (state, { payload }) => {
      state.brandName = payload;
    },

    setBrandUsers: (state, { payload }) => {
      state.user.brand.users = payload;
    },

    setBrandStatus: (state, { payload }) => {
      state.user.brand.online = payload.online;
      state.user.brand.access = payload.access;
      state.user.brand.sub_domain = payload.sub_domain;
    },

    setBrandRequests: (state, { payload }) => {
      state.user.brand.requests = payload;
    },

    setBrandProperties: (state, { payload }) => {
      state.brandProperties = { ...state.brandProperties, ...payload };
    },

    updateProfile: (state, { payload }) => {
      state.user.last_name = payload.last_name;
      state.user.first_name = payload.first_name;
      state.user.newsletter = payload.newsletter;
    },

    stepUp: (state) => {
      // reset error message
      state.errorMessage = null;
      state.step += 1;
    },

    stepDown: (state) => {
      state.step -= 1;
    },

    setStep: (state, { payload }) => {
      state.step = payload;
    },

    setCredential: (state, { payload: credential }) => {
      state.credential = { ...credential };
    },

    setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },

    updateUser: (state, { payload }) => {
      state.user = payload;
    },

    setColors: (state, { payload: colors }) => {
      state.user.brand.colors = colors;
    },

    setFonts: (state, { payload: fonts }) => {
      state.user.brand.fonts = fonts;
    },

    setStyles: (state, { payload: styles }) => {
      state.user.brand.styles = styles;
    },

    setRequestSent: (state, { payload }) => {
      state.requestSent = payload;
    },

    setBrand: (state, { payload }) => {
      state.brand = payload;
    },

    setBrandProps: (state, { payload }) => {
      state.user.brand = { ...state.user.brand, ...payload };
    },
  },
});

export const {
  stepUp,
  setStep,
  setBrand,
  stepDown,
  setFonts,
  setColors,
  setStyles,
  updateUser,
  setBrandName,
  setCredential,
  updateProfile,
  setBrandUsers,
  toggleLoading,
  setBrandProps,
  setRequestSent,
  setBrandStatus,
  setErrorMessage,
  setBrandRequests,
  setBrandProperties,
} = userSlice.actions;

export const doLogout = () => async () => {
  localStorage.clear();
  window.location.href = '/';
};

export const doLogin =
  (credential, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await login(credential);
    dispatch(setErrorMessage(error));
    cb(error, data);
  };

export const doResend = (credential) => async () => {
  await resend(credential);
};

// request code for reset password
export const doReset = (credential) => async (dispatch) => {
  const { error } = await reset(credential);
  if (error) dispatch(setErrorMessage(error));
  else dispatch(stepUp());
};

// update password
export const doUpdate = (credential) => async (dispatch) => {
  const { error } = await update(credential);
  if (error) dispatch(setErrorMessage(error));
  else dispatch(stepUp());
};

// docheckEmail
export const doCheckEmail = (credential) => async (dispatch) => {
  const { error } = await checkEmail(credential);
  if (error) dispatch(setErrorMessage(error));
  else dispatch(stepUp());
};

// doSignup
export const doSignup =
  (credential, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await signup(credential);
    dispatch(setErrorMessage(error));
    cb(error, data);
  };

// doGetUser
export const doGetUser =
  (args, cb = () => {}) =>
  async (dispatch) => {
    let id = '';
    let bid = '';
    if (typeof args === 'string') id = args;
    else {
      ({ id, bid } = args);
    }
    const { data, error } = await getUser(id, bid);
    if (!error) {
      dispatch(updateUser(data));
      dispatch(st(undefined));
      cb(null, data);
      return;
    }
    cb(error);
  };

// doPasswordReset
export const doPasswordReset =
  (payload, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await passwordReset(payload);
    dispatch(setErrorMessage(error));
    cb(error, data);
  };

// doUpdatePassword
export const doUpdatePassword =
  (payload, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await updatePassword(payload);
    dispatch(setErrorMessage(error));
    cb(error, data);
  };

// doVerify
export const doVerify =
  (credential, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await verify(credential);
    dispatch(setErrorMessage(error));
    cb(error, data);
  };

export default userSlice.reducer;
