import { del, get, post, put } from './client';

// eslint-disable-next-line
export const getUser = (id, bid) => {
  let q = '';
  if (bid) q = `?brand_id=${bid}`;
  return get(`/users/${id}${q}`);
};

// eslint-disable-next-line
export const updatePassword = (payload) => {
  return put(`/auth/update-password`, payload);
};

// eslint-disable-next-line
export const passwordReset = (payload) => {
  return post(`/auth/reset-password`, payload);
};

// eslint-disable-next-line
export const updateUser = (id, payload) => {
  return put(`/users/${id}`, payload);
};

// eslint-disable-next-line
export const deleteUser = (id) => {
  return del(`/users/${id}`);
};
