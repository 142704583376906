import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button } from '../ui';

const Wrap = styled.div`
  position relative;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  ${({ fixed }) =>
    fixed &&
    `
  position: fixed;
  top: 111px;
  background-color: #f0f0f0;
  `}

  header {
    position: relative;
    margin: 0 auto;
    width: 960px;
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;

    a, a:active, a:hover {
      color: #888888;
      font-size: 12px;
      font-weight; 300;
      letter-spacing: 1px;
      text-decoration: none;
      border: none;
      font-family: Roboto;
      margin-right: 16px;
    }

    a.active {
      font-weight: normal;
      color: #000000;
    }

    & > div {
      flex: 1;
      padding: 0 32px;
      &:last-child { text-align: right; }
    }
  }
`;

export default ({
  fixed,
  onCancel,
  onConfirm,
  showCancel,
  cancelText = 'CANCEL',
  confirmText = 'CONFIRM',
  confirmIcon = 'tick',
  showConfirm,
}) => {
  const { page } = useParams();
  const { user } = useSelector(({ user: u }) => u);

  const menus = [
    ['Styles', '/settings'],
    ['Users', '/settings/users'],
  ];

  if (user?.brand?.role === 'owner') {
    if (!user?.brand?.premium)
      menus.push(...[['Billing', '/settings/billing']]);
    menus.push(...[['Advanced', '/settings/advanced']]);
  }

  return (
    <Wrap fixed={fixed ? 'true' : ''}>
      <header>
        <div>
          {menus.map((l) => (
            <Link
              key={l[0]}
              to={l[1]}
              className={(() => {
                const p = page || '';
                if (l[1].replace(/^\/settings\/?/, '') === p) return 'active';
                return '';
              })()}
            >
              {l[0]}
            </Link>
          ))}
        </div>
        <div>
          {showCancel && onCancel && (
            <Button icon="cross" text onClick={onCancel}>
              {cancelText}
            </Button>
          )}
          {showConfirm && (
            <Button
              icon={confirmIcon}
              onClick={onConfirm}
              disabled={!onConfirm}
            >
              {confirmText}
            </Button>
          )}
        </div>
      </header>
    </Wrap>
  );
};
