import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Card,
  Input,
  FormDialog,
  Button,
  FlexCenterMid,
} from '../ui';
import { setCredential, doReset, doUpdate } from '../store/user';

export default () => {
  const dispatch = useDispatch();

  const { loading, credential, errorMessage, step } = useSelector(
    ({ user, global }) => ({ ...user, ...global })
  );

  const onChangeHandler = (key, val) => {
    dispatch(
      setCredential({
        ...credential,
        [key]: val,
      })
    );
  };

  const reset = (e) => {
    e.preventDefault();
    dispatch(doReset(credential));
  };

  const update = (e) => {
    e.preventDefault();
    dispatch(doUpdate(credential));
  };

  const strongPassword = (pwd) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
      pwd
    );
  };

  const codeValidate = (code) => {
    return /^[0-9]{6}$/.test(code);
  };

  const emailValidate = (email) => {
    return /[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/.test(email);
  };

  return (
    <div className="solo">
      <Card width={620} loading={loading} style={{ padding: '18px 54px' }}>
        {step === 1 && (
          <>
            <Container>
              <FormDialog
                title="Reset Password"
                description="Please provide email address."
              />
            </Container>

            {errorMessage && !errorMessage.match(/not found/i) && (
              <Container>
                <p className="error">{errorMessage}</p>
              </Container>
            )}

            <form onSubmit={reset}>
              <Container>
                <Input
                  block
                  error={
                    (errorMessage && errorMessage.match(/not found/i)) ||
                    (credential.email &&
                      !credential.email.match(
                        /[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/
                      ))
                  }
                  onChange={(e) => onChangeHandler('email', e.target.value)}
                  placeholder="Enter email address"
                />

                {errorMessage && errorMessage.match(/not found/i) && (
                  <p className="error small">
                    Email address is not yet registered.
                  </p>
                )}
              </Container>
              <Container>
                <Button
                  iconRight="next"
                  disabled={
                    !credential.email || !emailValidate(credential.email)
                  }
                  className="right"
                >
                  NEXT
                </Button>
              </Container>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <FormDialog
              title="Update Password"
              description="Please enter the reset code and updated password."
            />
            {errorMessage && <p className="error">{errorMessage}</p>}
            <form onSubmit={update} autoComplete="sds">
              <Container>
                <Input
                  error={credential.code && !codeValidate(credential.code)}
                  name="code"
                  block
                  placeholder="Enter 6 digit validation code"
                  type="text"
                  onChange={(e) => onChangeHandler('code', e.target.value)}
                />
                <Input
                  error={
                    credential.password && !strongPassword(credential.password)
                  }
                  disabled={!codeValidate(credential.code)}
                  block
                  type="password"
                  onChange={(e) => onChangeHandler('password', e.target.value)}
                  placeholder="Enter a password"
                />

                <Input
                  disabled={
                    !codeValidate(credential.code) ||
                    !credential.password ||
                    !strongPassword(credential.password)
                  }
                  error={
                    credential.confirmPassword &&
                    credential.password !== credential.confirmPassword
                  }
                  block
                  type="password"
                  onChange={(e) => {
                    onChangeHandler('confirmPassword', e.target.value);
                  }}
                  placeholder="Re-enter password"
                />
              </Container>
              <Container>
                <Button
                  iconRight="next"
                  className="right"
                  i
                  disabled={
                    !codeValidate(credential.code) ||
                    !strongPassword(credential.password) ||
                    credential.password !== credential.confirmPassword
                  }
                >
                  Next
                </Button>
              </Container>
            </form>
          </>
        )}
        {step === 3 && (
          <FlexCenterMid>
            <Container>
              <p className="spacer">
                Password reset succesfull
                <Link className="light" to="/login">
                  Log in
                </Link>
                to continue.
              </p>
            </Container>
          </FlexCenterMid>
        )}
      </Card>
    </div>
  );
};
