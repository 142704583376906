import { createSlice } from '@reduxjs/toolkit';
import { updatePage as update } from '../svc/brand';

const ctypes = [
  [
    {
      span: 1,
      items: [],
    },
  ],
  [
    {
      span: 1,
      items: [],
    },
    {
      span: 1,
      items: [],
    },
  ],
  [
    {
      span: 2,
      items: [],
    },
    {
      span: 1,
      items: [],
    },
  ],
  [
    {
      span: 1,
      items: [],
    },
    {
      span: 2,
      items: [],
    },
  ],
  [
    {
      span: 1,
      items: [],
    },
    {
      span: 1,
      items: [],
    },
    {
      span: 1,
      items: [],
    },
  ],
];

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    editMode: false,
    sections: [],
    past: [],
    future: [],
  },

  reducers: {
    initPage: (state, { payload: { brand, slug } }) => {
      if (!brand) return;

      const {
        styles: { logo },
        pages,
        fonts,
        colors,
        name,
      } = brand;
      const primary = colors[0];
      const heading = fonts[0];
      const page = pages.find((p) => p.slug === slug);

      state.slug = page?.slug;
      state.brandName = name;

      if (page && page.revisions.length !== 0) {
        state.sections = page.revisions[page.revisions.length - 1].sections;
        state.sectionsRef = state.sections;
      } else {
        if (slug !== 'basics') return;
        state.sections = [
          {
            type: 'section',
            collapse: false,
            rows: [
              {
                type: 'row',
                layout: 2,
                columns: [
                  {
                    span: 1,
                    items: [
                      {
                        type: 'welcomeintro',
                        size: 15,
                        welcome: {},
                        heading: {
                          level: 3,
                          size: 32,
                          text: `${brand?.name} Brand Basics`,
                        },
                        text: `Welcome to your very own Brand Basics. You’ll have everything you need to make on-brand materials.  Add elements like tone of voice, imagery, video, iconography, etc. to bring your brand to life.  Click ‘Edit Page’ to edit this paragraph or add your own message.
                        `,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'section',
            title: 'Logo',
            collapse: true,
            rows: [
              {
                type: 'row',
                layout: 3,
                columns: [
                  {
                    span: 1,
                    items: [
                      {
                        type: 'text',
                        size: 12,
                        logo: true,
                        text: 'Your logo is one of the most important elements of your brand, so this digital artwork ensures it won’t be altered or recreated in any way. Include variations of your logo and how not to use your logo, so that you have everything covered.',
                        insider:
                          'Define sufficient clear space around your logo to let it breathe and avoid losing its clarity and visual impact.',
                      },
                    ],
                  },

                  {
                    span: 2,
                    items: [
                      {
                        type: 'image',
                        url: logo,
                        placeholder:
                          'Upload logo file (transparent PNG or SVG, no margins)',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'section',
            title: 'Colour',
            collapse: true,
            rows: [
              {
                type: 'row',
                layout: 3,
                columns: [
                  {
                    span: 1,
                    items: [
                      {
                        type: 'text',
                        size: 12,
                        heading: {
                          level: 5,
                          size: 15,
                          text: 'Core colours',
                        },
                        text: 'Your colours help build recognition for your brand. Add in primary, secondary and accent colours to make your brand pop. Make sure to use the correct breakdowns in RGB, CMYK or Pantone across all communications to stay on-brand.',
                        insider:
                          'Balance your designs by playing around with this ratio – 60% primary colour, 30% secondary colour and 10% accent colour.',
                      },
                    ],
                  },

                  {
                    span: 1,
                    items: [
                      {
                        type: 'color',
                        placeholder: 'Add core colour',
                        ...primary,
                      },
                    ],
                  },

                  {
                    span: 1,
                    items: [
                      {
                        type: 'color',
                        placeholder: 'Enter another core colour',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'section',
            collapse: true,
            title: 'Typography',
            rows: [
              {
                type: 'row',
                layout: 4,
                columns: [
                  {
                    span: 1,
                    items: [
                      {
                        type: 'text',
                        size: 12,
                        heading: {
                          level: 5,
                          size: 15,
                          text: 'Core fonts',
                        },
                        text: 'Your fonts help convey your message and act as a complement to visuals. Include all your fonts for various use cases, for example, body copy font, web fonts, app fonts, etc. Simplicity and legibility here is key.',
                        insider:
                          'Light or bold fonts for the body copy may look nice, but it’s actually harder to read. Stick to the regular font weight for readability.',
                      },
                    ],
                  },

                  {
                    span: 1,
                    items: [
                      {
                        type: 'typography',
                        name: heading?.name,
                        url: heading?.url,
                        variant: heading?.variant,
                        placeholder: 'Add a heading font',
                      },
                    ],
                  },

                  {
                    span: 1,
                    items: [
                      {
                        type: 'typography',
                        placeholder: 'Add a body text font',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ];
        state.sectionsRef = state.sections;
      }
    },

    save: (state) => {
      state.sectionsRef = state.sections;
      state.editMode = !state.editMode;
      state.selected = undefined;
      state.past = [];
      state.future = [];
    },

    setBackground: (state, { payload }) => {
      state.background = payload;
    },

    insertRow: (state, { payload: { section, row, c } }) => {
      state.sections[section].rows.splice(row, 0, {
        type: 'row',
        layout: c,
        columns: [...ctypes[c]],
      });
    },

    insertSection: (state, { payload: { section, c } }) => {
      if (state.sections.length === 0) {
        state.sections.push({
          type: 'section',
          collapse: false,
          title: 'Section',
          rows: [
            {
              type: 'row',
              layout: c,
              columns: [...ctypes[0]],
            },
          ],
        });
        return;
      }
      state.sections.splice(section, 0, {
        type: 'section',
        collapse: false,
        title: 'Section',
        rows: [
          {
            type: 'row',
            layout: c,
            columns: [...ctypes[c]],
          },
        ],
      });
    },

    updatePage: (state, { payload }) => {
      state.slug = payload.slug;
      state.sections = payload.sections;
      state.brandId = payload.brandId;
      state.sectionsRef = payload.sections; // copy for reference
    },

    toggleEditMode: (state) => {
      if (state.editMode) {
        state.selected = undefined;
        state.sections = state.sectionsRef;
      }
      state.editMode = !state.editMode;
      state.past = [];
      state.future = [];
    },

    setSelected: (state, { payload }) => {
      state.selected = payload;
    },

    setDragTarget: (state, { payload }) => {
      state.dragTarget = payload;
    },

    setDropTarget: (state, { payload }) => {
      state.dropTarget = payload;
    },

    updateItem: (state, { payload: { section, row, column, item, props } }) => {
      const newItem = {
        ...state.sections[section].rows[row].columns[column].items[item],
        ...props,
      };
      if (newItem.type === 'welcomeintro' && props.new) {
        newItem.welcome = {
          invertHeading: false,
        };
        newItem.heading = {
          text: `Welcome to ${state.brandName} BrandBox`,
          level: 3,
          size: 28,
        };
        newItem.text = `Everything you need to start making ${state.brandName}-branded materials.`;
        newItem.size = 15;
      }

      state.sections[section].rows[row].columns[column].items[item] = newItem;
    },

    setNewItem: (state, { payload }) => {
      state.newItem = payload;
    },

    move: (state, { payload = {} }) => {
      const { dropTarget, dragTarget } = state;
      const { type, up, section, row } = payload;
      const sectionCopy = state.sections[section];
      const rowCopy = state.sections[section]?.rows[row];

      // reset
      state.dropTarget = undefined;
      state.dragTarget = undefined;

      if (type === 'section') {
        if (up && state.sections[section - 1]) {
          // remove
          state.sections.splice(section, 1);
          state.sections.splice(section - 1, 0, sectionCopy);
        } else if (!up && state.sections[section + 1]) {
          // remove
          state.sections.splice(section, 1);
          state.sections.splice(section + 1, 0, sectionCopy);
        }
      } else if (type === 'row') {
        // if trying to move row upward
        if (up) {
          // check if row can be move up
          if (state.sections[section].rows[row - 1]) {
            state.sections[section].rows.splice(row, 1);
            state.sections[section].rows.splice(row - 1, 0, rowCopy);
            // otherwise, check for available previous section and move the
            // selected row there
          } else if (state.sections[section - 1]) {
            state.sections[section].rows.splice(row, 1);
            state.sections[section - 1].rows.push(rowCopy);
          }
          // moving row down
        } else {
          // eslint-disable-next-line
          if (state.sections[section].rows[row + 1]) {
            // remove
            state.sections[section].rows.splice(row, 1);
            state.sections[section].rows.splice(row + 1, 0, rowCopy);
          } else if (state.sections[section + 1]) {
            state.sections[section].rows.splice(row, 1);
            state.sections[section + 1].rows.splice(0, 0, rowCopy);
          }
        }
      } else if (dropTarget && dragTarget) {
        const isSameColumn =
          dropTarget.section === dragTarget.section &&
          dropTarget.row === dragTarget.row &&
          dropTarget.column === dragTarget.column;

        // copy value first
        const dragTargetItem =
          state.sections[dragTarget.section].rows[dragTarget.row].columns[
            dragTarget.column
          ].items[dragTarget.item];

        // remove dragTarget
        state.sections[dragTarget.section].rows[dragTarget.row].columns[
          dragTarget.column
        ].items.splice(dragTarget.item, 1);

        let pad = 1;
        // migrate
        if (isSameColumn && dropTarget.item > dragTarget.item) pad = 0;

        const idx =
          dropTarget.position === 0 ? dropTarget.item : dropTarget.item + pad;
        state.sections[dropTarget.section].rows[dropTarget.row].columns[
          dropTarget.column
        ].items.splice(idx, 0, dragTargetItem);

        state.selected = {
          type: dragTarget.type,
          section: dropTarget.section,
          row: dropTarget.row,
          column: dropTarget.column,
          item: idx,
        };
      } else if (type === 'new' && state.newItem && dropTarget) {
        if (state.newItem.type === 'text') state.newItem.size = 12;
        if (state.newItem.type === 'welcomeintro') {
          state.newItem.welcome = {
            invertHeading: false,
          };
          state.newItem.heading = {
            text: `Welcome to ${state.brandName} BrandBox`,
            level: 3,
            size: 28,
          };
          state.newItem.text = `Everything you need to start making ${state.brandName}-branded materials.`;
          state.newItem.size = 15;
        }
        const pad = 1;
        const idx =
          dropTarget.position === 0 ? dropTarget.item : dropTarget.item + pad;
        state.sections[dropTarget.section].rows[dropTarget.row].columns[
          dropTarget.column
        ].items.splice(idx, 0, state.newItem);
        // set new item as selected
        state.selected = {
          ...dropTarget,
          type: state.newItem.type,
          item: idx,
        };
      }
      // reset newItem
      state.newItem = undefined;
    },

    removeComponent: (state, { payload }) => {
      const { type, section, row, column, item } = payload;

      state.selected = undefined;

      switch (type) {
        case 'section':
          state.sections.splice(section, 1);
          break;
        case 'row':
          if (state.sections[section].rows.length > 1) {
            state.sections[section].rows.splice(row, 1);
          } else {
            state.sections[section].rows[0] = {
              type: 'row',
              layout: 2,
              columns: [
                {
                  span: 1,
                  items: [],
                },
              ],
            };
          }
          break;
        default:
          state.sections[section].rows[row].columns[column].items.splice(
            item,
            1
          );
      }
    },

    duplicateComponent: (state, { payload }) => {
      const { type, section, row, column, item } = payload;

      switch (type) {
        case 'section':
          state.sections.splice(section, 0, state.sections[section]);
          break;

        case 'row':
          state.sections[section].rows.splice(
            row,
            0,
            state.sections[section].rows[row]
          );
          break;

        default:
          state.sections[section].rows[row].columns[column].items.splice(
            item,
            0,
            state.sections[section].rows[row].columns[column].items[item]
          );
          state.selected = {
            type,
            section,
            row,
            column,
            item: item + 1,
          };
      }
    },

    updateSection: (state, { payload = {} }) => {
      const { collapse } = payload;
      if (payload.title) state.sections[payload.section].title = payload.title;
      if (collapse !== undefined)
        state.sections[payload.section].collapse = collapse;
      if (collapse === false) {
        const rowsCopy = state.sections[payload.section].rows.splice(1);
        const newSections = rowsCopy.map((row) => ({
          rows: [row],
          collapse: false,
          type: 'section',
        }));
        state.sections.splice(payload.section + 1, 0, ...newSections);
      }
    },

    updateRow: (state, { payload = {} }) => {
      const { section, row, layout } = payload;
      // This did the trick for us!!!
      //
      // I mentioned about moving everything in here to the controller but I've
      // decided to not do it. Instead, I moved the columntypes template inside
      // this function scope which fixes the object props being read-only and
      // causes a lot of pain in our tokus!
      const coloumnTypes = [
        [
          {
            span: 1,
            items: [],
          },
        ],
        [
          {
            span: 1,
            items: [],
          },
          {
            span: 1,
            items: [],
          },
        ],
        [
          {
            span: 2,
            items: [],
          },
          {
            span: 1,
            items: [],
          },
        ],
        [
          {
            span: 1,
            items: [],
          },
          {
            span: 2,
            items: [],
          },
        ],
        [
          {
            span: 1,
            items: [],
          },
          {
            span: 1,
            items: [],
          },
          {
            span: 1,
            items: [],
          },
        ],
      ];

      const current = state.sections[section].rows[row].columns;
      const columns = [...coloumnTypes[layout]];

      current.forEach((c, i) => {
        // Since we are looping inside an array that has a possibility of having
        // a length greater than the columns that might cause some weird out
        // of boundary index error, we add some sort of constraint to our index.
        let idx = i;
        if (current.length > columns.length) {
          idx = Math.min(i, columns.length - 1);
        }
        // Create a new empty array and then just concat whatever is in
        // the current columns[idx].items
        // and then concat again the c.items
        //
        // NOTE: This is kind of similar to what we did before, but this is more
        // refine.
        columns[idx].items = [].concat(columns[idx].items).concat(c.items);
      });

      state.sections[section].rows[row].columns = columns;
      state.sections[section].rows[row].layout = layout;
    },

    undo: (state) => {
      const previous = state.past[state.past.length - 1];
      const newPast = state.past.slice(0, state.past.length - 1);

      state.past = newPast;
      state.future = [state.sections, ...state.future];
      state.sections = previous;
    },

    redo: (state) => {
      const next = state.future[0];
      const newFuture = state.future.slice(1);

      state.past = [...state.past, state.sections];
      state.sections = next;
      state.future = newFuture;
    },

    updatePresent: (state) => {
      state.past = [...state.past, state.sections];
      state.future = [];
    },

    reset: (state) => {
      Object.keys(state).forEach((k) => {
        state[k] = undefined;
      });
    },
  },
});

export const {
  undo,
  redo,
  save,
  move,
  reset,
  initPage,
  updateRow,
  insertRow,
  updatePage,
  updateItem,
  setNewItem,
  setSelected,
  insertSection,
  setDragTarget,
  setBackground,
  setDropTarget,
  updateSection,
  updatePresent,
  toggleEditMode,
  removeComponent,
  duplicateComponent,
} = pageSlice.actions;

// doUpdatePage
export const doUpdatePage =
  (payload, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await update(payload);
    if (!error) {
      dispatch(save());
    }
    cb(error, data);
  };

export default pageSlice.reducer;
