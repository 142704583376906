/**
 * ColorInput Component
 *
 * Includes a preview box and an input field with hex value-specific
 * constrain.
 *
 * Events:
 * - onColorChange will be triggered if color is valid and pass the color
 *   to the handler func
 * - onSubmit will be triggered once the user hit the return key and value
 *   from the input field will be reset as well. Example use case would be
 *   a color list generator.
 *
 * Properties:
 * - placeholder
 */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import ColorBox from './ColorBox';

/**
 * Main ColorInput wrapper
 */
const ColorInput = styled.span`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 200px;
  height: 36px;
  background-color: transparent;

  ${({ block }) =>
    block &&
    `
      width: 100%;
      display: block;
  `}
`;

const Flex = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

/**
 * Input comp
 */
const Input = styled.input`
  display: block;
  position: relative;
  font-size: 15px;
  font-weight: 300;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-indent: 15px;
  margin-left: 10px;
  background-color: transparent;
  box-sizing: border-box;
  border: 0;

  ${({ bordered, error }) =>
    bordered &&
    `
      border-bottom: 1px solid #d2d2d2;
      &:focus {
        border-bottom: 1px solid ${error ? '#ff2419' : darken(0.3, '#d2d2d2')};
      }
      color: ${error ? '#ff2419' : 'inherit'};
  `}
`;

const InputWrap = styled.span`
  flex-grow: 1;
  height: 100%;
  position: relative;

  &::before {
    content: '#';
    position: absolute;
    color: #aaaaaa;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
`;

export default ({
  id,
  name,
  block,
  onError = () => {},
  bordered,
  placeholder = 'Enter Color',
  defaultValue = '',
  onColorChange = () => {},
}) => {
  const [color, setColor] = useState(undefined);
  const [error, setError] = useState(false);

  const inputRef = useRef();

  const onKeyDown = (e) => {
    // whitelist them bitchass keys
    const list = [13, 8, 91, 37, 39, 17, 16];
    // allow the following keys in the list
    if (list.find((k) => k === e.keyCode)) return true;
    // alpha numeric
    if (String.fromCharCode(e.keyCode).match(/[0-9a-f]/i)) return true;
    // cannot bring!
    return e.preventDefault();
  };

  const checkVal = (e) => {
    e.preventDefault();
    const { target } = e;
    // sanitize and remove invalid color char
    target.value = target.value.replace(/[^0-9a-fA-F]*/g, '').toUpperCase();
    // valid value
    if ([3, 6].find((n) => n === target.value.length)) {
      setError(false);
      onError(false);

      setColor(target.value);
      onColorChange(target.value);
      return;
    }

    // only show error if val is not empty and val is not valid
    if (!target.value.length) {
      onError(false);
      setError(false);
    } else {
      onError(true);
      setError(true);
      return;
    }

    setColor(undefined);
    onColorChange(undefined);
  };

  useEffect(() => {
    setColor(defaultValue);
    inputRef.current.value = defaultValue;
  }, [defaultValue]);

  return (
    <ColorInput {...{ block }}>
      <Flex>
        <ColorBox color={color} />
        <InputWrap>
          <Input
            {...{
              id,
              ref: inputRef,
              name,
              error,
              onChange: checkVal,
              bordered,
              onKeyDown,
              placeholder,
            }}
            maxLength={6}
          />
        </InputWrap>
      </Flex>
    </ColorInput>
  );
};
