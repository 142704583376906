import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DropDown, Switch } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const CropDropDown = styled(DropDown)`
  margin-bottom: 12px;
`;

const selectCrop = [
  { label: 'Regular Spacing', value: 0 },
  { label: 'More Space', value: 1 },
  { label: 'Less Space', value: 2 },
];

const selectSizing = [
  { label: 'Disable heading', value: 0 },
  { label: 'Small heading', value: 1 },
  { label: 'Regular heading', value: 2 },
  { label: 'Large heading', value: 3 },
];

export default (attrs) => {
  const { props } = attrs;
  const { space, position = false, size = 0 } = props;
  const dispatch = useDispatch();

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onPositionChange = (v) => {
    update({ position: v });
  };

  const onSizeChange = (v) => {
    update({ size: v });
  };

  const onSelectSpace = (value) => {
    update({
      space: value,
    });
  };

  return (
    <Ctl>
      <section>
        <h3>Clear space</h3>
        <p>Define the amount of clear space required required for your logo.</p>
      </section>

      <CropDropDown
        items={selectCrop}
        onSelect={onSelectSpace}
        defaultValue={selectCrop[space]?.value || 0}
        dropDownType="text"
        disabled={false}
        block
        size="small"
      />
      <section>
        <h3>Heading Options</h3>
        <p>Enable heading, choose its size and specify its placement.</p>
        <DropDown
          items={selectSizing}
          onSelect={onSizeChange}
          defaultValue={selectSizing[size]?.value}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        <br />
        {size !== 0 && (
          <Switch
            size="small"
            block
            defaultValue={position}
            onChange={onPositionChange}
            leftText="Below"
            rightText="Above"
          />
        )}
      </section>
    </Ctl>
  );
};
