///////////////////////////////////////////////////////
//
// Container
//
// Brandbox specific container component.
// This wraps the content with customisable
// bottom margin. Good for spacing out grouped
// elements.
//
///////////////////////////////////////////////////////
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: visible;

  margin-bottom: ${({ margin }) => {
    const parsedMargin = parseInt(margin, 10);

    if (Number(parsedMargin)) return `${parsedMargin}px`;

    return '26px';
  }};

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }
`;

export default ({ margin, children }) => {
  return <Container margin={margin}>{children}</Container>;
};
