import React, { useState } from 'react';
import styled from 'styled-components';

import { toast } from '../ui/Toast';
import SettingsFooter from './editor/SettingsFooter';
import { Button, Icon, DropDown, Spinner } from '../ui';

import { reset } from '../svc/auth';
import { updateUserRole } from '../svc/brand';
import DeleteUser from './DeleteUser';

const UserIcon = styled(Icon)`
  margin-right: 6px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  .name {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.13px;
    color: #000000;
    margin-right: 10px;
  }
  .email {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    color: #000000;
  }
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 79px;
  button {
    padding: 25px 0 0 0;
  }
`;

const RoleDropdown = styled(DropDown)`
  width: 315px;
`;

const selectRole = [
  { label: 'Admin', value: 'admin' },
  { label: 'Reg. user', value: 'user' },
];

const roleLookUp = {
  admin: 0,
  user: 1,
};

export default ({
  user = {},
  brandId,
  onDelete = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  sessionRole, // currently logged in user's role
}) => {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(0);
  const [role, setRole] = useState(roleLookUp[user?.role]);
  const [changed, setChanged] = useState(false);

  const handleReset = async () => {
    setLoading(true);
    const { error: err } = await reset({ email: user.email });
    if (err) {
      toast.error(
        'Oops, something went wrong',
        'An unexpected error has occurred. Please try again.      '
      );
    } else {
      toast('Success', 'Reset link sent!');
      onCancel();
    }
    setLoading(false);
  };

  const onRoleChange = (val) => {
    setRole(roleLookUp[val]);
    setChanged(true);
  };

  const onConfirmhandler = async () => {
    setLoading(true);

    await updateUserRole(brandId, user.id, { role });
    const updatedUser = { ...user, role: selectRole[role].value };
    setLoading(false);

    onConfirm(updatedUser);
  };

  return (
    <Spinner spin={loading}>
      {action === 0 && (
        <>
          <h3 style={{ fontWeight: 'normal' }}>User settings</h3>
          <UserInfo>
            <UserIcon className={`brand-${user.role}`} name="user-circle" />
            <span className="name">{user.first_name}</span>
            <span className="email">{user.email}</span>
          </UserInfo>
          <p
            style={{
              fontWeight: '300',
              fontSize: 12,
              color: '#444444',
              width: 437,
            }}
          >
            Update user permissions below. Regular users are not able to edit
            pages or upload media. Admins have access to all features.
          </p>

          {(sessionRole === 'owner' ||
            (sessionRole === 'admin' && user.role === 'user')) && (
            <RoleDropdown
              items={selectRole}
              onSelect={onRoleChange}
              defaultValue={(() => {
                if (role === 0) return 'admin';
                return 'user';
              })()}
              dropDownType="text"
              disabled={false}
              block
              size="small"
            />
          )}

          <Actions>
            <Button icon="send" text size="small" onClick={handleReset}>
              Reset Password
            </Button>
            <Button
              icon="delete"
              text
              size="small"
              onClick={() => setAction(1)}
            >
              Delete user account?
            </Button>
          </Actions>
          <SettingsFooter
            showCancel
            bordered
            showConfirm
            onCancel={onCancel}
            onConfirm={onConfirmhandler}
            disabled={!changed}
          />
        </>
      )}
      {action === 1 && (
        <DeleteUser
          userId={user.id}
          brandId={brandId}
          onCancel={() => {
            setAction(0);
          }}
          onDelete={onDelete}
        />
      )}
    </Spinner>
  );
};
