///////////////////////////////////////////////////////
//
// Button
//
// Brandbox specific button component.
//
///////////////////////////////////////////////////////
import React from 'react';
import styled from 'styled-components';
import { getColorFromProps } from '../config';
import Icon from './Icon';

const Button = styled.button`
  border: 0;
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 3px;
  font-family: Roboto !important;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          padding: 5px 18px;
          font-size: 12px;
        `;
      case 'large':
        return `
          padding: 12px 32px;
          font-size: 15px;
        `;
      default:
        return `
          padding: 7px 26px;
          font-size: 13px;
        `;
    }
  }}

  position: relative;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  &:not(:last-child) {
    margin-right: 12px;
  }

  background-color: ${(props) => {
    return getColorFromProps({ props });
  }};

  color: ${(props) => {
    return getColorFromProps({ props, isFg: true });
  }};

  & svg {
    fill: ${(props) => {
      return getColorFromProps({ props, isFg: true });
    }};
  }

  &:hover {
    background-color: ${(props) => {
      return getColorFromProps({ props, state: 'hover' });
    }};
    color: ${(props) => {
      return getColorFromProps({ props, isFg: true, state: 'hover' });
    }};
  }

  &:active {
    top: 1px;
  }

  &:disabled {
    background-color: ${(props) => {
      return getColorFromProps({ props, state: 'disabled' });
    }};
    color: ${(props) => {
      return getColorFromProps({ props, isFg: true, state: 'disabled' });
    }};
    & svg {
      fill: ${(props) => {
        return getColorFromProps({ props, isFg: true, state: 'disabled' });
      }};
    }
  }

  ${({ block }) =>
    block &&
    `
      width: 100%;
      display: block;
    `}

  ${({ bordered, text }) =>
    text &&
    `
    border: ${bordered ? 1 : 0}px solid #000000;
    padding-left: 0;
    padding-right: 0;
    font-weight: normal;
  `}
`;

export default (props) => {
  const { link, to, icon, style, iconRight, children } = props;

  let handler = () => {};
  // if button is a link and has a `to` prop
  // then we activate enable navigation
  if (link && to) {
    handler = (e) => {
      e.preventDefault();
      window.location.href = to;
    };
  }

  return (
    <Button onClick={handler} {...props}>
      {icon && (
        <>
          <Icon
            style={{ position: 'relative', top: 2 }}
            name={icon}
            size={Number(style && style.fontSize) || 13}
          />
          <span style={{ marginRight: 8 }} />
        </>
      )}
      {children}
      {iconRight && (
        <>
          <span style={{ marginRight: 8 }} />
          <Icon
            style={{ position: 'relative', top: 2 }}
            name={iconRight}
            size={Number(style && style.fontSize) || 13}
          />
        </>
      )}
    </Button>
  );
};
