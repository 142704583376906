import { del, post, put, get } from './client';

// eslint-disable-next-line
export const updatePage = ({ brandId, slug, sections }) => {
  return put(`/brands/${brandId}/pages/${slug}`, sections);
};

export const getFiles = (brandId, collectionId, query) => {
  let q = '';
  if (query) {
    if (typeof query === 'string') {
      q = `?search=${query}`;
    } else {
      if (collectionId) query.collection_id = collectionId;
      q = `?${Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join('&')}`;
    }
  }

  const url = `/brands/${brandId}/files${q}`;
  return get(url);
};

export const getTouchpoints = (brandId, collectionId, query) => {
  let q = '';
  if (query) {
    if (typeof query === 'string') {
      q = `?search=${query}`;
    } else if (Object.keys(query).length > 0) {
      q = `?${Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join('&')}`;
    }
  }

  let url = `/brands/${brandId}/touchpoints${q}`;
  if (collectionId) {
    url = `/brands/${brandId}/touchpoints/collections/${collectionId}${q}`;
  }
  return get(url);
};

export const getTouchpoint = (id) => {
  const url = `/brands/touchpoints/${id}`;
  return get(url);
};

export const getCollectionsWithTouchpoints = (bid, query) => {
  let q = '';
  if (query) {
    if (typeof query === 'string') {
      q = `?search=${query}`;
    } else if (Object.keys(query).length > 0) {
      q = `?${Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join('&')}`;
    }
  }
  const url = `/brands/${bid}/touchpoints/collections${q}`;
  return get(url);
};

export const createTouchpoint = (brandId, payload) => {
  return post(`/brands/${brandId}/touchpoints`, payload);
};

export const updateTouchpoint = (touchpointId, payload) => {
  return put(`/brands/touchpoints/${touchpointId}`, payload);
};

export const getCollections = (brandId, query) => {
  let q = '';

  if (query) {
    if (typeof query === 'string') {
      q = `?search=${query}`;
    } else {
      q = `?${Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join('&')}`;
    }
  }

  const url = `/brands/${brandId}/collections${q}`;
  return get(url);
};

export const getBrand = (id, shouldRetry) => {
  const opts = {};

  if (shouldRetry) {
    opts['axios-retry'] = {
      retries: 3,
      retryDelay: (c) => c * 500,
      retryCondition: (e) => {
        if (e.message.match(/404/i)) return true;
      },
    };
  }

  return get(`/brands/${id}`, opts);
};

export const updateBrand = (id, payload) => {
  return put(`/brands/${id}`, payload);
};

export const updateFile = (bid, id, { file, tags = [] }) => {
  // create new form data instance
  const payload = new FormData();
  // append file
  if (file) {
    let f;
    if (Array.isArray(file)) {
      f = file[0];
    } else {
      f = file;
    }
    payload.append('file', f, f.name.replace(' ', '-'));
  }

  payload.append('tags', JSON.stringify(tags));

  return put(`/brands/${bid}/files/${id}`, payload, {
    'Content-Type': 'multipart/form-data',
  });
};

export const addFileToCollections = (bid, id, collections) => {
  return post(`/brands/${bid}/files/${id}/collections`, collections);
};

export const addTouchpointToCollections = (bid, id, collections) => {
  return post(`/brands/${bid}/touchpoints/${id}/collections`, collections);
};

export const removeFileFromCollections = (bid, id, collections) => {
  return del(`/brands/${bid}/files/${id}/collections`, collections);
};

export const removeTouchpointFromCollections = (bid, id, collections) => {
  return del(`/brands/${bid}/touchpoints/${id}/collections`, collections);
};

export const deleteFile = (bid, id) => {
  return del(`/brands/${bid}/files/${id}`);
};

export const createCollection = (bid, collection) => {
  return post(`/brands/${bid}/collections`, collection);
};

export const createTouchpointsCollection = (bid, collection) => {
  return post(`/brands/${bid}/touchpoints/collections`, collection);
};

export const updateCollection = (bid, cid, collection) => {
  return put(`/brands/${bid}/collections/${cid}`, collection);
};

export const updateTouchpointsCollection = (bid, cid, collection) => {
  return put(`/brands/${bid}/touchpoints/collections/${cid}`, collection);
};

export const deleteCollection = (bid, cid) => {
  return del(`/brands/${bid}/collections/${cid}`);
};

export const deleteTouchpointsCollection = (bid, cid) => {
  return del(`/brands/${bid}/touchpoints/collections/${cid}`);
};

export const deleteTouchpoint = (id) => {
  return del(`/brands/touchpoints/${id}`);
};

export const addColors = (bid, colors) => {
  if (!colors || colors.length === 0) return;
  return post(`/brands/${bid}/colors`, colors);
};

export const updateColors = (bid, colors) => {
  if (!colors || colors.length === 0) return;
  return put(`/brands/${bid}/colors`, colors);
};

export const deleteColors = (bid, colors) => {
  if (!colors || colors.length === 0) return;
  return del(`/brands/${bid}/colors`, colors);
};

export const updateFonts = (bid, fonts) => {
  if (!fonts || fonts.length === 0) return;
  return post(`/brands/${bid}/fonts`, fonts);
};

export const deleteFonts = (bid, fonts) => {
  if (!fonts || fonts.length === 0) return;
  return del(`/brands/${bid}/fonts`, fonts);
};

export const updateStyles = (bid, styles) => {
  return put(`/brands/${bid}/styles`, styles);
};

export const activateUser = (bid, payload) => {
  return put(`/brands/${bid}/users`, payload);
};

export const addUser = (bid, users) => {
  return post(`/brands/${bid}/users`, users);
};

export const userRequest = (bid, payload) => {
  return post(`/brands/${bid}/requests`, payload);
};

export const getUserRequest = (bid) => {
  return get(`/brands/${bid}/requests`);
};

export const deleteUserRequest = (bid, payload) => {
  return del(`/brands/${bid}/requests`, payload);
};

export const getUsers = (bid) => {
  return get(`/brands/${bid}/users`);
};

export const deleteBrandUser = (bid, uid) => {
  return del(`brands/${bid}/users/${uid}`);
};

export const updateUserRole = (bid, uid, payload) => {
  return put(`brands/${bid}/users/${uid}`, payload);
};

// PUT /brands/:bid/users/:uid { role }
// role is 0 - 2
// 0 admin
// 1 user
// 2 guest?
