import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Icon, Button } from '../ui';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  background-color: #ffffff;

  input {
    position: absolute;
    right: -2px;
    top: 12px;
    border: 0;
    font-size: 12px;
    padding: 8px 0 8px 24px;
    font-weight: 300;
    width: 227px;
    border-bottom: 1px solid #e9e9e9;
  }

  .search {
    position: absolute;
    top: 19px;
    right: 230px;
    z-index: 10;
    fill: #999999;
  }

  button,
  button:active {
    display: none;
    padding: 0;
    position: absolute;
    top: 27px;
    right: -17px;
    z-index: 10;
  }

  ${({ on }) =>
    on &&
    `
   border: 0;
   border: 1px solid #d2d2d2;
   opacity: 0.7;

    input {
      position: relative;
      right: -2px;
      top: 12px;
      border: 0;
      font-size: 24px;
      padding: 8px 0 8px 32px;
      font-weight: normal;
      width: 80%;
    }

    .search {
      position: absolute;
      top: 22px;
      left: 0;
      z-index: 10;
      fill: #999999;
      width: 24px;
      height: 24px;
    }

    button, button:active { display: inline-block; };
  `}
`;

const Content = styled.div`
  margin: 0 auto;
  width: 960px;
  box-sizing: border-box;
  position: relative;
`;

export default ({
  onSearch = () => {},
  placeholder = 'Search Media',
  reset: rs,
}) => {
  const [on, setOn] = useState(false);
  const [to, setTo] = useState(undefined);
  const [previous, setPrevious] = useState(undefined);
  const inputRef = useRef();

  const change = (e) => {
    const val = e.target.value.trim();
    if (to) clearTimeout(to);

    if (!val && previous) {
      setPrevious(val);
      onSearch(val);
      return;
    }

    setTo(
      setTimeout(() => {
        setPrevious(val);
        onSearch(val);
      }, 1000)
    );
  };

  const reset = () => {
    if (inputRef.current) {
      if (inputRef.current.value.trim()) onSearch(undefined);
      inputRef.current.value = '';
      inputRef.current.blur();
      setOn(false);
    }
  };

  useEffect(() => {
    if (rs) rs.current = reset;
  }, []);

  return (
    <Wrap on={on ? 'true' : ''}>
      <Content>
        <Icon className="search" name="search" />
        <Button text size="small" icon="cross" onClick={reset} />
        <input
          ref={inputRef}
          placeholder={placeholder}
          type="text"
          onChange={change}
          onFocus={() => setOn(true)}
        />
      </Content>
    </Wrap>
  );
};
