import React from 'react';
import Button from '../ui/Button';
import errorCover from '../error-image.png';

export default ({ url }) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <img
      style={{
        width: '100%',
        height: '100%',
        zIndex: 0,
        position: 'fixed',
        objectFit: 'cover',
      }}
      src={errorCover}
      alt="cover"
    />
    }
    <div style={{ zIndex: 1, paddingLeft: 146 }}>
      <h2
        style={{
          width: 537,
          height: 160,
          fontSize: 72,
          color: '#ffffff',
          lineHeight: 1.11,
          letterSpacing: 0.5,
          fontWeight: 'normal',
        }}
      >
        Something is not right here
      </h2>
      <p
        style={{
          width: 676,
          height: 34,
          fontSize: 24,
          fontWeight: 'normal',
          lineHeight: 1.42,
          letterSpacing: 0.45,
          color: '#ffffff',
        }}
      >
        This page is not available.
      </p>
      <Button
        icon="previous"
        onClick={() => {
          window.location.href = url || '/';
        }}
      >
        GO BACK
      </Button>
    </div>
  </div>
);
