import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  AiOutlineFilePdf,
  AiOutlineFileText,
  AiOutlinePlayCircle,
  AiOutlineSound,
} from 'react-icons/ai';

import { DropDown, Spinner, Uploader, Icon, Button } from '../ui';
import { getFiles } from '../svc/brand';
import SettingsFooter from './editor/SettingsFooter';
import LazyLoad from './LazyLoad';

const Wrap = styled.div`
  width: 586px;
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
  }
`;

const Upload = styled.div`
  width: 100%;
  height: 116px;
  margin-bottom: 24px;
`;

const Media = styled.div`
  position: relative;
  min-height: 200px;

  header {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
      flex: 1;
      height: 36px;
      &:last-child {
        position: relative;
        display: flex;
        align-items: left;
        justify-content: center;

        button {
          position: absolute;
          top: 9px;
          padding: 0;
          margin: 0;
          &:first-child {
            ${({ searchable }) =>
              searchable &&
              `
          left: 0;
          `};
            ${({ searchable }) =>
              !searchable &&
              `
          right: 0;
          `};
          }

          &.close {
            right: 0 !important;
          }
        }

        input {
          ${({ searchable }) =>
            !searchable &&
            `
            display: none;
          `}
          flex: 1;
          font-size: 12px;
          border: none;
          border-bottom: 1px solid #e9e9e9;
          box-sizing: border-box;
          padding: 0 10px 0 18px;
        }
      }
    }
  }

  main {
    overflow: scroll;
    padding: 1px;
    max-height: 320px;
    margin-bottom: 32px;
  }
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #4b9aff;
  border-radius: 50px;
  svg {
    fill: #ffffff !important;
    width: 10px !important;
    height: 10px !important;
  }
`;

const Thumb = styled.div`
  width: 136px;
  height: 110px;
  padding: 3px;
  box-sizing: bordered-box;
  float: left;
  margin-bottom: 12px;
  &:not(:last-child) {
    margin-right: 4px;
  }
  cursor: pointer;
  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      outline: 1px solid #d2d2d2;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    outline: 1px solid #4b9aff;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: .3;
  `}

  .pic {
    position: relative;
    outline: 1px solid #dedede;
    width: 100%;
    height: 80px;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    video,
    object {
      height: 100%;
      object-fit: cover;
    }
    object {
      pointer-events: none;
    }
    svg {
      fill: #999999;
      width: 32px;
      height: 32px;
    }
  }

  .desc {
    padding-top: 8px;
    font-size: 12px;
    color: #444444;
  }
`;

export default ({
  zip,
  title = 'Select files',
  type,
  multi,
  category,
  onCancel = () => {},
  onConfirm = () => {},
  uploadOnly,
  collectionId, // id from the collection that this comp is mounted from
}) => {
  const searchRef = useRef();
  const [to, setTo] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [complete, setComplete] = useState(false);
  const [search, setSearch] = useState();
  const [previous, setPrevious] = useState(undefined);
  const [selected, setSelected] = useState(multi ? [] : undefined);
  const [searchable, setSearchable] = useState(false);
  const [collection, setCollection] = useState();
  const [collections, setCollections] = useState();

  const { user = {} } = useSelector((store) => store.user);
  const { brand = {} } = user;

  const uploadEndpoint = (() => {
    let query = '';
    if (category) query = `?category=${category}`;
    // brand with specified collection id
    if (collection)
      return `/api/v1/brands/${brand.id}/collections/${collection}/files${query}`;
    // brand
    return `/api/v1/brands/${brand.id}/files${query}`;
  })();

  const accept = (() => {
    if (zip) return '.zip,.rar,.7zip,.gz';
    if (!type) return undefined;
    return `${type}/*`;
  })();

  const onUpload = (uploads) => {
    if (typeof uploads === 'object' && uploads.error) return;
    const filesCopy = [...files];
    const nfiles = uploads.map((u) => ({
      id: Date.now().toString(),
      new: true,
      ...u.data,
    }));
    filesCopy.splice(0, 0, ...nfiles);
    setFiles(filesCopy);
    if (!uploadOnly) {
      if (multi) setSelected([...selected, ...nfiles.map((nf) => nf.id)]);
      else setSelected(nfiles[0].id);
    }
  };

  const doSearch = (val) => {
    if (to) clearTimeout(to);

    if (!val && previous) {
      setFiles(undefined);
      setPrevious(val);
      setSearch(val);
      return;
    }

    setTo(
      setTimeout(() => {
        setFiles(undefined);
        setPrevious(val);
        setSearch(val);
      }, 1000)
    );
  };
  const change = (e) => {
    const val = e.target.value.trim();
    doSearch(val);
  };

  useEffect(() => {
    (async () => {
      if (!user || !brand || uploadOnly) return;

      const queries = {
        max_rows: 30,
        sort_field: 'created_at',
      };
      if (type) queries.type = type;
      if (search) queries.search = search;
      const { data, error } = await getFiles(brand.id, collection, queries);
      if (!error) {
        setFiles(data.files);
        setCollections(data.collections);
      }
    })();
  }, [user, collection, type, search, uploadOnly]);

  return (
    <Wrap>
      <h3>{title}</h3>
      <Upload>
        <Uploader
          bordered
          disabled={!files && !uploadOnly}
          endpoint={uploadEndpoint}
          onUpload={onUpload}
          multi={multi}
          accept={accept}
          placeholder="Choose or drag & drop a new file here"
        />
      </Upload>
      <Spinner spin={!files && !uploadOnly}>
        <Media searchable={searchable ? 'true' : ''}>
          {!uploadOnly && (
            <header>
              <div>
                <DropDown
                  onSelect={(col) => {
                    const cid = col.match(/all/i) ? undefined : col;
                    if (cid === collection) return;
                    setFiles(undefined);
                    setCollection(cid);
                  }}
                  disabled={!collections}
                  placeholder="Filter by collection"
                  items={
                    !collections
                      ? []
                      : [
                          { label: 'All Items', value: 'all' },
                          ...collections.map((c) => ({
                            label: c.name,
                            value: c.id,
                          })),
                        ]
                  }
                  size="small"
                />
              </div>
              <div>
                <Button
                  disabled={searchable}
                  icon="search"
                  size="small"
                  text
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchable(true);
                    if (searchRef.current)
                      setTimeout(() => searchRef.current.focus(), 500);
                  }}
                />
                {searchable && (
                  <Button
                    className="close"
                    icon="cross-sml"
                    size="small"
                    text
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchable(false);
                      doSearch(undefined);
                      if (searchRef.current)
                        setTimeout(() => {
                          searchRef.current.blur();
                          searchRef.current.value = '';
                        }, 500);
                    }}
                  />
                )}
                <input
                  ref={searchRef}
                  type="text"
                  placeholder="Search"
                  onChange={change}
                />
              </div>
            </header>
          )}
          <main>
            {files &&
              files
                .filter((f) =>
                  zip ? f.file_name.match(/(zip|rar|gz)$/i) : true
                )
                .map((f) => (
                  <Thumb
                    disabled={(() => {
                      if (!collectionId) return false;
                      if (f.collections?.find((c) => c.id === collectionId))
                        return true;
                      return false;
                    })()}
                    key={f.id}
                    selected={(() => {
                      if (multi && Array.isArray(selected)) {
                        if (selected.find((s) => s === f.id)) return true;
                      }
                      return f.id === selected;
                    })()}
                    onClick={() => {
                      if (!multi) {
                        if (selected === f.id) setSelected(undefined);
                        else setSelected(f.id);
                      } else {
                        // copy
                        const sc = [...selected];
                        const idx = sc.findIndex((s) => s === f.id);
                        // if found, then delete
                        if (idx >= 0) {
                          sc.splice(idx, 1);
                        } else {
                          sc.push(f.id);
                        }
                        setSelected(sc);
                      }
                    }}
                  >
                    <div className="pic">
                      {(() => {
                        if (multi && Array.isArray(selected)) {
                          if (selected.find((s) => s === f.id)) return true;
                        }
                        return f.id === selected;
                      })() && (
                        <Check>
                          <Icon name="tick" />
                        </Check>
                      )}
                      {(() => {
                        const src =
                          (f.new && f.urls.original) ||
                          f.urls.thumbnail ||
                          f.urls.original;
                        if (f.file_name.match(/svg/i))
                          return <object data={src}>{f.file_name}</object>;
                        if (f.file_type.match(/image/i))
                          return <img src={src} alt={f.file_name} />;
                        if (f.file_type.match(/video/i))
                          return <AiOutlinePlayCircle />;
                        if (f.file_type.match(/audio/i))
                          return <AiOutlineSound />;
                        if (f.file_name.match(/pdf/i))
                          return <AiOutlineFilePdf />;
                        return <AiOutlineFileText />;
                      })()}
                    </div>
                    <div className="desc">{f.file_name.slice(0, 20)}</div>
                  </Thumb>
                ))}
            {files?.length >= 20 && !complete && (
              <LazyLoad
                brandId={brand.id}
                collection={collection}
                lastValue={files[files?.length - 1].created_at}
                search={search}
                onLoad={({ data }) => {
                  if (data?.files?.length !== 0) {
                    setFiles([...files, ...data.files]);
                  }
                  if (data?.files.length < 20) setComplete(true);
                }}
                maxRows={20}
              />
            )}
          </main>
        </Media>
      </Spinner>
      <SettingsFooter
        showCancel
        showConfirm
        bordered
        onCancel={onCancel}
        confirmText={uploadOnly ? 'DONE' : 'CONFIRM'}
        onConfirm={(() => {
          if (uploadOnly === 1 && files.length > 0)
            return () => {
              onConfirm(files);
            };
          if (uploadOnly && files.length > 0) return onConfirm;

          if (!selected || (Array.isArray(selected) && selected.length === 0))
            return undefined;

          return () => {
            let payload;
            if (multi)
              payload = selected.map((s) => {
                const selectedFile = files.find((f) => f.id === s);
                if (selectedFile.new) delete selectedFile.new;
                return selectedFile;
              });
            else {
              payload = files.find((f) => f.id === selected);
              if (payload.new) delete payload.new;
            }
            onConfirm(payload);
          };
        })()}
      />
    </Wrap>
  );
};
