import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { VerticalColorSwatch } from '../ui';

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default ({ colors, onChange = () => {} }) => {
  const [coreColors, setCoreColors] = useState();
  const [selected, setSelected] = useState();

  const add = (c) => {
    if (coreColors.find((cc) => cc.color === c.color)) return;
    const newColors = [...coreColors, c];
    setCoreColors(newColors);
    onChange(newColors, c.color);
  };

  const del = (i) => {
    const newColors = [...coreColors];
    newColors.splice(i, 1);
    setCoreColors(newColors);
    onChange(newColors);
    setSelected();
  };

  const update = (i, c) => {
    const newColors = [...coreColors];
    newColors[i] = c;
    setCoreColors(newColors);
    onChange(newColors, c.color);
  };

  useEffect(() => {
    if (Array.isArray(colors)) setCoreColors([...colors]);
  }, [colors]);

  if (!coreColors) return <></>;

  return (
    <Wrap>
      {coreColors.map((c, i) => (
        <VerticalColorSwatch
          key={c.color}
          color={c}
          index={i}
          onDelete={del}
          onUpdate={update}
          edit={selected === i}
          onSelect={setSelected}
        />
      ))}
      <VerticalColorSwatch
        onSelect={setSelected}
        index={-1}
        edit={selected === -1}
        newColor
        onAdd={add}
      />
    </Wrap>
  );
};
