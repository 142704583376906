import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { doGetUser } from '../store/user';
import { addColors, updateColors, deleteColors } from '../svc/brand';

import ColorBrowser from './ColorBrowser';
import { Spinner } from '../ui';

import SettingsFooter from './editor/SettingsFooter';

const Wrap = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 620px;

  .desc {
    width: 484px;
    margin-bottom: 24px;

    p {
      color: #444444;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  main {
    margin-bottom: 32px;
    max-height: ${window.innerHeight - 252}px;
  }
`;

const clone = (o) => JSON.parse(JSON.stringify(o));
const stringy = (j) => JSON.stringify(j);

export default ({
  colors,
  onCancel = () => {},
  onConfirm = () => {},
  brandId,
}) => {
  const dispatch = useDispatch();

  const [coreColors, setCoreColors] = useState();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    await Promise.all([
      deleteColors(
        brandId,
        colors
          .filter((ic) => !coreColors.find((c) => ic.id === c.id))
          .map((ic) => ic.id)
      ),

      addColors(
        brandId,
        coreColors.filter((c) => !c.id)
      ),

      updateColors(
        brandId,
        coreColors.filter(
          (c) =>
            c.id &&
            !colors.find((ic) => JSON.stringify(c) === JSON.stringify(ic))
        )
      ),
    ]);

    dispatch(
      doGetUser(localStorage.getItem('userId'), (_, data) => {
        let color;
        if (selected) {
          color = data?.brand?.colors.find(
            (c) => c.color === selected || c.id === selected
          );
        }
        onConfirm(color);
        setLoading(false);
      })
    );
  };

  const reset = () => {
    setCoreColors(clone(colors));
  };

  useEffect(() => {
    if (!coreColors) {
      reset();
    }
  });

  return (
    <Wrap>
      <Spinner spin={loading}>
        <main className="frame">
          <h3>Add colours</h3>
          <div className="desc">
            <p>
              Add new colours to your colour palette. These colours will be
              available to use throughout your BrandBox.
            </p>
            <p>
              Fill in the relevant Hex value/RGB for digital communications
              and/or CMYK and Pantone for print, if available
            </p>
            <p>
              Note: You can update the colour values anytime in Settings {'>'}
              Styles.
            </p>
          </div>

          <ColorBrowser
            colors={coreColors}
            onChange={(ccs, cs) => {
              setCoreColors(ccs);
              setSelected(cs);
            }}
          />
        </main>

        <SettingsFooter
          bordered
          showCancel
          showConfirm
          onCancel={onCancel}
          onConfirm={(() => {
            if (stringy(coreColors) !== stringy(colors)) return confirm;
          })()}
        />
      </Spinner>
    </Wrap>
  );
};
