import React from 'react';

import { ColorSwatch } from '../../ui';
import Placeholder from './PlaceHolder';

export default (props) => {
  const { color: c, secondaryColor: sc, edit, readOnly, onClickHolder } = props;
  const { color, placeholder } = c || {};
  if (!edit && !color && placeholder && !readOnly)
    return (
      <Placeholder
        placeholder={placeholder}
        height={90}
        onClick={onClickHolder}
      />
    );
  if (!edit && !color) return <></>;
  c.secondaryColor = sc?.color;
  c.secondaryCmyk = sc?.cmyk;
  c.secondaryName = sc?.name;
  return <ColorSwatch {...c} />;
};
