import React, { useState } from 'react';
import { Spinner } from '../ui';
import SettingsFooter from './editor/SettingsFooter';
import { deleteBrandUser } from '../svc/brand';

export default ({
  onCancel = () => {},
  onDelete = () => {},
  userId,
  brandId,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const { data, error } = await deleteBrandUser(brandId, userId);
    onDelete(error, data);
    setLoading(false);
  };
  return (
    <Spinner spin={loading}>
      <h3 style={{ fontWeight: 'normal' }}>Delete user account??</h3>
      <p style={{ fontWeight: '300', fontSize: 12, color: '#444444' }}>
        If you delete this user’s account, all user information will be removed
        and cannot be undone.
      </p>
      <SettingsFooter
        showCancel
        showConfirm
        onCancel={onCancel}
        onConfirm={handleDelete}
      />
    </Spinner>
  );
};
