import axios, { CancelToken } from 'axios';
import axiosRetry from 'axios-retry';

// base timeout for our cancel token. 10k is good based from most devs.
const defaultTimeout = 10000;
const devTimeout = 500;

const baseURL = '/api/v1';

const client = axios.create({
  baseURL,
});

axiosRetry(client, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
});

// Returns { data, status, error }
// if success, then data will be available
// otherwise, error.
const request = async (method, path, data, headers = {}) => {
  // add a little delay if we're on dev mode
  if (window.location.host.match(/(localhost|127|192)/i))
    await new Promise((ok) => setTimeout(ok, devTimeout));

  // cancel et al
  const token = CancelToken;
  const source = token.source();
  const timeout = setTimeout(
    () => source.cancel('Request timeout!'),
    defaultTimeout
  );

  const axiosRetryConfig = data['axios-retry'] || {};
  delete data['axios-retry'];

  const extra = {};
  const userToken = localStorage.getItem('token');
  if (userToken) extra['Authorization'] = `Bearer ${userToken}`; // eslint-disable-line

  try {
    const { data: response, status } = await client({
      url: path,
      data,
      'axios-retry': axiosRetryConfig,
      cancelToken: source.token,
      method,
      headers: {
        'content-type': 'application/json',
        ...extra,
        ...headers,
      },
    });

    const { token: t, user_details: u } = response;
    if (t && u?.brand?.id) {
      localStorage.setItem('token', response.token);
      localStorage.setItem('userId', response.user_details.id);
    }

    clearTimeout(timeout);
    return { data: response, status };
  } catch (e) {
    // check for canceled/timeout error
    if (e.message && e.message.match(/timeout/i)) {
      return {
        error: e.message,
        status: 408,
      };
    }

    // catch other error
    if (!e.response) {
      return {
        error: e.message,
        status: Number(e.message.split(' ').pop()),
      };
    }

    // server specific error
    const {
      response: {
        data: { error = 'Something went wrong, try again later.' },
        status,
      },
    } = e;

    if (error.match(/(expir|authorized)/i)) {
      localStorage.clear();
      window.location.href = '/';
    }

    return { error, status };
  }
};

export const post = (path, payload, headers = {}) => {
  return request('post', path, payload, headers);
};

export const put = (path, payload, headers = {}) => {
  return request('put', path, payload, headers);
};

export const get = (path, opts = {}, headers = {}) => {
  return request('get', path, opts, headers);
};

export const del = (path, opts = {}, headers = {}) => {
  return request('delete', path, opts, headers);
};

export const download = (url, opts = {}) => {
  return client({
    url,
    method: 'get',
    responseType: 'blob',
    ...opts,
  });
};
