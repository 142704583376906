import React from 'react';
import styled from 'styled-components';

const Wrap = styled.span`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: ${({ size = 35 }) => size}px;
  height: ${({ size = 35 }) => size}px;
`;
const ColorBox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  width: ${({ size = 35 }) => size}px;
  height: ${({ size = 35 }) => size}px;
  background-color: #${({ color }) => (color && color.replace('#', '')) || 'ffffff'};
  border: 1px solid #${({ color }) => (color && color.replace('#', '')) || 'aaaaaa'};

  ${({ color, size = 35 }) =>
    !color &&
    `
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: ${size / 2}px;
      left: -${(Math.sqrt(size * size * 2) - size) / 2}px;
      width: ${Math.sqrt(size * size * 2)}px;
      transform: rotate(-45deg) translate(35%, 10%);
      height: ${Math.sqrt(size * size * 2)}px;
      border-top: 1px solid #aaaaaa;
    }
  `}
`;

export default (props) => (
  <Wrap {...props}>
    <ColorBox {...props} />
  </Wrap>
);
