/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from './Modal';
import { Button, Checkbox } from '../ui';
import playButton from '../playbutton.png';
import videoCover from '../welcome-walkthrough-cover.png';

const ModalTitle = styled.p`
  margin-bottom: 18px;
  font-size: 30px;
  line-height: normal;
  letter-spacing: normal;
`;

const ModalSubTitile = styled.p`
  width: 382px;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: #444444;
  margin-bottom: 11px;
  > a,
  a:active,
  a:visited {
    padding: 0;
  }
`;

const VideoWrapper = styled.div`
  margin: 18px 0 29px 0;
  position: relative;
`;

const Gradiant = styled.div`
  background-image: url(${videoCover});
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0px;
`;

const PlayButton = styled.img`
  height: 60px;
  width: 60px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default () => {
  const { user } = useSelector(({ user: usr }) => usr);
  const [checked, setChecked] = useState(false);
  const [play, setPlay] = useState(false);
  const [welcome, setWelcome] = useState(
    localStorage.getItem(`welcome-${user?.id}`)
  );

  const togglePlay = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPlay(!play);
  };

  const handleInputChange = () => {
    setChecked(!checked);
  };

  if (!user) return <></>;

  return (
    <>
      <Modal open={welcome}>
        <ModalTitle>Welcome {user.first_name}!</ModalTitle>
        <ModalSubTitile>
          Your 30-day Brand Starter trial is ready to go.
        </ModalSubTitile>

        <ModalSubTitile>
          Here’s a 2-minute tutorial to help you get started
        </ModalSubTitile>

        <VideoWrapper>
          {!play && (
            <PlayButton
              src={playButton}
              className="hero-video-btn"
              alt="video-play"
              onClick={togglePlay}
            />
          )}
          {!play && <Gradiant />}
          <video
            width="485px"
            height="273px"
            preload="auto"
            controls
            autoPlay={play}
          >
            <source
              src="https://storage.googleapis.com/brandbox_v2_production/pub/2022/bb_gettingstarted_audiocleaned.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </VideoWrapper>
        <ModalFooter>
          <Checkbox
            name="agree-terms"
            label="I agree to the Terms of use"
            onChange={handleInputChange}
            checked={checked}
          />
          <Button
            icon="next"
            className="right"
            onClick={() => {
              setWelcome(undefined);
              localStorage.removeItem(`welcome-${user.id}`, true);
            }}
            disabled={!checked}
          >
            Start trial
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
