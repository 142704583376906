///////////////////////////////////////////////////////
//
// FormDialog
//
// This is brandbox specific title + subtitle kind of
// layout.
//
// Based on Jamie's current zeplin handoff.
//
///////////////////////////////////////////////////////
import React from 'react';
import styled from 'styled-components';

const Dialog = styled.div`
  & h1,
  & h2,
  & h3,
  & h4,
  & p {
    margin-bottom: 8px;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 300;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  max-width: 380px;
`;

export default (p) => {
  const { title, description } = p; // eslint-disable-line
  return (
    <Dialog>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Dialog>
  );
};
