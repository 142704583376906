import { createSlice } from '@reduxjs/toolkit';

import {
  getTouchpoints as ga,
  getCollectionsWithTouchpoints as gcwtp,
} from '../svc/brand';

const touchpointSlice = createSlice({
  name: 'touchpoint',

  initialState: {
    error: null,
  },

  reducers: {
    setTouchpoints: (state, { payload }) => {
      state.touchpoints = payload;
    },

    setCollections: (state, { payload }) => {
      state.collections = payload;
    },

    deleteTouchpoint: (state, { payload: touchpoint }) => {
      state.touchpoints.touchpoints = state.touchpoints.touchpoints.filter(
        (t) => t.id !== touchpoint.id
      );
    },
  },
});

export const { setTouchpoints, setCollections, deleteTouchpoint } =
  touchpointSlice.actions;

// getTouchpoints
export const getTouchpoints =
  (bid, cid, query, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await ga(bid, cid, query);
    if (!error) {
      dispatch(setTouchpoints(data));
    }
    cb(error, data);
  };

// getCollections
export const getCollections =
  (
    bid,
    search,
    lastValue,
    sortField = 'created_at',
    ascending = false,
    maxRows
  ) =>
  async (dispatch) => {
    const query = {
      ascending,
      sort_field: sortField,
    };
    if (search) query.search = search;
    if (lastValue) query.last_value = lastValue;
    if (maxRows) query.max_rows = maxRows;
    const { error, data } = await gcwtp(bid, query);
    if (!error) {
      dispatch(setCollections(data));
    }
  };

// getAll
export const getAll =
  (
    bid,
    cid,
    search,
    lastValue,
    sortField = 'created_at',
    ascending = false,
    maxRows
  ) =>
  async (dispatch) => {
    const query = {
      ascending,
      sort_field: sortField,
    };
    if (search) query.search = search;
    if (lastValue) query.last_value = lastValue;
    if (maxRows) query.max_rows = maxRows;
    const { error, data } = await ga(
      bid,
      cid === 'all' ? undefined : cid,
      query
    );
    if (!error) {
      dispatch(setTouchpoints(data));
    }
  };

export default touchpointSlice.reducer;
