import React from 'react';
import styled from 'styled-components';

import SettingsHeader from './SettingsHeader';

const Wrap = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  padding: 32px 16px;
  margin: 0 auto;
  width: 960px;
  margin-bottom: 64px;

  & section {
    padding: 12px;
    margin-bottom: 32px;
  }

  p.description {
    width: 484px;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 300;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    line-height: 22px;
  }

  h5 {
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    color: #000000;
    margin-bottom: 12px;
  }
`;

export default ({
  showCancel,
  cancelText,
  confirmText,
  confirmIcon,
  showConfirm,
  onCancel,
  onConfirm,
  children,
}) => {
  return (
    <>
      <SettingsHeader
        fixed
        cancelText={cancelText}
        confirmText={confirmText}
        confirmIcon={confirmIcon}
        showCancel={showCancel}
        showConfirm={showConfirm}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <Wrap>{children}</Wrap>
    </>
  );
};
