/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import {
  File,
  Card,
  Input,
  Select,
  Button,
  Progress,
  Container,
  ColorSelect,
  FormDialog,
  FlexCenterMid,
} from '../ui';

import { getBrand } from '../svc/brand';

import { toast } from '../ui/Toast';

import {
  stepUp,
  setStep,
  doLogin,
  doSignup as signup,
  doCheckEmail as checkEmail,
  setCredential,
  setErrorMessage,
} from '../store/user';

const PasswordWrapper = styled.div`
  position: relative;
`;

// checks for password strength
// returns, true or false
const strongPassword = (pwd) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
    pwd
  );
};

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [fonts, setFonts] = useState(null);

  const { step, loading, credential, errorMessage } = useSelector(
    ({ user, global }) => ({ ...user, ...global })
  );

  const { brandName, brandProperties } = credential;

  const navigate = (link) => {
    history.push(link);
  };

  const emailExists = (msg) => {
    return /(exist|taken|not available)/i.test(msg);
  };

  const onChange = (key, val) => {
    dispatch(setErrorMessage(''));
    dispatch(
      setCredential({
        ...credential,
        [key]: val,
      })
    );
  };

  const checkEmailHandler = (e) => {
    e.preventDefault();
    dispatch(checkEmail(credential));
  };

  useEffect(() => {
    if (step !== 5) return;
    (async () => {
      const fontsString = localStorage.getItem('google-fonts');
      if (fontsString) {
        setFonts(JSON.parse(fontsString));
        return;
      }

      const { data } = await axios.get(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAmjpXmQ8mJYtd9nOkSy_pfZVYEY_ScAyY'
      );

      if (data) {
        const { items } = data;
        const gfonts = items.reduce((s, n) => {
          if (n.files.regular)
            s.push({
              name: n.family,
              url: n.files.regular,
            });

          return s;
        }, []);

        setFonts(gfonts);
        localStorage.setItem('google-fonts', JSON.stringify(gfonts));
      }
    })();
  }, [step]);

  return (
    <FlexCenterMid>
      <Progress
        color="#4b9aff"
        style={{ width: 620, marginBottom: 24 }}
        label="Create your account"
        progress={(step / 6) * 100}
      />
      <Card width={620} loading={loading} style={{ padding: '54px' }}>
        {step === 1 && (
          <>
            <Container>
              <FormDialog
                title="Let’s start your 30-day Brand Starter free trial"
                description="We’ll have you up and running in no time."
              />
            </Container>

            {errorMessage && !emailExists(errorMessage) && (
              <Container>
                <p className="error">{errorMessage}</p>
              </Container>
            )}

            <form onSubmit={checkEmailHandler}>
              <Container>
                <Input
                  block
                  error={
                    credential.firstName && credential.firstName.length <= 2
                  }
                  errorMessage={
                    credential.firstName && credential.firstName.length <= 2
                      ? 'Name is too short'
                      : ''
                  }
                  excludeKey={/[^a-zA-Z\b\t ]/}
                  onChange={(e) => onChange('firstName', e.target.value)}
                  placeholder="Enter name"
                />
                <Input
                  block
                  error={
                    (errorMessage && emailExists(errorMessage)) ||
                    (credential.email &&
                      !credential.email.match(
                        /[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/
                      ))
                  }
                  errorMessage={
                    (errorMessage && emailExists(errorMessage) && (
                      <>
                        This email is already registered. Try a different email
                        or log in{' '}
                        <span
                          className="link"
                          onClick={() => navigate('/login')}
                        >
                          here
                        </span>{' '}
                      </>
                    )) ||
                    (credential.email &&
                      !credential.email.match(
                        /[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/
                      ) && <>Please enter a valid email address</>)
                  }
                  onChange={(e) => onChange('email', e.target.value)}
                  placeholder="Enter email address"
                />
              </Container>

              <Container>
                <Button
                  icon="next"
                  disabled={
                    !credential.firstName ||
                    !credential.email ||
                    !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
                  }
                  className="right"
                >
                  NEXT
                </Button>
              </Container>
            </form>
          </>
        )}

        {step === 2 && (
          <>
            <Container>
              <FormDialog
                title="Set your password"
                description="Choose a strong password with at least 8 characters containing a capital letter, a number, and a special character."
              />
            </Container>

            {errorMessage && (
              <Container>
                <p className="error">An error occured:{errorMessage}.</p>
              </Container>
            )}

            <form onSubmit={(e) => !e.preventDefault() && dispatch(stepUp())}>
              <Container>
                <PasswordWrapper>
                  <Input
                    error={
                      credential.password &&
                      !strongPassword(credential.password)
                    }
                    block
                    type="password"
                    toggle
                    onChange={(e) => onChange('password', e.target.value)}
                    placeholder="Enter a password"
                  />
                  {credential.password &&
                    !strongPassword(credential.password) && (
                      <p className="error">
                        Passwords must have at least 8 characters containing a
                        capital letter, a number, and a special character.
                      </p>
                    )}
                </PasswordWrapper>
                <PasswordWrapper>
                  <Input
                    className="m-0"
                    disabled={
                      !credential.password ||
                      !strongPassword(credential.password)
                    }
                    error={
                      credential.confirmPassword &&
                      credential.password !== credential.confirmPassword
                    }
                    block
                    toggle
                    type="password"
                    onChange={(e) =>
                      onChange('confirmPassword', e.target.value)
                    }
                    placeholder="Confirm password"
                  />
                </PasswordWrapper>
                {credential.confirmPassword &&
                  credential.password !== credential.confirmPassword && (
                    <p className="error">Your passwords do not match</p>
                  )}
              </Container>
              <Container>
                <Button
                  icon="next"
                  disabled={
                    !credential.password ||
                    !strongPassword(credential.password) ||
                    credential.password !== credential.confirmPassword
                  }
                  className="right"
                >
                  NEXT
                </Button>
              </Container>
            </form>
          </>
        )}

        {step === 3 && (
          <>
            <Container>
              <FormDialog title="What's the name of your brand?" />
            </Container>

            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const { error } = await getBrand(
                  brandName.toLowerCase().trim()
                );

                if (!error) {
                  dispatch(
                    setErrorMessage(
                      'The brand name is already in use. Try a different brand name.'
                    )
                  );
                  return;
                }

                dispatch(stepUp());
              }}
            >
              <Container>
                <Input
                  block
                  onChange={(e) => onChange('brandName', e.target.value)}
                  placeholder="Enter brand name"
                  error={(brandName && brandName.length <= 2) || errorMessage}
                  excludeKey={/[^a-z0-9\s\b]/i}
                  errorMessage={
                    (brandName &&
                      brandName.length <= 2 &&
                      'Brand name is too short') ||
                    errorMessage
                  }
                />
              </Container>
              <Container>
                <Button
                  icon="next"
                  disabled={!brandName || brandName.length < 1}
                  className="right"
                >
                  NEXT
                </Button>
              </Container>
            </form>
          </>
        )}

        {step === 4 && (
          <>
            <Container>
              <FormDialog
                title={`What is ${brandName}’s primary colour?`}
                description="This optional step will help customise your BrandBox. Enter your brand colour's hex value below, or if you're unsure of it, choose a default colour from the dropdown."
              />
            </Container>

            <form onSubmit={(e) => !e.preventDefault() && dispatch(stepUp())}>
              <Container>
                <ColorSelect
                  block
                  onColorChange={(color) => {
                    if (!color) return;
                    onChange('brandProperties', {
                      ...brandProperties,
                      color: { name: 'Primary', color },
                    });
                  }} // eslint-disable-line
                />
              </Container>
              <Container>
                <Button
                  icon="next"
                  disabled={!brandProperties || !brandProperties.color}
                  className="right"
                >
                  NEXT
                </Button>
                <Button text icon="next" className="right">
                  SKIP
                </Button>
              </Container>
            </form>
          </>
        )}

        {step === 5 && (
          <>
            <Container>
              <FormDialog
                title={`What font does ${brandName} use?`}
                description="This optional step will help customise your BrandBox. Choose a Google font for your brand from the dropdown below, or upload the font files if you have them."
              />
            </Container>

            <form onSubmit={(e) => !e.preventDefault() && dispatch(stepUp())}>
              <Container>
                <Select
                  block
                  disabled={!fonts}
                  defaultValue=""
                  onChange={(val, ind) => {
                    if (!val.match(/^(http)?.+\.(ttf|otf|woff)$/)) return;
                    onChange('brandProperties', {
                      ...brandProperties,
                      font: {
                        name: fonts[ind - 1].name,
                        url: val,
                        variant: 'regular',
                      },
                    });
                  }}
                >
                  <option value="" disabled>
                    {fonts
                      ? 'Select from Google Fonts'
                      : 'Loading google fonts...'}
                  </option>
                  {Array.isArray(fonts) &&
                    fonts.map((f) => (
                      <option key={f.name} value={f.url}>
                        {f.name}
                      </option>
                    ))}
                </Select>
                <File
                  block
                  placeholder="Or, add a font from file"
                  endpoint="/api/v1/files"
                  onUpload={({ data, status }) => {
                    if (status !== 200) return;
                    onChange('brandProperties', {
                      ...brandProperties,
                      font: {
                        name: data.file_name,
                        url: data.urls.original,
                        variant: 'regular',
                      },
                    });
                  }}
                  accept={['.otf, .ttf']}
                />
              </Container>
              <Container>
                <Button
                  icon="next"
                  disabled={!fonts || !brandProperties || !brandProperties.font}
                  className="right"
                >
                  NEXT
                </Button>
                <Button text icon="next" disabled={!fonts} className="right">
                  SKIP
                </Button>
              </Container>
            </form>
          </>
        )}

        {step === 6 && (
          <>
            <Container>
              <FormDialog
                title="And the final step, upload your logo
                "
                description="Upload your brand logo (supports JPEG, PNG, GIF, or SVG). Max. file size 1MB If you do not have it handy, upload it at a later time.
                "
              />
            </Container>

            <form
              onSubmit={(e) =>
                !e.preventDefault() &&
                dispatch(
                  signup(credential, (error) => {
                    if (!error) {
                      dispatch(
                        doLogin(credential, (err, data) => {
                          if (!err) {
                            localStorage.setItem(
                              `welcome-${data.user_details.id}`,
                              'welcome'
                            );
                            localStorage.setItem(
                              `tooltip-color-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-font-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-asset-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-row-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-section-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-section-toggle-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-media-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-touchpoint-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `tooltip-trial-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem(
                              `insider-${data.user_details.id}`,
                              true
                            );
                            localStorage.setItem('token', data.token);
                            localStorage.setItem(
                              'userId',
                              data.user_details.id
                            );
                            localStorage.setItem(
                              'brandId',
                              data.user_details.brand.id
                            );
                            window.location.href = '/basics';
                          }
                        })
                      );
                    } else {
                      toast.error(
                        'Signup failed',
                        'The brand name is already in use. Try a different brand name.'
                      );
                      dispatch(setStep(3));
                    }
                  })
                )
              } // eslint-disable-line
            >
              <Container>
                <File
                  block
                  accept={['.png, .jpg, .jpeg', '.svg', '.gif']}
                  endpoint="/api/v1/files"
                  onUpload={({ data = {} }) => {
                    onChange('brandProperties', {
                      ...brandProperties,
                      logo: data?.urls?.original,
                    });
                  }}
                />
              </Container>
              <Container>
                <Button
                  icon="next"
                  disabled={
                    !brandProperties ||
                    !brandProperties.logo ||
                    !brandProperties.logo.match(
                      /^(http)?.+\.(jpg|png|jpeg|svg|gif)$/
                    )
                  }
                  className="right"
                >
                  NEXT
                </Button>
                <Button text icon="next" className="right">
                  SKIP
                </Button>
              </Container>
            </form>
          </>
        )}
      </Card>
    </FlexCenterMid>
  );
};
