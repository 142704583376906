import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Spinner } from '../ui';
import SettingsFooter from './editor/SettingsFooter';

import { removeFileFromCollections, addFileToCollections } from '../svc/brand';

const Wrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 64px;

  p {
    margin: 0;
    margin-bottom: 2px;
  }

  label {
    font-size: 12px;
    font-weight: 300;
    margin-left: 12px;
    position: relative;
    top: -2px;
  }

  .title {
    font-size: 15px;
  }
  .sub {
    opacity: 1;
    font-size: 12px;
  }
  .mute {
    color: #828282;
    line-height: 16px;
    margin-bottom: 12px;
  }

  & > div:not(:last-child) {
    margin-right: 24px;
  }

  & > .f1 {
    flex: 1;
  }

  & > .f2 {
    flex: 2;
  }
  & > .f3 {
    flex: 3;
  }

  & > .thumb {
    background: #000000 url(${({ thumbnail }) => thumbnail}) center center /
      cover no-repeat;
    position: relative;
    overflow: hidden;
  }
`;

const Error = styled.p`
  position: absolute;
  bottom: -28px;
  left: 0;
  color: red;
`;

export default ({
  file,
  onComplete = () => {},
  onCancel = () => {},
  collections: cols,
}) => {
  let isCanceled = false;
  const [collections, setCollections] = useState(
    [...cols].map((a) => {
      const c = { ...a };
      c.selected = false;
      if (file.collections.find((fc) => fc.id === c.id)) c.selected = true;
      return c;
    })
  );

  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    if (isCanceled) return;
    setLoading(true);
    setError(undefined);

    const newCols = collections
      .filter((c) => c.selected === true)
      .filter((c) => !file.collections.find((fc) => fc.id === c.id))
      .map((c) => c.id);

    const delCols = collections
      .filter((c) => c.selected === false)
      .filter((c) => file.collections.find((fc) => fc.id === c.id))
      .map((c) => c.id);

    await Promise.all([
      addFileToCollections(file.brand_id, file.id, newCols),
      removeFileFromCollections(file.brand_id, file.id, delCols),
    ]);

    onComplete();
    setLoading(false);
  };

  const updateCollection = (value, index) => {
    if (isCanceled) return;
    const newCollections = [...collections];
    newCollections[index] = { ...newCollections[index], selected: value };
    setCollections(newCollections);
  };

  useEffect(
    () => () => {
      setCollections(undefined);
      setError(undefined);
      setLoading(undefined);
      isCanceled = true;
    },
    []
  );

  return (
    <Spinner spin={loading}>
      <h3 style={{ fontWeight: 'normal' }}>Collections</h3>
      <Wrap>
        <div className="f3">
          <p className="sub mute">
            Our collections are like playlists for your files. Add files to
            multiple collections without duplicating the files and taking up
            extra storage space.
          </p>
          <p>&nbsp;</p>
          {collections.map((c, i) => (
            <p key={c.id}>
              <input
                id={c.id}
                name={c.id}
                type="checkbox"
                defaultChecked={c.selected}
                onChange={(e) => updateCollection(e.target.checked, i)}
              />
              <label htmlFor={c.id}>{c.name}</label>
            </p>
          ))}
        </div>
        <div className="f1">&nbsp;</div>
      </Wrap>
      <SettingsFooter
        showCancel
        bordered
        showConfirm
        onCancel={onCancel}
        onConfirm={confirm}
      />
      {error && (
        <Error className="error">An error occured! Please try again.</Error>
      )}
    </Spinner>
  );
};
