import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, DropDown, Tooltip } from '../../ui';
import { setSelected, updateSection, updateRow } from '../../store/page';

import SettingsFooter from './SettingsFooter';

const Head = styled.h4`
  font-size: 24px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 19px;
`;

const Sub = styled.h5`
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.06px;
  color: #000000;
  margin-bottom: 8px;
`;
const Text = styled.p`
  width: 437px;
  height: 50px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.42;
  letter-spacing: 0.05px;
  color: #444444;
  margin-bottom: 8px;
`;

const selectLayout = [
  { label: '1 column', value: 0, img: '1col.jpg' },
  { label: '2 column A', value: 1, img: '2cola.jpg' },
  { label: '2 column B', value: 2, img: '2colc.jpg' },
  { label: '2 column C', value: 3, img: '2colb.jpg' },
  { label: '3 column', value: 4, img: '3col.jpg' },
];

const selector = ({ user }) => user;

export default ({ props, section }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(selector);

  const { collapse, rows } = props;
  const { layout } = rows.length === 0 ? 0 : rows[0];

  const [enabled, setEnabled] = useState();
  const [newLayout, setNewLayout] = useState();
  const [toggleTooltip, setToggleTooltip] = useState(
    localStorage.getItem(`tooltip-section-toggle-${user.id}`)
  );

  useEffect(() => {
    if (layout === undefined) return;
    setNewLayout(layout);
    setEnabled(collapse);
  }, []); // eslint-disable-line

  const cancel = () => {
    dispatch(setSelected(undefined));
  };

  const confirm = () => {
    if (!enabled) {
      dispatch(
        updateRow({
          row: 0,
          layout: newLayout,
          section,
        })
      );
    }
    dispatch(
      updateSection({
        section,
        collapse: enabled,
      })
    );
    cancel();
  };

  return (
    <>
      <Head>Section Settings</Head>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Sub>Collapsible section</Sub>
        {toggleTooltip && (
          <Tooltip
            style={{ marginLeft: 10 }}
            text="Toggle Section"
            onDismiss={() => {
              setToggleTooltip(undefined);
              localStorage.removeItem(
                `tooltip-section-toggle-${user.id}`,
                true
              );
            }}
          />
        )}
      </div>
      <Text>
        Enable collapsible section to neatly group all your content in the row
        and toggle between hiding and showing your content.
      </Text>
      <Switch
        size="small"
        width={315}
        defaultValue={collapse}
        onChange={(value) => {
          setEnabled(value);
          setToggleTooltip(undefined);
          localStorage.removeItem(`tooltip-section-toggle-${user.id}`, true);
        }}
      />
      <br />
      {!enabled && (
        <>
          <Sub>Column layout</Sub>
          <Text>Add or remove columns in the row.</Text>
          <Text>
            Note: Removing columns will not delete your content. The content
            will be shifted to the adjacent column.
          </Text>
          <DropDown
            size="small"
            block
            items={selectLayout}
            onSelect={setNewLayout}
            disabled={false}
            dropDownType="text"
            defaultValue={newLayout}
          />
        </>
      )}
      <SettingsFooter
        style={{
          marginTop: 32,
          padding: 0,
        }}
        onCancel={cancel}
        onConfirm={confirm}
        showCancel
        showConfirm
      />
    </>
  );
};
