import React, { useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { DropDown, Button, Switch, Modal, Tooltip } from '../../ui';

import { updateItem } from '../../store/page';
import { setFonts } from '../../store/user';

import AddFonts from '../AddFonts';

const Ctl = styled.div`
  box-sizing: border-box;

  .add {
    padding: 0;
    margin-top: 12px;
    font-size: 12px;
    font-weight: normal;
  }

  div {
    margin-bottom: 12px;
  }
`;

const selectUsage = [
  { label: 'Headings & body text', value: 1 },
  { label: 'Headings only', value: 2 },
  { label: 'Body text only', value: 3 },
];

const selectSizing = [
  { label: 'Disable heading', value: 0 },
  { label: 'Small heading', value: 1 },
  { label: 'Regular heading', value: 2 },
  { label: 'Large heading', value: 3 },
];
const selector = ({ user }) => user;

export default (attrs) => {
  const { props, section, row, column, item } = attrs;
  const { usage = 1, position = false, size = 0, url, variant } = props;

  const dispatch = useDispatch();
  const { user } = useSelector(selector);

  const [open, setOpen] = useState(false);
  const [fontTooltip, setFontTooltip] = useState(
    localStorage.getItem(`tooltip-font-${user.id}`)
  );

  const update = (newProps) => {
    dispatch(
      updateItem({
        row,
        item,
        props: { ...newProps },
        column,
        section,
      })
    );
  };

  const onFont = (i) => {
    update({ ...user.brand.fonts[i] });
  };

  const onPositionChange = (v) => {
    update({ position: v });
  };

  const onSizeChange = (v) => {
    update({ size: v });
  };

  const onUsageChange = (v) => {
    update({ usage: v });
  };

  return (
    <Ctl>
      <section>
        <h3>Font display</h3>
        <p>Choose and edit an existing font, or add a new font.</p>
        <DropDown
          size="small"
          items={user.brand.fonts.map((f, i) => ({
            label: `${f.name.split('.')[0].replace('-', ' ')}`,
            value: i,
          }))}
          block
          disabled={false}
          onSelect={onFont}
          defaultValue={(() => {
            const idx = user.brand.fonts.findIndex(
              (f) => f.url === url && f.variant === variant
            );
            if (idx >= 0) return idx;
            return undefined;
          })()}
          dropDownType="text"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Button
            text
            size="small"
            icon="add-sml"
            onClick={() => {
              setOpen(!open);
              setFontTooltip(undefined);
              localStorage.removeItem(`tooltip-font-${user.id}`, true);
            }}
            className="add"
            style={{ letterSpacing: 'normal', marginRight: 0 }}
          >
            Manage fonts
          </Button>
          {fontTooltip && (
            <Tooltip
              text="Add a new font"
              onDismiss={() => {
                setFontTooltip(undefined);
                localStorage.removeItem(`tooltip-font-${user.id}`, true);
              }}
            />
          )}
        </div>
      </section>
      <section>
        <h3>Font usage</h3>
        <p>Specify how your font will be used and generate a preview.</p>
        <DropDown
          size="small"
          items={selectUsage}
          block
          disabled={false}
          onSelect={onUsageChange}
          defaultValue={usage}
          dropDownType="text"
        />
      </section>
      <section>
        <h3>Heading Options</h3>
        <p>Enable heading, choose its size and specify its placement.</p>
        <DropDown
          items={selectSizing}
          onSelect={onSizeChange}
          defaultValue={size}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        {size !== 0 && (
          <Switch
            size="small"
            block
            defaultValue={position}
            onChange={onPositionChange}
            leftText="Below"
            rightText="Above"
          />
        )}
      </section>
      {open && (
        <Modal visible={open}>
          <AddFonts
            fonts={[...user.brand.fonts]}
            onCancel={() => setOpen(false)}
            onConfirm={(fonts) => {
              dispatch(setFonts(fonts));
              setOpen(false);
              update([...fonts].pop());
            }}
            brandId={user.brand.id}
          />
        </Modal>
      )}
    </Ctl>
  );
};
