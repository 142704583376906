import React from 'react';
import styled from 'styled-components';

import { Button } from '../../ui';

const Footer = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ bordered }) =>
    bordered &&
    `
    border-top: 1px solid #d2d2d2;
    padding-top: 25px;
  `}
`;

export default ({
  style,
  onCancel,
  bordered = false,
  onConfirm,
  cancelText = 'CANCEL',
  showCancel,
  confirmText = 'CONFIRM',
  showConfirm,
  disabled,
}) => {
  return (
    <Footer style={style} bordered={bordered}>
      {showCancel && (
        <Button icon="cross" text onClick={() => onCancel()}>
          {cancelText}
        </Button>
      )}
      {showConfirm && (
        <Button
          icon={confirmText.match(/done/i) ? 'tick' : 'next-sml'}
          onClick={onConfirm}
          disabled={!onConfirm || disabled}
        >
          {confirmText}
        </Button>
      )}
    </Footer>
  );
};
