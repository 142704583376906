import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineLoading } from 'react-icons/ai';

import { Spinner } from '../../ui';
import brandboxLogo from '../../brandbox.png';
import loginCover from '../../login-image.png';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #eaeaea;

  img.cover {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: fixed;
    object-fit: cover;
  }
`;

const Card = styled.div`
  z-index: 1;
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  padding: 15px 15px 0px;
  width: 290px;
  background-color: #ffffff;
  margin: 0 0 0 137px;
  padding-bottom: 64px;
  outline: 1px solid #cccccc77;

  ${({ loading }) =>
    loading &&
    css`
      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        z-index: 3;
        margin: -16px 0 0 -16px;
        animation: spin 0.5s linear infinite;
        opacity: 0.75;
        font-weight: bold;
      }

      &::before {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        background-color: white;
        color: #aaa;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        z-index: 2;
        align-items: center;
        justify-content: center;
      }
    `}
`;

const BrandBox = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  margin-right: 3px;
  margin-bottom: 32px;
  ${({ color }) =>
    color &&
    `
    background-color: #${color.replace('#', '')};
  `}
  a {
    padding: 0;
    widht: 100%;
    height: 100%;
  }
  img {
    width: 50px;
    height: 50px;
  }
`;

export default ({ children }) => {
  const [cover, setCover] = useState();
  const { brand, loading } = useSelector(({ global }) => global);

  useEffect(() => {
    if (loading) return;

    if (brand) setCover(brand.styles?.background);
    else setCover(`${window.location.origin}${loginCover}`);
  }, [brand, loading]); // eslint-disable-line

  if (!brand && localStorage.getItem('brandId')) return <Spinner spin />;

  return (
    <Wrapper>
      {cover && <img className="cover" src={cover} alt="cover" />}
      <Card loading={loading ? 'true' : ''} width={290}>
        {loading && <AiOutlineLoading />}
        <BrandBox color="black">
          <Link to="/">
            <img src={brandboxLogo} alt="bb-logo" />
          </Link>
        </BrandBox>
        {brand && (
          <BrandBox
            color={(() => {
              const color = brand?.colors.find(
                (c) => c.id === brand?.styles?.logo_color
              );
              return color?.color || 'd2d2d2'; // eslint-disable-line
            })()}
          />
        )}
        {children}
      </Card>
    </Wrapper>
  );
};
