import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.1px;
  color: #333333;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 7.5px !important;
  &.block {
    width: 100%;
  }
  & > .left {
    float: left;
  }
  & > .right {
    float: right;
  }
  ${({ title, size }) =>
    title &&
    `
    font-size: ${(() => {
      if (size === 2) return 22;
      if (size === 3) return 28;
      return 15;
    })()}px;
  `}
`;

const Symbol = styled(Label)`
  color: #999999;
  width: auto;
  margin-right: 5px;
`;

const ColorBlock = styled.div`
  height: 90px;
  width: 100%;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    #d2d2d2 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  border: ${({ valid }) => (valid ? 'none' : '1px solid #d2d2d2')};
  margin-bottom: 8px !important;
  ${({ valid, color }) =>
    valid &&
    `
    background: #${color.replace('#', '')};
  `}
  ${({ validGradiant, gradiant }) =>
    validGradiant &&
    `
    background: #${gradiant.replace('#', '')};
  `}
  ${({ valid, color, validGradiant, gradiant }) =>
    valid &&
    validGradiant &&
    `
    background: linear-gradient(to right, #${color.replace(
      '#',
      ''
    )}, #${gradiant.replace('#', '')});
`}
`;

const ColorSwatch = styled.div`
  min-width: 170px;
  width: 100%;

  .info div {
    margin-bottom: 6px !important;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 0px !important;
  line-height: 0 !important;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .nocol {
    font-size: 13px;
    font-weight: 300;
    opacity: 0.5;
    display: inline-block;
    margin: 12px 0;
  }
`;

const ColorWrapper = styled.div`
  flex-grow: 0 !important;
`;

const SecondaryWrapper = styled.div`
  flex-grow: 0 !important;
`;

export default ({
  size = 0, // text size
  cmyk = ['', '', '', ''],
  name, // defaults to the hex value
  color = '', // prevent undef
  extra = true, // defaults to false
  pantone = '',
  selected = false,
  position = false, // defaults to bottom
  secondaryCmyk = ['', '', '', ''],
  secondaryName,
  secondaryColor = '', // secondaryColor color for gradiant
}) => {
  const isValidHex = color.match(/^#?([0-9A-F]{3}){1,2}$/i);
  const isValidSecondary = secondaryColor.match(/^#?([0-9A-F]{3}){1,2}$/i);

  const hexValue = color.replace('#', '').toUpperCase();
  const hexLength = isValidHex && hexValue.length === 3 ? 1 : 2;

  const secondaryHexValue = secondaryColor.replace('#', '').toUpperCase();
  const secondaryHexLength =
    isValidHex && secondaryHexValue.length === 3 ? 1 : 2;

  const [r, g, b] = isValidHex
    ? hexValue
        .match(new RegExp(`.{1,${hexLength}}`, 'g'))
        .map((i) => parseInt(i, 16))
    : [null];

  const [c, m, y, k] = cmyk || [];

  const [sr, sg, sb] = isValidSecondary
    ? secondaryHexValue
        .match(new RegExp(`.{1,${secondaryHexLength}}`, 'g'))
        .map((i) => parseInt(i, 16))
    : [null];

  const [sc, sm, sy, sk] =
    isValidSecondary && Array.isArray(secondaryCmyk)
      ? secondaryCmyk
      : ['', '', '', ''];

  return (
    <ColorSwatch>
      {position && size !== 0 && (
        <Label
          title="true"
          size={size}
          as={`h${[3, 2, 1][size - 1] + 2}`}
          className={(() => {
            if (size === 1) return `brand-font-small brand-color-small block`;
            if (size === 2)
              return `brand-font-regular brand-color-regular block`;
            if (size === 3) return `brand-font-large brand-color-large block`;
          })()}
        >
          <span className="left">{name || color}</span>
          <span className="right">{secondaryName || secondaryColor}</span>
        </Label>
      )}
      <ColorBlock
        valid={isValidHex}
        color={color}
        gradiant={secondaryColor}
        validGradiant={isValidSecondary}
      />
      {!position && size !== 0 && (
        <Label
          title="true"
          as={`h${[3, 2, 1][size - 1] + 1}`}
          size={size}
          className={(() => {
            if (size === 1) return `brand-font-small brand-color-small block`;
            if (size === 2)
              return `brand-font-regular brand-color-regular block`;
            if (size === 3) return `brand-font-large brand-color-large block`;
          })()}
        >
          <span className="left">{name || color}</span>
          <span className="right">{secondaryName || secondaryColor}</span>
        </Label>
      )}
      <InfoWrapper>
        {(!color || !isValidHex) && selected && (
          <span className="nocol">
            Enter value in the side panel, to the left
          </span>
        )}
        {color && isValidHex && (
          <>
            <ColorWrapper>
              <Wrapper>
                <Symbol>#</Symbol>
                <Label>{hexValue}</Label>
              </Wrapper>
              <Wrapper>
                <Symbol>R</Symbol>
                <Label>{r}</Label>
                <Symbol>G</Symbol>
                <Label>{g}</Label>
                <Symbol>B</Symbol>
                <Label>{b}</Label>
              </Wrapper>
              {extra && (
                <>
                  <Wrapper>
                    <Symbol>C</Symbol>
                    <Label>{c}</Label>
                    <Symbol>M</Symbol>
                    <Label>{m}</Label>
                    <Symbol>Y</Symbol>
                    <Label>{y}</Label>
                    <Symbol>K</Symbol>
                    <Label>{k}</Label>
                  </Wrapper>
                  <Wrapper>
                    <Symbol>Pantone</Symbol>
                    <Label>{pantone}</Label>
                  </Wrapper>
                </>
              )}
            </ColorWrapper>
            {secondaryColor && (
              <SecondaryWrapper>
                <Wrapper>
                  <Symbol>#</Symbol>
                  <Label>{isValidHex && secondaryHexValue}</Label>
                </Wrapper>
                <Wrapper>
                  <Symbol>R</Symbol>
                  <Label>{sr}</Label>
                  <Symbol>G</Symbol>
                  <Label>{sg}</Label>
                  <Symbol>B</Symbol>
                  <Label>{sb}</Label>
                </Wrapper>
                {extra && (
                  <>
                    <Wrapper>
                      <Symbol>C</Symbol>
                      <Label>{sc}</Label>
                      <Symbol>M</Symbol>
                      <Label>{sm}</Label>
                      <Symbol>Y</Symbol>
                      <Label>{sy}</Label>
                      <Symbol>K</Symbol>
                      <Label>{sk}</Label>
                    </Wrapper>
                    <Wrapper>
                      <Symbol>Pantone</Symbol>
                      <Label>{pantone}</Label>
                    </Wrapper>
                  </>
                )}
              </SecondaryWrapper>
            )}
          </>
        )}
      </InfoWrapper>
    </ColorSwatch>
  );
};
