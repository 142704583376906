import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Input, Button } from '../ui';

import { setCredential } from '../store/user';
import { doSendRequest } from '../store/brand';
import { toast } from '../ui/Toast';

const CardHeader = styled.div`
  clear: both;
  margin: 32px 0 44px;
  p {
    font-family: Roboto;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    padding-right: 32px;
    font-style: normal;
    line-height: 1.23em;
    letter-spacing: 0.12px;
    color: #000000;
    margin: 0;

    &.sub {
      margin-top: 32px;
      font-size: 12px;
      font-weight: 300;
      color: #444444;
      line-height: 20px;
    }
  }
`;

const Links = styled.div`
  z-index: 2;
  width: 290px;
  height: 30px;
  background-color: #f8f8f8;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    width: 227px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444444;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      border-bottom: 0;
    }
  }
`;

export default () => {
  const dispatch = useDispatch();
  const { credential, brand } = useSelector(({ user: usr, global }) => ({
    ...usr,
    ...global,
  }));
  const [sent, setSent] = useState();

  const onChange = (key, val) => {
    dispatch(
      setCredential({
        ...credential,
        [key]: val,
      })
    );
  };

  const submit = (e) => {
    e.preventDefault();
    dispatch(
      doSendRequest(brand?.id, { ...credential }, (error) => {
        if (error)
          toast.error(
            'Oops, something went wrong',
            'An unexpected error has occurred. Please try again.      '
          );
        else setSent(true);
      })
    );
  };

  return (
    <>
      <CardHeader>
        <p>Request access to {brand?.name}</p>
      </CardHeader>
      <form onSubmit={sent ? undefined : submit}>
        <Container margin={37}>
          <Input
            disabled={sent}
            block
            error={credential.name && credential.name.length <= 2}
            errorMessage={
              credential.name && credential.name.length <= 2
                ? 'Name is too short'
                : ''
            }
            excludeKey={/[^a-zA-Z\b\t ]/}
            onChange={(e) => onChange('name', e.target.value)}
            placeholder="Name"
          />
          <Input
            disabled={sent}
            block
            error={
              credential.email &&
              !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
            }
            errorMessage={
              credential.email &&
              !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
                ? 'Invalid email'
                : ''
            }
            onChange={(e) => onChange('email', e.target.value)}
            placeholder="Email address"
          />
        </Container>

        <Container>
          {sent ? (
            <Button
              type="button"
              block
              text
              bordered
              icon="tick"
              onClick={() => {}}
            >
              Your admin has been notified
            </Button>
          ) : (
            <Button
              block
              disabled={
                !credential.email ||
                !credential.name ||
                credential.name.length < 2 ||
                !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
              }
            >
              REQUEST ACCESS
            </Button>
          )}
        </Container>
      </form>

      <Links>
        <Link to="/login">Back to login</Link>
      </Links>
    </>
  );
};
