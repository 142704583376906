import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

import { Spinner } from '../ui';
import {
  addCard,
  getPlans,
  subscribe,
  updateSubscription,
} from '../svc/payment';

import SettingsFooter from './editor/SettingsFooter';
import Card from './Card';

const Title = styled.p`
  font-size: ${({ fs = 15 }) => fs}px;
  font-weight: ${({ fw = 'normal' }) => fw};
  margin-bottom: ${({ mb = 0 }) => mb}px;
  margin-top: ${({ mt = 0 }) => mt}px;
  margin-right: ${({ mr = 0 }) => mr}px;
  margin-left: ${({ ml = 0 }) => ml}px;
  color: ${({ color = '#000000' }) => color};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.06px;
  line-height: 1.3;
  width: 213px;
  width: ${({ width = 213 }) => width}px;
`;

const CardUI = styled.div`
  width: 230px;
  height: 330px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border: ${({ selected }) => selected && 'solid 2px #4b9aff'};
  background-color: #ffffff;
  position: relative;
  box-sizing: border-box;
  margin: 5px;
`;

const Selected = styled.div`
  height: 50px;
  background-color: ${({ selected }) => selected && '#4b9aff'};
  border-top: ${({ selected }) =>
    selected ? '1px solid #ffffff' : '1px solid #d2d2d2'};
  position: absolute;
  bottom: 0;
  width: 219px;
  margin: 4px;
  display: flex;
  align-items: center;
  #outer-circle {
    border-radius: 50%;
    position: relative;
    width: 20px;
    height: 20px;
    border: solid 1px #d2d2d2;
    background-color: #ffffff;
    margin-left: 18px;

    /*
     Child elements with absolute positioning will be
     positioned relative to this div
    */
  }
  #inner-circle {
    position: absolute;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #4b9aff;
    /*
     Put top edge and left edge in the center
    */
    top: 50%;
    left: 50%;
    margin: -6px 0px 0px -6px;
    /*
     Offset the position correctly with
     minus half of the width and minus half of the height
    */
  }
`;

const loadedStripe = loadStripe(
  process.env.NODE_ENV === 'development'
    ? 'pk_test_fkc5I0BtJvVu7k8xtreaC0hS00Aw0Bee1q'
    : 'pk_live_zjiV78SUKxtIfhm5Wa6nl7YL00aa4iY9bg'
);

const copy = (o) => (o ? JSON.parse(JSON.stringify(o)) : o);

export default ({
  sid,
  coupon,
  payment: pm,
  cardOnly,
  onCancel = () => {},
  onConfirm = () => {},
  subscription: defaultSubscription,
}) => {
  const [plans, setPlans] = useState();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(cardOnly ? 2 : 1);
  const [subscription, setSubscription] = useState(copy(defaultSubscription));
  const [token, setToken] = useState();

  const brandId = useSelector(({ user }) => user?.user?.brand?.id);

  const payment = typeof pm === 'object' ? copy(pm) : {};

  const handleSelectSubscription = (val) => {
    setSubscription(plans[val]);
  };

  const handleConfirm = async () => {
    // add card
    if (cardOnly) {
      setLoading(true);
      const { error } = await addCard(brandId, {
        token: token.token.id,
      });
      if (!error) onConfirm();
      setLoading(false);
      return;
    }

    // update
    if (defaultSubscription && subscription.id !== defaultSubscription.id) {
      setLoading(true);
      const { error } = await updateSubscription(brandId, sid, {
        plan_id: subscription.id,
      });
      if (!error) onConfirm();
      setLoading(false);
      return;
    }

    setStep(2);
    if (step === 2) {
      setLoading(true);
      const { error } = await subscribe(
        brandId,
        {
          token: token.token.id,
          plan_id: subscription.id,
        },
        coupon
      );
      onConfirm(error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (step === 2 && !cardOnly) {
      setStep(1);
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    if (cardOnly) return;
    (async () => {
      setLoading(true);
      const { data, error } = await getPlans();
      if (!error && data.length > 0) {
        setPlans(data.sort((a, b) => a.amount - b.amount));
        if (!defaultSubscription) setSubscription(data[0]);
      }
      setLoading(false);
    })();
  }, []); // eslint-disable-line

  return (
    <Spinner spin={loading}>
      <h3
        style={{
          fontWeight: 'normal',
          textAlign: step === 1 ? 'center' : 'left',
        }}
      >
        {(() => {
          if (payment.billing_details) return 'Edit payment details';
          if (cardOnly) return 'Add new card';
          if (defaultSubscription) return 'Change current plan';
          return step === 1
            ? 'Upgrade to full subscription'
            : 'Confirm subscription';
        })()}
      </h3>
      {step === 1 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {plans && plans.length > 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {plans.map((p, i) => {
                  return (
                    <CardUI
                      key={p.nickname}
                      onClick={() => handleSelectSubscription(i)}
                      selected={subscription?.nickname === p.nickname}
                    >
                      <Title fs={15} fw={300} ml={14} mt={14}>
                        {p.nickname}
                      </Title>
                      <Title fs={24} fw={300} ml={14} mt={14}>
                        USD ${`${(p.amount / 100).toLocaleString()}/mth`}
                      </Title>
                      {p.metadata.description.split('\n').map((d) => (
                        <Title
                          fw={300}
                          fs={12}
                          color="#444444"
                          ml={14}
                          mt={10}
                          key={`${d}-${Math.random() * Date.now()}`}
                        >
                          {d}
                        </Title>
                      ))}
                      <Selected
                        selected={subscription?.nickname === p.nickname}
                      >
                        <div id="outer-circle">
                          {subscription?.nickname === p.nickname && (
                            <>
                              <div id="inner-circle" />
                              <Title
                                fs={12}
                                fw={300}
                                ml={30}
                                mt={3}
                                color="#ffffff"
                              >
                                Selected
                              </Title>
                            </>
                          )}
                        </div>
                      </Selected>
                    </CardUI>
                  );
                })}
              </div>
              <Title
                fs={12}
                fw={300}
                ml={10}
                mt={19}
                mb={30}
                color="#444444"
                width={480}
              >
                *Scalable at US$100 per TB/month{' '}
                {coupon && (
                  <small>
                    &nbsp; COUPON: <strong>{coupon}</strong>
                  </small>
                )}
              </Title>
            </>
          )}
        </div>
      )}
      {step === 2 && (
        <>
          {!cardOnly && (
            <>
              <Title mb={16}>Complete payment info</Title>
              <Title fw={300} color="#666666">
                Your plan
                {` ’${subscription.nickname}’`}
              </Title>
              <Title mb={21} fw={300} color="#666666">
                USD ${`${(subscription.amount / 100).toLocaleString()}/mth`},
                recurring payment
              </Title>
            </>
          )}

          <Elements stripe={loadedStripe}>
            <Card onToken={setToken} payment={payment} />
          </Elements>
        </>
      )}

      <SettingsFooter
        showCancel
        showConfirm
        onCancel={handleCancel}
        onConfirm={
          (defaultSubscription && defaultSubscription.id === subscription.id) ||
          (step === 2 && !token?.token?.id)
            ? undefined
            : handleConfirm
        }
        confirmText={(() => {
          if (payment.billing_details) return 'CONFIRM';
          if (cardOnly) return 'ADD CARD';
          if (defaultSubscription) return 'CONFIRM';
          return step === 1 ? 'CONTINUE' : 'SUBSCRIBE NOW';
        })()}
      />
    </Spinner>
  );
};
