import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import Button from './Button';

const Input = styled.input`
  border: none;
  background-color: transparent;
  font-size: ${({ title }) => (title ? '15px' : '12px')};
  font-weight: ${({ title }) => (title ? 'normal' : 300)};
  line-height: ${({ title }) => (title ? 1.33 : 1.67)};
  width: ${({ title }) => (title ? '120px' : '100px')};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  color: #333333;
  display: inline-block;
  margin-bottom: ${({ title }) => (title ? '4px' : 0)};
`;

const Label = styled.span`
  font-size: ${({ title }) => (title ? '15px' : '12px')};
  font-weight: ${({ title }) => (title ? 'bold' : 300)};
  line-height: ${({ title }) => (title ? 1.33 : 1.67)};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  color: #333333;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: ${({ title }) => (title ? '4px' : 0)};
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Symbol = styled(Label)`
  color: #999999;
  width: auto;
  margin-right: 5px;
`;

const ColorBlock = styled.div`
  height: 100px;
  width: 100px;
  margin-right: 16px;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    #d2d2d2 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
  border: ${({ valid }) => (valid ? 'none' : '1px solid #d2d2d2')};
  ${({ valid, color }) =>
    valid &&
    `
    background: #${color.replace('#', '')};
  `}
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 128px;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 8px 0px 0px 9px;
  box-sizing: border-box;
  .cmyk {
    height: 20px;
  }
  .cmyk input {
    width 20px;
  }
`;

const Wrapper = styled.div`
  line-height: 0;

  &.action {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  line-height: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  min-width: 170px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  box-sizing: border-box;
  z-index: 3;
`;

const Icon = styled(Button)`
  padding: 0;

  span {
    margin: 3px !important;
  }
  &:not(:last-child) {
    margin: 0;
  }
`;

const Col = styled.div`
  width: 300px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0);
  .action {
    display: none;
  }
  &:hover {
    background-color: #efefef;
    .action {
      display: block;
    }
  }
  &::after {
    content: '';
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    box-sizing: border-box;
    ${({ edit }) =>
      edit &&
      `
    display: none;;
  `}
  }
  ${({ edit }) =>
    edit &&
    `
    background-color: rgba(0,0,0,0) !important;
    border: 1px solid #d2d2d2;
    cursor: default;
  `}
`;

const VerticalColorSwatch = ({
  onSelect = () => {},
  onUpdate = () => {},
  onDelete = () => {},
  onAdd = () => {},
  newColor = false,
  color: {
    id = undefined,
    color: clr = '',
    name: t = 'New colour',
    pantone: p = '',
    cmyk: c = ['', '', '', ''],
  } = {},
  index,
  edit,
}) => {
  const [title, setTitle] = useState(t);
  const [color, setColor] = useState(clr);
  const [pantone, setPantone] = useState(p);
  const [cmyk, setCmyk] = useState(c);
  const [hover, setHover] = useState(false);
  const titleEl = useRef(null);
  const hexEl = useRef(null);
  const pantoneEl = useRef(null);

  // cmyks
  const cmyk0 = useRef(null);
  const cmyk1 = useRef(null);
  const cmyk2 = useRef(null);
  const cmyk3 = useRef(null);

  const isValidHex = color.match(/^#?([0-9A-F]{3}){1,2}$/i);
  const hexValue = color.replace('#', '').toUpperCase();
  const hexLength = isValidHex && hexValue.length === 3 ? 1 : 2;

  const [r, g, b] = isValidHex
    ? hexValue
        .match(new RegExp(`.{1,${hexLength}}`, 'g'))
        .map((i) => parseInt(i, 16))
    : [null];

  const reset = (isNew) => {
    onSelect();
    if (isNew) {
      setTitle(t);
      setColor(clr);
      setPantone(p);
      setCmyk(c);
      titleEl.current.value = t;
      hexEl.current.value = clr;
      cmyk0.current.value = '';
      cmyk1.current.value = '';
      cmyk2.current.value = '';
      cmyk3.current.value = '';
      pantoneEl.current.value = '';
    } else {
      titleEl.current.disabled = true;
      hexEl.current.disabled = true;
      pantoneEl.current.disabled = true;
      cmyk0.current.disabled = true;
      cmyk1.current.disabled = true;
      cmyk2.current.disabled = true;
      cmyk3.current.disabled = true;
    }
  };

  const handleEdit = (e) => {
    setHover();
    if (e.target.nodeName.match(/input/i)) e.target.focus();
    else titleEl.current.focus();
    onSelect(index);
  };

  const handleChange = (e, handler) => {
    handler(e.target.value);
  };

  const handleConfirm = () => {
    onUpdate(index, { name: title, color: hexValue, pantone, cmyk, id });
    reset();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(index);
  };

  const handleAdd = () => {
    onAdd({ name: title, color: hexValue, pantone, cmyk });
    titleEl.current.value = 'New colour';
    hexEl.current.value = '';
    setTitle(t);
    setColor('');
    reset(true);
  };

  useEffect(() => {
    if (edit && !newColor) {
      hexEl.current.focus();
    }
  }, [edit, newColor]);

  return (
    <Col onClick={!edit ? handleEdit : undefined} edit={edit} className="cb">
      <ColorWrapper hover={hover} edit={edit} newColor={newColor}>
        <ColorBlock valid={isValidHex} color={color} />
        <Wrapper>
          <TitleWrapper>
            <Input
              ref={titleEl}
              defaultValue={title}
              disabled={!newColor && !edit}
              title="true"
              onChange={(e) => handleChange(e, setTitle)}
            />
            {!edit && !newColor && (
              <Wrapper className="action">
                <Icon icon="delete" text onClick={handleDelete} />
              </Wrapper>
            )}
          </TitleWrapper>
          <Wrapper>
            <Symbol>#</Symbol>
            <Input
              disabled={!newColor && !edit}
              maxLength={6}
              ref={hexEl}
              onKeyDown={(e) => {
                if (
                  ['Enter', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(
                    e.key
                  )
                )
                  return true;

                if (!e.key.match(/[a-fA-F0-9]/)) e.preventDefault();
              }}
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              onChange={(e) => handleChange(e, setColor)}
              defaultValue={hexValue}
            />
          </Wrapper>
          <Wrapper>
            <Symbol>R</Symbol>
            <Label>{r}</Label>
            <Symbol>G</Symbol>
            <Label>{g}</Label>
            <Symbol>B</Symbol>
            <Label>{b}</Label>
          </Wrapper>
          <Wrapper className="cmyk">
            <Symbol>C</Symbol>
            <Label>
              <Input
                type="number"
                disabled={!newColor && !edit}
                ref={cmyk0}
                onChange={(e) => {
                  const ncmyk = cmyk ? [...cmyk] : ['', '', '', ''];
                  ncmyk[0] = e.target.value.trim();
                  setCmyk(ncmyk);
                }}
                defaultValue={cmyk ? cmyk[0] : ''}
              />
            </Label>
            <Symbol>M</Symbol>
            <Label>
              <Input
                type="number"
                disabled={!newColor && !edit}
                ref={cmyk1}
                onChange={(e) => {
                  const ncmyk = cmyk ? [...cmyk] : ['', '', '', ''];
                  ncmyk[1] = e.target.value.trim();
                  setCmyk(ncmyk);
                }}
                defaultValue={cmyk ? cmyk[1] : ''}
              />
            </Label>
            <Symbol>Y</Symbol>
            <Label>
              <Input
                type="number"
                disabled={!newColor && !edit}
                ref={cmyk2}
                onChange={(e) => {
                  const ncmyk = cmyk ? [...cmyk] : ['', '', '', ''];
                  ncmyk[2] = e.target.value.trim();
                  setCmyk(ncmyk);
                }}
                defaultValue={cmyk ? cmyk[2] : ''}
              />
            </Label>
            <Symbol>K</Symbol>
            <Label>
              <Input
                type="number"
                disabled={!newColor && !edit}
                ref={cmyk3}
                onChange={(e) => {
                  const ncmyk = cmyk ? [...cmyk] : ['', '', '', ''];
                  ncmyk[3] = e.target.value.trim();
                  setCmyk(ncmyk);
                }}
                defaultValue={cmyk ? cmyk[3] : ''}
              />
            </Label>
            <Label>&nbsp;</Label>
          </Wrapper>
          <Wrapper>
            <Symbol>Pantone</Symbol>
            <Input
              type="text"
              disabled={!newColor && !edit}
              ref={pantoneEl}
              onChange={(e) => handleChange(e, setPantone)}
              defaultValue={pantone}
            />
          </Wrapper>
        </Wrapper>
      </ColorWrapper>
      <ButtonWrapper>
        {!newColor && edit && (
          <>
            <Button icon="cross-sml" text onClick={() => onSelect()}>
              CANCEL
            </Button>
            <Button
              disabled={!isValidHex}
              icon="tick-sml"
              text
              onClick={handleConfirm}
            >
              CONFIRM
            </Button>
          </>
        )}
        {newColor && edit && (
          <>
            <Button icon="cross-sml" text onClick={() => reset(true)}>
              CANCEL
            </Button>
            <Button disabled={!isValidHex} icon="add" text onClick={handleAdd}>
              ADD
            </Button>
          </>
        )}
      </ButtonWrapper>
    </Col>
  );
};

export default VerticalColorSwatch;
