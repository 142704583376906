import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
`;

const Line = styled.hr`
  margin: 20px 0;
  ${({ size }) =>
    size &&
    size === 1 &&
    `
    margin: 30px 0;
`}
  ${({ size }) =>
    size &&
    size === 2 &&
    `
    margin: 10px 0;
`}
`;

export default ({ size }) => {
  return (
    <Divider>
      <Line size={size} />
    </Divider>
  );
};
