///////////////////////////////////////////////////////
//
// FlexHGroup
//
// Creates an evenly-distributed columnar layout.
// This is good for creating grouped form fields.
//
///////////////////////////////////////////////////////
import React from 'react';
import styled from 'styled-components';

const FlexHGroup = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & * {
    flex-grow: 1;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default ({ children }) => {
  return <FlexHGroup>{children}</FlexHGroup>;
};
