import React from 'react';
import styled from 'styled-components';

import Icon from '../ui/Icon';
import SettingsFooter from './editor/SettingsFooter';

const Bullet = styled.ul`
  font-size: 12px;
  line-height: 1.75;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
`;

export default ({ onCancel = () => {}, onConfirm = () => {} }) => {
  return (
    <div>
      <h3
        style={{
          fontWeight: 'normal',
          lineHeight: 2,
          marginBottom: 6,
        }}
      >
        Verify your account to access Settings
      </h3>
      <p
        style={{
          fontWeight: '300',
          fontSize: 12,
          color: '#444444',
          width: 320,
          lineHeight: 1.42,
          marginBottom: 11,
        }}
      >
        Simply complete your personal data in your account to receive a
        verification email and unlock all Settings
      </p>
      <Bullet>
        <li>
          <Icon color="#000000" name="tick-sml" /> Add users
        </li>
        <li>
          <Icon color="#000000" name="tick-sml" />
          More customization
        </li>
        <li>
          <Icon color="#000000" name="tick-sml" />
          Manage colours & fonts
        </li>
        <li>
          <Icon color="#000000" name="tick-sml" />
          Define security
        </li>
      </Bullet>
      <SettingsFooter
        showCancel
        showConfirm
        onCancel={onCancel}
        onConfirm={onConfirm}
        confirmText="VERIFY ACCOUNT"
      />
    </div>
  );
};
