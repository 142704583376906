import { createSlice } from '@reduxjs/toolkit';

import {
  getFiles as gf,
  updateFile as uf,
  getCollections as gc,
} from '../svc/brand';

export const mediaSlice = createSlice({
  name: 'media',

  initialState: {},

  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },

    setFiles: (state, { payload }) => {
      state.files = payload;
    },

    appendFiles: (state, { payload }) => {
      if (payload) state.files.files = [...state.files.files, ...payload];
    },

    prependFiles: (state, { payload }) => {
      if (payload) state.files.files = [...payload, ...state.files.files];
    },

    setCollections: (state, { payload }) => {
      state.collections = payload;
    },

    appendCollections: (state, { payload }) => {
      state.collections = [...state.collections, ...payload];
    },

    setCollectionFiles: (state, { payload }) => {
      state.collectionFiles = payload;
    },

    appendCollectionFiles: (state, { payload }) => {
      state.collectionFiles.files = [
        ...state.collectionFiles.files,
        ...payload,
      ];
    },

    prependCollectionFiles: (state, { payload }) => {
      state.collectionFiles.files = [
        ...payload,
        ...state.collectionFiles.files,
      ];
    },

    deleteFile: (state, { payload }) => {
      const { id, cid } = payload;
      if (cid) {
        state.collectionFiles.files = state.collectionFiles.files.filter(
          (f) => f.id !== cid
        );
        return;
      }

      state.files.files = state.files.files.filter((f) => f.id !== id);

      const list = [];
      if (state.collections)
        state.collections.forEach((c, ci) => {
          c.files.forEach((cf, cfi) => {
            if (cf.id === id) {
              list.push([ci, cfi]);
            }
          });
        });

      list.forEach((c) => {
        if (state.collections[c[0]])
          state.collections[c[0]].files.splice(c[1], 1);
      });
    },

    reset: (state) => {
      Object.keys(state).forEach((k) => {
        state[k] = undefined;
      });
    },
  },
});

export const {
  reset,
  setError,
  setFiles,
  appendFiles,
  prependFiles,
  deleteFile,
  setCollections,
  appendCollections,
  setCollectionFiles,
  appendCollectionFiles,
  prependCollectionFiles,
} = mediaSlice.actions;

export const getFiles =
  (
    brandId,
    collectionId,
    search,
    lastValue,
    maxRows = 30,
    sortField = 'created_at', // defaults to created_at
    ascending = false // defaults to descending order
  ) =>
  async (dispatch) => {
    const query = {};
    if (maxRows) query.max_rows = maxRows;
    if (lastValue) query.last_value = lastValue;
    if (sortField) query.sort_field = sortField;
    if (ascending) query.ascending = ascending;

    // clean up only if we searchin'
    if (search) {
      query.search = search;
      dispatch(setFiles(undefined));
      dispatch(setCollections(undefined));
      dispatch(setCollectionFiles(undefined));
    }

    const cid = collectionId?.match(/all|collection|favourites/)
      ? undefined
      : collectionId;

    if (cid) {
      const { data, error } = await gf(brandId, cid, query);
      if (!error) dispatch(setCollectionFiles(data));
      return;
    }

    const files = await gf(brandId, undefined, query);

    if (!files.error) dispatch(setFiles(files.data));
  };

export const getCollections =
  (
    brandId,
    search,
    lastValue,
    maxRows = 30,
    sortField = 'created_at',
    ascending = false
  ) =>
  async (dispatch) => {
    const query = {};
    if (maxRows) query.max_rows = maxRows;
    if (lastValue) query.last_value = lastValue;
    query.sort_field = sortField;
    query.ascending = ascending;

    // clean up only if we searchin'
    if (search) {
      query.search = search;
      dispatch(setCollections(undefined));
    }

    const collections = await gc(brandId, query);

    if (!collections.error) dispatch(setCollections(collections.data));
  };

export const updateFile = (id, payload) => async (dispatch) => {
  const { error } = await uf(id, payload);
  if (!error) {
    dispatch(setError(error.message));
  }
};

export default mediaSlice.reducer;
