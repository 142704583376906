import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { Modal, Icon } from '../../ui';
import Placeholder from './PlaceHolder';
import MediaBrowser from '../MediaBrowser';

const Video = styled.div`
  width: 100%;
  position: relative;
  pading-top: 56.25%;
  box-sizing: border-box;
  border: 1px solid #efefef;

  ${({ url }) =>
    !url &&
    `
    background: linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 0.8px),#d2d2d2 50%,rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%);
    border: 1px solid #d2d2d2;
    min-height: 312px;
  `}

  video {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  span {
    color: #000000;
  }

  div {
    background: black;
    opacity: ${({ paused }) => (paused ? 0.5 : 0)};
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    margin: 0%;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  svg {
    opacity: ${({ paused }) => (paused ? 1 : 0)};
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    z-index: 4;
  }
  &:hover {
    div {
      opacity: 0.5;
    }
    svg {
      opacity: 1;
    }
  }
`;

export default ({
  url = '',
  edit,
  selected,
  placeholder,
  onClickHolder,
  changeHandler = () => {},
  loop = false,
  autoPlay = false,
}) => {
  const [open, setOpen] = useState(false);
  const [paused, setPaused] = useState(!autoPlay);
  const videoRef = useRef();

  if (!edit && !url && placeholder)
    return (
      <Placeholder
        placeholder={placeholder}
        height={368}
        onClick={onClickHolder}
      />
    );

  const togglePlay = () => {
    if (edit) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPaused(false);
    } else {
      videoRef.current.pause();
      setPaused(true);
    }
  };

  const setVideo = (sel) => {
    if (videoRef.current) videoRef.current.src = sel.urls.original;
    changeHandler({
      url: sel.urls.original,
    });
    setOpen(false);
  };

  return (
    <>
      <Video
        paused={paused}
        edit={edit}
        url={url}
        onClick={() => selected && setOpen(!open)}
      >
        {!edit && url && (
          <Icon color="#ffffff" name={paused ? 'play' : 'pause'} />
        )}
        {!edit && url && <div onClick={togglePlay} />}
        {url ? (
          <video
            ref={videoRef}
            controls={edit}
            playsInline
            loop={!edit && loop}
            autoPlay={!edit && autoPlay}
          >
            <source src={`${url}#t=10`} />
          </video>
        ) : (
          <span />
        )}
      </Video>

      {selected && (
        <Modal visible={open}>
          <MediaBrowser
            type="video"
            category="basics"
            onCancel={() => setOpen(false)}
            onConfirm={setVideo}
          />
        </Modal>
      )}
    </>
  );
};
