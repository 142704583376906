import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { DropDown, Button, Switch, Modal, Tooltip } from '../../ui';
import { updateItem } from '../../store/page';

import AddColors from '../AddColors';

const Ctl = styled.div`
  box-sizing: border-box;

  .add {
    padding: 0;
    margin-top: 12px;
    font-size: 12px;
    font-weight: normal;
  }

  div {
    margin-bottom: 12px;
  }
`;

const ColorSelect = styled(DropDown)`
  margin: 13px 0;
`;

const selector = ({ user }) => user;

const selectSizing = [
  { label: 'Disable heading', value: 0 },
  { label: 'Small heading', value: 1 },
  { label: 'Regular heading', value: 2 },
  { label: 'Large heading', value: 3 },
];

export default (attrs) => {
  const { props } = attrs;
  const { id, position = false, size = 0 } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(selector);

  const [open, setOpen] = useState(false);
  const [colorTooltip, setColorTooltip] = useState(
    localStorage.getItem(`tooltip-color-${user.id}`)
  );

  const update = (newProps, confirm = false) => {
    if (id && confirm) return;
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onPositionChange = (v) => {
    update({ position: v });
  };

  const onSizeChange = (v) => {
    const p = user.brand.colors.find((c) => c.id === v);
    update({ ...p, size: v });
  };

  const onColor = (v) => {
    const p = user.brand.colors.find((c) => c.id === v);
    if (!p) return;
    update(p);
  };

  return (
    <Ctl>
      <section>
        <h3>Color Values</h3>
        <p>Choose and edit an existing colour, or add a new colour.</p>
        <ColorSelect
          onSelect={onColor}
          items={[
            ...user.brand.colors.map((c) => ({
              label: c.name,
              value: c.id,
              color: c.color,
            })),
          ]}
          defaultValue={id}
          dropDownType="color"
          disabled={false}
          block
          size="small"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Button
            text
            size="small"
            icon="add-sml"
            onClick={() => {
              setColorTooltip(undefined);
              localStorage.removeItem(`tooltip-color-${user.id}`, true);
              setOpen(!open);
            }}
            className="add"
            style={{ letterSpacing: 'normal', marginRight: 0 }}
          >
            Manage colours
          </Button>
          {colorTooltip && (
            <Tooltip
              text="Add a new colour"
              onDismiss={() => {
                setColorTooltip(undefined);
                localStorage.removeItem(`tooltip-color-${user.id}`, true);
              }}
            />
          )}
        </div>
      </section>

      {id && (
        <section>
          <h3>Heading Options</h3>
          <p>Enable heading and specify its placement.</p>
          <DropDown
            items={selectSizing}
            onSelect={onSizeChange}
            defaultValue={size}
            dropDownType="text"
            disabled={false}
            block
            size="small"
          />
          {size !== 0 && (
            <Switch
              size="small"
              block
              defaultValue={position}
              onChange={onPositionChange}
              leftText="Below"
              rightText="Above"
            />
          )}
        </section>
      )}
      {!id && (
        <section>
          <p>
            <small>
              The selected color doesn't seem to exists in the core colors. You
              can click the "manage colours" button to re-add this to the core
              color, or you can select a new color from the dropdown menu.
            </small>
          </p>
        </section>
      )}
      {open && (
        <Modal visible={open}>
          <AddColors
            colors={[...user.brand.colors]}
            onCancel={() => setOpen(false)}
            onConfirm={(c) => {
              update(c);
              setOpen(false);
            }}
            brandId={user.brand.id}
          />
        </Modal>
      )}
    </Ctl>
  );
};
