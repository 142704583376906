///////////////////////////////////////////////////////
//
// Input
//
// Brandbox specific input component. Based on
// Jamie's latest zeplin handoff.
//
///////////////////////////////////////////////////////
import React, { useState } from 'react';
import styled from 'styled-components';
import { getColorFromProps } from '../config';

import theme from './themes/default';
import Button from './Button';

const { colors } = theme;

const Input = styled.input`
  width: ${({ block }) => (block ? '100%' : 'auto')};

  color: ${(p) => getColorFromProps({ props: p, input: true, front: true })};

  padding: 14px 0;
  font-size: ${({ size }) => (size === 'small' ? '12px' : '15px')};
  height: ${({ size }) => (size === 'small' ? '30px' : '46px')};
  box-sizing: border-box;
  font-weight: 300;
  font-family: Roboto !important;
  margin-right: ${({ block: b }) => (b ? '0px' : '12px')};
  letter-spacing: 0.3px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #e9e9e9;
  ${(p) => {
    if (p.error) return colors.danger;
    return getColorFromProps({ props: p, input: true });
  }};

  &:focus {
    border-color: ${(p) => {
      if (p.error) return colors.danger;
      return getColorFromProps({ props: p, input: true, state: 'hover' });
    }};
  }

  &:disabled {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 12px;
  font-weight: 700;
  text-transform: capitalize;
`;

const Error = styled.p`
  margin-top: -8px;
  font-size: 13px;
  font-weight: 300;
  color: red;
  margin-bottom: 0;
`;

const Wrap = styled.div`
  position: relative;
  box-sizing: bordered-box;
  margin-bottom: 12px;

  button,
  button:active {
    padding: 0;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    svg {
      fill: #${({ active }) => (active ? '000000' : 'b3b3b3')};
      width: 15px;
      height: 15px;
    }
  }
`;

const InputWrap = styled.div`
  position: relative;
  box-sizing: border-box;
`;

export default (p) => {
  const {
    label,
    type: t = 'text',
    toggle,
    error,
    errorMessage,
    cRef,
    excludeKey,
  } = p;
  const [type, setType] = useState(t);

  return (
    <InputWrap>
      <Wrap active={type === 'text' && toggle} tabIndex={-1}>
        {label && <Label>{label}</Label>}
        {t.match(/password/) && toggle && (
          <Button
            tabIndex={-1}
            text
            icon="preview"
            type="button"
            onClick={() => {
              setType(type === 'text' ? 'password' : 'text');
            }}
          />
        )}
        <Input
          ref={cRef}
          {...p}
          type={type}
          autoComplete="off"
          onKeyPress={(e) => {
            if (!excludeKey) return true;
            const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (key.match(excludeKey)) return e.preventDefault();
            return true;
          }}
        />
      </Wrap>
      {error && errorMessage && <Error>{errorMessage}</Error>}
    </InputWrap>
  );
};
