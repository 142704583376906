import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import BrandStyle from '../BrandStyle';

import { updateStyles } from '../../store/brand';

const Page = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
`;

const Main = styled.div`
  margin-top: 20px;
  ${({ uid }) =>
    uid &&
    `
    margin-top: 111px;
  `}
  overflow: auto;
  position: relative;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  background-color: #ffffff;
  ${({ isLoading }) =>
    isLoading &&
    `
  &::after {
    content: 'PLEASE WAIT...';
    font-family: Roboto !important;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1px;
    height: ${window.innerHeight - 111}px;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  `}

  ${({ editMode }) =>
    editMode &&
    `
    background-color: #f0f0f0;
  `}

  ${({ background }) =>
    background &&
    `
    background-color: ${background};
  `}
`;

const Info = styled.div`
  background-color: #f8f8f8;
  padding: 24px 0;
  border-top: 1px solid #cccccc;

  ${({ editMode }) =>
    editMode &&
    `
  @media only screen and (max-width: 1496px) {
    padding-left: ${270 - Math.abs(window.innerWidth - 960 - 270) + 32}px;
  }
  `}

  div {
    width: 960px;
    margin: 0 auto;
  }

  p {
    width: 70%;
    font-size: 12px;
    color: #999999;
    margin: 0;
  }
`;

const selector = ({ page, user, brand, global }) => ({
  ...user,
  page,
  brand,
  global,
});

export default ({ children }) => {
  const dispatch = useDispatch();
  const {
    page,
    user: { brand: ubrand, id: uid } = {},
    brand: sbrand,
    global: { brand: gbrand },
  } = useSelector(selector);

  const brand = ubrand || gbrand;
  const styles = JSON.parse(JSON.stringify(brand?.styles || {}));
  const owner = brand?.users?.find((i) => i.role === 'owner');

  const logoColor =
    brand?.colors.find(
      (c) => c.id === styles.logo_color || c.color === styles.logo_color.color
    ) || brand?.colors.find((c) => c.name.match(/primary/));
  const actionColor = brand?.colors.find(
    (c) => c.id === styles.action_color || c.color === styles.action_color.color
  );

  styles.logo_color = logoColor;
  styles.action_color = actionColor;
  if (!styles.large_headings) styles.large_headings = {};
  styles.large_headings.color = brand?.colors.find(
    (c) =>
      c.id === styles.large_headings.color ||
      c.color === styles.large_headings.color?.color
  );
  styles.large_headings.font = brand?.fonts.find(
    (c) =>
      c.id === styles.large_headings.font ||
      c.url === styles.large_headings.font?.url
  );
  if (!styles.regular_headings) styles.regular_headings = {};
  styles.regular_headings.color = brand?.colors.find(
    (c) =>
      c.id === styles.regular_headings.color ||
      c.color === styles.regular_headings.color?.color
  );
  styles.regular_headings.font = brand?.fonts.find(
    (c) =>
      c.id === styles.regular_headings.font ||
      c.url === styles.regular_headings.font?.url
  );
  if (!styles.small_headings) styles.small_headings = {};
  styles.small_headings.color = brand?.colors.find(
    (c) =>
      c.id === styles.small_headings.color ||
      c.color === styles.small_headings.color?.color
  );
  styles.small_headings.font = brand?.fonts.find(
    (c) =>
      c.id === styles.small_headings.font ||
      c.url === styles.small_headings.font?.url
  );

  useEffect(() => {
    if (brand && !sbrand?.styles) {
      dispatch(updateStyles(styles));
    }
  }); //eslint-disable-line

  return (
    <Page>
      <BrandStyle
        logoColor={logoColor?.color}
        actionColor={actionColor?.color}
        styles={sbrand?.styles}
      />
      <Header fixed />
      <Main
        background={page?.background}
        editMode={page?.editMode}
        uid={uid}
        className="main"
      >
        {children}
      </Main>
      {(uid || owner) && (
        <Info editMode={page?.editMode}>
          <div>
            <p>
              For more information please contact{' '}
              <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
              <br /> All downloadable assets are the intellectual property of{' '}
              {brand?.name}, &copy; {new Date().getFullYear()} {brand?.name}
            </p>
          </div>
        </Info>
      )}
      <Footer />
    </Page>
  );
};
