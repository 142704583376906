import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button } from '../../ui';

import Component from './Component'; // eslint-disable-line

import { updateSection, insertRow } from '../../store/page';
import PlaceHolder from './PlaceHolder';

const Section = styled.div`
  display: block;
  box-sizing: border-box;
  padding: ${({ edit }) => (edit ? 12 : 24)}px;
  min-height: auto;

  ${({ collapse }) =>
    collapse &&
    `
  background-color: #f8f8f8;
  `}

  ${({ rows, edit }) =>
    !edit &&
    rows.length === 1 &&
    rows[0].columns.length === 1 &&
    `
    padding: 0 !important;
  `}
`;

const Head = styled.div`
  height: 75px;
  display: flex;
  padding: 0 24px;
  position: relative;
  align-items: center;
  background-color: #f0f0f0;

  h3 {
    margin: 0;
    font-weight: normal;
  }

  ${({ edit }) =>
    !edit &&
    `
    cursor: pointer;
  `}

  ${({ open }) =>
    !open &&
    `
  button svg {
    transform: rotate(-90deg); }
  `}
`;

const Toggle = styled.div`
  flex: 1;
  text-align: right;

  button {
    padding: 0 !important;
    margin: 0 !important;
    right: -10px;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

const HeadText = styled.h3`
  ${({ contentEditable }) =>
    contentEditable &&
    `
  cursor: pointer !important;
  &:focus {
    cursor: text !important;
  }
  `}
`;

const paste = (e) => {
  // Prevent the default action
  e.preventDefault();

  // Get the copied text from the clipboard
  // eslint-disable-next-line
  const text = e.clipboardData
    ? (e.originalEvent || e).clipboardData.getData('text/plain')
    : // For IE
    window.clipboardData
    ? window.clipboardData.getData('Text')
    : '';

  if (document.queryCommandSupported('insertText')) {
    document.execCommand('insertText', false, text);
  } else {
    // Insert text at the current position of caret
    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export default ({
  readOnly,
  rows,
  collapse,
  edit,
  section,
  title = 'Title',
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [to, setTo] = useState();
  const headingRef = useRef();

  const toggleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const clear = () => {
    if (to) clearTimeout(to);
  };

  const onDeselect = useCallback(() => {
    const headingVal = headingRef.current && headingRef.current.textContent;

    dispatch(
      updateSection({
        section,
        title: headingVal,
      })
    );
  }, [dispatch, section]);

  const insert = (e) => {
    e.preventDefault();
    dispatch(insertRow({ section, row: 0 }));
  };

  useEffect(() => {
    if (!collapse) setOpen(true);
  }, [collapse]);

  return (
    <>
      {collapse && (
        <Head open={open} edit={edit} onClick={!edit ? toggleOpen : undefined}>
          <HeadText
            contentEditable={edit}
            suppressContentEditableWarning
            className="brand-font-large brand-color-large"
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onBlur={() => setTo(setTimeout(onDeselect, 100))}
            onFocus={clear}
            onPaste={paste}
            ref={headingRef}
          >
            {title}
          </HeadText>
          <Toggle>
            <Button text icon="open" onClick={toggleOpen} />
          </Toggle>
        </Head>
      )}

      {open && ( // Show only if open
        <Section collapse={collapse} edit={edit} rows={rows}>
          {(() => {
            if (!rows || (rows.length === 0 && edit))
              return (
                <PlaceHolder
                  height={128}
                  placeholder="Add New Row"
                  onClick={insert}
                />
              );
            return rows.map((r, i) => (
              <Component
                key={`section-${i}`}
                isSingleRow={rows.length === 1}
                row={i}
                props={r}
                section={section}
                collapse={collapse}
                readOnly={readOnly}
                noSettings={!collapse}
              />
            ));
          })()}
        </Section>
      )}
    </>
  );
};
