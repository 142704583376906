/**
 * BrandStyle
 *
 * wraps content with brand specific styling
 * i.e: brand fonts, colors etc...
 */
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { readableColor } from 'polished';

const BrandStyle = createGlobalStyle`
  ${({
    logoColor = '#000000',
    actionColor = '#000000',
    styles: [large = {}, regular = {}, small = {}, body = {}],
  }) => {
    let style = ``;

    // if large headings font exists
    if (large.font) {
      style += `
      @font-face {
        font-family: '${large.name}-large';
        src: url(${large.font});
      }
      .brand-font-large {
        font-family: '${large.name}-large';
        font-weight: ${large.weight} !important;
        font-style: ${large.style} !important;
      }`;
    }
    if (regular.font) {
      style += `
      @font-face {
        font-family: '${regular.name}-regular';
        src: url(${regular.font});
      }
      .brand-font-regular {
        font-family: '${regular.name}-regular';
        font-weight: ${regular.weight} !important;
        font-style: ${regular.style} !important;
      }`;
    }
    if (small.font) {
      style += `
      @font-face {
        font-family: '${small.name}-small';
        src: url(${small.font});
      }
      .brand-font-small {
        font-family: '${small.name}-small';
        font-weight: ${small.weight} !important;
        font-style: ${small.style} !important;
      }`;
    }
    if (body.font) {
      style += `
      @font-face {
        font-family: '${body.name}-body';
        src: url(${body.font});
      }
      .brand-font-body {
        font-family: '${body.name}-body';
        font-weight: ${body.weight} !important;
        font-style: ${body.style} !important;
      }`;
    }

    style += `
      .brand-bgcolor {
        background-color: ${
          logoColor ? `#${logoColor.replace('#', '')}` : 'inherit'
        } !important;

        color: ${readableColor(
          `#${logoColor.replace('#', '')}`,
          '#000000',
          '#ffffff'
        )} !important;

        svg {
          fill: ${readableColor(
            `#${logoColor.replace('#', '')}`,
            '#000000',
            '#ffffff'
          )} !important;
        }
      }
      .brand-action-bgcolor {
        background-color: ${
          actionColor ? `#${actionColor.replace('#', '')}` : 'inherit'
        } !important;

        color: ${readableColor(
          `#${actionColor.replace('#', '')}`,
          '#000000',
          '#ffffff'
        )} !important;

        svg {
          fill: ${readableColor(
            `#${actionColor.replace('#', '')}`,
            '#000000',
            '#ffffff'
          )} !important;
        }
      }
      .brand-action-bdcolor {
        background-color: transparent !important;

        border: 2px solid ${
          actionColor ? `#${actionColor.replace('#', '')}` : 'inherit'
        } !important;

        color: ${
          actionColor ? `#${actionColor.replace('#', '')}` : 'inherit'
        } !important;

        svg {
          fill: ${
            actionColor ? `#${actionColor.replace('#', '')}` : 'inherit'
          } !important;
        }
      }
      .brand-color-large, .brand-color-large * {
        color: ${
          large.color
            ? `#${large.color.replace('#', '')} !important`
            : 'inherit'
        };
        svg {
          fill: ${large.color ? `#${large.color.replace('#', '')}` : 'inherit'};
        }
      }
      .brand-color-regular, .brand-color-regular * {
        color: ${
          regular.color
            ? `#${regular.color.replace('#', '')} !important`
            : 'inherit'
        };
        svg {
          fill: ${
            regular.color ? `#${regular.color.replace('#', '')}` : 'inherit'
          };
        }
      }
      .brand-color-small, .brand-color-small * {
        color: ${
          small.color
            ? `#${small.color.replace('#', '')} !important`
            : 'inherit'
        };
        svg {
          fill: ${small.color ? `#${small.color.replace('#', '')}` : 'inherit'};
        }
      }
      .brand-color-body {
        color: ${body.color ? `#${body.color.replace('#', '')}` : 'inherit'};
        svg {
          fill: ${body.color ? `#${body.color.replace('#', '')}` : 'inherit'};
        }
      }
    `;

    return style;
  }}

  ${({ actionColor }) =>
    actionColor &&
    `
    .brand-action-color {
      color: #${actionColor.replace('#', '')} !important;
      svg {
        fill: #${actionColor.replace('#', '')} !important;
      }
    }
  `}

  [contenteditable="true"] {
    cursor: text;
  }

  .brand-pending {
    color: #999999 !important;
    fill: #999999 !important;
  }

  .brand-owner {
    color: #4b9aff !important;
    fill: #4b9aff !important;
  }

  .brand-admin {
    color: #a87be1 !important;
    fill: #a87be1 !important;
  }

  .brand-user {
    color: #98b5be !important;
    fill: #98b5be !important;
  }
`;

export default ({ styles, actionColor, logoColor }) => {
  if (!styles) return <></>;

  return (
    <BrandStyle
      styles={styles}
      logoColor={logoColor}
      actionColor={actionColor}
    />
  );
};
