import React, { useState } from 'react';
import styled from 'styled-components';

const Sort = styled.span`
  display: inline-block;
  position: relative;
  font-size: 0.8rem;
  margin-right: 10px;
  .mute {
    opacity: 0.5;
    margin-right: 0.5rem;
  }
  .trigger {
    cursor: pointer;
  }

  .items {
    z-index: 1;
    background: #fff;
    border: 1px solid #d2d2d2;
    box-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
    position: absolute;
    padding: 16px;
    top: 20px;
    left: 0;
    width: 128px;
    .item {
      display: inline-block;
      width: 100%;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      padding: 0.5rem;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
`;

const Sorter = ({
  onChange = () => {},
  label = 'Sort by',
  items = ['Newest', 'Oldest'],
  defaultValue = 0,
}) => {
  const [selected, setSelected] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    if (!open) {
      // attach event to body to close popup
      // when clicked outside
      document.body.addEventListener('click', function hl(e) {
        if (e.target.className.match(/trigger|item/g)) return;
        setOpen(false);
        document.body.removeEventListener('click', hl);
      });
    }
    const nopen = !open;
    setOpen(nopen);
  };

  return (
    <Sort>
      {label !== '' && <span className="mute">{label}</span>}
      <span className="trigger" onClick={toggle}>
        {items[selected === -1 ? 0 : selected]}
      </span>
      {open && (
        <span className="items">
          {items.map((item, i) => (
            <span
              key={i}
              className={`item ${i === selected ? 'disabled' : ''}`}
              onClick={() => {
                if (i === selected) return;
                setOpen();
                setSelected(i);
                onChange(i);
              }}
            >
              {item}
            </span>
          ))}
        </span>
      )}
    </Sort>
  );
};

export default Sorter;
