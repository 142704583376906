/**
 * ColorSelect component
 *
 * A combobox of some sort that allows user to input
 * or select a valid color value.
 *
 * Events:
 * - onColorChange will be triggered if color is valid, and then pass the color
 *   value to the handler func.
 *
 * Properties:
 * - placeholder
 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import Theme from './themes/default';
import Button from './Button';
import ColorBox from './ColorBox';
import ColorInput from './ColorInput';

const { colors, selectColors } = Theme;

/**
 * ColorSelect style
 */
const ColorSelect = styled.div`
  min-width: 400px;
  height: 46px;
  border-bottom: 1px solid #e9e9e9;
  display: inline-block;
  position: relative;
  overflow: visible;
  background-color: transparent;
  transition: border 0.3s;

  ${({ block }) =>
    block &&
    `
  width: 100%;
  display: block;
  `};

  ${({ error }) =>
    error &&
    `
    border-bottom: 1px solid ${colors.danger} !important;
  `}

  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:first-child {
      left: 5px;
    }
  }

  ${({ open }) =>
    open &&
    `
  box-shadow: 1px 5px 3px 0px rgba(0,0,0,0.1);
  `};
`;

const DropBtn = styled(Button)`
  padding: 5px;
  right: -12px;
  width: 45px;
  height: 100%;
  & > span {
    margin: 0 !important;
  }
  &:active {
    top: 50%;
  }
  ${({ open }) =>
    open &&
    `
  & {
  transform: rotate(180deg) translateY(50%);
  }
  `}
`;

const Container = styled.div`
  width: 100%;
  top: 46px;
  left: -1px;
  transform: translate(0, 0);
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
  z-index: 3;
`;

const List = styled.ul`
  width: 100%;
  border: 1px solid ${({ error }) => (error ? colors.danger : colors.default)};
  background-color: #ffffff;
  max-height: 320px;
  overflow-y: scroll;
`;

const Item = styled.li`
  position: relative;
  box-sizing: border-box;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 100%;
  height: 45px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.03, '#ffffff')};
  }

  & :first-child {
    margin-right: 12px;
  }
`;

export default ({ placeholder, block, onColorChange = () => {} }) => {
  const [error, setError] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const toggleDrop = (e) => {
    e.preventDefault();

    // bind event to body on open
    if (!open) {
      document.body.addEventListener('click', function x(ev) {
        // unbind listener
        document.body.removeEventListener('click', x);

        // prevent propagating event downwards
        ev.preventDefault();

        const {
          target: { className = '' },
        } = ev;

        // stop propagating evt bubble to the dropdown button
        if (className.match(/toggle/i)) ev.stopPropagation();
        // don't close when clicking item list
        if (className.match && className.match(/list-item/i)) {
          return;
        }

        setOpen(false);
      });
    }

    setOpen(!open);
  };

  // handle error gracefully
  const onError = (err) => {
    setError(err);
    // we also need to call the colorchange handler
    // with the undefined value
    onColorChange(undefined);
  };

  const setColor = (c) => (e) => {
    // this shit prevents memory leak, son!
    //
    // PENZUR TIP: do this for comps that are bound to  be unmoutend
    // and remounted later like dropdown or combobox.
    //
    e.target.removeEventListener('click', setColor);
    e.preventDefault();
    // trim #
    const color = c.value.replace('#', '').toUpperCase();
    // call handler
    onColorChange(color);
    // propagate color changes to the input since setting input value
    // programatically won't trigger event.
    setSelected(color);
    // done
    setOpen(false);
  };

  useEffect(
    () => () => {
      setSelected('');
      setError(undefined);
      setOpen(false);
    },
    []
  );

  return (
    <>
      <ColorSelect {...{ error, open, block }}>
        <ColorInput
          onError={onError}
          placeholder={placeholder}
          defaultValue={selected}
          onColorChange={onColorChange}
        />

        <DropBtn
          text
          open={open}
          icon="dropdown"
          onClick={toggleDrop}
          className="toggle"
        />

        {open && (
          <Container>
            <List {...{ error }}>
              {selectColors.map((c) => (
                <Item
                  key={`${c.label}-${c.value}`}
                  className="list-item"
                  onClick={setColor(c)}
                >
                  <ColorBox color={c.value} className="list-item-box" />
                  {c.label}
                </Item>
              ))}
            </List>
          </Container>
        )}
      </ColorSelect>
    </>
  );
};
