import React from 'react';

import { Typography } from '../../ui';
import Placeholder from './PlaceHolder';

export default ({
  name,
  url,
  item,
  edit,
  size,
  usage,
  row,
  column,
  section,
  variant,
  readOnly,
  position,
  placeholder,
  onClickHolder,
}) => {
  if (!edit && !url && placeholder && !readOnly)
    return (
      <Placeholder
        placeholder={placeholder}
        height={170}
        onClick={onClickHolder}
      />
    );
  if (!edit && !url) return <></>;
  return (
    <Typography
      id={`${section}${row}${column}${item}`}
      font={{ name, url, variant }}
      size={size}
      position={position}
      usage={usage}
    />
  );
};
