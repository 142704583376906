import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
  background-color: #f0f0f0;
  width: 100%;
  height: 50px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 400;
    font-size: 12px;
    color: #9b9b9b;
    margin-bottom: 0;
  }
`;

export default () => {
  return (
    <Footer>
      <p>&copy; {new Date().getFullYear()} BrandBox. All rights reserved.</p>
    </Footer>
  );
};
