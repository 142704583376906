import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useParams, Link, useHistory } from 'react-router-dom';

import { setBackground } from '../store/page';
import { setBrandProps } from '../store/user';
import {
  updateBrand as ub,
  getFiles as gf,
  getCollections as gc,
} from '../svc/brand';
import {
  reset,
  setFiles,
  appendFiles,
  prependFiles,
  getFiles,
  getCollections,
  appendCollections,
  deleteFile,
  setCollectionFiles,
  appendCollectionFiles,
  prependCollectionFiles,
} from '../store/media';

import { Modal, Button, Spinner, Icon } from '../ui';
import { toast } from '../ui/Toast';
import Empty from '../components/Empty';
import Sorter from '../ui/Sorter';
import MediaBox from '../components/MediaBox';
import EditFile from '../components/EditFile';
import DeleteFile from '../components/DeleteFile';
import MediaSearch from '../components/MediaSearch';
import NewCollection from '../components/NewCollection';
import CollectionBox from '../components/CollectionBox';
import CollectionMediaBrowser from '../components/CollectionMediaBrowser';
import FileCollections from '../components/FileCollections';
import MediaBrowser from '../components/MediaBrowser';
import DescriptionBox from '../components/DescriptionBox';
import LazyLoad from '../components/LazyLoad';

const MAX_ROWS = 30;

const Spin = styled.div`
  height: ${window.innerHeight - 224}px;
  width: 100%;
  position: relative;
  margin-top: -32px;
`;

const SubNav = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 32px 0;

  .left {
    width: 50%;
    flex: 1;
  }
  .right {
    width: 50%;
    flex: 1;
    margin-bottom: 0 !important;
    .uploader {
      width: 173px;
      height: 30px;
      border-radius: 3px;
      float: right;
      background-color: black;
      span {
        color: white !important;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }

  a,
  a:active {
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-right: 18px;
    color: #888;
    outline: none;

    &.active {
      color: #000000;
      font-weight: bold;
    }
    &.disabled {
      pointer-events: none;
    }
  }
`;

const Content = styled.div`
  width: 960px;
  margin: 0 auto;
  clear: both;
  position: relative;

  .clear {
    clear: both;
  }

  h2 {
    button {
      padding: 0;
      margin-left: 6px;
    }
    svg {
      fill: #000000;
    }
  }

  h4 {
    position: relative;
    font-weight: normal;
    clear: both;

    .count {
      font-family: 'Roboto';
      display: inline-block;
      position: absolute;
      bottom: -6px;
      font-size: 12px;
      font-weight: normal;
      right: 0;
      letter-spacing: 0.5px;
      .mute {
        color: #b3b3b3;
      }
      svg {
        fill: #000000;
        position: relative;
        top: 3px;
      }
    }
  }

  &:not(:first-child) {
    padding-top: 32px;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 0;
    font-weight: normal;
  }

  .right {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 32px;
  }

  .media {
    position: relative;
    h3 {
      margin-bottom: 32px;
      font-size: 23px;

      button {
        position: relative;
        top: -2px;
        margin-left: 16px;
        svg {
          fill: #222;
        }
      }
    }
  }

  .media > div {
    float: left;
    &:not(:nth-child(4n)) {
      margin-right: 20px;
    }
    margin-bottom: 20px;
  }

  a,
  a:hover {
    text-decoration: none;
    border-bottom: none;
  }

  .back {
    margin: 0;
    font-size: 12px;
    svg {
      margin-right: 6px;
    }
    a,
    a:hover {
      color: #000000;
    }
    span {
      position: relative;
      top: -3px;
    }
  }
`;

const ContentHead = styled.div`
  padding: 32px 0;
  background-color: #ffffff;
  margin-bottom: 32px;
  border-bottom: 1px solid #f0f0f0;
`;

const Wrap = styled.div`
  padding-bottom: 32px;
  overflow: auto;
`;

const selector = ({ user, media, global }) => ({
  ...user,
  ...media,
  gbrand: global.brand,
});

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collection } = useParams();

  const { user, files, collections, collectionFiles, gbrand } =
    useSelector(selector);

  const brand = user?.brand || gbrand;
  const currentUser = brand?.users?.find((u) => u?.id === user?.id);

  const [search, setSearch] = useState(undefined);
  const [action, setAction] = useState(undefined);
  const [complete, setComplete] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [sortField, setSortField] = useState('created_at');
  const [ascending, setAscending] = useState(false);

  const resetFunc = useRef(null);

  const desc = brand?.meta?.media_description || {
    show: true,
    text: 'Organize your assets and find them quickly in media library with easy drag and drop upload, sorting and collection.',
  };

  const sortValues = ['created_at'];
  const sortItems = ['Date added'];
  if (collection === 'collections') {
    sortValues.push(...['name', 'total_items']);
    sortItems.push(...['Name', 'Total items']);
  } else {
    sortValues.push(...['file_name', 'file_size']);
    sortItems.push(...['File name', 'File size']);
  }

  const cancel = () => {
    setSelected(undefined);
    setAction(undefined);
  };

  const done = () => {
    cancel();
    dispatch(getFiles(brand.id, collection, search));
  };

  useEffect(() => {
    dispatch(setBackground('#f8f8f8'));
    const sf = sortField;
    if (sortValues.indexOf(sf) === -1) setSortField(sortValues[0]);

    if (brand && collection !== 'collections') {
      if (!search) {
        dispatch(setFiles(undefined));
        dispatch(setCollectionFiles(undefined));
      }
      dispatch(
        getFiles(
          brand.id,
          collection,
          search,
          undefined,
          undefined,
          sf,
          ascending
        )
      );
      setComplete(false);
    } else if (brand && collection === 'collections') {
      if (!search) {
        dispatch(setFiles(undefined));
        dispatch(setCollectionFiles(undefined));
      }
      dispatch(
        getCollections(brand.id, search, undefined, undefined, sf, ascending)
      );
      setComplete(false);
    }

    return () => {
      dispatch(setBackground(undefined));
      dispatch(reset());
    };
  }, [brand, dispatch, collection, search, sortField, ascending]);

  if (!user && brand?.access === 0) {
    return <></>;
  }

  return (
    <>
      <MediaSearch onSearch={setSearch} reset={resetFunc} />
      <ContentHead>
        <Content>
          {(() => {
            let label = 'Media';
            if (search) {
              label = 'searching...';
              if (collections)
                label = `${collections.length} ${
                  collections.length > 1 ? 'collections' : 'collection'
                } found for "${search}"`;
              if (collection !== 'collections') {
                if (collectionFiles)
                  label = `${collectionFiles.files.length} ${
                    collectionFiles.files.length > 1 ? 'items' : 'item'
                  } found for "${search}"`;
                if (files)
                  label = `${files?.files.length} ${
                    files?.files.length > 1 ? 'items' : 'item'
                  } found for "${search}"`;
              }
            }

            return (
              <>
                <h2 className="brand-font-large brand-color-large">{label}</h2>
                <pre
                  style={{
                    color: '#444444',
                    fontSize: 12,
                    marginTop: 12,
                    lineHeight: 1.42,
                    fontWeight: 300,
                    width: 'fit-content',
                    maxWidth: 400,
                    position: 'relative',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {!search && desc.show && desc.text}
                  {!search && user && currentUser?.role === 'owner' && (
                    <Button
                      onClick={() => setAction(7)}
                      icon="edit"
                      text
                      size="small"
                      style={{
                        position: 'absolute',
                        top: -6,
                        right: -30,
                      }}
                    />
                  )}
                </pre>
              </>
            );
          })()}
        </Content>
      </ContentHead>
      {(() => {
        if (
          (!collection && !files) ||
          (collection &&
            !collection.match(/all|coll|fav/) &&
            !collectionFiles) ||
          (collection && collection.match(/coll|fav/) && !collections)
        ) {
          return (
            <Spin>
              <Spinner spin />
            </Spin>
          );
        }

        return (
          <Wrap>
            <Content>
              {!search && (
                <SubNav>
                  <div className="left">
                    {!collectionFiles && (
                      <Link
                        className={
                          !collection || collection === ''
                            ? 'active disabled'
                            : ''
                        }
                        onClick={() => resetFunc.current()}
                        to="/media"
                      >
                        All files
                      </Link>
                    )}
                    <Link
                      className={(() => {
                        let cls = '';
                        if (collection && !collection?.match(/fav/))
                          cls += 'active';
                        if (collection === 'collections') cls += ' disabled';
                        return cls;
                      })()}
                      onClick={() => resetFunc.current()}
                      to="/media/collections"
                    >
                      {collectionFiles && (
                        <Icon
                          name="next-sml"
                          style={{
                            transform: 'scaleX(-100%)',
                            WebkitTransform: 'scaleX(-100%)',
                            position: 'relative',
                            top: 3,
                          }}
                        />
                      )}{' '}
                      Collections
                    </Link>
                    <span
                      style={{
                        position: 'relative',
                        marginRight: 20,
                        opacity: 0.3,
                      }}
                    >
                      |
                    </span>
                    <Sorter
                      items={sortItems}
                      onChange={(v) => setSortField(sortValues[v])}
                      defaultValue={sortValues.indexOf(sortField)}
                    />
                    <span
                      style={{
                        position: 'relative',
                        marginRight: 10,
                        opacity: 0.3,
                      }}
                    >
                      :
                    </span>
                    <Sorter
                      label=""
                      items={['Ascending', 'Descending']}
                      onChange={(v) => setAscending([true, false][v])}
                      defaultValue={[true, false].indexOf(ascending)}
                    />
                  </div>
                  {user &&
                    currentUser?.role.match(/^admin$|^owner$/i) &&
                    collection &&
                    collection === 'collections' &&
                    collections.length > 0 &&
                    !search && (
                      <div className="right">
                        <Button
                          onClick={() => setAction(5)}
                          icon="add-to-collection"
                          style={{ fontWeight: 'normal' }}
                        >
                          ADD NEW COLLECTION
                        </Button>
                      </div>
                    )}
                  {user &&
                    currentUser?.role.match(/^admin$|^owner$/i) &&
                    !collection &&
                    files?.files?.length > 0 &&
                    !search && (
                      <div className="right">
                        <Button
                          icon="add"
                          type="button"
                          onClick={() => setAction(6)}
                        >
                          UPLOAD MEDIA
                        </Button>
                      </div>
                    )}

                  {user &&
                    currentUser?.role.match(/^admin$|^owner$/i) &&
                    collectionFiles &&
                    collectionFiles.files.length > 0 && (
                      <div className="right">
                        <Button
                          icon="add"
                          type="button"
                          onClick={() => {
                            setSelected(collectionFiles.meta);
                            setAction(4);
                          }}
                        >
                          ADD MEDIA TO COLLECTION
                        </Button>
                      </div>
                    )}
                </SubNav>
              )}
              <div className="media">
                {collection && !collection.match(/all|coll|fav/) && (
                  <h3 className="brand-font-large brand-color-large">
                    {collectionFiles.meta.name}
                    {!search &&
                      brand.role?.match(/(admin|owner)/i) &&
                      collection &&
                      collection !== 'collections' &&
                      collectionFiles && (
                        <Button
                          icon="edit"
                          text
                          onClick={() => {
                            setSelected(collectionFiles.meta);
                            setAction(3);
                          }}
                        />
                      )}
                  </h3>
                )}
              </div>
              {(() => {
                if (!collection && files?.files.length === 0) {
                  if ((!user && brand.access === 1) || search)
                    return (
                      <Empty
                        onClick={undefined}
                        disabled
                        title="Media is empty"
                        sub="Media doesn't have any file uploaded yet"
                      />
                    );

                  return (
                    <Empty
                      onClick={() => setAction(6)}
                      title="Ready to add some files?"
                      sub="Click to add new files"
                    />
                  );
                }

                if (collection?.match(/coll/) && collections.length === 0) {
                  if (
                    (!user && brand.access === 1) ||
                    !currentUser?.role.match(/^admin$|^owner$/i) ||
                    search
                  )
                    return (
                      <Empty
                        onClick={undefined}
                        disabled
                        title="Collections is empty"
                        sub="You don't have any collection yet"
                      />
                    );
                  return (
                    <Empty
                      onClick={() => setAction(5)}
                      title="Ready to add some collections?"
                      icon="add-to-collection"
                      sub="Click to add new collection"
                    />
                  );
                }

                if (collection?.match(/fav/)) {
                  return (
                    <Empty
                      title="No favourites yet!"
                      icon="super"
                      sub="Click to add favourite"
                    />
                  );
                }

                if (
                  collection &&
                  !collection.match(/fav|all|coll/) &&
                  collectionFiles.files.length === 0
                ) {
                  if (search)
                    return (
                      <Empty
                        onClick={undefined}
                        disabled
                        title="Collections is empty"
                        sub="You don't have any collection yet"
                      />
                    );
                  return (
                    <Empty
                      title="Ready to add some files?"
                      sub="Click to add files to this collection"
                      onClick={() => {
                        setSelected(collectionFiles.meta);
                        setAction(4);
                      }}
                    />
                  );
                }
              })()}
              <div className="media">
                {!collection?.match(/all|coll|fav/) &&
                  (files || collectionFiles) &&
                  (() => {
                    let c = [];
                    if (collection) c = collectionFiles.files;
                    else c = files?.files;
                    return [...c];
                  })().map((f, i) => (
                    <MediaBox
                      download
                      readOnly={!brand.role?.match(/(admin|owner)/i)}
                      file={f}
                      key={`${f.id}-${i}`}
                      onEdit={(s) => {
                        setSelected(s);
                        setAction(0);
                      }}
                      showCollection={files?.collections?.length > 0}
                      onCollection={(s) => {
                        setSelected(s);
                        setAction(1);
                      }}
                      onDelete={(s) => {
                        setSelected(s);
                        setAction(2);
                      }}
                    />
                  ))}
              </div>
              {collection === 'collections' &&
                collections &&
                collections.length > 0 && (
                  <div className="media">
                    {(() => {
                      return [...collections];
                    })().map((c, i) => (
                      <CollectionBox
                        readOnly={!brand.role?.match(/(admin|owner)/i)}
                        files={c.files}
                        name={c.name}
                        count={c.total_items}
                        cid={c.id}
                        bid={brand.id}
                        key={`${c.id}-${i}`}
                        onClick={() => history.push(`/media/${c.id}`)}
                        onDelete={() => {
                          setSelected(c);
                          setAction(33);
                        }}
                        onEdit={() => {
                          setSelected(c);
                          setAction(3);
                        }}
                        download
                      />
                    ))}
                  </div>
                )}
              {collection === 'collections' &&
                collections.length >= MAX_ROWS &&
                !complete && (
                  <LazyLoad
                    onView={async (cb) => {
                      const q = {
                        sort_field: sortField,
                        ascending,
                      };
                      if (search) q.search = search;
                      const lv = collections[collections.length - 1][sortField];
                      if (lv) q.last_value = lv;
                      const { data, error } = await gc(brand.id, q);
                      if (!error) {
                        dispatch(appendCollections(data));
                        if (data.length < MAX_ROWS) setComplete(true);
                        cb();
                      }
                    }}
                  />
                )}
              {!collection && files?.files?.length >= MAX_ROWS && !complete && (
                <LazyLoad
                  onView={async (cb) => {
                    const q = {
                      sort_field: sortField,
                      ascending,
                    };
                    if (search) q.search = search;
                    const lv = files?.files[files?.files.length - 1][sortField];
                    if (lv) q.last_value = lv;
                    const { data, error } = await gf(brand.id, collection, q);
                    if (!error) {
                      dispatch(appendFiles(data?.files));
                      if (data?.files.length < MAX_ROWS) setComplete(true);
                      cb();
                    }
                  }}
                />
              )}
              {collection &&
                !collection.match(/all|coll/i) &&
                collectionFiles?.files?.length >= 20 &&
                !complete && (
                  <LazyLoad
                    onView={async (cb) => {
                      const q = {
                        sort_field: sortField,
                        ascending,
                      };
                      if (search) q.search = search;
                      const lv =
                        collectionFiles?.files[
                          collectionFiles?.files.length - 1
                        ][sortField];
                      if (lv) q.last_value = lv;
                      const { data, error } = await gf(brand.id, collection, q);
                      if (!error) {
                        dispatch(appendCollectionFiles(data?.files));
                        if (data?.files.length < MAX_ROWS) setComplete(true);
                        cb();
                      }
                    }}
                  />
                )}
            </Content>
            <Modal width={666} visible={action !== undefined}>
              {selected && action === 0 && (
                <EditFile
                  file={selected}
                  onUpdate={() => {
                    done();
                  }}
                  onCancel={cancel}
                />
              )}

              {selected && action === 1 && (
                <FileCollections
                  onCancel={cancel}
                  onCreate={() => {
                    done();
                  }}
                  file={selected}
                  onComplete={() => {
                    done();
                    toast('Success', 'File added to collection');
                  }}
                  collections={
                    (files && files.collections) ||
                    (collectionFiles && collectionFiles.collections) ||
                    []
                  }
                />
              )}

              {selected && action === 2 && (
                <DeleteFile
                  file={selected}
                  onDelete={(id) => {
                    cancel();
                    const p = {};
                    if (collection) p.cid = id;
                    else p.id = id;
                    dispatch(deleteFile(p));
                  }}
                  onCancel={cancel}
                />
              )}

              {selected && action === 3 && (
                <NewCollection
                  onCancel={cancel}
                  bid={brand.id}
                  collection={selected}
                  onDelete={() => {
                    window.location.href = '/media/collections';
                  }}
                  onUpdate={() => {
                    done();
                  }}
                />
              )}

              {selected && action === 33 && (
                <NewCollection
                  onCancel={cancel}
                  bid={brand.id}
                  collection={selected}
                  showDelete
                  onDelete={() => {
                    window.location.href = '/media/collections';
                  }}
                  onUpdate={() => {
                    done();
                  }}
                />
              )}

              {selected && collection && action === 4 && (
                <CollectionMediaBrowser
                  brandId={brand.id}
                  collectionId={collection}
                  onCancel={cancel}
                  onConfirm={(newCollectionFiles) => {
                    cancel();
                    dispatch(prependCollectionFiles(newCollectionFiles));
                  }}
                />
              )}

              {action === 5 && (
                <NewCollection
                  onCancel={cancel}
                  bid={brand.id}
                  onCreate={(id) => {
                    done();
                    history.push(`/media/${id}`);
                  }}
                />
              )}

              {action === 6 && (
                <MediaBrowser
                  category="media"
                  onCancel={() => setAction()}
                  multi
                  uploadOnly={1}
                  title="Select a file to upload"
                  onConfirm={(data) => {
                    setAction();
                    dispatch(prependFiles(data));
                  }}
                />
              )}

              {action === 7 && (
                <DescriptionBox
                  onCancel={() => setAction()}
                  description={desc}
                  title="Media Library"
                  onConfirm={(dsc) => {
                    const payload = {
                      online: brand?.online,
                      access: brand?.access,
                      meta: { ...brand?.meta, media_description: dsc },
                    };
                    dispatch(setBrandProps(payload));
                    ub(brand.id, payload);
                    setAction();
                  }}
                />
              )}
            </Modal>
          </Wrap>
        );
      })()}
    </>
  );
};
