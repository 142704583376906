import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

import { Spinner } from './ui';
import { toast as t } from './ui/Toast';
import { setPath, setBrand } from './store/global';
import { doGetBrand } from './store/brand';
import { doGetUser, setCredential, setErrorMessage } from './store/user';

import NotFound from './pages/NotFound';

import { setFiles, setCollections, setCollectionFiles } from './store/media';

const Listener = ({ history, children }) => {
  const dispatch = useDispatch();
  const bid = localStorage.getItem('brandId');
  const subdomain = window.location.host.split('.')[0];
  const gbrand = useSelector(({ global }) => global.brand);

  // google analytics
  ReactGA.initialize('UA-96750785-3');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [ready, setReady] = useState();

  const [notfound, setNotfound] = useState();

  useEffect(() => {
    if (ready) return;
    (async () => {
      // Flash messages like a pro!
      let flashMessage;
      try {
        const fstr = localStorage.getItem('flash');
        const flash = JSON.parse(fstr);
        if (flash && Object.keys(flash).length === 2) {
          flashMessage = flash;
        }
      } catch {} // eslint-disable-line
      if (flashMessage) {
        t(flashMessage.title, flashMessage.description);
        localStorage.removeItem('flash');
      }

      const qtoken = new URLSearchParams(window.location.search).get('token');
      const quserId = new URLSearchParams(window.location.search).get('userId');
      const qbrandId = new URLSearchParams(window.location.search).get(
        'brandId'
      );
      const qoffline = new URLSearchParams(window.location.search).get(
        'offline'
      );

      if (window.location.pathname.match(/\/login/i) && qtoken && quserId) {
        let url = '/basics';
        if (qoffline) url = '/settings/advanced';

        localStorage.setItem('token', qtoken);
        localStorage.setItem('userId', quserId);
        localStorage.setItem('brandId', qbrandId);

        window.location.href = url;
        return;
      }

      if (!subdomain.match(/(www|local|v2|brandbox|^a$|^w$|127)/)) {
        dispatch(
          doGetBrand(
            subdomain,
            (error, data) => {
              if (error) {
                setNotfound(true);
                dispatch(setBrand(undefined));
              } else {
                dispatch(setBrand(data));
              }
              setReady(true);
            },
            true
          )
        );
      } else {
        setReady(true);
      }

      // check if userId exists
      const userId = localStorage.getItem('userId');
      const terms = localStorage.getItem(`bb-terms-${userId}`) || '';
      if (userId && flashMessage && flashMessage.title.match(/verified/i)) {
        localStorage.setItem(`tooltip-color-${userId}`, true);
        localStorage.setItem(`tooltip-font-${userId}`, true);
        localStorage.setItem(`tooltip-asset-${userId}`, true);
        localStorage.setItem(`tooltip-row-${userId}`, true);
        localStorage.setItem(`tooltip-section-${userId}`, true);
        localStorage.setItem(`tooltip-section-toggle-${userId}`, true);
        localStorage.setItem(`tooltip-media-${userId}`, true);
        localStorage.setItem(`tooltip-touchpoint-${userId}`, true);
        localStorage.setItem(`tooltip-trial-${userId}`, true);
        localStorage.setItem(`insider-${userId}`, true);
      }

      dispatch(setPath(history.location.pathname));

      history.listen(({ pathname: path }) => {
        // close em toasts!
        toast.dismiss();
        ReactGA.pageview(window.location.pathname + window.location.search);

        const uid = localStorage.getItem('userId');
        // clear err and credential
        dispatch(setPath(path));
        dispatch(setCredential({}));
        dispatch(setErrorMessage(null));

        if (!uid && path.match(/^\/$/)) return history.push('/login');

        if (
          (uid || gbrand?.access === 1) &&
          path.match(/(login|signup|reset-password|^\/$)/i)
        )
          return history.push('/basics');

        if (uid && path.match(/^\/$/)) {
          localStorage.setItem(`bb-terms-${uid}`, terms);
        }

        if (uid && path.match(/media/i)) {
          dispatch(setFiles(undefined));
          dispatch(setCollections(undefined));
          dispatch(setCollectionFiles(undefined));
        }
      });

      // not logged in
      if (!userId && gbrand?.access !== 1) {
        // if on home page, redirect to login
        if (
          ![
            'login',
            'signup',
            'reset-password',
            'password',
            'forgot',
            'request',
            'verify',
            'media',
            'touchpoints',
          ].includes(history.location.pathname.split('/')[1])
        ) {
          history.push('/login');
        }
        return;
      }

      // here we logged in as fuck
      // fetch user details
      if (userId) {
        dispatch(
          doGetUser({ id: userId, bid }, (err, user) => {
            if (err) {
              localStorage.removeItem('userId');
              localStorage.removeItem('token');
              localStorage.removeItem('brandId');
              history.push('/login');
            }
            if (!user?.verified) {
              history.push('/');
            }
          })
        );
      }
      const path = history.location.pathname;
      if (
        [
          'login',
          'signup',
          'reset-password',
          'password',
          'forgot',
          'request',
          '',
        ].includes(path.split('/')[1])
      ) {
        history.push('/basics');
      }
    })();
  }, [gbrand]); // eslint-disable-line

  if (!ready) return <Spinner spin />;
  if (notfound)
    return (
      <NotFound
        url={window.location.href
          .replace(`${subdomain}.`, '')
          .replace('/login', '')}
      />
    );

  return <>{children}</>;
};

export default withRouter(Listener);
