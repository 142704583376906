import React, { useState } from 'react';

import { addTouchpointToCollections } from '../svc/brand';

import { Spinner } from '../ui';
import TouchpointBrowser from './TouchpointBrowser';

export default ({
  title,
  brandId,
  collectionId,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const confirm = async (files) => {
    setLoading(true);
    await Promise.all(
      files.map((f) =>
        addTouchpointToCollections(brandId, f.id, [collectionId])
      )
    );
    setLoading(false);
    onConfirm();
  };

  return (
    <Spinner spin={loading}>
      <TouchpointBrowser
        title={title}
        multi
        onCancel={onCancel}
        onConfirm={confirm}
      />
    </Spinner>
  );
};
