import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import user from './user';
import page, { updatePresent } from './page';
import media from './media';
import brand from './brand';
import global, { setError, setLoading } from './global';
import touchpoint from './touchpoint';

let timeOut;

const debounce = (cb) => {
  if (timeOut) {
    clearTimeout(timeOut);
  }

  timeOut = setTimeout(cb, 100);
};

export default configureStore({
  reducer: {
    user,
    page,
    media,
    brand,
    global,
    touchpoint,
  },
  middleware: [
    // this middleware will set the global.loading state to true
    // everytime a side-effect action is being dispatch, and then
    // set the loading state back to false right after.
    //
    // the first
    (store) => (next) => (action) => {
      // under store you can also extract "getState", which is a function that
      // returns the root state.
      //
      // e.g:
      // const { user, page } = getState();
      const { dispatch } = store;
      // if side-effect actions
      if (typeof action === 'function')
        next(async (...args) => {
          // reset error
          dispatch(setError(null));
          dispatch(setLoading(true));
          await action(...args);
          dispatch(setLoading(false));
        });
      // if we are setting dropTarget, then we need to debounce it to prevent
      // lag inputs
      else if (action.type.match(/droptarget/i)) debounce(() => next(action));
      // regular actions
      else {
        if (
          action.type === 'page/move' ||
          action.type === 'page/updateRow' ||
          action.type === 'page/updateSection' ||
          action.type === 'page/duplicateComponent' ||
          action.type === 'page/removeComponent' ||
          action.type === 'page/updateItem'
        ) {
          dispatch(updatePresent());
        }
        next(action);
      }
    },
    ...getDefaultMiddleware(),
  ],
});
