import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Icon from './Icon';
import Spinner from './Spinner';

const Uploader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ progress }) =>
    progress > 0 &&
    `
  opacity: 0.5;
  `}

  ${({ block }) =>
    block &&
    `
    width: 100%;
    display: block;
  `};

  border-radius: 0;
  font-size: 12px;
  position: relative;
  box-sizing: border-box;
  font-weight: 300;
  font-family: Roboto;
  margin-bottom: 12px;
  margin-right: ${({ block: b }) => (b ? '0px' : '12px')};
  letter-spacing: 0.3px;

  & input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: .7;
      & select {
        cursor: not-allowed;
      }
      & svg {
        opacity: 0.4;
      }
  `}

  & span.placeholder {
    color: #000000;
    box-sizing: border-box;
    padding: 4px;
    text-align: center;
    &.error {
      color: red;
    }
  }

  & label {
    cursor: ${({ progress, disabled }) =>
      disabled || (progress > 0 && progress !== 100) ? 'default' : 'pointer'};
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    ${({ progress, disabled }) =>
      progress === 0 &&
      !disabled &&
      `
    &:hover {
      background-color: rgba(0,0,0,0.05);
    }
    `}

    ${({ bordered, disabled, progress }) =>
      bordered &&
      `
    border: 1px dashed #${disabled || progress !== 0 ? 'd2d2d2' : '000000'};
    `}

    & svg {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      fill: #000000;
      ${({ error }) =>
        error &&
        `
      fill: red !important;
      `}
    }

    &.drop {
      border: 1px dashed #000000;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  ${({ progress }) =>
    progress > 0 &&
    `
    pointer-events: none;
  `}
`;

export default (p) => {
  const props = { ...p };
  const {
    icon,
    name = 'file',
    multi = false,
    onFile = () => {},
    accept,
    endpoint,
    onUpload = () => {},
    disabled,
    formData = {},
    bordered,
    iconColor = 'white',
    autoupload = true,
    placeholder = 'Add new files',
  } = props;

  // remove so styled comp won't complain
  delete props.onUpload; // eslint-disable-line
  delete props.onFile; // eslint-disable-line

  const labelRef = useRef();

  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);

  const upload = async (files) => {
    const tooLarge = files.find((f) => f.size > 1000000000);
    if (tooLarge) {
      setError('file exceeds limit');
      return;
    }
    // reset
    setProgress(0);
    setError(false);

    if (autoupload === false) {
      onFile(files);
      return;
    }

    if (!files || files.length < 1 || !endpoint) return;

    setProgress(10);
    try {
      const uploads = await Promise.all(
        files.map(async (item) => {
          // create new form data instance
          const payload = new FormData();
          // append file
          payload.append('file', item, item.name.replace(' ', '-'));
          Object.keys(formData).forEach((k) => {
            payload.append(k, formData[k]);
          });

          // send query
          const { data, status = 0 } = await axios.post(endpoint, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },

            onUploadProgress: (evt) => {
              const percent = Math.ceil((evt.loaded * 100) / evt.total);
              if (percent <= 100) setProgress(percent);
            },
          });

          return { data, status };
        })
      );
      if (typeof onUpload === 'function') onUpload(uploads);
      setProgress(0);
    } catch (err) {
      if (err.message.match(/401/)) {
        localStorage.clear();
        window.location.href = '/';
      }
      // reset progress back to 0
      setProgress(0);
      setError(true);

      if (typeof onUpload === 'function') {
        onUpload({ error: err.message });
      }
    }
  };

  const drop = (e) => {
    e.preventDefault();
    e.target.classList.remove('drop');
    upload([...e.dataTransfer.files]);
  };

  const dragover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add('drop');
  };

  const dragleave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove('drop');
  };

  const onFiles = (e) => {
    const { files } = e.target;
    const ufiles = Array.from(files);
    if (multi) upload(ufiles);
    else upload([ufiles[0]]);
  };

  useEffect(() => {
    if (!labelRef.current) return;
    const labelRefCopy = labelRef;

    labelRefCopy.current.addEventListener('drop', drop);
    labelRefCopy.current.addEventListener('dragover', dragover);
    labelRefCopy.current.addEventListener('dragleave', dragleave);

    return () => {
      labelRefCopy.current.removeEventListener('drop', drop);
      labelRefCopy.current.removeEventListener('dragover', dragover);
      labelRefCopy.current.removeEventListener('dragleave', dragleave);
    };
  });

  return (
    <>
      <Uploader {...props} progress={progress} error={error}>
        {progress === 0 && bordered && <span style={{ fontSize: 24 }}>+</span>}
        {icon && <Icon color={iconColor} name={icon} />}
        <span className={`placeholder ${error ? 'error' : ''}`}>
          {(() => {
            // in progress
            if (progress > 0 && progress < 99)
              return `Uploading file(s) ${progress}%`;
            if (progress > 99) return <Spinner spin />;
            // error
            if (error) {
              let errmsg = 'Upload failed!';
              if (typeof error === 'string' && error?.match(/exceed/i))
                errmsg = 'Your file exceeds the maximum file size of 1GB';
              return errmsg;
            }
            // default
            return placeholder;
          })()}
        </span>

        <input
          id={name}
          name={name}
          type="file"
          disabled={disabled || (progress > 0 && progress < 100)}
          accept={accept}
          multiple
          onChange={onFiles}
        />

        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        <label ref={labelRef} htmlFor={name}>
          &nbsp;
        </label>
      </Uploader>
    </>
  );
};
