import React, { useState } from 'react';
import styled from 'styled-components';
import { BlockButton } from '../ui';
import SettingsFooter from './editor/SettingsFooter';

import MediaBrowser from './MediaBrowser';

const Main = styled.main`
  height: 445px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  h3 {
    width: 461px;
    height: 30px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.1px;
    color: #000000;
    margin-bottom: 21px;
  }
  h4 {
    width: 484px;
    height: 21px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.1px;
    color: #000000;
    margin: 41px 0 14px;
  }
  p {
    width: 300px;
    height: 34px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.08px;
    color: #444444;
    margin-bottom: 26px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin: 1px 0 5px 5px;
    box-sizing: border-box;
  }
`;

export default ({ onConfirm = () => {}, onCancel = () => {} }) => {
  const [selected, setSelected] = useState();
  const [media, setMedia] = useState();

  const confirm = () => {
    if (selected && selected.match(/image|video|audio/i)) {
      setMedia(selected);
      return;
    }
    onConfirm(selected);
  };

  if (media) {
    return (
      <MediaBrowser
        type={media}
        category="basics"
        onCancel={() => setMedia()}
        onConfirm={(o) => {
          onConfirm({
            url: o.urls.original,
            type: media,
          });
        }}
      />
    );
  }

  return (
    <>
      <Main>
        <h3>General content blocks</h3>
        <p>
          Choose a component below and click Confirm to add new content to the
          layout.
        </p>
        <ButtonWrapper>
          {[
            ['audio', 'Audio'],
            ['divider', 'Divider'],
            ['image', 'Image'],
            ['text', 'Text'],
            ['video', 'Video'],
            ['welcomeintro', 'Welcome Intro'],
          ].map((comp) => (
            <BlockButton
              key={comp[0]}
              icon={comp[0]}
              text={comp[1]}
              onClick={() => {
                if (comp[0] === 'carousel') return;
                if (selected === comp[0]) setSelected();
                else setSelected(comp[0]);
              }}
              selected={selected === comp[0]}
            />
          ))}
        </ButtonWrapper>
        <h4>Advanced content blocks</h4>
        <p>
          Choose a component below and click Confirm to automate useful brand
          details.
        </p>
        <ButtonWrapper>
          {[
            ['color', 'Color'],
            ['typography', 'Typography'],
            ['gradient', 'Gradient'],
            ['minimumsize', 'Logo Min. Size'],
            ['exclusionzone', 'Logo Spacing'],
          ].map((comp) => (
            <BlockButton
              key={comp[0]}
              icon={comp[0]}
              text={comp[1]}
              onClick={() => {
                if (selected === comp[0]) setSelected();
                else setSelected(comp[0]);
              }}
              selected={selected === comp[0]}
            />
          ))}
        </ButtonWrapper>
      </Main>
      <SettingsFooter
        bordered
        showCancel
        showConfirm
        onCancel={onCancel}
        onConfirm={selected ? confirm : undefined}
      />
    </>
  );
};
