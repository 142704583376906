import React from 'react';
import styled from 'styled-components';

import { Icon, Button } from '../ui';

import { download as dl } from '../svc/client';

const Box = styled.div`
  box-sizing: border-box;
  width: 225px;
  height: 180px;
  font-size: 12px;
`;

const CBox = styled(Box)`
  border: 1px solid #d2d2d2;
  background-color: #ffffff;

  img {
    height: 100%;
  }
  object {
    width: 100%;
    height: inherit;
    object-fit: contain;
    box-sizing: border-box;
    padding: 12px;
  }
  video {
    width: 100%;
    height: 100%;
  }

  audio {
    position: absolute;
    bottom: 0;
  }

  .main {
    position: relative;
    box-sizing: border-box;
    height: 135px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;

    & > .thumb {
      width: 107px;
      height: 63px;
      float: left;
      margin: 2px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-child(4n + 0),
      &:first-child {
        background-color: #f0f0f0;
      }
    }

    img,
    object {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding: 0;
    }

    svg {
      fill: #ffffff;
      width: 64px;
      height: 64px;
    }

    .icon {
      fill: rgba(0, 0, 0, 0.3);
      width: 40px;
      height: 40px;
    }

    .toggle {
      overflow: auto;

      button {
        position: absolute;
        right: -2px;
        top: 4px;
        padding: 2px 2px !important;
        margin: 0 !important;
        & > svg {
          width: 32px;
          height: 16px;
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      & > .menu {
        display: none;
        position: absolute;
        box-sizing: border-box;
        top: 24px;
        right: 10px;
        width: 130px;
        padding: 12px;
        padding-bottom: 24px;
        background-color: #ffffff;
        border: 1px solid #d2d2d2;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);

        button {
          position: relative;
          font-size: 12px;
          margin: 0 !important;
          padding: 0 !important;
          font-weight: 300;
          &:not(:last-child) {
            margin-bottom: 16px !important;
          }
          text-align: left;
        }

        svg {
          fill: #000000;
          background-color: transparent;
          width: 12px;
          height: 12px;
          border-radius: 0;
        }
      }

      &:hover {
        .menu {
          display: block;
        }
      }
    }
  }

  ${({ small }) =>
    small &&
    `
    width: 190px;
    height: 150px;
    .main { height: 105px; }
  `}

  footer {
    position: relative;
    font-size: 12px;
    padding: 2px 15px;

    p {
      margin-bottom: -8px;
    }

    .mute {
      color: #999999;
    }

    .download {
      position: absolute;
      top: 9px;
      right: 12px;
      padding: 0;
      margin: 0;
      &:hover {
        text-decoration: none;
        border: 0;
      }
    }
  }
`;

const Triangle = styled.span`
  display: inline-block;
  position: absolute;
  top: -5px;
  right: 8px;
  width: 10px;
  height: 5px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px dotted transparent;
    border-right: 5px dotted transparent;
    border-bottom: 5px solid #d2d2d2;
    position: absolute;
  }
  &:after {
    border-bottom: 5px solid #ffffff;
    top: 2px; /* offset sets width of border */
  }
`;

export default ({
  cid,
  bid,
  name,
  small,
  count,
  files,
  onEdit = () => {},
  onClick = () => {},
  readOnly,
  download,
  onDelete = () => {},
  touchpoint,
  onDownloading = () => {},
}) => {
  return (
    <>
      <CBox small={small ? 'small' : ''} key={cid}>
        <div className="main" onClick={onClick}>
          {(() => {
            const fls = files
              .filter((f) =>
                f.urls.original.match(/jpg|jpeg|gif|svg|png|webp/i)
              )
              .slice(0, 4);
            return Array(4)
              .fill(0)
              .map((_, i) => (
                <div className="thumb" key={`k-${i}`}>
                  {fls[i] && (
                    <object
                      data={fls[i].urls.thumbnail || fls[i].urls.original}
                      alt={fls[i].file_name}
                    >
                      &nbsp;
                    </object>
                  )}
                </div>
              ));
          })()}
          {!readOnly && (
            <div className="toggle">
              <Button icon="more" text />
              <div className="menu">
                <Triangle />
                <Button
                  block
                  size="small"
                  text
                  icon="edit"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit(cid);
                  }}
                >
                  Edit
                </Button>
                <Button
                  block
                  size="small"
                  text
                  icon="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete(cid);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          )}
        </div>
        <footer>
          <p>
            {name.slice(0, 23)}
            {name.length > 23 && '...'}
          </p>
          <p className="mute">
            {count || files.length} item{(count || files.length) > 1 ? 's' : ''}
          </p>
          {bid &&
            download && ( // eslint-disable-next-line
              <a
                href="/#"
                onClick={async (e) => {
                  e.preventDefault();
                  onDownloading(true);
                  const { data, error } = await dl(
                    `/brands/${bid}/${
                      touchpoint ? 'touchpoints/' : ''
                    }collections/${cid}/download`
                  );
                  if (!error) {
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(data);
                    a.download = `${name.replace(' ', '-')}.zip`;
                    a.click();
                  }
                  onDownloading(false);
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="download brand-color"
                download={name}
              >
                <Icon name="download" />
              </a>
            )}
          {!bid && download && files.length > 0 && (
            <a
              href={`/api/v1/file?url=${window.encodeURIComponent('aaa')}`}
              target="_blank"
              rel="noopener noreferrer"
              className="download brand-color"
              download={name}
            >
              <Icon name="download" />
            </a>
          )}
        </footer>
      </CBox>
    </>
  );
};
