import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import { AiOutlineCreditCard } from 'react-icons/ai';

import SettingsWrap from '../components/SettingsWrap';
import Subscription from '../components/Subscription';

import { doGetUser } from '../store/user';

import { Button, Modal, Tooltip } from '../ui';
import { toast } from '../ui/Toast';

const CurrentPlan = styled.div`
  h2:not(:first-child) {
    margin-top: 64px;
  }
  .billing {
    width: 740px;
    box-sizing: border-box;

    & > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      padding: 12px 0;
      & > div {
        flex: 1;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  dl {
    font-size: 15px;
    margin: 32px 0;
    width: 740px;

    dt {
      color: #444444;
      margin-top: 20px;
    }

    dd {
      padding: 10px 0 20px 0;
      color: #aaaaaa;
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      &.small {
        font-size: 12px;
      }
    }
  }

  .card {
    padding: 18px;
    width: 740px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    position: relative;

    button {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      button {
        display: inline-block;
      }
    }

    & > div {
      margin-right: 12px;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    p {
      font-weight: normal;
      margin: 0;

      &.sub {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  small.default {
    border-radius: 3px;
    font-size: 10px;
    opacity: 0.5;
    position: relative;
    background-color: #c1c1c1;
    color: black;
    padding: 2px 8px;
  }

  .plan-section {
    width: 740px;
    height: 240px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    padding: 59px;
    margin-bottom: 15px;
    position: relative;
    .title {
      height: 30px;
      font-size: 24px;
      font-weight: normal;
      line-height: 1.25;
      letter-spacing: 0.1px;
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }
    .desc {
      display: block;
      width: 100%;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.4;
      letter-spacing: 0.1px;
      text-align: center;
      color: #444444;
      margin-bottom: 25px;
      a {
        font-weight: normal;
        color: #000000;
      }
    }
    button {
      height: 30px;
      text-transform: uppercase;
    }
  }
  .status {
    span {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: 0.05px;
      color: #444444;
    }
    span:last-child {
      font-weight: normal;
      color: #000000;
    }
  }
`;

export default () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user: u }) => u.user);

  const [open, setOpen] = useState(false);
  const { brand, id: uid } = user;
  const coupon = new URLSearchParams(window.location.search)
    .get('coupon')
    ?.trim();

  const [trialTooltip, setTrialTooltip] = useState(
    localStorage.getItem(`tooltip-trial-${user.id}`)
  );

  const {
    invoices,
    created_at: created,
    subscription,
    next_invoice: nextInvoice,
    default_payment: pid,
    payment_methods: payments,
  } = brand;

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = (error) => {
    if (error) {
      toast.error(
        'Subscription Failed!',
        'Could not process your payment. Please try again.'
      );
      return;
    }
    onCancel();
    dispatch(
      doGetUser(uid, (err, u) => {
        if (err) return;
        if (u?.brand?.subscription)
          toast(
            'Account upgraded',
            'Congratulations! Your account has been upgraded to full subscription'
          );
      })
    );
  };

  const start = new Date(created).getTime();
  const elapse = Math.round((Date.now() - start) / 8.64e7);

  return (
    <SettingsWrap>
      {user && (
        <section>
          <CurrentPlan>
            <h2>Your plan</h2>
            {!subscription && (
              <>
                <div className="plan-section">
                  {30 - elapse <= 5 && trialTooltip && (
                    <Tooltip
                      style={{ position: 'absolute', top: 17, right: 17 }}
                      text="Trial expiring soon"
                      onDismiss={() => {
                        setTrialTooltip(undefined);
                        localStorage.removeItem(
                          `tooltip-trial-${user.id}`,
                          true
                        );
                      }}
                    />
                  )}

                  <p className="title">Brand Starter (free trial version)</p>
                  <p className="desc">
                    Best for brands looking to easily access and manage their
                    brand guidelines.
                  </p>
                  <Button size="small" onClick={() => setOpen(1)}>
                    Upgrade to full subscription
                  </Button>
                </div>
                <p className="status">
                  <span>Free trial status: </span>
                  <span>{30 - elapse} days left</span>
                </p>
              </>
            )}
            {subscription && (
              <>
                <dl>
                  <dt className="title">{subscription.plan.nickname}</dt>
                  <dd className="desc">
                    {subscription.plan.metadata?.description?.split('\n')[0]}
                  </dd>
                  <dt>Next payment</dt>
                  <dd>
                    US${(nextInvoice.amount / 100).toLocaleString()} to be
                    charged to your card on{' '}
                    {dayjs(nextInvoice.date * 1000).format('MMM DD, YYYY')}
                  </dd>
                  <dt>&nbsp;</dt>
                  <dd className="small">
                    <a href="mailto:support@brandboxhq.com">Get in touch</a> to
                    upgrade or cancel your subscription
                  </dd>
                </dl>

                <h2>Payment</h2>
                {(!payments || payments.length === 0) && (
                  <p>
                    Could not find any payment method.
                    <br />
                    <br />
                    <Button onClick={() => setOpen(2)}>ADD ONE NOW</Button>.
                  </p>
                )}
                {payments &&
                  payments.length > 0 &&
                  payments
                    .filter((p) => p.id === pid)
                    .map((p) => (
                      <div className="card" key={p.id}>
                        <div>
                          <AiOutlineCreditCard />
                        </div>
                        <div>
                          <p>{p.billing_details.name}</p>
                          <p className="sub">XXXX XXXX XXXX {p.card.last4}</p>
                        </div>
                        <div>
                          <p className="sub">
                            {p.id === pid ? (
                              <small className="default">DEFAULT</small>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </p>
                          <p
                            className="sub"
                            style={{ marginTop: -1, position: 'relative' }}
                          >
                            exp. {p.card.exp_month}/
                            {p.card.exp_year.toString().slice(2)}
                          </p>
                          <Button
                            text
                            size="small"
                            icon="edit"
                            onClick={() => setOpen(p)}
                          />
                        </div>
                      </div>
                    ))}

                <h2>Billing History</h2>
                <div className="billing">
                  <div>
                    <div>
                      <strong>Date</strong>
                    </div>
                    <div>
                      <strong>Description</strong>
                    </div>
                    <div>
                      <strong>Amount</strong>
                    </div>
                    <div>
                      <strong>Status</strong>
                    </div>
                  </div>
                  {invoices.map((i) => (
                    <div key={i.id}>
                      <div>
                        {dayjs(i.period_end * 1000).format('MMM DD, YYYY')}
                      </div>
                      <div>'{i.lines.data[0].price.nickname}' subscription</div>
                      <div>US${(i.total / 100).toLocaleString()}</div>
                      <div
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {i.status}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </CurrentPlan>
        </section>
      )}
      <Modal visible={open} width={657}>
        {open === 1 && (
          <Subscription
            sid={subscription?.items?.data[0].id}
            coupon={coupon}
            onCancel={onCancel}
            onConfirm={onConfirm}
            subscription={subscription?.plan}
          />
        )}

        {(open === 2 || typeof open === 'object') && (
          <Subscription
            sid={subscription?.items?.data[0].id}
            payment={open}
            cardOnly
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </Modal>
    </SettingsWrap>
  );
};
