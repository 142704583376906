import React from 'react';
import styled from 'styled-components';

import Header from '../Header';
import Footer from '../Footer';

const Default = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.div`
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 0;
`;

export default ({ children }) => {
  return (
    <Default>
      <Header />
      <Main className="main">{children}</Main>
      <Footer />
    </Default>
  );
};
