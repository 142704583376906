import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TinySwitch } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const SwitchWrapper = styled.div`
  display: flex;
`;

const SwitchLabel = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.08px;
  color: ${({ checked }) => (checked ? '#000000' : '#999999')};
  margin-left: 40px;
`;

const UISwitch = styled(TinySwitch)`
  margin-top: 2px;
`;

export default (attrs) => {
  const { props } = attrs;
  const { loop = false, autoPlay = false } = props;
  const dispatch = useDispatch();

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onSelectAutoPlay = (v) => {
    update({ autoPlay: v });
  };
  const onSelectLoop = (v) => {
    update({ loop: v });
  };

  return (
    <Ctl>
      <section>
        <h3>Playback</h3>
        <p>Choose playback method when video section appears.</p>

        <SwitchWrapper>
          <UISwitch
            checked={autoPlay}
            name="autoplay"
            onChange={onSelectAutoPlay}
          />
          <SwitchLabel checked={autoPlay}>Auto-play video</SwitchLabel>
        </SwitchWrapper>
        <SwitchWrapper>
          <UISwitch checked={loop} name="loop" onChange={onSelectLoop} />
          <SwitchLabel checked={loop}>Set playback to loop</SwitchLabel>
        </SwitchWrapper>
      </section>
    </Ctl>
  );
};
