import React, { useState } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import Icon from './Icon';
import { getColorFromProps } from '../config';

const Select = styled.div`
  width: auto;
  display: inline-block;

  color: ${(p) => getColorFromProps({ props: p, input: true, front: true })};

  ${({ block }) =>
    block &&
    `
    width: 100%;
    display: block;
  `};

  border-radius: 0;
  font-size: 15px;
  position: relative;
  box-sizing: border-box;
  font-weight: 300;
  font-family: Roboto;
  margin-bottom: 12px;
  margin-right: ${({ block: b }) => (b ? '0px' : '12px')};
  letter-spacing: 0.3px;
  background-color: transparent;

  border-bottom: 1px solid
    ${(p) => {
      if (p.error) return '#ff2419 !important';
      return getColorFromProps({ props: p, input: true });
    }};
  &:hover {
    border-color: ${(p) => {
      let color = getColorFromProps({ props: p, input: true });
      if (p.error) color = '#ff2419 !important';
      if (p.disabled) return color;
      return darken(0.2, color);
    }};
  }

  & select {
    cursor: pointer;
    background-color: transparent;
    display: block;
    border: 0;
    padding: 0;
    width: 100%;
    height: 45px;
    -moz-appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  & svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    fill: ${(p) => getColorFromProps({ props: p, input: true, front: true })};
  }

  ${({ mute }) =>
    mute &&
    `
    & select {
      color: #999999;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: .7;
      & select {
        cursor: not-allowed;
      }
      & svg {
        opacity: 0.4;
      }
  `}
`;

export default (p) => {
  const props = { ...p };
  const { children, onChange, id, name, disabled, defaultValue } = props;

  const [mute, setMute] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const val = e.target.value.trim();

    if (typeof onChange === 'function') onChange(val, e.target.selectedIndex);
    if (!val) setMute(true);
    else setMute(false);
  };

  return (
    <Select {...props} mute={mute}>
      <Icon name="dropdown" />
      <select {...{ id, name, disabled, defaultValue }} onChange={handleChange}>
        {children}
      </select>
    </Select>
  );
};
