import React from 'react';
import styled, { css } from 'styled-components';

import Spinner from './Spinner';

const FontTitle = styled.p`
  font-family: Roboto;
  margin-bottom: 7.5px !important;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #999999;

  &:first-child {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-top: 24px;
  }
  ${({ size }) =>
    size &&
    `
    font-size: ${(() => {
      if (size === 2) return 22;
      if (size === 3) return 28;
      return 15;
    })()}px;
  `}
`;

const FontBody = styled.p`
  font-size: 17px;
  line-height: 1em !important;
  color: #999999;
  word-break: break-word;
  max-width: 230px;
`;

const FontHead = styled.p`
  font-size: 30px;
  font-weight: bold;
  line-height: 1em !important;
  color: #999999;
  word-break: break-word;
  margin-bottom: 16px !important;
`;

const Typography = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  background-color: #ffffff;

  ${({ font, fontName, weight = 'normal', fstyle = 'normal' }) =>
    font &&
    css`
      @font-face {
        font-family: ${fontName};
        font-display: swap;
        src: url(/api/v1/file?url=${window.encodeURIComponent(font)});
      }

      .font {
        font-family: ${fontName} !important;
        font-weight: ${weight} !important;
        font-style: ${fstyle} !important;
      }
    `};
`;

export default (props) => {
  const { font = {}, id, size = 0, usage = 1, position = false } = props;
  const text = `AaBbCcDdEeFfGgHhIiJj
  KkLlMmNnOoPpQqRrSs
  TtUuVvWwXxYyZz
`;

  let weight = 'normal';
  let style = 'normal';

  // extract numeric char
  if (font.variant) {
    const w = font.variant.toString().replace(/[^0-9]/g, '');
    if (w.match(/[0-9]{2,}/)) weight = w;
    // extract alpha char
    const s = font.variant.toString().replace(/[^a-z]/gi, '');
    if (s.match(/[a-z]{2,}/i)) style = s.replace(/regular/i, 'normal');
  }

  const head = `AaBbCcDdEeFf 0123456789`;

  const fn = (name) => {
    return name.split('.')[0].replace('-', ' ');
  };

  return (
    <Typography
      font={font.url}
      weight={weight}
      fstyle={style}
      fontName={!font.name ? 'Roboto' : `${fn(font.name)}-${id}`}
    >
      <Spinner spin={font?.name && !font.url}>
        {position && size !== 0 && (
          <FontTitle
            size={size}
            as={`h${[3, 2, 1][size - 1] + 2}`}
            className={(() => {
              if (size === 1) return `brand-font-small brand-color-small`;
              if (size === 2) return `brand-font-regular brand-color-regular`;
              if (size === 3) return `brand-font-large brand-color-large`;
            })()}
          >
            {font.name ? fn(font.name) : 'System placeholder font'}
          </FontTitle>
        )}

        {(usage === 1 || usage === 2) && (
          <FontHead className="font">{head}</FontHead>
        )}
        {(usage === 1 || usage === 3) && (
          <FontBody className="font">{text}</FontBody>
        )}

        {!position && size !== 0 && (
          <FontTitle
            size={size}
            as={`h${[3, 2, 1][size - 1] + 2}`}
            className={(() => {
              if (size === 1) return `brand-font-small brand-color-small`;
              if (size === 2) return `brand-font-regular brand-color-regular`;
              if (size === 3) return `brand-font-large brand-color-large`;
            })()}
          >
            {font.name ? fn(font.name) : 'System placeholder font'}
          </FontTitle>
        )}
      </Spinner>
    </Typography>
  );
};
