import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../ui';

const Collapse = styled.div`
  position: relative;
  margin-bottom: 12px;

  .header {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    cursor: pointer;
    display: flex;
    height: 75px;
    align-items: center;
    width: 100%;
    background-color: #f8f8f8;

    & > * {
      flex: 1;
      padding: 0 20px;

      &:first-child {
        margin: 0;
        font-size: 24px;
        font-weight: normal;
        color: #000000;
      }
      &:last-child {
        text-align: right;

        button {
          padding: 0;
        }
      }
    }
  }

  section {
    padding: 32px 12px;
  }
`;

export default ({ title = 'Collapse', children, open: isOpen }) => {
  const [open, setOpen] = useState(isOpen);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Collapse>
      <div className="header" onClick={toggle}>
        <h3>{title}</h3>
        <div>
          <Button text icon={open ? 'arrow-down' : 'arrow-right'} />
        </div>
      </div>
      {open && <section>{children}</section>}
    </Collapse>
  );
};
