import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import Button from './Button';
import ColorBox from './ColorBox';

import Theme from './themes/default';

const { colors } = Theme;

const DropDownSelect = styled.div`
  height: ${({ size }) => (size === 'small' ? '30px' : '46px')};
  border-bottom: 1px solid #e9e9e9;
  display: inline-block;
  position: relative;
  overflow: visible;
  background-color: transparent;
  transition: border 0.3s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  box-sizing: border-box;
  margin: 3px 0;
  min-width: ${({ placeholder }) => placeholder.length * 8}px;
  width: ${({ width }) => width && width}px;

  ${({ block }) =>
    block &&
    `
	width: 100%;
	display: block;
    `};

  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:first-child {
      left: 1px;
    }
  }
`;

const DropBtn = styled(Button)`
  padding: 5px;
  width: 45px;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  & > span {
    margin: 0 !important;
  }
  &:active {
    top: 50%;
  }
  svg {
    top: 0 !important;
    right: 8px;
  }
  ${({ open }) =>
    open &&
    `
  & {
    svg {
      transform: rotate(180deg);
    }
  }
  `}
`;

const Flex = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const InputWrap = styled.span`
  flex-grow: 1;
  height: 100%;
  position: relative;
`;

const DropDownInput = styled.input`
  display: block;
  position: relative;
  font-weight: 300;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-left: ${({ dropDownType }) => (dropDownType !== 'color' ? 0 : '10px')};
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
  border: 0;
  opacity: 1;
  font-size: ${({ size }) => (size === 'small' ? '12px' : '15px')};
  color: ${colors.black};
`;

const Container = styled.div`
  width: 100%;
  top: ${({ size }) => (size === 'small' ? '30px' : '46px')};
  left: -1px;
  transform: translate(0, 0);
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1);
  z-index: 32;
`;

const List = styled.ul`
  width: 100%;
  border: 1px solid ${colors.default};
  background-color: #ffffff;
  max-height: 320px;
  overflow-y: scroll;
`;

const Item = styled.li`
  position: relative;
  box-sizing: border-box;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding-left: 5px;
  width: 100%;
  font-size: ${({ size }) => (size === 'small' ? '12px' : '15px')};
  height: ${({ size }) => (size === 'small' ? '31px' : '45px')};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${darken(0.03, '#ffffff')};
  }

  & :first-child {
    margin-right: 12px;
  }

  .pad {
    margin-left: 8px;
  }

  ${({ selected }) =>
    selected &&
    `
  background-color: #f0f0f0 !important;
  `}
`;

export default ({
  size = 'large',
  dropDownType = 'text',
  defaultValue = '',
  placeholder = 'Select',
  disabled,
  items = [],
  block = false,
  onSelect = () => {},
  inputRef = useRef(),
  className: cname = '',
  width = '',
}) => {
  const [img, setImg] = useState();
  const [open, setOpen] = useState(false);

  const defaultLabel = items.find(
    (i) => String(i.value) === String(defaultValue)
  )?.label;

  const defaultColor = items.find(
    (i) => String(i.value) === String(defaultValue)
  )?.color;

  const defaultImg = items.find(
    (i) => String(i.value) === String(defaultValue)
  )?.img;

  const toggleDrop = (e) => {
    e.preventDefault();
    if (disabled) return;

    if (!open) {
      document.body.addEventListener('click', function x(ev) {
        const {
          target: { className = '' },
        } = ev;
        if (className.match(/item-label/i)) return;
        document.body.removeEventListener('click', x);
        ev.preventDefault();
        // stop propagating evt bubble to the dropdown button
        if (className.match(/toggle/i)) ev.stopPropagation();
        // don't close when clicking item list
        if (className.match && className.match(/list-item/i)) {
          return;
        }

        setOpen(false);
      });
    }

    setOpen(!open);
  };

  const setItem = (item) => (e) => {
    e.stopPropagation();
    e.target.removeEventListener('click', setItem);
    e.preventDefault();

    if (item.img) setImg(item.img);

    inputRef.current.value = item.label;
    setOpen(false);

    // for color type input, we need to also pass the label
    // as a second argument to the onSelect handler.
    if (dropDownType !== 'text') return onSelect(item.value, item.label);
    onSelect(item.value);
  };

  return (
    <DropDownSelect
      className={cname}
      block={block}
      placeholder={placeholder}
      size={size}
      disabled={disabled || !items || items.length === 0}
      width={width}
    >
      <Flex>
        {dropDownType === 'color' && (
          <ColorBox color={defaultColor} size={size === 'small' ? 22 : 35} />
        )}
        {(defaultImg || img) && (
          <img
            alt="img"
            src={`/${defaultImg || img}`}
            height="20px"
            style={{ marginRight: 16 }}
          />
        )}
        <InputWrap>
          <DropDownInput
            dropDownType={dropDownType}
            placeholder={placeholder}
            size={size}
            disabled
            ref={inputRef}
            value={defaultLabel || defaultValue}
          />
        </InputWrap>
      </Flex>

      <DropBtn
        text
        open={open}
        icon="dropdown"
        disabled={disabled || !items || items.length === 0}
        onClick={toggleDrop}
        className="toggle"
      />
      {open && (
        <Container size={size}>
          <List>
            {items.map((item) => {
              return (
                <Item
                  key={`${item.label}-${item.value}`}
                  className="list-item"
                  size={size}
                  selected={item.value === defaultValue}
                  defaultValue={defaultValue}
                  onClick={setItem(item)}
                >
                  {dropDownType === 'color' && (
                    <ColorBox
                      color={item.color || item.value}
                      size={size === 'small' ? 22 : 35}
                    />
                  )}
                  {item.img && (
                    <img alt="img" src={`/${item.img}`} height="16px" />
                  )}
                  <span
                    className={`item-label ${
                      dropDownType !== 'color' ? 'pad' : ''
                    }`}
                  >
                    {item.label}
                  </span>
                </Item>
              );
            })}
          </List>
        </Container>
      )}
    </DropDownSelect>
  );
};
