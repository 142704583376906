import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Users from './Users';
import Styles from './Styles';
import Billing from './Billing';
import Advanced from './Advanced';
import { Spinner } from '../ui';
import { setBackground } from '../store/page';

const Wrap = styled.div`
  position: relative;
  min-height: ${window.innerHeight - 262}px;
  width: 100%;
  margin-top: 90px;

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  h2 {
    font-size: 30px;
  }

  h5 {
    &.uppercase {
      text-transform: uppercase;
    }
  }

  p {
    font-weight: 300;
    color: #444444;

    &.desc {
      font-size: 15px;
      width: 484px;
    }
    &.tiny-desc {
      font-size: 12px;
      color: #999999;
      width: 314px;
      line-height: 1.5em;
      margin-bottom: 0;
    }
  }
`;

const select = ({ user, global }) => ({ ...user, ...global });

export default () => {
  const dispatch = useDispatch();
  const { page } = useParams();
  const { user, loading } = useSelector(select);

  useEffect(() => {
    dispatch(setBackground('#f0f0f0'));
  }, []); // eslint-disable-line

  if (!user) return <></>;

  return (
    <Wrap>
      <Spinner spin={loading}>
        {(() => {
          let comp;

          switch (page) {
            case undefined:
              comp = <Styles />;
              break;
            case 'users':
              comp = <Users />;
              break;
            case 'billing':
              comp = <Billing />;
              break;
            case 'advanced':
              comp = <Advanced />;
              break;
            default:
              comp = <></>;
          }

          return comp;
        })()}
      </Spinner>
    </Wrap>
  );
};
