import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button, Icon } from '../ui';

const Wrap = styled.div`
  position relative;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  ${({ fixed }) =>
    fixed &&
    `
  position: fixed;
  top: 111px;
    background-color: #f0f0f0;
  `}

  header {
    position: relative;
    margin: 0 auto;
    width: 960px;
    height: 90px;
    display: flex;
    align-items: center;
    ${({ edit }) =>
      edit &&
      `
    border-bottom: 1px solid #d2d2d2;
    `}

    .back {
      margin: 0;
      font-size: 12px;
      svg {
        margin-right: 6px;
      }
      a,
      a:hover {
        color: #000000;
      }
      span {
        position: relative;
        top: -3px;
      }
    }

    a, a:active, a:hover {
      color: #888888;
      font-size: 12px;
      font-weight; 300;
      letter-spacing: 1px;
      text-decoration: none;
      border: none;
      font-family: Roboto;
      margin-right: 16px;
    }

    a.active {
      font-weight: normal;
      color: #000000;
    }

    & > div {
      flex: 1;
      ${({ edit }) =>
        edit &&
        `
      padding: 0 32px;
      `}
      &:last-child { text-align: right; }
    }

    button:not(:last-child) {
      margin-right: 32px;
    }
  }
`;

export default ({ fixed, edit, onEdit, onCancel, onConfirm }) => {
  return (
    <Wrap fixed={fixed ? 'true' : ''} edit={edit}>
      {onEdit && (
        <header>
          <div className="back">
            {!edit && (
              <Link to="/touchpoints">
                <Icon name="return" />
                <span>All Touchpoints</span>
              </Link>
            )}
          </div>
          <div>
            {edit && (
              <Button text icon="cross" onClick={onCancel} disabled={!onCancel}>
                CANCEL
              </Button>
            )}
            {edit ? (
              <Button icon="tick" onClick={onConfirm} disabled={!onConfirm}>
                SAVE
              </Button>
            ) : (
              <Button onClick={onEdit}>EDIT</Button>
            )}
          </div>
        </header>
      )}
    </Wrap>
  );
};
