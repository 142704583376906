import React from 'react';

import { ReactComponent as Addrow } from './icons/add-row.svg';
import { ReactComponent as Addsml } from './icons/add-sml.svg';
import { ReactComponent as AddtoCollection } from './icons/add-to-collection.svg';
import { ReactComponent as Add } from './icons/add.svg';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as Attention } from './icons/attention.svg';
import { ReactComponent as Audio } from './icons/audio.svg';
import { ReactComponent as Carousel } from './icons/carousel.svg';
import { ReactComponent as Colour } from './icons/colour.svg';
import { ReactComponent as Copylink } from './icons/copylink.svg';
import { ReactComponent as CrossSml } from './icons/cross-sml.svg';
import { ReactComponent as Cross } from './icons/cross.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Desktop } from './icons/desktop.svg';
import { ReactComponent as Divider } from './icons/divider.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as DropdownSml } from './icons/dropdown-sml.svg';
import { ReactComponent as Dropdown } from './icons/dropdown.svg';
import { ReactComponent as Duplicate } from './icons/duplicate.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Exclusionzone } from './icons/exclusionzone.svg';
import { ReactComponent as Go } from './icons/go.svg';
import { ReactComponent as Gradient } from './icons/gradient.svg';
import { ReactComponent as Hide } from './icons/hide.svg';
import { ReactComponent as Image } from './icons/image.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as LivedesignArtwork } from './icons/livedesign-artwork.svg';
import { ReactComponent as LivedesignComponent } from './icons/livedesign-component.svg';
import { ReactComponent as LivedesignDocument } from './icons/livedesign-document.svg';
import { ReactComponent as Lock } from './icons/lock.svg';
import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Mediatext } from './icons/mediatext.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Minimumsize } from './icons/minimumsize.svg';
import { ReactComponent as MinusSml } from './icons/minus-sml.svg';
import { ReactComponent as Minus } from './icons/minus.svg';
import { ReactComponent as MobMenuPointer } from './icons/mob-menu-pointer.svg';
import { ReactComponent as Mobile } from './icons/mobile.svg';
import { ReactComponent as More } from './icons/more.svg';
import { ReactComponent as Move } from './icons/move.svg';
import { ReactComponent as NextSml } from './icons/next-sml.svg';
import { ReactComponent as Next } from './icons/next.svg';
import { ReactComponent as Open } from './icons/open.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Plus } from './icons/plus.svg';
import { ReactComponent as Preview } from './icons/preview.svg';
import { ReactComponent as Previous } from './icons/previous.svg';
import { ReactComponent as RedoSml } from './icons/redo-sml.svg';
import { ReactComponent as Redo } from './icons/redo.svg';
import { ReactComponent as Return } from './icons/return.svg';
import { ReactComponent as SearchSml } from './icons/search-sml.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Send } from './icons/send.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as Stop } from './icons/stop.svg';
import { ReactComponent as Super } from './icons/super.svg';
import { ReactComponent as Text } from './icons/text.svg';
import { ReactComponent as TickSml } from './icons/tick-sml.svg';
import { ReactComponent as Tick } from './icons/tick.svg';
import { ReactComponent as Typography } from './icons/typography.svg';
import { ReactComponent as UndoSml } from './icons/undo-sml.svg';
import { ReactComponent as Undo } from './icons/undo.svg';
import { ReactComponent as Video } from './icons/video.svg';
import { ReactComponent as Welcomeintro } from './icons/welcomeintro.svg';
import { ReactComponent as UserCircle } from './icons/user-circle.svg';
import { ReactComponent as AlertCircle } from './icons/alert-circle.svg';
import { ReactComponent as Logout } from './icons/logout.svg';
import { ReactComponent as ArrowUpCircle } from './icons/arrow-up-circle.svg';
import { ReactComponent as ArrowDownCircle } from './icons/arrow-down-circle.svg';

const iconGen = (
  Elem,
  { size = 15, color = '#000000', style = {}, className = '' }
) => (
  <Elem
    className={className}
    style={style}
    fill={color}
    width={size}
    height={size}
  />
);

export default (props) => {
  const { name } = props;

  switch (name) {
    case 'add-row':
      return iconGen(Addrow, props);
    case 'add-sml':
      return iconGen(Addsml, props);
    case 'add-to-collection':
      return iconGen(AddtoCollection, props);
    case 'add':
      return iconGen(Add, props);
    case 'arrow-down':
      return iconGen(ArrowDown, props);
    case 'arrow-left':
      return iconGen(ArrowLeft, props);
    case 'arrow-right':
      return iconGen(ArrowRight, props);
    case 'arrow-up':
      return iconGen(ArrowUp, props);
    case 'attention':
      return iconGen(Attention, props);
    case 'audio':
      return iconGen(Audio, props);
    case 'carousel':
      return iconGen(Carousel, props);
    case 'color':
      return iconGen(Colour, props);
    case 'copylink':
      return iconGen(Copylink, props);
    case 'cross-sml':
      return iconGen(CrossSml, props);
    case 'cross':
      return iconGen(Cross, props);
    case 'delete':
      return iconGen(Delete, props);
    case 'desktop':
      return iconGen(Desktop, props);
    case 'divider':
      return iconGen(Divider, props);
    case 'download':
      return iconGen(Download, props);
    case 'dropdown-sml':
      return iconGen(DropdownSml, props);
    case 'dropdown':
      return iconGen(Dropdown, props);
    case 'duplicate':
      return iconGen(Duplicate, props);
    case 'edit':
      return iconGen(Edit, props);
    case 'exclusionzone':
      return iconGen(Exclusionzone, props);
    case 'go':
      return iconGen(Go, props);
    case 'gradient':
      return iconGen(Gradient, props);
    case 'hide':
      return iconGen(Hide, props);
    case 'image':
      return iconGen(Image, props);
    case 'info':
      return iconGen(Info, props);
    case 'livedesign-artwork':
      return iconGen(LivedesignArtwork, props);
    case 'livedesign-component':
      return iconGen(LivedesignComponent, props);
    case 'livedesign-document':
      return iconGen(LivedesignDocument, props);
    case 'lock':
      return iconGen(Lock, props);
    case 'mail':
      return iconGen(Mail, props);
    case 'mediatext':
      return iconGen(Mediatext, props);
    case 'menu':
      return iconGen(Menu, props);
    case 'minimumsize':
      return iconGen(Minimumsize, props);
    case 'minus-sml':
      return iconGen(MinusSml, props);
    case 'minus':
      return iconGen(Minus, props);
    case 'mob-menu-pointer':
      return iconGen(MobMenuPointer, props);
    case 'mobile':
      return iconGen(Mobile, props);
    case 'more':
      return iconGen(More, props);
    case 'move':
      return iconGen(Move, props);
    case 'next-sml':
      return iconGen(NextSml, props);
    case 'next':
      return iconGen(Next, props);
    case 'open':
      return iconGen(Open, props);
    case 'pause':
      return iconGen(Pause, props);
    case 'play':
      return iconGen(Play, props);
    case 'plus':
      return iconGen(Plus, props);
    case 'preview':
      return iconGen(Preview, props);
    case 'previous':
      return iconGen(Previous, props);
    case 'redo-sml':
      return iconGen(RedoSml, props);
    case 'redo':
      return iconGen(Redo, props);
    case 'return':
      return iconGen(Return, props);
    case 'search-sml':
      return iconGen(SearchSml, props);
    case 'search':
      return iconGen(Search, props);
    case 'send':
      return iconGen(Send, props);
    case 'settings':
      return iconGen(Settings, props);
    case 'stop':
      return iconGen(Stop, props);
    case 'super':
      return iconGen(Super, props);
    case 'text':
      return iconGen(Text, props);
    case 'tick-sml':
      return iconGen(TickSml, props);
    case 'tick':
      return iconGen(Tick, props);
    case 'typography':
      return iconGen(Typography, props);
    case 'undo-sml':
      return iconGen(UndoSml, props);
    case 'undo':
      return iconGen(Undo, props);
    case 'video':
      return iconGen(Video, props);
    case 'welcomeintro':
      return iconGen(Welcomeintro, props);
    case 'user-circle':
      return iconGen(UserCircle, props);
    case 'alert-circle':
      return iconGen(AlertCircle, props);
    case 'logout':
      return iconGen(Logout, props);
    case 'arrow-up-circle':
      return iconGen(ArrowUpCircle, props);
    case 'arrow-down-circle':
      return iconGen(ArrowDownCircle, props);
    default:
      return <></>;
  }
};
