import React from 'react';
import styled from 'styled-components';

const Checkmark = styled.span`
  width: 20px;
  height: 20px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  &::after {
    display: none;
    content: '';
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Checkbox = styled.input`
  position: absolute;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  height: 30px;
  width: 30px;

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const Label = styled.label`
  width: 178px;
  height: 21px;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.1px;
  color: #444444;
  margin-left: 28px;
`;

export default (p) => {
  const { label, cRef } = p; // eslint-disable-line
  return (
    <div style={{ position: 'relative' }}>
      <Label>
        {label}
        <Checkbox type="checkbox" ref={cRef} {...p} />
        <Checkmark />
      </Label>
    </div>
  );
};
