import React, { useRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Icon } from '../../ui';
import Placeholder from './PlaceHolder';

const hSize = [0, 42, 35, 28, 22, 15, 12];

const Background = styled.div`
  ${({ bg }) =>
    bg &&
    `
    background-image: url(${bg}) !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${({ edit, count }) =>
    !edit &&
    count === 1 &&
    `
    width: 100vw;
    left: calc((100vw - 960px) / 2 * -1);
  `}
  height: 100%;
  z-index: 0;
`;

const HeadText = styled.div`
  font-size: ${({ size = 12 }) => size}px !important;
  z-index: 1;
  margin-bottom: ${({ size = 12 }) => size / 2}px !important;
  font-weight: 400;
  line-height: 1.25em !important;
  p {
    margin-bottom: 0 !important;
  }
  ${({ inverted, welcome }) =>
    welcome &&
    inverted &&
    `
    color: #ffffff !important;
    & p {
      color: #ffffff !important;
    }
  `}
  ${({ welcome, count }) =>
    welcome &&
    `
      max-width: ${count === 1 ? 50 : 100}%;
      margin-bottom: 30px !important;
      line-height: 1.2;
    & p {
      margin-bottom: 0 !important;
      line-height: 1.2;
    }
  `}
`;

const Text = styled.div`
  font-weight: 300;
  z-index: 1;
  font-size: ${({ size = 12 }) => size}px;
  max-width: ${({ welcome, count }) =>
    welcome && count === 1 ? '50%' : '100%'};

  ${({ inverted }) =>
    inverted &&
    `
    p {
      color: #ffffff !important;
    }
  `}
  & > p {
    margin-bottom: 0 !important;
    line-height: 1.5em !important;
    word-break: break-word;
  }

  div {
    margin-bottom: 1em;
  }
`;

const UrlButton = styled.a`
  text-decoration: none;
  padding: 0 !important;
  display: inline-flex;
  align-items: center;
  &:hover {
    border-bottom: none;
  }
  &:visited {
    padding: 0;
  }
  ${({ welcome }) =>
    welcome &&
    `
    text-transform: uppercase;
    height: 31px;
    letter-spacing: 0.2px;
`};
  span {
    font-size: ${({ size = 12 }) => size}px;
    margin-left: 5px;
  }
  svg {
    width: ${({ size = 12 }) => size}px;
    height: ${({ size = 12 }) => size}px;
  }
  margin-top: 1em;
`;

const TextButton = styled.div`
  margin-top: 1em;
  padding: 0;
  z-index: 1;
  ${({ welcome }) =>
    welcome &&
    `
    & > a, & > div {
      padding: 3px 16px !important;
    }
`};
`;

const TextWrapper = styled.div`
  ${({ welcome, bg }) =>
    welcome &&
    `
    min-height: ${bg ? 500 : 335}px;
    padding: 24px;
    display: flex !important;
    box-sizing: border-box;
    align-items: flext-start !important;
    justify-content: center !important;
    flex-direction: column !important;
`};
`;

const Insider = styled.div`
  span {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    color: #999999;
  }
  .title {
    font-weight: normal;
  }
  p {
    line-height: 1;
  }
`;

const paste = (e) => {
  // Prevent the default action
  e.preventDefault();

  // Get the copied text from the clipboard
  // eslint-disable-next-line
  const text = e.clipboardData
    ? (e.originalEvent || e).clipboardData.getData('text/plain')
    : // For IE
    window.clipboardData
    ? window.clipboardData.getData('Text')
    : '';

  if (document.queryCommandSupported('insertText')) {
    document.execCommand('insertText', false, text);
  } else {
    // Insert text at the current position of caret
    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const selector = ({ user }) => user;

export default ({
  bg,
  edit,
  size,
  text,
  head,
  logo,
  ccount,
  button,
  welcome,
  insider,
  heading,
  selected,
  changeHandler,
  onClickHolder,
}) => {
  const { user } = useSelector(selector);

  const [to, setTo] = useState();
  const indsiderDisplay = localStorage.getItem(`insider-${user?.id}`);

  const tip = localStorage.getItem(`tooltip-asset-${user?.id}`);

  const textRef = useRef();
  const headingRef = useRef();
  const buttonRef = useRef();
  const onDeselect = useCallback(() => {
    if (!textRef.current) return;

    const val = textRef.current.innerHTML.toString().replace(/div>/gi, 'p>');
    const headingVal =
      headingRef.current &&
      headingRef.current.innerHTML.toString().replace(/div>/gi, 'p>');
    const buttonVal = buttonRef.current && buttonRef.current.textContent;

    if (changeHandler) {
      const payload = { text: val };

      if (heading) {
        if (
          heading?.text === headingVal &&
          text === val &&
          button?.text === buttonVal
        )
          return;
      } else if (button?.text === buttonVal && text === val) {
        return;
      }

      if (headingVal)
        payload.heading = {
          ...heading,
          text: headingVal,
        };

      if (buttonVal) {
        payload.button = {
          ...button,
          text: buttonVal,
        };
      }

      changeHandler(payload);
    }
  }, [textRef, text, headingRef, changeHandler, heading, buttonRef, button]);

  const clear = () => {
    if (to) clearTimeout(to);
  };

  // if plain, then we wrap it with p
  const htmlText = (() => {
    const tt = document.createElement('div');
    tt.innerHTML = text;
    if (!tt.innerText) return '';
    if (/<p>/gi.test(text)) return text;
    return `<p>${text || 'Type here...'}</p>`;
  })();

  const htmlHeadText = (() => {
    const tt = document.createElement('div');
    tt.innerHTML = heading?.text;
    if (!tt.innerText) return '';
    if (/<p>/gi.test(heading?.text)) return heading?.text;
    return `<p>${heading?.text || 'Type here...'}</p>`;
  })();

  const hTag = `${!heading ? 'div' : `h${heading.level || 1}`}`;
  const hClass = (() => {
    let c = '';
    let t = '';

    if (heading?.level === 3 || welcome) {
      if (!welcome?.invertHeading) {
        c = 'brand-color-large';
      }
      t = 'brand-font-large';
    }

    if (heading?.level === 4) {
      c = 'brand-color-regular';
      t = 'brand-font-regular';
    }

    if (heading?.level === 5) {
      c = 'brand-color-small';
      t = 'brand-font-small';
    }

    if (heading?.level === 6) {
      c = 'brand-color-body';
      t = 'brand-font-body';
    }

    return `${c} ${t}`;
  })();

  return (
    <>
      <TextWrapper bg={bg} welcome={welcome}>
        {welcome && bg && (
          <Background bg={bg} welcome={welcome} edit={edit} count={ccount} />
        )}
        {heading && (
          <HeadText
            as={hTag}
            ref={headingRef}
            size={hSize[heading.level]}
            count={ccount}
            welcome={welcome}
            onBlur={() => setTo(setTimeout(onDeselect, 100))}
            onFocus={clear}
            onPaste={paste}
            inverted={welcome?.invertHeading}
            onKeyDown={(e) => {
              return !welcome && e.key === 'Enter' && e.preventDefault();
            }}
            className={hClass}
            contentEditable={selected}
            suppressContentEditableWarning
            dangerouslySetInnerHTML={{
              __html: htmlHeadText,
            }}
          />
        )}

        {(edit || htmlText) && (
          <Text
            ref={textRef}
            size={size}
            count={ccount}
            className={(() => {
              const s = 'brand-font-body';
              if (!head) return s;
              if (size === 15) return `brand-font-small brand-color-small`;
              if (size === 22) return `brand-font-regular brand-color-regular`;
              if (size === 28) return `brand-font-large brand-color-large`;
              return s;
            })()}
            inverted={welcome?.invertText}
            contentEditable={selected}
            welcome={welcome}
            suppressContentEditableWarning
            onBlur={() => setTo(setTimeout(onDeselect, 100))}
            onFocus={clear}
            onPaste={paste}
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        )}
        {indsiderDisplay && insider && (
          <Insider>
            <p>
              <span className="title">Insider tip </span>
              <span>{insider}</span>
            </p>
          </Insider>
        )}
        {button && (
          <TextButton welcome={welcome} edit={edit}>
            <UrlButton
              {...(!edit && { href: button.url, _target: 'blank' })}
              {...(button.type === 'download' && {
                href: `/api/v1/file?url=${button.url}`,
                download: button.url.split('/').pop(),
              })}
              size={size}
              target="_blank"
              welcome={welcome}
              as={edit && 'div'}
              className={welcome ? 'brand-bgcolor' : 'brand-action-color'}
            >
              <Icon name={button.type} />
              <span
                ref={buttonRef}
                contentEditable={selected}
                suppressContentEditableWarning
                onBlur={() => setTo(setTimeout(onDeselect, 100))}
                onFocus={clear}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                style={{ fontWeight: 'bold' }}
              >
                {button.text || 'Click Me'}
              </span>
            </UrlButton>
          </TextButton>
        )}
      </TextWrapper>
      {!edit && logo && tip && (
        <Placeholder
          placeholder="Upload logo assets"
          height={30}
          onClick={onClickHolder}
          width="188px"
          direction="row"
        />
      )}
    </>
  );
};
