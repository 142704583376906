import React, { useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

import Icon from './Icon';
import Theme from './themes/default';
import Button from './Button';

const { colors } = Theme;

const Context = styled.span`
  display: inline-block;
  position: relative;
  box-sizing: border-box;

  & > button {
    display: flex;
    align-items: center;
    & svg {
      display: inline-block;
      width: 12px;
      font-size: 12px;
      margin-right: 8px;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 32px;
  right: 0;
  box-sizing: border-box;
  border: 1px solid ${colors.default};
  background-color: ${colors.white};
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  min-width: 144px;
  padding: 20px;
  z-index: 3;

  .icn,
  .icnr {
    fill: #4b9aff;
  }
  .icn {
    margin-right: 6px;
  }
  .icnr {
    margin-left: 6px;
  }

  & ul {
    flex: 1;
    & li:not(:last-child) {
      margin-bottom: 12px;
    }
    & li button {
      display: flex;
      align-items: center;
      width: 100%;
      text-transform: none;
      &:hover {
        color: ${lighten(0.3, colors.black)};
      }
    }
  }

  & footer {
    margin-top: 24px;
    border-top: 1px solid ${colors.default};
    padding-top: 12px;
    & svg {
      font-size: 15px;
      margin-right: 4px;
    }
    & button {
      display: flex;
      width: 100%;
      align-items: center;
      font-size: 12px;
      text-transform: none;
      &:hover {
        color: ${lighten(0.3, '#000000')};
      }
    }
  }

  & button {
    border: 0;
    font-size: 12px;
    display: inline-block;
    text-align: left;
  }
`;

const Triangle = styled.span`
  display: inline-block;
  position: absolute;
  top: -10px;
  right: 8px;
  width: 20px;
  height: 10px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px dotted transparent;
    border-right: 10px dotted transparent;
    border-bottom: 10px solid ${colors.default};
    position: absolute;
  }
  &:after {
    border-bottom: 10px solid ${colors.white};
    top: 2px; /* offset sets width of border */
  }
`;

export default ({
  icon = '',
  items,
  label = 'Click Me',
  footerLabel = '',
  footerIcon = '',
  footerClickHandler = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = (e) => {
    e.preventDefault();

    // bind event to body on open
    if (!open) {
      document.body.addEventListener('click', function x(ev) {
        // unbind listener
        document.body.removeEventListener('click', x);

        // prevent propagating event downwards
        ev.preventDefault();

        const {
          target: { className = '' },
        } = ev;

        // stop propagating evt bubble to the dropdown button
        if (className.match(/toggle/i)) ev.stopPropagation();
        // don't close when clicking item list
        if (className.match && className.match(/item/i)) {
          document.body.addEventListener('click', x);
          return;
        }

        setOpen(false);
      });
    }

    setOpen(!open);
  };

  return (
    <Context>
      <Button onClick={toggleOpen} text size="small" className="toggle">
        {icon && (typeof icon === 'string' ? <Icon name={icon} /> : icon)}
        {label}
      </Button>
      {open && (
        <Menu>
          <Triangle />
          {Array.isArray(items) && (
            <ul>
              {items.map((it) => (
                <li key={Math.random() * Date.now()}>
                  <button type="button" className="item" onClick={it.handler}>
                    {it.icon && <Icon name={it.icon} className="icn" />}
                    {it.label}
                    {it.iconRight && (
                      <Icon name={it.iconRight} className="icnr" />
                    )}
                  </button>
                </li>
              ))}
            </ul>
          )}
          {footerLabel && (
            <footer>
              <button
                className="item"
                type="button"
                onClick={footerClickHandler}
              >
                {footerIcon &&
                  (typeof footerIcon === 'string' ? (
                    <Icon name={footerIcon} />
                  ) : (
                    footerIcon
                  ))}
                {footerLabel}
              </button>
            </footer>
          )}
        </Menu>
      )}
    </Context>
  );
};
