import React from 'react';
import { toast as t } from 'react-toastify';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin: 0;
    font-family: Roboto;
    color: #000000;
    font-weight: normal;
    font-size: 15px;

    &.sub {
      margin-top: -6px;
      font-weight: 300;
      font-size: 12px;
      color: #444444;
    }
  }
`;

const alert = (
  title = 'Notice',
  description = 'This is a message',
  error = false
) => {
  t.dismiss();
  const opts = { style: {} };
  if (error) opts.className = 'error';
  t(
    <Wrap>
      <p>{title}</p>
      <p className="sub">{description}</p>
    </Wrap>,
    opts
  );
};

// eslint-disable-next-line
export const toast = (title, description) => {
  alert(title, description);
};

toast.error = (title, description) => {
  alert(title, description, true);
};
