import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',

  initialState: {
    loading: false,
    path: '',
    error: null,
  },

  reducers: {
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },

    setError: (state, { payload }) => {
      state.error = payload;
    },

    setPath: (state, { payload }) => {
      state.path = payload;
    },

    setBrand: (state, { payload }) => {
      state.brand = payload;
    },

    setBrandProps: (state, { payload }) => {
      state.brand = { ...state.brand, ...payload };
    },
  },
});

export const {
  setPath,
  setError,
  setBrand,
  setLoading,
  setBrandProps,
  toggleLoading,
} = globalSlice.actions;

export default globalSlice.reducer;
