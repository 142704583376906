import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal } from '../../ui';
import Placeholder from './PlaceHolder';
import Text from './Text';
import MediaBrowser from '../MediaBrowser';

const Wrap = styled.div`
  div {
    margin-bottom: 0 !important;
    & > div > p {
      font-weight: 400;
      ${({ position }) =>
        position
          ? 'margin-bottom: 12px !important;'
          : 'margin-top: 12px !important;'}
    }
  }
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 14%;
  background-color: #efefef;

  ${({ selected }) =>
    selected &&
    `
  &:hover {
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
    }
    &:before {
      content: '+';
      display: block;
      font-size: 32px;
      color: white;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }`}

  ${({ url }) =>
    !url &&
    `
    background: linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 0.8px),#d2d2d2 50%,rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%);
    border: 1px solid #d2d2d2;
    min-height: 312px;
  `}

  img, object {
    width: 100%;
    object-fit: contain;
    background-color: white;
    pointer-events: none;
    svg {
      object-fit: contain;
      width: 100% !important;
      height: 100% !important;
    }
  }

  span {
    color: #000000;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: #d2d2d2;
  }

  ${({ space }) =>
    space &&
    space === 1 &&
    `
    padding: 21%;
  `}

  ${({ space }) =>
    space &&
    space === 2 &&
    `
    padding: 7%;
  `}
`;

export default ({
  url,
  edit,
  selected,
  placeholder,
  onClickHolder,
  space,
  position = false,
  size = 0,
  title = 'Logo spacing',
  changeHandler = () => {},
}) => {
  const [open, setOpen] = useState(false);

  const setImage = (sel) => {
    changeHandler({
      url: sel.urls.original,
    });
    setOpen(false);
  };

  if (!edit && !url && placeholder)
    return (
      <Placeholder
        placeholder={placeholder}
        height={368}
        onClick={onClickHolder}
      />
    );

  return (
    <Wrap position={position}>
      {position && size !== 0 && (
        <Text
          head
          edit={edit}
          type="text"
          text={title}
          selected={selected}
          size={(() => {
            if (size === 2) return 22;
            if (size === 3) return 28;
            return 15;
          })()}
          changeHandler={({ text }) => {
            changeHandler({
              title: text,
            });
          }}
        />
      )}
      <Image
        edit={edit}
        url={url}
        selected={selected}
        onClick={() => selected && setOpen(!open)}
        space={space}
      >
        {url ? (
          <img src={`/api/v1/file?url=${url}`} alt="logo-space" />
        ) : (
          <span />
        )}
      </Image>
      {!position && size !== 0 && (
        <Text
          head
          edit={edit}
          type="text"
          text={title}
          selected={selected}
          size={(() => {
            if (size === 2) return 22;
            if (size === 3) return 28;
            return 15;
          })()}
          changeHandler={({ text }) => {
            changeHandler({
              title: text,
            });
          }}
        />
      )}

      {selected && (
        <Modal visible={open}>
          <MediaBrowser
            type="image"
            category="basics"
            pub
            onCancel={() => setOpen(false)}
            onConfirm={setImage}
          />
        </Modal>
      )}
    </Wrap>
  );
};
