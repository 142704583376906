import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Button from './Button';

const Cover = styled.div`
  display: flex;
  backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90%;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
`;

const Body = styled.div`
  position: relative;
  ${({ width = 'auto' }) =>
    width &&
    `
  width: ${`${width}px`};
  `}
  box-sizing: border-box;
  display: block;
  flex: 1;
  padding: 40px;
`;

const Footer = styled.div`
  background-color: #ffffff;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Portal = ({
  width,
  loading,
  closable,
  children,
  onCancel,
  onConfirm,
  cancelText = 'CANCEL',
  showCancel,
  confirmText = 'CONFIRM',
  showConfirm,
}) => {
  // main modal container
  const modal = useRef(document.createElement('div'));
  modal.current.style.top = '0';
  modal.current.style.left = '0';
  modal.current.style.width = '100%';
  modal.current.style.height = '100%';
  modal.current.style.zIndex = '31337';
  modal.current.style.position = 'fixed';

  useEffect(() => {
    document.body.appendChild(modal.current);
    document.body.classList.add('modal-open');

    // on unmount, remove modal from body
    return () => {
      document.body.removeChild(modal.current);
      document.body.classList.remove('modal-open');
    };
  }, []);

  // this prevents the modal to close when clicking the body
  // and closable is set to true
  const stop = (e) => {
    if (e.type.match(/click/i) && e.target.nodeName.match(/(label|input)/i))
      return;
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return ReactDOM.createPortal(
    <Cover
      onBlur={stop}
      onClick={closable ? onCancel : () => {}}
      onFocus={stop}
      onMouseOut={stop}
      onMouseOver={stop}
    >
      <Container onClick={stop} loading={loading}>
        <Body width={width}>{children}</Body>
        {(showCancel || showConfirm) && (
          // Show footer if either of the two should be displayed
          <Footer>
            {showCancel && (
              // Cancel Button
              <Button icon="cross" text onClick={onCancel}>
                {cancelText}
              </Button>
            )}

            {showConfirm && (
              // Confirm Button
              <Button icon="next-sml" onClick={onConfirm}>
                {confirmText}
              </Button>
            )}
          </Footer>
        )}
      </Container>
    </Cover>,
    modal.current
  );
};

export default (props) => {
  const { visible } = props;
  if (visible) return <Portal {...props} />;
  return <></>;
};
