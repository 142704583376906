import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { setBrandStatus } from '../store/user';

import SettingsWrap from '../components/SettingsWrap';

import { Switch, Spinner, DropDown, Input } from '../ui';
import { toast } from '../ui/Toast';

import { getBrand, updateBrand } from '../svc/brand';

const Split = styled.div`
  display: flex;
  margin-bottom: 32px;
  margin-top: 20px;
  align-items: center;
  & > * {
    &:first-child {
      position: relative;
      width: 400px;
      box-sizing: border-box;
      margin-right: 24px;
    }

    &:second-child {
      flex: 1;
    }
  }
  .subdomain {
    font-size: 15px;
    font-weight: 300;
    color: #000000;
    padding-bottom: 13px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 5px;
    position: relative;

    .spinner {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 13px;
      right: 0;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    span:last-child {
      color: #999999;
    }
    ${({ error }) =>
      error &&
      `
    border-bottom: 1px solid #ff2419;
    .error {
      margin-bottom: 28px;
    }
    `}
  }
`;
const select = ({ user, global }) => ({ ...user, ...global });

export default () => {
  const dispatch = useDispatch();
  const subRef = useRef();

  // also extract virtual brand if we're logged in through sub domain
  const { user, brand: vBrand } = useSelector(select);
  const { brand } = user;
  const {
    sub_domain: sd,
    id: brandId,
    online,
    name: bname,
    access: am,
  } = brand;

  const [to, setTo] = useState();
  const [name, setName] = useState(bname);
  const [status, setStatus] = useState(online);
  const [access, setAccess] = useState(am);
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [subDomain, setSubDomain] = useState(sd);
  const [isValidSD, setIsValidSD] = useState(true);

  const updateSubDomain = (e) => {
    const { textContent } = e.target;
    if (to) clearTimeout(to);
    // if empty fuck that shit!
    if (!textContent.trim()) return;
    setTo(
      setTimeout(() => {
        (async () => {
          setChecking(true);
          const { error } = await getBrand(textContent);
          if (error) {
            setSubDomain(textContent);
            setIsValidSD(true);
          } else {
            setIsValidSD(false);
          }
          setChecking(false);
          if (subRef.current) subRef.current.focus();
        })();
      }, 500)
    );
  };

  const updateStatus = (val) => {
    setStatus(val);
  };

  const handleCancel = () => {
    if (subRef.current) {
      subRef.current.textContent = sd;
      setSubDomain(sd);
      setStatus(online);
      setAccess(am);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const { error } = await updateBrand(brandId, {
      sub_domain: subDomain,
      online: status,
      access,
      name,
    });
    if (error)
      toast.error(
        'Oops, something went wrong',
        'An unexpected error has occurred. Please try again.      '
      );
    else if (subDomain !== sd && vBrand?.id && status) {
      const token = localStorage.getItem('token');
      const uid = localStorage.getItem('userId');
      // purge/clean access
      localStorage.clear();
      // redirect
      const { host, protocol } = window.location;
      const url = `${protocol}//${subDomain}.${host
        .split('.')
        .slice(1)
        .join('.')}/login?token=${token}&userId=${uid}&brandId=${brandId}`;
      window.location.href = url;
    } else if (!status && vBrand?.id) {
      const token = localStorage.getItem('token');
      const uid = localStorage.getItem('userId');
      // purge/clean access
      localStorage.clear();
      const { host, protocol } = window.location;
      const url = `${protocol}//${host
        .split('.')
        .slice(1)
        .join(
          '.'
        )}/login?token=${token}&userId=${uid}&brandId=${brandId}&offline=1`;
      window.location.href = url;
    }
    dispatch(
      setBrandStatus({
        online: status,
        access,
        sub_domain: subDomain,
      })
    );
    setLoading(false);
  };

  return (
    <SettingsWrap
      showCancel={(() => {
        if (
          sd !== subDomain ||
          bname !== name ||
          status !== online ||
          access !== am
        ) {
          if (name.length < 3) return false;
          if (subDomain.length < 3) return false;
          return true;
        }
      })()}
      showConfirm
      confirmText="SAVE"
      onCancel={handleCancel}
      onConfirm={(() => {
        if (
          sd !== subDomain ||
          bname !== name ||
          status !== online ||
          access !== am
        ) {
          if (name.length < 3) return undefined;
          if (subDomain.length < 3) return undefined;
          return handleSave;
        }
      })()}
    >
      <Spinner spin={loading}>
        <section>
          <h2>Advanced Settings</h2>
        </section>
        <section>
          <h5 className="uppercase">brand name</h5>
          <Split>
            <div>
              <div>
                <Input
                  block
                  error={
                    name && name.length < 3
                      ? new Error('Invalid name')
                      : undefined
                  }
                  placeholder="Enter a brand name"
                  defaultValue={name}
                  onChange={(e) => {
                    setName(e.target.value.trim());
                  }}
                />
                {subDomain.length === 0 && (
                  <span className="error">enter valid brand name</span>
                )}
                {subDomain.length !== 0 && subDomain !== sd && !isValidSD && (
                  <span className="error">brand name is already taken</span>
                )}
              </div>
              {subDomain.length === 0 && (
                <span className="error">enter valid brand name</span>
              )}
              {subDomain.length !== 0 && subDomain !== sd && !isValidSD && (
                <span className="error">brand name is already taken</span>
              )}
            </div>
            <div>
              <p className="tiny-desc">
                How you enter your brand’s name here will reflect across the
                whole BrandBox. For instance, if you enter in all upercase
                characters, then that is how users will see it.
              </p>
            </div>
          </Split>
          <h5 className="uppercase">subdomain</h5>
          <Split
            error={subDomain.length === 0 || (subDomain !== sd && !isValidSD)}
          >
            <div className="subdomain">
              <span className="spinner">
                <Spinner spin={checking} />
              </span>
              <span
                id="subdomain"
                contentEditable={!checking}
                onKeyDown={(e) => {
                  if (['Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key))
                    return;
                  if (!/^[a-z0-9-]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onInput={updateSubDomain}
                suppressContentEditableWarning
                ref={subRef}
              >
                {sd}
              </span>
              <span>.brandboxhq.com</span>
            </div>
            <div>
              <p className="tiny-desc">
                Avoid changing your subdomain unless it’s entirely necessary.
              </p>
              <br />

              <p className="tiny-desc">
                Domain propagation can take up to 72 hours to propagate
                worldwide, although it typically takes a few hours.
              </p>
            </div>
          </Split>
          <h5 className="uppercase">Access Method</h5>
          <Split>
            <div>
              <DropDown
                block
                items={[
                  { label: 'User passwords', value: 0 },
                  { label: 'Public', value: 1 },
                ]}
                defaultValue={access}
                onSelect={setAccess}
              />
            </div>
            <div>
              <p className="tiny-desc">
                Select your preferred level of security. ‘User passwords’ is the
                most secure, but each user will require their own details. ‘Open
                access’ means the whole world can see!
              </p>
            </div>
          </Split>
          <h5 className="uppercase">{`${sd} BrandBox status`}</h5>
          <Split>
            <div>
              <Switch
                block
                defaultValue={status}
                onChange={updateStatus}
                leftText="Offline"
                rightText="Online"
              />
            </div>
            <div>
              <p className="tiny-desc">
                Pause all access to your BrandBox by switching it offline. Your
                content will NOT be lost.
              </p>
            </div>
          </Split>
        </section>
      </Spinner>
    </SettingsWrap>
  );
};
