import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Modal } from '../../ui';
import Placeholder from './PlaceHolder';
import MediaBrowser from '../MediaBrowser';

const Image = styled.div`
  width: 100%;
  box-sizing: border-box;
  .add {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    ${({ edit }) =>
      edit &&
      `
    img {
      filter: brightness(50%);
    }
    `}
    .add {
      display: block;
    }
  }

  ${({ stroke }) =>
    stroke &&
    `
    border: 1px solid #d2d2d2;
    padding: 10px;
  `}

  ${({ url }) =>
    !url &&
    `
    background: linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 0.8px),#d2d2d2 50%,rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%);
    border: 1px solid #d2d2d2;
    min-height: 312px;
  `}

  img, object {
    width: 100%;
    box-sizing: border-box;
    padding: inherit;
    ${({ crop }) =>
      crop &&
      `
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
    `}
  }
  object {
    pointer-events: none;
    padding: 100px;
    background-color: white;
  }

  ${({ crop }) =>
    crop &&
    crop === 1 &&
    `
    position: relative;

    &::after {
      display: block;
      content: '';
      padding-bottom: 100%;
    }
  `}

  ${({ crop }) =>
    crop &&
    crop === 2 &&
    `
    position: relative;

    &::after {
      display: block;
      content: '';
      padding-bottom: 56.25%;
    }
  `}

  ${({ crop }) =>
    crop &&
    crop === 3 &&
    `
    position: relative;

    &::after {
      display: block;
      content: '';
      padding-bottom: 75%;
    }
  `}

  span {
    color: #000000;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: #d2d2d2;
  }

  ${({ selected }) =>
    selected &&
    `
  cursor: pointer;
  &:hover {
  opacity: 0.6;
  }
  `}
`;

export default ({
  url,
  edit,
  width,
  crop = 0,
  stroke,
  height = 368,
  selected,
  readOnly,
  direction,
  placeholder,
  onClickHolder,
  changeHandler = () => {},
}) => {
  const [open, setOpen] = useState(false);

  if (!edit && !url && placeholder && !readOnly)
    return (
      <Placeholder
        placeholder={placeholder}
        height={height}
        onClick={onClickHolder}
        width={width}
        direction={direction}
      />
    );

  if (!edit && !url) return <></>;

  const setImage = (sel) => {
    changeHandler({
      url: sel.urls.original,
    });
    setOpen(false);
  };

  return (
    <>
      <Image
        edit={edit}
        selected={selected}
        url={url}
        onClick={() => setOpen(!open)}
        crop={crop}
        stroke={stroke ? 'true' : ''}
      >
        {(() => {
          if (!url) return <span />;
          if (url.match(/svg/i)) return <object data={url}>&nbsp;</object>;
          return <img alt="logo" src={url} />;
        })()}
        {edit && <Icon text name="plus" className="add" />}
      </Image>
      {selected && (
        <Modal visible={open}>
          <MediaBrowser
            type="image"
            category="basics"
            onCancel={() => setOpen(false)}
            onConfirm={setImage}
          />
        </Modal>
      )}
    </>
  );
};
