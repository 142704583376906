import React, { useState } from 'react';
import styled from 'styled-components';

const TinySwitch = styled.span`
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;

  input {
    width: 30px;
    height: 16px;
    opacity: 0;
    position: absolute;
    left: -1000000px;
  }

  label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: 100px;
    background-color: #b3b3b3;
    opacity: 0.5;
    width: 30px;
    height: 16px;
  }

  label::after {
    content: ' ';
    display: inline-block;
    background-color: #ffffff;
    border-radius: 100px;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 2px;
    top: 2px;
  }

  span.label {
    position: relative;
    font-size: 12px;
    color: #000000;
    font-weight: 300;
    left: 8px;
  }

  ${({ checked }) =>
    checked &&
    `
    label {
      background-color: #000000;
      opacity: 1;
    }
    label::after {
      left: 16px;
    }
  `}
`;

export default ({
  name = Date.now().toString(),
  label,
  checked,
  onChange = () => {},
  className = '',
}) => {
  const [value, setValue] = useState(checked);

  const toggle = ({ target: { checked: val } }) => {
    setValue(val);
    onChange(val);
  };

  return (
    <TinySwitch checked={value} className={className}>
      <input
        name={name}
        id={name}
        type="checkbox"
        onChange={toggle}
        defaultChecked={checked}
      />
      <label htmlFor={name} />
      <span className="label">{label}</span>
    </TinySwitch>
  );
};
