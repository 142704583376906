import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Input, Button } from '../ui';
import { toast } from '../ui/Toast';

import { setCredential, doUpdatePassword, doLogin } from '../store/user';
import { doActivateUser } from '../store/brand';

const PasswordWrapper = styled.div`
  position: relative;
`;

const CardHeader = styled.div`
  clear: both;
  margin: 32px 0;
  p {
    font-family: Roboto;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.12px;
    color: #000000;
    margin: 0;

    &.sub {
      margin-top: 32px;
      font-size: 12px;
      font-weight: 300;
      color: #444444;
      line-height: 20px;
    }
  }
`;

const Links = styled.div`
  z-index: 2;
  width: 290px;
  height: 30px;
  background-color: #f8f8f8;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    width: 227px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444444;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      border-bottom: 0;
    }
  }
`;

export default () => {
  const dispatch = useDispatch();

  const name = new URLSearchParams(window.location.search).get('name');
  const token = new URLSearchParams(window.location.search).get('token');
  const email = new URLSearchParams(window.location.search).get('email');
  const onboard = new URLSearchParams(window.location.search).get('onboard');

  const { error, credential, brand } = useSelector(
    ({ user: usr, brands, global: { brand: br } }) => ({
      ...usr,
      ...brands,
      brand: br,
    })
  );

  const strongPassword = (pwd) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
      pwd
    );
  };

  const onChange = (key, val) => {
    dispatch(
      setCredential({
        ...credential,
        [key]: val,
      })
    );
  };

  const save = (e) => {
    e.preventDefault();

    if (onboard) {
      dispatch(
        doActivateUser(
          brand?.id,
          {
            ...credential,
            token,
          },
          (err) => {
            if (err) {
              toast.error('Error', err.message);
              return;
            }

            dispatch(
              doLogin({ email, password: credential.password }, (errr) => {
                if (!errr) window.location.href = '/basics';
              })
            );
          }
        )
      );
    } else {
      dispatch(
        doUpdatePassword(
          {
            ...credential,
            token,
            brandId: brand?.id,
          },
          (err) => {
            if (err) {
              toast.error('Error', err.message);
              return;
            }

            window.location.href = '/login?password=2';
          }
        )
      );
    }
  };

  return (
    <>
      <CardHeader>
        {onboard ? (
          <p>
            Hi {name}, choose <br />a password for your account
          </p>
        ) : (
          <p>
            Hi {name}, choose <br />a new password
          </p>
        )}
        <p className="sub">
          Your password should have at least 8 characters long and have at least
          one upper case letter, a number and a special character.
        </p>
      </CardHeader>
      <form onSubmit={save}>
        <Container margin={37}>
          <PasswordWrapper>
            <Input
              error={
                credential.password && !strongPassword(credential.password)
              }
              block
              type="password"
              toggle
              onChange={(e) => onChange('password', e.target.value)}
              placeholder="Enter password"
            />
          </PasswordWrapper>
          <PasswordWrapper>
            <Input
              className="m-0"
              disabled={
                !credential.password || !strongPassword(credential.password)
              }
              error={
                credential.confirmPassword &&
                credential.password !== credential.confirmPassword
              }
              block
              toggle
              type="password"
              onChange={(e) => onChange('confirmPassword', e.target.value)}
              placeholder="Confirm password"
            />
            {error && <p className="error">{error}</p>}
          </PasswordWrapper>
        </Container>

        <Container>
          <Button
            block
            disabled={
              !credential.password ||
              !strongPassword(credential.password) ||
              credential.password !== credential.confirmPassword
            }
          >
            {onboard ? 'SET PASSWORD' : 'CHANGE PASSWORD'}
          </Button>
        </Container>
      </form>

      <Links>
        <Link to="/login">Back to login</Link>
      </Links>
    </>
  );
};
