import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  margin-left: 16px;

  a,
  a:hover,
  a:active {
    margin-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
  }
  .active,
  .active:hover,
  .active:active {
    font-weight: bold;
  }
`;

export default ({ routes = [] }) => {
  const { pathname } = useLocation();

  return (
    <Wrap>
      {routes.map((r) => (
        <Link
          className={(() => {
            if (pathname.includes(r.route)) return 'active';
            return '';
          })()}
          key={r.name}
          to={r.route}
        >
          {r.name}
        </Link>
      ))}
    </Wrap>
  );
};
