import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { DropDown } from '../../ui';
import { setSelected, updateRow } from '../../store/page';

import SettingsFooter from './SettingsFooter';

const Head = styled.h4`
  font-size: 24px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 19px;
`;

const Sub = styled.h5`
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.06px;
  color: #000000;
  margin-bottom: 8px;
  font-weight: 300;
`;
const Text = styled.p`
  width: 437px;
  height: 50px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.42;
  letter-spacing: 0.05px;
  color: #444444;
  margin-bottom: 8px;
`;

const selectLayout = [
  { label: '1 column', value: 0, img: '1col.jpg' },
  { label: '2 column A', value: 1, img: '2cola.jpg' },
  { label: '2 column B', value: 2, img: '2colc.jpg' },
  { label: '2 column C', value: 3, img: '2colb.jpg' },
  { label: '3 column', value: 4, img: '3col.jpg' },
];

export default ({ props, section, row }) => {
  const dispatch = useDispatch();
  const { layout = 0 } = props;

  const [newLayout, setNewLayout] = useState();

  const cancel = () => {
    dispatch(setSelected(undefined));
  };

  const confirm = () => {
    dispatch(
      updateRow({
        row,
        layout: newLayout,
        section,
      })
    );
    cancel();
  };

  useEffect(() => {
    if (layout === undefined) return;
    setNewLayout(layout);
  }, []); // eslint-disable-line

  return (
    <>
      <Head>Row Settings</Head>
      <Sub>Layout</Sub>
      <Text>
        Change the column layout. Content from removed columns will not be lost,
        simply transferred to the adjacent column.
      </Text>
      <DropDown
        size="small"
        block
        items={selectLayout}
        onSelect={setNewLayout}
        disabled={false}
        dropDownType="text"
        defaultValue={newLayout}
      />
      <SettingsFooter
        style={{
          marginTop: 32,
          padding: 0,
        }}
        onCancel={cancel}
        onConfirm={confirm}
        showCancel
        showConfirm
      />
    </>
  );
};
