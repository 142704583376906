import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AiOutlineFileImage } from 'react-icons/ai';

import { DropDown, Spinner, Icon, Button } from '../ui';
import { getTouchpoints } from '../svc/brand';
import SettingsFooter from './editor/SettingsFooter';

const Wrap = styled.div`
  width: 586px;
  position: relative;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
  }
`;

const Media = styled.div`
  position: relative;
  min-height: 200px;

  header {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
      flex: 1;
      height: 36px;
      &:last-child {
        position: relative;
        display: flex;
        align-items: left;
        justify-content: center;

        button {
          position: absolute;
          top: 9px;
          padding: 0;
          margin: 0;
          &:first-child {
            ${({ searchable }) =>
              searchable &&
              `
          left: 0;
          `};
            ${({ searchable }) =>
              !searchable &&
              `
          right: 0;
          `};
          }

          &.close {
            right: 0 !important;
          }
        }

        input {
          ${({ searchable }) =>
            !searchable &&
            `
            display: none;
          `}
          flex: 1;
          font-size: 12px;
          border: none;
          border-bottom: 1px solid #e9e9e9;
          box-sizing: border-box;
          padding: 0 10px 0 18px;
        }
      }
    }
  }

  main {
    overflow: scroll;
    padding: 1px;
    max-height: 320px;
    margin-bottom: 32px;
  }
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #4b9aff;
  border-radius: 50px;
  svg {
    fill: #ffffff !important;
    width: 10px !important;
    height: 10px !important;
  }
`;

const Thumb = styled.div`
  width: 136px;
  height: 110px;
  padding: 3px;
  box-sizing: bordered-box;
  float: left;
  margin-bottom: 12px;
  &:not(:last-child) {
    margin-right: 4px;
  }
  cursor: pointer;
  ${({ selected }) =>
    !selected &&
    `
    &:hover {
      outline: 1px solid #d2d2d2;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    outline: 1px solid #4b9aff;
  `}

  .pic {
    position: relative;
    outline: 1px solid #dedede;
    width: 100%;
    height: 80px;
    overflow: hidden;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    video,
    object {
      height: 100%;
      object-fit: cover;
    }
    object {
      pointer-events: none;
    }
    svg {
      fill: #999999;
      width: 32px;
      height: 32px;
    }
  }

  .desc {
    padding-top: 8px;
    font-size: 12px;
    color: #444444;
  }
`;

export default ({
  title = 'Select touchpoints',
  type,
  multi,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const searchRef = useRef();
  const [to, setTo] = useState(undefined);
  const [touchpoints, setTouchpoints] = useState();
  const [search, setSearch] = useState();
  const [previous, setPrevious] = useState(undefined);
  const [selected, setSelected] = useState(multi ? [] : undefined);
  const [searchable, setSearchable] = useState(false);
  const [collection, setCollection] = useState();
  const [collections, setCollections] = useState();

  const { user } = useSelector((store) => store.user);

  const doSearch = (val) => {
    if (to) clearTimeout(to);

    if (!val && previous) {
      setTouchpoints(undefined);
      setPrevious(val);
      setSearch(val);
      return;
    }

    setTo(
      setTimeout(() => {
        setTouchpoints(undefined);
        setPrevious(val);
        setSearch(val);
      }, 1000)
    );
  };

  const change = (e) => {
    const val = e.target.value.trim();
    doSearch(val);
  };

  useEffect(() => {
    (async () => {
      if (!user || !user.brand) return;

      const queries = {};

      if (type) queries.type = type;
      if (search) queries.search = search;

      const { data, error } = await getTouchpoints(
        user.brand.id,
        collection,
        queries
      );

      if (!error) {
        setTouchpoints(data.touchpoints);
        setCollections(data.collections);
      }
    })();
  }, [user, collection, type, search]);

  return (
    <Wrap>
      <h3>{title}</h3>
      <Spinner spin={!collections}>
        <Media searchable={searchable ? 'true' : ''}>
          <header>
            <div>
              <DropDown
                onSelect={(col) => {
                  const cid = col.match(/all/i) ? undefined : col;
                  if (cid === collection) return;
                  setTouchpoints(undefined);
                  setCollection(cid);
                }}
                disabled={!collections}
                placeholder="Filter by collection"
                items={
                  !collections
                    ? []
                    : [
                        { label: 'All Items', value: 'all' },
                        ...collections.map((c) => ({
                          label: c.name,
                          value: c.id,
                        })),
                      ]
                }
                size="small"
              />
            </div>
            <div>
              <Button
                disabled={searchable}
                icon="search"
                size="small"
                text
                onClick={(e) => {
                  e.preventDefault();
                  setSearchable(true);
                  if (searchRef.current)
                    setTimeout(() => searchRef.current.focus(), 500);
                }}
              />
              {searchable && (
                <Button
                  className="close"
                  icon="cross-sml"
                  size="small"
                  text
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchable(false);
                    doSearch(undefined);
                    if (searchRef.current)
                      setTimeout(() => {
                        searchRef.current.blur();
                        searchRef.current.value = '';
                      }, 500);
                  }}
                />
              )}
              <input
                ref={searchRef}
                type="text"
                placeholder="Search"
                onChange={change}
              />
            </div>
          </header>
          <main>
            {touchpoints &&
              touchpoints.map((t) => (
                <Thumb
                  key={t.id}
                  selected={(() => {
                    if (multi && Array.isArray(selected)) {
                      if (selected.find((s) => s === t.id)) return true;
                    }
                    return t.id === selected;
                  })()}
                  onClick={() => {
                    if (!multi) {
                      if (selected === t.id) setSelected(undefined);
                      else setSelected(t.id);
                    } else {
                      // copy
                      const sc = [...selected];
                      const idx = sc.findIndex((s) => s === t.id);
                      // if found, then delete
                      if (idx >= 0) {
                        sc.splice(idx, 1);
                      } else {
                        sc.push(t.id);
                      }
                      setSelected(sc);
                    }
                  }}
                >
                  <div className="pic">
                    {(() => {
                      if (multi && Array.isArray(selected)) {
                        if (selected.find((s) => s === t.id)) return true;
                      }
                      return t.id === selected;
                    })() && (
                      <Check>
                        <Icon name="tick" />
                      </Check>
                    )}
                    {(() => {
                      if (t.thumbnails.length < 1)
                        return <AiOutlineFileImage />;
                      const thumbnail =
                        t.thumbnails[0]?.urls?.thumbnail ||
                        t.thumbnails[0]?.urls?.original;
                      return (
                        <img
                          src={thumbnail}
                          //eslint-disable-next-line
                          alt={t?.thumbnails[0]?.file_name || ''}
                        />
                      );
                    })()}
                  </div>
                  <div className="desc">{t.name.slice(0, 20)}</div>
                </Thumb>
              ))}
          </main>
        </Media>
      </Spinner>
      <SettingsFooter
        showCancel
        showConfirm
        bordered
        onCancel={onCancel}
        onConfirm={
          !selected || (Array.isArray(selected) && selected.length === 0)
            ? undefined
            : () => {
                let payload;
                if (multi)
                  payload = selected.map((s) =>
                    touchpoints.find((t) => t.id === s)
                  );
                else payload = touchpoints.find((t) => t.id === selected);
                onConfirm(payload);
              }
        }
      />
    </Wrap>
  );
};
