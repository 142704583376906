import React, { useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Nav = styled.div`
  position: absolute;
  top: -10px;
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    border-radius: 100px;
    display: inline-block;
    border: 1px solid #999999;
    background-color: transparent;
    width: 9px;
    height: 9px;
    padding: 0;
    margin-right: 8px;
    &.selected {
      background-color: #999999;
    }
  }
`;

const ImageWrap = styled.div`
  width: 100%;
  height: ${({ imgHeight }) => imgHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  object,
  img {
    height: 90%;
    width: 90%;
    object-fit: contain;
  }
`;

export default ({ images, imgHeight = 400 }) => {
  const [index, setIndex] = useState(0);

  return (
    <Wrap>
      <Nav>
        {images.map((_, i) => (
          <button
            type="button"
            className={i === index ? 'selected' : ''}
            onClick={() => {
              setIndex(i);
            }}
            key={`b-${i}`}
          >
            &nbsp;
          </button>
        ))}
      </Nav>
      <ImageWrap imgHeight={imgHeight}>
        {(() => {
          const src = images[index];
          if (!src) return;
          if (src.split('.').pop().match(/svg/i))
            return <object data={src}>not supported</object>;
          return <img src={src} alt="img" />;
        })()}
      </ImageWrap>
    </Wrap>
  );
};
