/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Icon, Modal, DropDown, Spinner } from '../ui';
import { toast } from '../ui/Toast';

import SettingsWrap from '../components/SettingsWrap';
import DeleteUser from '../components/DeleteUser';
import EditUser from '../components/EditUser';

import { doAddUsers, doDeleteRequest } from '../store/brand';
import { setBrandUsers, setBrandRequests } from '../store/user';
import { getRole } from '../config';

import { reset } from '../svc/auth';
import { deleteBrandUser } from '../svc/brand';

const InputWrapper = styled.form`
  width: 558px;
  height: 45px;
  background-color: #f8f8f8;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
const Input = styled.input`
  border: none;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  color: #333333;
  margin-right: 8px;
  display: inline-block;
  width: ${({ width }) => width}px;
  height: 30px;
  padding: 0 8px;
  box-sizing: border-box;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
`;

const AddButton = styled(Button)`
  padding: 0 0 0 10px;
`;

const UserList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: solid 1px #f0f0f0;
  padding: 7px 7px;
  width: 570px;
  box-sizing: border-box;
  height: 48px;
  align-items: flex-end;
  background-color: ${({ bg }) => (bg ? '#f8f8f8' : 'transparent')};

  li {
    display: flex;
    height: 17px;
    align-items: center;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    color: #000000;

    span.name {
      font-size: 15px;
    }
  }

  .actions {
    height: 33px;
    display: none;
  }

  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }

  &:hover > .actions {
    display: block;
  }
`;

const ButtonIcon = styled(Button)`
  padding: 0;

  span {
    margin: 3px !important;
  }
  &:not(:last-child) {
    margin: 0;
  }
`;

const UserIcon = styled(Icon)`
  margin-right: 6px;
`;

const ConfirmButtom = styled(Button)`
  margin-top: 27px;
`;

const UserRequest = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  p {
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.1px;
    color: #444444;
    span {
      font-weight: 500;
      color: #4b9aff;
    }
  }
`;

const BulkAction = styled.div`
  display: flex;
  margin-top: 36px;
  align-items: center;
  & > button {
    width: 112px;
    height: 30px;
    margin-left: 10px;
  }
`;

const selectBulkAction = [
  { label: 'Remove users', value: 1 },
  { label: 'Reset Password', value: 2 },
];

const access = {
  user: 0,
  admin: 1,
  owner: 2,
};

const select = ({ user, global }) => ({ ...user, ...global });
const copy = (o) => JSON.parse(JSON.stringify(o));

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector(select);
  const { brand, id } = user;
  const {
    id: brandId,
    users: brandUsers,
    requests: brandRequests,
    role: sessionRole,
  } = brand;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [bulkAction, setBulkAction] = useState();
  const [selectedUser, setSelectedUser] = useState([]);

  const onChange = (key, val) => {
    key(val);
  };

  const addUser = () => {
    if (brandUsers?.some((bu) => bu.email === email)) {
      toast.error(
        'User name / email already exists',
        'Please try with another one'
      );
    } else {
      setUsers([...users, { name, email }]);
    }
    setName('');
    setEmail('');
  };

  const removeUser = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  };

  const addBrandUser = (newUsers) => {
    dispatch(
      doAddUsers(brandId, newUsers, (error, data) => {
        if (error) toast.error('Error', error.message);
        if (data.length > 0) {
          const usersCopy = copy(brandUsers);
          usersCopy.push(...data);
          dispatch(setBrandUsers(usersCopy));

          toast(
            'Success',
            `User${data.length > 1 ? 's' : ''} successfully added`
          );

          // check if newly added users is in the request pool
          const forDeleteIndexes = data.reduce((s, n, i) => {
            if (brandRequests.find((br) => br.email === n.email)) s.push(i);
            return s;
          }, []);

          // then we remove em
          const requestsCopy = copy(brandRequests);
          dispatch(
            setBrandRequests(
              requestsCopy.filter((_, i) => !forDeleteIndexes.includes(i))
            )
          );
        }
      })
    );
  };

  const onConfirm = () => {
    addBrandUser(users);
    setUsers([]);
  };

  const onDeletePrompt = (cu, index) => {
    setSelected({ ...cu, index });
    setAction(0);
  };

  const onEditPrompt = (e, cu, index) => {
    e.stopPropagation();
    setSelected({ ...cu, index });
    setAction(1);
  };

  const onCancel = () => {
    setSelected(null);
    setAction(null);
  };

  const handleDelete = (error) => {
    if (error) {
      toast.error(
        'Oops, something went wrong',
        'An unexpected error has occurred. Please try again.      '
      );
    } else {
      toast('Success', 'User deleted');
      const usersCopy = copy(brandUsers);
      usersCopy.splice(selected.index, 1);
      dispatch(setBrandUsers(usersCopy));
    }
    onCancel();
  };

  const handleRequestConfirm = (reqUser) => {
    addBrandUser([reqUser]);
  };

  const handleRequestDelete = (requestEmail, index) => {
    dispatch(
      doDeleteRequest(brandId, requestEmail, (error) => {
        if (!error) {
          const requestCopy = copy(brandRequests);
          requestCopy.splice(index, 1);
          dispatch(setBrandRequests(requestCopy));
        }
      })
    );
  };

  const handleEdit = (updatedUser) => {
    const usersCopy = copy(brandUsers);
    usersCopy.splice(selected.index, 1, updatedUser);
    dispatch(setBrandUsers(usersCopy));
    onCancel();
    toast('Success', 'User settings updated!');
  };

  const onBulkActionChange = (val) => {
    setBulkAction(val);
  };

  const handleBulkAction = async () => {
    setLoading(true);

    if (bulkAction === 1) {
      await Promise.all(
        selectedUser.map((su) => deleteBrandUser(brandId, su.id))
      )
        .then(() => {
          const newUsers = brandUsers.filter(
            (elem) => !selectedUser.find((su) => elem.id === su.id)
          );
          dispatch(setBrandUsers(newUsers));
        })
        .catch(() => toast('Success', 'Reset link sent'));
    }
    if (bulkAction === 2) {
      await Promise.all(selectedUser.map((su) => reset({ email: su.email })))
        .then(() => toast.error('Error', 'Failed to remove user'))
        .catch(() => toast.error('Error', 'Failed to remove user'));
    }
    setLoading(false);
    setSelectedUser([]);
  };

  const toggleUser = (su) => {
    if (su.id === id || access[sessionRole] <= access[su.role]) return;
    if (selectedUser.includes(su)) {
      const newSu = selectedUser.filter((nus) => nus.id !== su.id);
      setSelectedUser([...newSu]);
    } else {
      setSelectedUser([...selectedUser, su]);
    }
  };

  return (
    <Spinner spin={loading}>
      <SettingsWrap>
        {brandRequests && brandRequests.length !== 0 && (
          <section>
            <UserRequest>
              <p>
                You have <span>{brandRequests?.length} pending</span> access
                requests.
              </p>
              {brandRequests?.map((bru, index) => {
                return (
                  <UserList key={bru.email} bg>
                    <li style={{ width: 162 }}>{bru.name}</li>
                    <li style={{ width: 215 }}>{bru.email}</li>
                    <li style={{ height: 22 }}>
                      <ButtonIcon
                        icon="tick-sml"
                        text
                        size="small"
                        style={{ marginRight: 21 }}
                        onClick={() => handleRequestConfirm(bru)}
                      >
                        Confirm
                      </ButtonIcon>
                      <ButtonIcon
                        icon="cross-sml"
                        text
                        size="small"
                        onClick={() => handleRequestDelete(bru.email, index)}
                      >
                        Ignore
                      </ButtonIcon>
                    </li>
                  </UserList>
                );
              })}
            </UserRequest>
          </section>
        )}
        <section>
          <h2>Add users</h2>
          <p className="description">
            Add a new team member to your BrandBox. An invitation will be sent
            after you complete their details and assign user permissions.
          </p>
          <div>
            <InputWrapper onSubmit={addUser}>
              <Input
                placeholder="Enter name"
                width={150}
                onChange={(e) => onChange(setName, e.target.value)}
                value={name}
              />
              <Input
                placeholder="Enter email address"
                width={315}
                onChange={(e) => onChange(setEmail, e.target.value)}
                value={email}
              />
              <AddButton
                icon="add"
                text
                onClick={addUser}
                disabled={
                  !name ||
                  !email ||
                  !email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
                }
              >
                ADD
              </AddButton>
            </InputWrapper>
            {users.length !== 0 &&
              users.map((cu, index) => {
                return (
                  <UserList key={cu.email}>
                    <li style={{ width: 162 }}>{cu.name}</li>
                    <li style={{ width: 363 }}>{cu.email}</li>
                    <li>
                      <AddButton
                        disabled={false}
                        icon="cross-sml"
                        text
                        onClick={() => removeUser(index)}
                      />
                    </li>
                  </UserList>
                );
              })}
            {users.length !== 0 && (
              <ConfirmButtom icon="tick-sml" onClick={onConfirm}>
                Confirm new users
              </ConfirmButtom>
            )}
          </div>
        </section>

        <section>
          <h2>Existing users</h2>
          <p className="description">
            Manage users by hovering over their details and clicking the edit
            icon. You can change user permissions or reset user passwords.
            Changes to name or email address can be made directly from the
            user’s account.
          </p>
          <div>
            {brandUsers &&
              brandUsers.map((brandUser, index) => {
                const role =
                  !brandUser.verified && brandUser.role !== 'owner'
                    ? 'pending'
                    : brandUser.role;

                const isMe = brandUser.id === id;

                return (
                  <UserList
                    key={brandUser.email}
                    onClick={() => toggleUser(brandUser)}
                    bg={selectedUser.some((su) => su.id === brandUser.id)}
                  >
                    <li style={{ width: 162 }}>
                      <UserIcon
                        className={`brand-${role}`}
                        name="user-circle"
                      />
                      <span className="name">{brandUser.first_name}</span>
                    </li>
                    <li style={{ width: 260 }}>{brandUser.email}</li>
                    <li className={`brand-${role}`} style={{ width: 80 }}>
                      {getRole[role]}
                    </li>
                    <li className="actions">
                      {!isMe && access[sessionRole] > access[brandUser.role] && (
                        <>
                          <ButtonIcon
                            icon="edit"
                            text
                            onClick={(e) => onEditPrompt(e, brandUser, index)}
                          />
                          <ButtonIcon
                            icon="delete"
                            text
                            onClick={() => onDeletePrompt(brandUser, index)}
                          />
                        </>
                      )}
                    </li>
                  </UserList>
                );
              })}
          </div>
          {false && (
            <BulkAction>
              <DropDown
                items={selectBulkAction}
                onSelect={onBulkActionChange}
                dropDownType="text"
                disabled={false}
                size="small"
                placeholder="Bulk actions"
                width={150}
              />
              <Button
                disabled={loading || !bulkAction}
                onClick={handleBulkAction}
              >
                APPLY
              </Button>
            </BulkAction>
          )}
        </section>

        <Modal visible={selected}>
          {action === 0 && (
            <DeleteUser
              userId={selected?.id}
              brandId={brandId}
              onCancel={onCancel}
              onDelete={handleDelete}
            />
          )}
          {action === 1 && (
            <EditUser
              user={selected}
              onCancel={onCancel}
              onConfirm={handleEdit}
              brandId={brandId}
              onDelete={handleDelete}
              sessionRole={sessionRole}
            />
          )}
        </Modal>
      </SettingsWrap>
    </Spinner>
  );
};
