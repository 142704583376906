import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Input, Button } from '../ui';

import { setCredential, doLogin } from '../store/user';

const PasswordWrapper = styled.div`
  position: relative;
`;

const CardHeader = styled.div`
  clear: both;
  margin: 32px 0 44px;
  p {
    font-family: Roboto;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5em;
    letter-spacing: 0.12px;
    color: #000000;
    padding-right: 12px;
    margin: 0;

    &.sub {
      margin-top: 32px;
      font-size: 12px;
      font-weight: 300;
      color: #444444;
      line-height: 20px;
    }
  }
`;

const Links = styled.div`
  z-index: 2;
  width: 290px;
  height: 30px;
  background-color: #f8f8f8;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    width: 227px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444444;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      border-bottom: 0;
    }
    outline: none;
  }
`;

export default () => {
  const dispatch = useDispatch();
  const owner = new URLSearchParams(window.location.search).get('owner');
  const password = new URLSearchParams(window.location.search).get('password');

  const { errorMessage, credential, brand } = useSelector(
    ({ user: usr, global }) => ({
      ...usr,
      ...global,
    })
  );

  const onChange = (key, val) => {
    dispatch(
      setCredential({
        ...credential,
        [key]: key.match(/password/) ? val.trim() : val,
      })
    );
  };

  const login = (e) => {
    e.preventDefault();
    dispatch(
      doLogin(
        {
          ...credential,
          brandId: brand?.id,
        },
        (error, data) => {
          if (error) return;

          let url = '/';
          const { host, protocol } = window.location;
          const subdomain = host.split('.')[0];
          if (brand?.id) {
            if (data.user_details.brand.sub_domain !== subdomain) {
              const {
                token,
                user_details: { brand: b, id: uid },
              } = data;
              url = `${protocol}//${b.sub_domain}.${host}/login?token=${token}&userId=${uid}&brandId=${b.id}`;
              localStorage.setItem('brandId', data.user_details.brand.id);
            }
          }

          localStorage.setItem('userId', data.user_details.id);
          localStorage.setItem('token', data.token);
          if (!data.user_details.brand?.online) {
            url = '/settings/advanced';
          }

          window.location.href = url;
        }
      )
    );
  };
  return (
    <>
      <CardHeader>
        {(() => {
          // fresh setup or after password reset
          const pwd = parseInt(password, 10);
          if (pwd) {
            if (pwd === 1) return <p>Your account is now ready</p>;
            if (pwd === 2) return <p>Your password has been updated</p>;
          }

          if (brand && !brand.online) {
            if (owner) return <p>Log in to {brand?.name || ''} as Owner</p>;
            return <p>The {brand?.name || ''} BrandBox is currently offline</p>;
          }
          return <p>Welcome to {brand?.name || ''} BrandBox</p>;
        })()}
      </CardHeader>
      {((brand && brand.online) || owner || !brand) && (
        <form onSubmit={login}>
          <Container margin={37}>
            <Input
              block
              error={
                credential.email &&
                !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
              }
              errorMessage={
                credential.email &&
                !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/)
                  ? 'Please enter a valid email'
                  : ''
              }
              placeholder="Email address"
              onChange={(e) => {
                onChange('email', e.target.value.trim().toLowerCase());
              }}
            />
            <PasswordWrapper>
              <Input
                error={credential.password && credential.password.length < 5}
                errorMessage={
                  credential.password && credential.password.length < 5
                    ? 'Password is too short'
                    : ''
                }
                block
                toggle
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  onChange('password', e.target.value.trim());
                }}
              />
              {errorMessage && (
                <p className="error">
                  Oops, incorrect password. Please try again.
                </p>
              )}
            </PasswordWrapper>
          </Container>

          <Container>
            <Button
              block
              disabled={
                !credential.email ||
                !credential.email.match(/[a-zA-Z0-9._\-+]{2,}@.+\..{2,}$/) ||
                !credential.password ||
                credential.password.length < 8
              }
            >
              LOG IN
            </Button>
          </Container>
        </form>
      )}
      {brand && !brand.online && !owner && (
        <p
          style={{
            fontSize: 12,
            width: 200,
            lineHeight: '24px',
            color: '#444444',
          }}
        >
          Contact the project owner for renewed access.
        </p>
      )}

      {(() => {
        if (brand && !brand.online) {
          if (owner)
            return (
              <Links>
                <Link to="/login">Back</Link>
              </Links>
            );
          return (
            <Links>
              <Link to="/login?owner=1">Owner access</Link>
            </Links>
          );
        }

        return (
          <Links>
            <Link to="/forgot">Forgot Password?</Link>
            <span className="spacer sub">|</span>
            <Link to={`/${brand ? 'request' : 'signup'}`}>
              {brand ? 'Request access' : 'Create account'}
            </Link>{' '}
          </Links>
        );
      })()}
    </>
  );
};
