import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { DropDown, File, TypographyBlock } from '../ui';
import { download } from '../svc/client';

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > .new {
    width: 320px;
    ${({ tile }) =>
      tile &&
      `
    width: 444px !important;
    `}

    button {
      padding: 0;
    }
  }
`;

const FileInfo = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #e9e9e9;
  color: #9e9e9e;

  & > button,
  & > button:active {
    padding: 0;
    position: absolute;
    top: 6px;
    right: 0;
    margin: 0 !important;
  }
`;

export default ({ tile, fonts, onChange = () => {} }) => {
  const [font, setFont] = useState();
  const [gfonts, setGfonts] = useState();
  const [coreFonts, setCoreFonts] = useState();
  const [fontVariants, setFontVariants] = useState();

  const fontRef = useRef();
  const variantRef = useRef();

  const del = (i) => {
    const newCoreFonts = [...coreFonts];
    newCoreFonts.splice(i, 1);
    setCoreFonts(newCoreFonts);
    onChange(newCoreFonts);
  };

  const add = () => {
    if (
      coreFonts.find((cf) => cf.url === font.url && cf.variant === font.variant)
    )
      return;
    const newCoreFonts = [...coreFonts, font];
    setCoreFonts(newCoreFonts);
    setFont(undefined);
    if (fontRef.current) fontRef.current.value = '';
    if (variantRef.current) variantRef.current.value = '';
    onChange(newCoreFonts);
  };

  const selectFont = (i) => {
    setFont(undefined);
    const f = gfonts[i];
    const variants = Object.keys(f.files);
    setFontVariants(variants);
    setFont({
      name: f.family,
      url: f.files.regular,
      variant: 'regular',
    });
  };

  const selectVariant = (i) => {
    const fv = fontVariants[i];
    setFont({
      ...font,
      variant: fv,
    });
  };

  useEffect(() => {
    if (font) {
      add();
      return;
    }
    (async () => {
      if (Array.isArray(fonts)) setCoreFonts([...fonts]);

      if (!gfonts) {
        const fontsString = localStorage.getItem('gfonts');
        if (fontsString) {
          setGfonts(JSON.parse(fontsString));
          return;
        }

        const { data, error } = await download(
          'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAmjpXmQ8mJYtd9nOkSy_pfZVYEY_ScAyY',
          { responseType: 'json' }
        );

        if (!error) {
          setGfonts(data.items);
          localStorage.setItem('gfonts', JSON.stringify(data.items));
        }
      }
    })();
  }, [gfonts, fonts, font]); // eslint-disable-line

  if (!coreFonts) return <></>;

  return (
    <Wrap tile={tile ? 'true' : ''}>
      {coreFonts.map((c, i) => (
        <TypographyBlock
          block={tile ? '' : 'true'}
          key={`${c.url}-${c.variant}`}
          font={c}
          index={i}
          onDelete={del}
        />
      ))}
      <div className="new">
        {gfonts && (
          <DropDown
            invert
            disabled={font && font.uploaded}
            placeholder="Choose a font"
            size="small"
            onSelect={selectFont}
            inputRef={fontRef}
            block
            items={
              !gfonts
                ? []
                : gfonts.map((gf, i) => ({ label: gf.family, value: i }))
            }
          />
        )}
        {font && !font.uploaded && fontVariants && fontVariants.length > 0 && (
          <DropDown
            size="small"
            placeholder="Pick a variant"
            onSelect={selectVariant}
            inputRef={variantRef}
            block
            defaultValue={fontVariants.findIndex((fv) => fv === font?.variant)}
            items={
              !fontVariants
                ? []
                : fontVariants.map((fv, i) => ({ label: fv, value: i }))
            }
          />
        )}
        {!(font && font.uploaded) ? (
          <File
            name="font-file"
            placeholder="Or upload one"
            size="small"
            disabled={font && !font.uploaded}
            endpoint="/api/v1/files"
            onUpload={({ data, status }) => {
              if (status !== 200) return;
              setFont({
                name: data.file_name,
                url: data.urls.original,
                variant: 'regular',
                uploaded: true,
              });
            }}
            block
            accept=".ttf,.otf,.woff"
          />
        ) : (
          <FileInfo>
            <span>{font.name} </span>
          </FileInfo>
        )}
      </div>
    </Wrap>
  );
};
