import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ direction = 'column' }) => direction};
  height: ${({ height = 90 }) => height}px;
  width: ${({ width = 'auto' }) => width};
  border: 1px solid #000000;
  ${({ placeholder }) =>
    placeholder &&
    `
  cursor: pointer;
  &:hover {
  background-color: #f0f0f0;
  }
  `}

  p {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    margin: 0;
  }
`;

const Icon = styled.span`
  font-size: 21px;
  margin-right: 6px;
`;

export default ({ height, placeholder, onClick, width, direction }) => (
  <Holder
    height={height}
    width={width}
    placeholder={placeholder}
    direction={direction}
    onClick={onClick}
  >
    <p>
      <Icon>+</Icon>
    </p>
    <p>
      {placeholder && (
        <>
          <span>{placeholder}</span>
        </>
      )}
    </p>
  </Holder>
);
