import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { setNewItem } from '../store/page';
import Icon from './Icon';

const Block = styled.div`
  width: 140px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7.5px 13px;
  background-color: #f0f0f0;
  background-color: #f0f0f0;
  box-sizing: border-box;
  margin: 0 0 5px 0;
  border-radius: 3px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  outline: ${({ selected }) => (selected ? ' 1px solid #000000' : 'none')};
  cursor: pointer;
  &:hover {
    background-color: #eaeaea;
  }
  ${({ block }) =>
    block &&
    `
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  `};
  ${({ draggable }) =>
    draggable &&
    `
  cursor: grab !important;
  }
  `}
`;

const Text = styled.span`
  font-size: 15px;
  margin: ${({ block }) => (!block ? '7px 0 0 0' : '0 0 0 9.5px')};
  text-transform: capitalize;
`;

export default ({
  icon,
  text,
  block,
  draggable = false,
  disabled,
  selected,
  onClick = () => {},
}) => {
  const blockRef = useRef();
  const dispatch = useDispatch();

  const dragstart = (e) => {
    e.stopPropagation();
    dispatch(setNewItem({ type: icon }));
  };

  const dragend = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setNewItem(undefined));
  };

  useEffect(() => {
    const copy = blockRef.current;
    if (!copy) return;

    copy.addEventListener('dragstart', dragstart);
    copy.addEventListener('dragend', dragend);

    return () => {
      copy.removeEventListener('dragstart', dragstart);
      copy.removeEventListener('dragend', dragend);
    };
  });

  return (
    <Block
      ref={blockRef}
      block={block}
      draggable={draggable}
      disabled={disabled}
      icon={icon}
      selected={selected}
      onClick={onClick}
    >
      <Icon name={icon} size={25} />
      <Text block={block}>{text}</Text>
    </Block>
  );
};
