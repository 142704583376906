import { post, put } from './client';

export const signup = ({
  email,
  password,
  firstName,
  brandName,
  brandProperties = {},
}) => {
  return post('/auth/signup', {
    email,
    password,
    firstName,
    brandName,
    brandProperties,
  });
};

export const sendVerification = ({ email }) => {
  return post('/auth/verify', { email });
};

export const verify = ({ token }) => {
  return put('/auth/verify', { token });
};

export const resend = ({ email }) => {
  return post('/auth/resend-code', { email });
};

export const login = (credential) => {
  return post('/auth/login', credential);
};

export const reset = ({ email }) => {
  return post('/auth/reset-password', { email });
};

export const update = ({ email, code, password }) => {
  return post('/auth/update-password', { email, code, password });
};

export const checkEmail = ({ email }) => {
  return post('/auth/check-email', { email });
};
