import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Welcome from '../components/Welcome';
import Page from '../components/Page';

export default () => {
  const { page } = useParams();
  const { user, brand } = useSelector((store) => ({
    brand: store.global.brand,
    ...store.user,
  }));

  if (!user && brand?.access !== 1) return <></>;
  if (!user?.brand?.pages?.find((p) => p.slug === page) && brand?.access !== 1)
    window.location.href = '/notfound';

  return (
    <>
      <Page slug={page} />
      <Welcome />
    </>
  );
};
