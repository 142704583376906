import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Text from '../components/editor/Text';
import Carousel from '../components/editor/Carousel';
import MediaBrowser from '../components/MediaBrowser';
import TouchpointWrap from '../components/TouchpointWrap';

import {
  getTouchpoint,
  updateTouchpoint,
  createTouchpoint,
} from '../svc/brand';

import { download } from '../svc/client';

import { Modal, Button, Spinner, FileSize } from '../ui';
import { setBackground } from '../store/page';

const Wrap = styled.div`
  position: relative;
  min-height: ${window.innerHeight - 262}px;
  width: 100%;
  margin-top: ${({ edit, user }) => {
    if (edit) return 90;
    if (!user) return 111;
    return 0;
  }}px;

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  h2 {
    font-size: 30px;
  }

  h5 {
    &.uppercase {
      text-transform: uppercase;
    }
  }

  p {
    font-weight: 300;
    color: #444444;

    &.desc {
      font-size: 15px;
      width: 484px;
    }
    &.tiny-desc {
      font-size: 12px;
      color: #999999;
      width: 314px;
      line-height: 1.5em;
      margin-bottom: 0;
    }
  }
`;

const Split = styled.div`
  position: relative;
  box-sizing: bordered-box;
  display: flex;
  flex-direction: row;
  margin-top: ${({ edit }) => (edit ? 24 : 0)}px;
  margin-bottom: ${({ edit }) => (edit ? 24 : 0)}px;
  & > div {
    h2,
    p {
      line-height: 1.42em;

      &:focus {
        outline: 1px solid #d2d2d2;
      }
    }
    p.sub {
      font-size: 12px;
      font-weight: 300;
      color: #444444;
    }

    &.info {
      flex: 1;
      padding-right: 6px;
    }

    &.details {
      width: ${({ edit }) => (edit ? 577 : 600)}px;
      box-sizing: border-box;
      padding: 24px;
      background-color: #f8f8f8;
      height: 500px;
      overflow-y: scroll;

      .thumbnails {
        & > * {
          width: 167px;
          height: 140px;
          border-radius: 0;
          float: left;
          margin-bottom: 12px;

          &:not(:nth-child(3)) {
            margin-right: 14px;
          }
        }

        .notice {
          clear: both;
          width: 100%;
          text-align: center;
          font-size: 13px;
          font-weight: normal;
          margin-top: 100px;
        }

        div.thumb {
          position: relative;
          box-sizing: border-box;
          padding: 16px;
          overflow: hidden;
          border: solid 1px #d2d2d2;
          background-color: #f0f0f0;

          img,
          object {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          .rm {
            position: absolute;
            top: -2px;
            right: -15px;
            display: none;
          }

          &:hover {
            .rm {
              display: inline-block;
            }

            border-color: black;
          }
        }

        button.thumb {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          font-size: 12px;

          svg {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
`;

const Group = styled.div`
  margin-bottom: 24px;

  [contenteditable='true'] {
    padding: 4px;
    &:focus {
      outline: 1px solid #d2d2d2;
    }
  }

  .divider {
    margin-right: 25px;
    box-sizing: border-box;
    background-color: #d2d2d2 !important;
  }

  p {
    margin-bottom: 16px;
    margin-right: 16px;

    &.sub {
      position: relative;
      .collapse {
        position: absolute;
        top: -4px;
        right: 0;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    &.item {
      position: relative;
      font-size: 12px;
      margin-bottom: 16px;
      margin-right: 20px;
      font-weight: normal;
      color: #444444;
      padding-bottom: 6px;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + button {
        margin-top: 6px;
      }

      .fs {
        width: 70px;
        display: inline-block;
      }

      .fn {
        width: 168px;
        display: inline-block;
      }

      .ft {
        width: 50px;
        display: inline-block;
      }

      .rm {
        position: absolute;
        right: -7px;
        top: -5px;
        display: none;
      }

      &:hover {
        .rm {
          display: inline-block;
        }
      }
    }
  }
`;

const select = ({ user, global }) => ({ ...user, ...global });
const copy = (o) => JSON.parse(JSON.stringify(o));

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { touchpoint: tid } = useParams();
  const { user, loading: gloading, brand: gbrand } = useSelector(select);

  const brand = user?.brand || gbrand;

  const [edit, setEdit] = useState(false);
  const [handler, setHandler] = useState();
  const [loading, setLoading] = useState();
  const [touchpoint, setTouchpoint] = useState();
  const [touchpointStr, setTouchpointStr] = useState(); // reference

  const [showSource, setShowSource] = useState(true);

  const save = async () => {
    let error;
    let data;

    setLoading(true);

    if (tid === 'new')
      ({ error, data } = await createTouchpoint(brand?.id, touchpoint));
    else ({ error, data } = await updateTouchpoint(tid, touchpoint));

    setLoading(false);

    if (!error) {
      history.push(data.id);
    }

    setEdit(false);
  };

  const update = (items, key) => {
    const tpc = copy(touchpoint);
    tpc[key].push(...items);
    setTouchpoint(tpc);
    setHandler();
  };

  const updateSource = (items) => {
    update([items], 'source_files');
  };
  updateSource.zip = true;

  const updateThumb = (items) => {
    update(items, 'thumbnails');
  };
  updateThumb.type = 'image';

  useEffect(() => {
    window.scrollTo(0, 0);
    // if edit mode set darker bg
    if (edit) dispatch(setBackground('#f0f0f0'));
    // white otherwise
    else dispatch(setBackground('#ffffff'));

    if (tid === 'new') {
      setEdit(true);

      const tp = {
        name: 'Enter touchpoint title',
        description: `
            Enter a description of your touchpoint here. This is also a good
            place to explain best practices for using the design files, and to
            point out anything specific users should watch out for.
            <br /><br />
            Add zip file below (eg. the Adobe working files).
        `,
        source_files: [],
        linked_files: [],
        thumbnails: [],
      };
      setTouchpoint(tp);
      setTouchpointStr(JSON.stringify(tp));
    } else {
      (async () => {
        const { error, data } = await getTouchpoint(tid);
        if (!error) {
          setTouchpoint(data);
          setTouchpointStr(JSON.stringify(data));
        }
      })();
    }
  }, [edit]); // eslint-disable-line

  if (!user && brand?.access === 0) return <></>;

  return (
    <Wrap edit={edit} user={user}>
      <Spinner spin={gloading || loading || !touchpoint}>
        {touchpoint && (
          <TouchpointWrap
            edit={edit}
            onEdit={user ? () => setEdit(true) : undefined}
            onCancel={() => {
              if (tid === 'new') return history.push('/touchpoints');
              setEdit(false);
              setTouchpoint(JSON.parse(touchpointStr));
            }}
            onConfirm={(() => {
              if (
                touchpointStr === JSON.stringify(touchpoint) ||
                touchpoint.source_files.length === 0
              )
                return undefined;
              return save;
            })()}
          >
            <Split edit={edit}>
              <div className="info">
                <Group>
                  {touchpoint.name && (
                    <Text
                      text={touchpoint.description}
                      selected={edit}
                      heading={{
                        text: touchpoint.name,
                        level: 4,
                      }}
                      changeHandler={(t) => {
                        const tpc = copy(touchpoint);

                        tpc.name = t.heading?.text
                          ? t.heading?.text.replace(/<\/?(br|div|p)>/gi, '')
                          : touchpoint.name;
                        tpc.description = t.text
                          ? t.text.replace(/<\/?(div|p)>/gi, '')
                          : touchpoint.description;

                        setTouchpoint(tpc);
                      }}
                    />
                  )}
                  <hr className="divider" />
                </Group>

                <Group>
                  <p className="sub">
                    Zip file{' '}
                    {!edit && touchpoint.source_files.length > 0 && (
                      <Button
                        onClick={() => setShowSource(!showSource)}
                        className="collapse"
                        text
                        icon={showSource ? 'minus' : 'plus'}
                        size="small"
                      />
                    )}
                  </p>
                  {(showSource || edit) &&
                    touchpoint.source_files.map((s, i) => (
                      <p key={`${s.id}-${i}`} className="item">
                        <span className="fn">
                          {s.file_name
                            .split('.')
                            .slice(0, -1)
                            .join('.')
                            .slice(0, 20)}
                        </span>
                        <span className="ft">
                          {s.file_name.split('.').pop()}
                        </span>
                        <span className="fs">
                          <FileSize size={s.file_size} />
                        </span>{' '}
                        {edit && (
                          <Button
                            text
                            size="small"
                            icon="delete"
                            className="rm"
                            onClick={() => {
                              const tpc = copy(touchpoint);
                              tpc.source_files.splice(i, 1);
                              setTouchpoint(tpc);
                            }}
                          />
                        )}
                      </p>
                    ))}
                  {edit && touchpoint.source_files.length === 0 && (
                    <Button
                      text
                      size="small"
                      icon="plus"
                      onClick={() => {
                        setHandler(() => updateSource);
                      }}
                    >
                      ADD
                    </Button>
                  )}
                </Group>

                {tid !== 'new' && !edit && (
                  <Group>
                    <br />
                    <Button
                      icon="download"
                      className="brand-action-bdcolor"
                      style={{ paddingTop: 12, paddingBottom: 12 }}
                      onClick={async (e) => {
                        e.preventDefault();
                        setLoading(true);
                        const { data, error } = await download(
                          `/brands/touchpoints/${tid}/download`
                        );
                        if (!error) {
                          const a = document.createElement('a');
                          a.href = window.URL.createObjectURL(data);
                          a.download = `${touchpoint.name.replace(
                            ' ',
                            '-'
                          )}.zip`;
                          a.click();
                        }
                        setLoading(false);
                      }}
                    >
                      DOWNLOAD TOUCHPOINT -{' '}
                      <FileSize
                        size={(() => {
                          return [
                            ...touchpoint.source_files,
                            ...touchpoint.linked_files,
                          ].reduce((s, n) => {
                            s += n.file_size;
                            return s;
                          }, 0);
                        })()}
                      />
                    </Button>
                  </Group>
                )}
              </div>

              <div className="details">
                {tid !== 'new' && !edit && (
                  <Carousel
                    images={touchpoint.thumbnails.map(
                      (t) => t.urls.thumbnail || t.urls.original
                    )}
                  />
                )}
                {edit && (
                  <div className="thumbnails">
                    {touchpoint.thumbnails.map((t, i) => (
                      <div className="thumb" key={`${t.id}-${i}`}>
                        <Button
                          text
                          size="small"
                          icon="delete"
                          className="rm"
                          onClick={() => {
                            const tpc = copy(touchpoint);
                            tpc.thumbnails.splice(i, 1);
                            setTouchpoint(tpc);
                          }}
                        />
                        {t.file_name.match(/svg/i) ? (
                          <object data={t.urls.thumbnail || t.urls.original}>
                            &nbsp;
                          </object>
                        ) : (
                          <img
                            src={t.urls.thumbnail || t.urls.original}
                            alt={t.file_name}
                          />
                        )}
                      </div>
                    ))}
                    <Button
                      text
                      bordered
                      icon="plus"
                      className="thumb"
                      onClick={() => setHandler(() => updateThumb)}
                    >
                      Add thumbnails
                    </Button>
                    {edit && touchpoint.thumbnails.length === 0 && (
                      <p className="notice">
                        Add visuals here to demonstrate what the Touchpoint is
                        and how it works / how to use it. You can add up to 10
                        visuals to create a carousel. Supported formats: ‘.png’
                        ‘.jpg’ ‘.svg’
                      </p>
                    )}
                  </div>
                )}
              </div>
            </Split>
          </TouchpointWrap>
        )}
      </Spinner>
      <Modal visible={handler}>
        {handler && (
          <MediaBrowser
            zip={handler.zip}
            title={handler.zip ? 'Upload a zip file' : undefined}
            multi={!handler.zip}
            type={handler.type}
            category="touchpoints"
            onCancel={setHandler}
            onConfirm={handler}
          />
        )}
      </Modal>
    </Wrap>
  );
};
