import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { doGetUser } from '../store/user';

import {
  addColors,
  updateFonts,
  deleteFonts,
  updateColors,
  deleteColors,
  updateStyles,
} from '../svc/brand';

import { Spinner, DropDown, Modal, Button } from '../ui';
import { toast } from '../ui/Toast';

import Collapse from '../components/Collapse';
import FontBrowser from '../components/FontBrowser';
import ColorBrowser from '../components/ColorBrowser';
import SettingsWrap from '../components/SettingsWrap';
import MediaBrowser from '../components/MediaBrowser';

const Divider = styled.div`
  width: 740px;
  height: 1px;
  background-color: #d2d2d2;
  margin: 32px 0;
`;

const Split = styled.div`
  display: flex;
  margin-bottom: 32px;

  & > * {
    &:first-child {
      position: relative;
      width: 400px;
      box-sizing: border-box;
      margin-right: 24px;
    }

    &:second-child {
      flex: 1;
    }
  }

  .bg {
    .rm {
      padding: 0;
      position absolute;
      top: 8px;
      right: -8px;
      z-index: 4;
      display: none;
    }

    .browse {
      background-color: transparent;
      border: 1px solid #000000;
      display: flex;
      width: 100%;
      min-height: 45px;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 12px;
      position: relative;
      span {
        z-index: 3;
      }

      ${({ bg }) =>
        bg &&
        `
      height: 164px;
      span {
        display: none;
      }
      `}

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        transition: all .5s;
      }
    }

    &:hover {
      ${({ bg }) =>
        bg &&
        `
        .browse {
          img {
            opacity: 0.1;
            filter: blur(2px);

            transition: all .5s;
          }
          span { display: inline; }
        }

        .rm { display: inline-block; }
      `}
    }

  }
`;

const select = ({ user }) => user;
const clone = (o) => JSON.parse(JSON.stringify(o));
const stringy = (j) => JSON.stringify(j);

export default () => {
  const dispatch = useDispatch();
  const { user } = useSelector(select);
  const { brand } = user;
  const { colors = [], fonts = [], styles, name, id: brandId } = brand;

  const [browse, setBrowse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coreFonts, setCoreFonts] = useState();
  const [coreColors, setCoreColors] = useState();
  const [coreStyles, setCoreStyles] = useState();

  const save = async () => {
    setLoading(true);
    await Promise.all([
      deleteColors(
        brandId,
        colors
          .filter((ic) => !coreColors.find((c) => ic.id === c.id))
          .map((ic) => ic.id)
      ),

      addColors(
        brandId,
        coreColors.filter((c) => !c.id)
      ),

      updateColors(
        brandId,
        coreColors.filter(
          (c) =>
            c.id &&
            !colors.find((ic) => JSON.stringify(c) === JSON.stringify(ic))
        )
      ),

      updateFonts(
        brandId,
        coreFonts.filter((c) => !fonts.find((ic) => c.url === ic.url))
      ),

      deleteFonts(
        brandId,
        fonts
          .filter((ic) => !coreFonts.find((c) => c.url === ic.url))
          .map((ic) => ic.url)
      ),

      updateStyles(brandId, coreStyles),
    ]);

    dispatch(
      doGetUser(user.id, (err) => {
        if (!err) toast('Success', 'Brand styles has been updated.');
        setLoading(false);
      })
    );
  };

  const reset = () => {
    setCoreColors(clone(colors));
    setCoreFonts(clone(fonts));

    setCoreStyles({
      large_headings: { color: '', font: '' },
      regular_headings: { color: '', font: '' },
      small_headings: { color: '', font: '' },
      body_text: { color: '', font: '' },
      ...clone(styles),
    });
  };

  useEffect(() => {
    if (!coreFonts && !coreColors) {
      reset();
    }
  });

  if (!coreFonts || !coreColors || !coreStyles) return <></>;

  return (
    <SettingsWrap
      showCancel
      showConfirm
      confirmText="SAVE"
      onCancel={(() => {
        if (stringy(coreColors) !== stringy(colors)) return reset;
        if (stringy(coreFonts) !== stringy(fonts)) return reset;
        if (stringy(coreFonts) !== stringy(fonts)) return reset;
        if (
          stringy({
            large_headings: { color: {}, font: {} },
            regular_headings: { color: {}, font: {} },
            small_headings: { color: {}, font: {} },
            body_text: { color: {}, font: {} },
            ...styles,
          }) !== stringy(coreStyles)
        )
          return reset;
      })()}
      onConfirm={(() => {
        if (stringy(coreColors) !== stringy(colors)) return save;
        if (stringy(coreFonts) !== stringy(fonts)) return save;
        if (stringy(coreFonts) !== stringy(fonts)) return save;
        if (
          stringy({
            large_headings: { color: {}, font: {} },
            regular_headings: { color: {}, font: {} },
            small_headings: { color: {}, font: {} },
            body_text: { color: {}, font: {} },
            ...styles,
          }) !== stringy(coreStyles)
        )
          return save;
      })()}
    >
      <Spinner spin={loading}>
        <section>
          <h2>Styles</h2>
          <p className="description">
            Manage your brand’s core colours and fonts here. All changes will be
            updated across BrandBox, including dropdown menus in Edit mode.
          </p>
        </section>
        <main>
          <Collapse title={`${name} core colours`}>
            <ColorBrowser onChange={setCoreColors} colors={coreColors} />
          </Collapse>
          <Collapse title={`${name} core fonts`}>
            <FontBrowser tile onChange={setCoreFonts} fonts={coreFonts} />
          </Collapse>
          <Collapse title="Custom BrandBox interface">
            <p className="description">
              Make BrandBox yours by customising the login screen and the
              colours.
            </p>

            <h5>LOGIN SCREEN BACKGROUND</h5>
            <Split bg={coreStyles.background}>
              <div>
                <div className="bg">
                  <Button
                    className="rm"
                    text
                    icon="cross"
                    onClick={() => {
                      const cs = clone(coreStyles);
                      cs.background = undefined;
                      setCoreStyles(cs);
                    }}
                  />
                  <button
                    type="button"
                    className="browse"
                    onClick={() => setBrowse(true)}
                  >
                    <span>+</span>
                    {coreStyles.background && (
                      <img
                        src={coreStyles.backgroundThumbnail}
                        alt="background"
                        width="100%"
                      />
                    )}
                  </button>
                </div>
                <Modal visible={browse}>
                  <MediaBrowser
                    type="image"
                    category="settings"
                    onCancel={() => setBrowse(false)}
                    onConfirm={(f) => {
                      const cs = clone(coreStyles);
                      cs.background = f.urls.original;
                      cs.backgroundThumbnail = f.urls.thumbnail;
                      setCoreStyles(cs);
                      setBrowse(false);
                    }}
                  />
                </Modal>
              </div>
              <div>
                <p className="tiny-desc">
                  Upload your own background image on the login screen (Max.
                  file size 1MB)
                </p>
              </div>
            </Split>

            <h5>LOGO COLOUR</h5>
            <Split>
              <div>
                <DropDown
                  dropDownType="color"
                  placeholder="System default color"
                  onSelect={(c) => {
                    const cs = { ...coreStyles };
                    cs.logo_color = coreColors.find((cc) => cc.id === c).id;
                    setCoreStyles(cs);
                  }}
                  items={coreColors.map((cc) => ({
                    label: cc.name,
                    color: cc.color,
                    value: cc.id,
                  }))}
                  defaultValue={(() => {
                    return brand.colors.find(
                      (c) =>
                        c.id === coreStyles.logo_color ||
                        c.color === coreStyles.logo_color.color
                    )?.id;
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose your BrandBox icon colour that appears on the top of
                  the screen.
                </p>
              </div>
            </Split>

            <h5>ACTION COLOUR</h5>
            <Split>
              <div>
                <DropDown
                  dropDownType="color"
                  placeholder="System default color"
                  onSelect={(c) => {
                    const cs = { ...coreStyles };
                    cs.action_color = coreColors.find((cc) => cc.id === c).id;
                    setCoreStyles(cs);
                  }}
                  items={coreColors.map((cc) => ({
                    label: cc.name,
                    color: cc.color,
                    value: cc.id,
                  }))}
                  defaultValue={(() => {
                    return brand.colors.find(
                      (c) =>
                        c.id === coreStyles.action_color ||
                        c.color === coreStyles.action_color.color
                    )?.id;
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose a color for hyperlinks, buttons and any other elements
                  that highlight an action.
                </p>
              </div>
            </Split>

            <Divider />

            <p className="description">
              Take your BrandBox a step further by customising the typography
              with your brand’s core colours and fonts.
            </p>
            <h5>LARGE HEADINGS</h5>
            <Split>
              <div>
                <DropDown
                  placeholder="System default font"
                  onSelect={(i) => {
                    const cs = { ...coreStyles };
                    cs.large_headings.font = i;
                    setCoreStyles(cs);
                  }}
                  items={[
                    { label: 'System', value: -1 },
                    ...coreFonts.map((cf) => ({
                      label: cf.name,
                      value: cf.id,
                    })),
                  ]}
                  defaultValue={(() => {
                    return (
                      coreFonts.find(
                        (c) =>
                          c.id === coreStyles.large_headings.font ||
                          c.url === coreStyles.large_headings.font?.url
                      )?.id || -1
                    );
                  })()}
                  block
                />
                <DropDown
                  dropDownType="color"
                  placeholder="System default color"
                  onSelect={(c) => {
                    const cs = { ...coreStyles };
                    cs.large_headings.color = coreColors.find(
                      (cc) => cc.id === c
                    ).id;
                    setCoreStyles(cs);
                  }}
                  items={coreColors.map((cc) => ({
                    label: cc.name,
                    color: cc.color,
                    value: cc.id,
                  }))}
                  defaultValue={(() => {
                    return brand.colors.find(
                      (c) =>
                        c.id === coreStyles.large_headings.color ||
                        c.color === coreStyles.large_headings.color?.color
                    )?.id;
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose a font for your large headings or opt for your brand’s
                  default font. Then choose a colour or use the default colour -
                  black.
                </p>
              </div>
            </Split>

            <h5>REGULAR HEADINGS</h5>
            <Split>
              <div>
                <DropDown
                  placeholder="System default font"
                  onSelect={(i) => {
                    const cs = { ...coreStyles };
                    cs.regular_headings.font = i;
                    setCoreStyles(cs);
                  }}
                  items={[
                    { label: 'System', value: -1 },
                    ...coreFonts.map((cf) => ({
                      label: cf.name,
                      value: cf.id,
                    })),
                  ]}
                  defaultValue={(() => {
                    return (
                      coreFonts.find(
                        (c) =>
                          c.id === coreStyles.regular_headings.font ||
                          c.url === coreStyles.regular_headings.font?.url
                      )?.id || -1
                    );
                  })()}
                  block
                />
                <DropDown
                  dropDownType="color"
                  placeholder="System default color"
                  onSelect={(c) => {
                    const cs = { ...coreStyles };
                    cs.regular_headings.color = coreColors.find(
                      (cc) => cc.id === c
                    ).id;
                    setCoreStyles(cs);
                  }}
                  items={coreColors.map((cc) => ({
                    label: cc.name,
                    color: cc.color,
                    value: cc.id,
                  }))}
                  defaultValue={(() => {
                    return brand.colors.find(
                      (c) =>
                        c.id === coreStyles.regular_headings.color ||
                        c.color === coreStyles.regular_headings.color?.color
                    )?.id;
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose a font for your regular headings or opt for your
                  brand’s default font. Then choose a colour or use the default
                  colour - black.
                </p>
              </div>
            </Split>

            <h5>SMALL HEADINGS</h5>
            <Split>
              <div>
                <DropDown
                  placeholder="System default font"
                  onSelect={(i) => {
                    const cs = { ...coreStyles };
                    cs.small_headings.font = i;
                    setCoreStyles(cs);
                  }}
                  items={[
                    { label: 'System', value: -1 },
                    ...coreFonts.map((cf) => ({
                      label: cf.name,
                      value: cf.id,
                    })),
                  ]}
                  defaultValue={(() => {
                    return (
                      coreFonts.find(
                        (c) =>
                          c.id === coreStyles.small_headings.font ||
                          c.url === coreStyles.small_headings.font?.url
                      )?.id || -1
                    );
                  })()}
                  block
                />
                <DropDown
                  dropDownType="color"
                  placeholder="System default color"
                  onSelect={(c) => {
                    const cs = { ...coreStyles };
                    cs.small_headings.color = coreColors.find(
                      (cc) => cc.id === c
                    ).id;
                    setCoreStyles(cs);
                  }}
                  items={coreColors.map((cc) => ({
                    label: cc.name,
                    color: cc.color,
                    value: cc.id,
                  }))}
                  defaultValue={(() => {
                    return brand.colors.find(
                      (c) =>
                        c.id === coreStyles.small_headings.color ||
                        c.color === coreStyles.small_headings.color?.color
                    )?.id;
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose a font for your small headings or opt for your brand’s
                  default font. We recommend using the default font and colour
                  to ensure text remains clean and legible at smaller sizes.
                </p>
              </div>
            </Split>

            <h5>BODY TEXT</h5>
            <Split>
              <div>
                <DropDown
                  placeholder="System default font"
                  onSelect={(i) => {
                    const cs = { ...coreStyles };
                    cs.body_text.font = i;
                    setCoreStyles(cs);
                  }}
                  items={[
                    { label: 'System', value: -1 },
                    ...coreFonts.map((cf) => ({
                      label: cf.name,
                      value: cf.id,
                    })),
                  ]}
                  defaultValue={(() => {
                    return (
                      coreFonts.find(
                        (c) =>
                          c.id === coreStyles.body_text.font ||
                          c.url === coreStyles.body_text.font?.url
                      )?.id || -1
                    );
                  })()}
                  block
                />
              </div>
              <div>
                <p className="tiny-desc">
                  Choose a font for your body text or opt for your brand’s
                  default font. We recommend using the default font to ensure
                  text remains clean and legible at smaller sizes.
                </p>
              </div>
            </Split>
          </Collapse>
        </main>
      </Spinner>
    </SettingsWrap>
  );
};
