import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Input } from '../ui';

const Tag = styled.span`
  font-size: 12px;
  display: inline-block;
  border-radius: 50px;
  background-color: #d2d2d2;
  color: #ffffff;
  padding: 3px 10px;
  margin-left: 4px;
  margin-bottom: 8px;
  &:hover {
    background-color: #999999;
  }

  strong {
    cursor: pointer;
    svg {
      fill: #ffffff;
      width: 8px;
      height: 8px;
      padding: 0;
      margin: 0;
    }
  }
`;

export default ({ tags, onChange = () => {} }) => {
  const [ltags, setTags] = useState(tags);

  const delTag = (idx) => {
    const otags = [...ltags];
    otags.splice(idx, 1);
    setTags(otags);
    onChange(otags);
  };

  const add = (e) => {
    if (e.key === 'Enter') {
      // check if empty
      if (!e.target.value.trim()) return;

      const tag = e.target.value.trim();
      // no duplicate please!
      if (ltags.find((t) => t === tag)) return;
      // otherwise, process it
      const ntags = [tag, ...ltags];
      setTags(ntags);
      onChange(ntags);

      e.target.value = '';
      e.target.focus();
    }
  };

  return (
    <div>
      <Input
        size="small"
        placeholder="Input a tag and hit Enter"
        block
        onKeyUp={add}
      />
      <div>
        {ltags.map((t, i) => (
          <Tag key={t}>
            {t.length > 18 ? `${t.slice(0, 18)}...` : t}{' '}
            <strong onClick={() => delTag(i)}>
              &nbsp;
              <Icon name="cross" />
            </strong>
          </Tag>
        ))}
      </div>
    </div>
  );
};
