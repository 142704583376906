import React, { useState } from 'react';
import styled from 'styled-components';

import {
  AiOutlineFilePdf,
  AiOutlineFileText,
  AiOutlineSound,
} from 'react-icons/ai';

import { FileSize, Spinner, Icon } from '../ui';
import SettingsFooter from './editor/SettingsFooter';
import { deleteFile } from '../svc/brand';

const Wrap = styled.div`
  display: flex;
  position: relative;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 64px;

  p {
    margin: 0;
    margin-bottom: 2px;
  }

  .title {
    font-size: 15px;
  }
  .sub {
    opacity: 1;
    font-size: 12px;
  }
  .mute {
    color: #444444;
  }

  & > div:not(:last-child) {
    margin-right: 24px;
  }

  & > .f1 {
    flex: 1;
  }

  & > .f2 {
    flex: 2;
  }
  & > .f3 {
    flex: 3;
  }

  & > .thumb {
    background-color: #f0f0f0;
    height: 80px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 32px;
      height: 32px;
      fill: rgba(0, 0, 0, 0.3);
    }
    object {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Error = styled.p`
  position: absolute;
  bottom: -28px;
  left: 0;
  color: red;
`;

export default ({ file, onCancel = () => {}, onDelete = () => {} }) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const ctype = (() => {
    if (file.file_name.match(/svg/i)) return 'svg';
    if (file.file_type.match(/image/i)) return 'image';
    if (file.file_type.match(/video/i)) return 'video';
    if (file.file_type.match(/audio/i)) return 'audio';
    if (
      file.file_type.match(/octet/i) &&
      file.file_name.match(/(mov|mp4|avi|flv|webm|ogg)/i)
    )
      return 'video';
    if (file.file_type.match(/txt/i) || file.file_name.match(/\.txt/i))
      return 'txt';
    if (file.file_type.match(/pdf/i)) return 'pdf';
    return '';
  })();

  const remove = async () => {
    setLoading(true);
    setError(undefined);

    const { error: err } = await deleteFile(file.brand_id, file.id);
    if (err) {
      setError(err);
    } else {
      onDelete(file.id);
    }
    setLoading(false);
  };

  return (
    <Spinner spin={loading}>
      <h3 style={{ fontWeight: 'normal', overflowWrap: 'break-word' }}>
        Delete {file.file_name}?
      </h3>
      <p style={{ fontWeight: '300', fontSize: 12, color: '#444444' }}>
        This file will be permanently deleted from your BrandBox
      </p>
      <Wrap>
        <div className="f1 thumb">
          {(() => {
            if (ctype === 'image')
              return <img src={file.urls.original} alt={file.file_name} />;
            if (ctype === 'pdf') return <AiOutlineFilePdf className="icon" />;
            if (ctype === 'font')
              return <Icon className="icon" name="typography" />;
            if (ctype === 'video')
              return (
                <video controls>
                  <source src={`${file.urls.original}#t=10`} />
                </video>
              );
            if (ctype === 'audio') return <AiOutlineSound className="icon" />;
            if (ctype === 'svg')
              return (
                <object data={file.urls.original}>{file.file_name}</object>
              );
            return <AiOutlineFileText className="icon" />;
          })()}
        </div>
        <div className="f2">
          <p className="sub">
            <span className="mute">File type:</span> {file.file_type}
          </p>
          <p className="sub">
            <span className="mute">File size:</span>{' '}
            <FileSize size={file.file_size} />
          </p>
        </div>
      </Wrap>
      <SettingsFooter
        showCancel
        bordered
        showConfirm
        onCancel={onCancel}
        onConfirm={remove}
      />
      {error && (
        <Error className="error">Update failed! Please try again.</Error>
      )}
    </Spinner>
  );
};
