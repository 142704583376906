import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { DropDown, TinySwitch } from '../../ui';
import { updateItem } from '../../store/page';

const Ctl = styled.div`
  box-sizing: border-box;
`;

const CropDropDown = styled(DropDown)`
  margin-bottom: 12px;
`;

const SwitchWrapper = styled.div`
  display: flex;
`;

const SwitchLabel = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.08px;
  color: ${({ checked }) => (checked ? '#000000' : '#999999')};
  margin-left: 40px;
`;

const UISwitch = styled(TinySwitch)`
  margin-top: 2px;
`;

const selectCrop = [
  { label: 'No crop', value: 0 },
  { label: '1:1 (square)', value: 1 },
  { label: '16:9', value: 2 },
  { label: '4:3', value: 3 },
];

export default (attrs) => {
  const { props } = attrs;
  const { crop, stroke } = props;
  const dispatch = useDispatch();

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onSelectCrop = (value) => {
    update({
      crop: value,
    });
  };

  const onSelectStroke = (value) => {
    update({
      stroke: value,
    });
  };
  return (
    <Ctl>
      <section>
        <h3>Image options</h3>
        <p>Choose the image crop size and apply an outline around the image</p>
      </section>

      <CropDropDown
        items={selectCrop}
        onSelect={onSelectCrop}
        defaultValue={selectCrop[crop]?.value || 0}
        dropDownType="text"
        disabled={false}
        block
        size="small"
      />

      <SwitchWrapper>
        <UISwitch checked={stroke} name="stroke" onChange={onSelectStroke} />
        <SwitchLabel checked={stroke}>Add outline around image</SwitchLabel>
      </SwitchWrapper>
    </Ctl>
  );
};
