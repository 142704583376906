export const colors = {
  default: {
    color: '#FFFFFF',
    backgroundColor: '#000000',

    hover: {
      color: '#FFFFFF',
      backgroundColor: '#222222',
    },

    active: {
      color: '#FFFFFF',
      backgroundColor: '#222222',
    },

    disabled: {
      color: '#FFFFFF',
      backgroundColor: '#b3b3b3',
    },
  },

  primary: {
    color: '#FFFFFF',
    backgroundColor: '#0000FF',

    hover: {
      color: '#FFFFFF',
      backgroundColor: '#3333FF',
    },

    active: {
      color: '#FFFFFF',
      backgroundColor: '#3333FF',
    },

    disabled: {
      color: '#CCCCFF',
      backgroundColor: '#AAAAFF',
    },
  },

  success: {
    color: '#FFFFFF',
    backgroundColor: '#00AA00',

    hover: {
      color: '#FFFFFF',
      backgroundColor: '#11BB11',
    },

    active: {
      color: '#FFFFFF',
      backgroundColor: '#11BB11',
    },

    disabled: {
      color: '#CCCCFF',
      backgroundColor: '#63c363',
    },
  },

  danger: {
    color: '#FFFFFF',
    backgroundColor: '#FF0000',

    hover: {
      color: '#DDDDDD',
      backgroundColor: '#FF3333',
    },

    active: {
      color: '#DDDDDD',
      backgroundColor: '#FF0000',
    },

    disabled: {
      color: '#FFCCCC',
      backgroundColor: '#FFAAAA',
    },
  },

  warning: {
    color: '#FFFFFF',
    backgroundColor: '#FF5E00',

    hover: {
      color: '#DDDDDD',
      backgroundColor: '#FF6810',
    },

    active: {
      color: '#DDDDDD',
      backgroundColor: '#FF6810',
    },

    disabled: {
      color: '#FBE3D6',
      backgroundColor: '#FFBD98',
    },
  },

  text: {
    color: '#000000',
    backgroundColor: 'transparent',

    hover: {
      color: '#222222',
      backgroundColor: 'transparent',
    },

    active: {
      color: '#222222',
      backgroundColor: 'transparent',
    },

    disabled: {
      color: '#CCCCCC',
      backgroundColor: 'transparent',
    },
  },

  input: {
    color: '#000000',
    backgroundColor: '#CCCCCC',

    hover: {
      color: '#000000',
      backgroundColor: '#999999',
    },

    active: {
      color: '#000000',
      backgroundColor: '#000000',
    },

    disabled: {
      color: '#CCCCCC',
      backgroundColor: '#AAAAAA',
    },
  },

  card: {
    color: '#000000',
    borderColor: '#f0f0f0',
    borderHoverColor: '#CCCCCC',
    backgroundColor: '#f8f8f8',
  },
};

// gets the type from props
const getType = (props) => {
  if (props.info) return 'info';
  if (props.text) return 'text';
  if (props.danger) return 'danger';
  if (props.warning) return 'warning';
  if (props.primary) return 'primary';
  if (props.success) return 'success';
  return 'default';
};

// gets the color based on passed params
export const getColorFromProps = ({ props, isFg, state, input, front }) => {
  let type = getType(props);
  // input fields hack
  if (type === 'default' && input) type = 'input';
  // layer 1 is foreground, other values will be background
  if (isFg || (type === 'input' && front))
    if (!state) return colors[type].color;
    else return colors[type][state].color;
  if (!state) return colors[type].backgroundColor;
  return colors[type][state].backgroundColor;
};

export const getRole = {
  owner: 'Owner',
  admin: 'Admin',
  user: 'Reg. user',
  pending: 'Pending',
};
