import React from 'react';
import styled from 'styled-components';

const Progress = styled.div`
  width: 100%;
  position: relative;
`;

const Bg = styled.div`
  width: 100%;
  height: 3px;
  background-color: #e3edf9;
`;

const Label = styled.p`
  color: ${({ color }) => (color && color) || '#000000'};
  font-size: 15px;
  margin-bottom: 5px;
`;

const Fg = styled.div`
  width: ${({ progress }) => {
    if (Number(progress)) {
      return `${progress}%`;
    }
    return '100%';
  }};
  height: 3px;
  background-color: ${({ color }) => (color && color) || '#000000'};
`;

export default (props) => {
  const { label, progress, color } = props;
  return (
    <Progress {...props}>
      {label && <Label color={color}>{label}</Label>}
      <Bg>
        <Fg progress={progress} color={color} />
      </Bg>
    </Progress>
  );
};
