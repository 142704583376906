import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { updateUser, deleteUser } from '../svc/user';
import { sendVerification } from '../svc/auth';

import { Spinner, Button, Icon, Input, TinySwitch } from '../ui';
import { toast } from '../ui/Toast';
import SettingsFooter from './editor/SettingsFooter';

const Account = styled.div`
  position: relative;
  width: 586px;
  ${({ p }) =>
    p === 2 &&
    `
    width: 400px;
  `}

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  p svg {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }

  p.role {
    margin-bottom: 25px;
    border-bottom: 1px solid #d2d2d2;
    padding: 12px 0;
  }

  hr {
    border: 0;
    outline: 0;
    height: 1px;
    background-color: #d2d2d2;
    margin: 20px auto;
  }

  .banner {
    text-align: center;
    background-color: #f8f8f8;
    padding: 20px;
    margin-top: -26px;
    margin-bottom: 32px;

    p {
      font-size: 12px;
      color: #000000;
      margin-bottom: 12px;
      line-height: 1.3em;

      &:last-child {
        font-weight: 300;
        color: #444444;
      }
    }
  }

  section {
    margin-bottom: 32px;

    .split {
      display: flex;
      & > div {
        flex: 1;

        &:last-child {
          p {
            margin-bottom: 0;
            font-size: 12px;
            color: #999999;
            font-weight: 300;
            padding-left: 20px;
          }
        }
      }
    }
  }

  .del {
    text-align: center;
    padding-top: 20px;

    p {
      font-size: 15px;
      color: #000000;
      font-weight: normal;
      margin-bottom: 12px;

      &:last-child {
        font-size: 12px;
        color: #444444;
        font-weight: 300;
      }
    }
  }
`;

const validPwd = (pwd) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
    pwd
  );
};

export default ({
  onCancel = () => {},
  onUpdate = () => {},
  onDelete = () => {},
}) => {
  const { user } = useSelector(({ user: u }) => u);

  const [p, setP] = useState();
  const [cred, setCred] = useState({});
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    newletter: user.newletter,
  });

  const currentUser = user?.brand?.users.find((i) => i.id === user.id);

  const update = async () => {
    setLoading(true);
    const { error } = await updateUser(user.id, profile);
    if (error) toast.error('Error', 'Could not update your profile');
    else {
      const send = !user?.last_name; // eslint-disable-line
      if (send) await sendVerification({ email: user.email });
      onUpdate(profile, send);
    }
    setLoading(false);
  };

  const resend = async () => {
    setLoading(true);
    await sendVerification({ email: user.email }); // eslint-disable-line
    onUpdate({}, true);
    setLoading(false);
  };

  const del = async () => {
    setLoading(true);
    const { error } = await deleteUser(user.id);
    if (error) toast.error('Error', 'Could not delete your ccount');
    else onDelete();
    setLoading(false);
  };

  const changePwd = async () => {
    setLoading(true);
    const { error } = await updateUser(user.id, cred);
    if (error) toast.error('Error', 'Could not update your password');
    else onUpdate(1);
    setLoading(false);
  };

  return (
    <Account p={p}>
      <Spinner spin={loading}>
        {p === 2 && (
          <>
            <main>
              <section className="del">
                <p>Are you sure you want to delete you account?</p>
                <p>
                  Deleting your account will also delete all your uploaded
                  files.
                </p>
              </section>
            </main>
          </>
        )}
        {p === 1 && (
          <>
            <header>
              <h3>Change your password</h3>
            </header>
            <main>
              <section className="password">
                <div className="split">
                  <div>
                    <Input
                      name="old_password"
                      size="small"
                      type="password"
                      toggle
                      block
                      placeholder="Enter old password"
                      onChange={(e) => {
                        setCred({
                          ...cred,
                          old_password: e.target.value.trim(),
                        });
                      }}
                    />
                  </div>
                  <div>&nbsp;</div>
                </div>

                <br />

                <div className="split">
                  <div>
                    <Input
                      name="password"
                      size="small"
                      type="password"
                      toggle
                      block
                      placeholder="New password"
                      error={cred.password && !validPwd(cred.password)}
                      disabled={!cred.old_password}
                      onChange={(e) => {
                        setCred({
                          ...cred,
                          password: e.target.value.trim(),
                        });
                      }}
                    />
                    {cred.old_password &&
                      cred.password &&
                      cred.password === cred.old_password && (
                        <p className="error" style={{ marginBottom: 0 }}>
                          New and old password must not match.
                        </p>
                      )}
                    <Input
                      name="confirm_password"
                      size="small"
                      type="password"
                      toggle
                      block
                      placeholder="Confirm new password"
                      disabled={!validPwd(cred.password)}
                      error={
                        cred.confirm_password &&
                        cred.password !== cred.confirm_password
                      }
                      onChange={(e) => {
                        setCred({
                          ...cred,
                          confirm_password: e.target.value.trim(),
                        });
                      }}
                    />
                  </div>
                  <div>
                    <p>
                      At least 8 characters long and have at least one upper
                      case letter, a number and a special character.
                    </p>
                  </div>
                </div>
              </section>
            </main>
          </>
        )}
        {!p && (
          <>
            <header>
              <h3>Your account</h3>
            </header>
            <main>
              <p className="role">
                <Icon
                  name="user-circle"
                  className={`brand-${user.brand.role}`}
                />{' '}
                <span>{user.first_name}</span>
                <span className={`brand-${user.brand.role}`}>
                  {' '}
                  (
                  {user.brand.role
                    .split('')
                    .map((s, i) => (i === 0 ? s.toUpperCase() : s))
                    .join('')}
                  ){' '}
                </span>
              </p>

              {!user.verified && (
                <div className="banner">
                  <p>
                    <Icon
                      name="alert-circle"
                      className={`brand-${user.brand.role}`}
                    />{' '}
                    {user.last_name
                      ? 'Account verification pending'
                      : 'Verify your account now'}
                  </p>
                  <p>
                    {user.last_name
                      ? `An email has been sent with instructions to complete verification and access all features.
                        If you have not received the email, please check your details and click ‘Re-send instructions’.
                      `
                      : `Once you have completed and submitted the fields below, you will receive an
                        email with simple instructions to complete verification and access all features.
                      `}
                  </p>
                </div>
              )}

              <section className="profile">
                <div className="split">
                  <div>
                    <Input
                      name="first_name"
                      size="small"
                      block
                      placeholder="Name"
                      defaultValue={user.first_name}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          first_name: e.target.value.trim(),
                        });
                      }}
                    />
                  </div>
                  <div>&nbsp;</div>
                </div>

                <div className="split">
                  <div>
                    <Input
                      name="last_name"
                      size="small"
                      block
                      placeholder="Surname"
                      defaultValue={user.last_name}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          last_name: e.target.value.trim(),
                        });
                      }}
                    />
                  </div>
                  <div>
                    {!user.verifieda && !user.last_name && (
                      <p>All fields must be filled in </p>
                    )}
                  </div>
                </div>

                <div className="split">
                  <div>
                    <Input
                      name="email"
                      size="small"
                      block
                      disabled
                      placeholder="Email"
                      defaultValue={user.email}
                    />
                  </div>
                  <div>
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div className="split">
                  <div>
                    <TinySwitch
                      label="Yes, I’d like to receive BrandBox newsletters"
                      checked={user.newsletter}
                      onChange={(v) => {
                        setProfile({
                          ...profile,
                          newsletter: v,
                        });
                      }}
                    />{' '}
                  </div>
                  <div>
                    <p>&nbsp;</p>
                  </div>
                </div>

                {user.verified && (
                  <>
                    <hr />
                    <div className="split">
                      <div>
                        <Button
                          text
                          size="small"
                          icon="lock"
                          onClick={() => setP(1)}
                        >
                          Change password
                        </Button>
                        <br />

                        {currentUser?.role === 'owner' ? (
                          <Button
                            text
                            size="small"
                            icon="delete"
                            onClick={() => setP(2)}
                          >
                            Delete account
                          </Button>
                        ) : null}
                      </div>
                      <div>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </>
                )}
              </section>
            </main>
          </>
        )}
        <SettingsFooter
          bordered={p !== 2}
          showCancel
          showConfirm
          confirmText={(() => {
            if (!user.last_name && !p) return 'VERIFY ACCOUNT';
            if (user.last_name && !user.verified) return 'RE-SEND INSTRUCTIONS';
            if (!p) return 'UPDATE ACCOUNT';
            if (p === 1) return 'UPDATE PASSWORD';
          })()}
          onCancel={p ? setP : onCancel}
          onConfirm={(() => {
            if (user.last_name && !user.verified) return resend;
            if (p === 1) {
              if (cred.password === cred.old_password) return undefined;
              return cred.old_password &&
                cred.password === cred.confirm_password
                ? changePwd
                : undefined;
            }
            if (p === 2) return del;
            return profile.first_name === user.first_name &&
              profile.last_name === user.last_name &&
              Boolean(profile.newsletter) === Boolean(user.newsletter)
              ? undefined
              : update;
          })()}
        />
      </Spinner>
    </Account>
  );
};
