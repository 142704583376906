import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, Modal, DropDown, TinySwitch, Input } from '../../ui';
import { updateItem } from '../../store/page';
import MediaBrowser from '../MediaBrowser';

const DlButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  height: 30px;
  align-items: center;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  text-overflow: ellipsis;
  svg {
    right: 13px;
    fill: #6d6d6d;
  }
`;

const Ctl = styled.div`
  box-sizing: border-box;

  .add {
    padding: 0;
    margin-top: 12px;
    font-size: 12px;
    font-weight: normal;
  }

  .img {
    display: block;
    width: 100%;
    height: 110px;
    border-radius: 0;
    border: 1px solid #000000;
    color: ${({ bg }) => (bg ? '#FFFFFFAA' : '#000000')};
    font-size: 30px;
    ${({ bg }) =>
      bg &&
      `
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${bg}) center center / cover;
    `}
  }
  .close {
    position: relative;
    top: -110px;
    left: 219px;
    & svg {
      fill: #ffffffaa;
    }
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UISwitch = styled(TinySwitch)`
  margin-top: 2px;
  span.label {
    color: #999999;
  }
`;

const TextSelect = styled(DropDown)`
  margin-bottom 12px;
`;

const selectTextSize = [
  { label: 'XL body text', value: 22 },
  { label: 'Large body text', value: 15 },
  { label: 'Disabled', value: 0 },
];

const selectHeadingSize = [
  { label: 'XXL heading', value: 1 },
  { label: 'XL heading', value: 2 },
  { label: 'Large heading', value: 3 },
  { label: 'Disabled', value: 0 },
];

const headingSize = [0, 42, 35, 28];

const selectButtonType = [
  { label: 'Button disabled', value: 0 },
  { label: 'Go to page', value: 'arrow-right' },
  { label: 'Download file', value: 'download' },
];

export default (attrs) => {
  const { props } = attrs;
  const { size = 15, heading, button, welcome = true, bg: bgi } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState();
  const [bg, setBg] = useState(false);

  const update = (newProps) => {
    dispatch(
      updateItem({
        ...attrs,
        props: newProps,
      })
    );
  };

  const onTextSize = (value) => {
    update({
      size: value,
    });
  };

  const onHeadingSize = (value) => {
    if (value === 0)
      return update({
        heading: undefined,
      });

    update({
      heading: {
        text: 'Title', // placeholder
        ...heading,
        level: value,
        size: headingSize[value],
      },
    });
  };

  const onSelectButtonType = (value) => {
    if (value === 0)
      return update({
        button: undefined,
      });

    update({
      button: {
        type: value,
        text: '',
        url: '',
      },
    });
  };

  const onButtonChange = (value, key) => {
    update({
      button: {
        ...button,
        [key]: value,
      },
    });
  };

  const onBgChange = (value) => {
    update({
      bg: value,
    });
  };

  const updateColor = (o) => {
    update({
      welcome: {
        ...welcome,
        ...o,
      },
    });
  };

  const removeBg = () => {
    update({
      bg: undefined,
    });
  };

  return (
    <Ctl bg={bgi}>
      <section>
        <h3>Background</h3>
        <p>
          Choose a background image. This works best with a 1 column layout.
        </p>
        <button className="img" type="button" onClick={() => setBg(true)}>
          +
        </button>
        {bgi && (
          <Button text icon="cross" className="close" onClick={removeBg} />
        )}
      </section>

      <section>
        <h3>Text options</h3>
        <p>
          Toggle heading &amp; body text on/off + choose a size. Consider making
          them white if you choose a dark background for this block.
        </p>
        <TextSelect
          items={selectHeadingSize}
          onSelect={onHeadingSize}
          defaultValue={heading?.level || 0}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        {heading && (
          <SwitchWrapper>
            <UISwitch
              name="headsw"
              checked={welcome.invertHeading}
              onChange={(hval) => updateColor({ invertHeading: hval })}
              label="Make heading white"
            />
          </SwitchWrapper>
        )}
      </section>

      <section>
        <h3>Body Text Size</h3>
        <TextSelect
          items={selectTextSize}
          onSelect={onTextSize}
          defaultValue={size}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />

        {size !== 0 && (
          <SwitchWrapper>
            <UISwitch
              name="bodysw"
              checked={welcome.invertText}
              onChange={(tval) => updateColor({ invertText: tval })}
              label="Make body text white"
            />
          </SwitchWrapper>
        )}
      </section>

      <section>
        <h3>Button</h3>
        <p>Enable action button and add a link if necessary</p>
        <TextSelect
          items={selectButtonType}
          onSelect={onSelectButtonType}
          defaultValue={button?.type || 0}
          dropDownType="text"
          disabled={false}
          block
          size="small"
        />
        {button && button.type === 'arrow-right' && (
          <Input
            block
            placeholder="E.g https://example.com"
            error={
              button.url &&
              !button.url.match(
                /^((ftp|http|https):\/\/)?([A-z]+)\.([A-z]{2,})/
              )
            }
            size="small"
            value={button.url}
            onChange={(e) => onButtonChange(e.target.value, 'url')}
          />
        )}
        {button && button.url && button.type === 'download' && (
          <DlButton
            className="add"
            onClick={() => setOpen(true)}
            text
            block
            size="small"
            iconRight="add"
          >
            <span
              style={{
                textOverflow: 'ellipsis',
                display: 'inline-block',
                width: '90%',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {button.url.split('/').pop()}
            </span>
          </DlButton>
        )}
        {button && !button.url && button.type === 'download' && (
          <Button
            className="add"
            onClick={() => setOpen(true)}
            text
            size="small"
            icon="add"
          >
            Select file
          </Button>
        )}
        <Modal visible={open || bg}>
          <MediaBrowser
            pub
            type="image"
            category="basics"
            onCancel={() => {
              setOpen(false);
              setBg(false);
            }}
            onConfirm={(f) => {
              if (open) onButtonChange(f.urls.original, 'url');
              else if (bg) onBgChange(f.urls.original);
              setOpen(false);
              setBg(false);
            }}
          />
        </Modal>
      </section>
    </Ctl>
  );
};
