import { createSlice } from '@reduxjs/toolkit';

import {
  addUser,
  getBrand,
  getUsers,
  userRequest,
  activateUser,
  updateColors,
  deleteColors,
  deleteBrandUser,
  getUserRequest,
  deleteUserRequest,
} from '../svc/brand';

import { download } from '../svc/client';

export const brandSlice = createSlice({
  name: 'brand',

  initialState: {},

  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },

    setUsers: (state, { payload }) => {
      state.brandUsers = payload;
    },

    setRequestedUsers: (state, { payload }) => {
      state.brandRequestedUsers = payload;
    },

    setStyles: (state, { payload }) => {
      state.styles = payload;
    },

    removeBrandUsers: (state, { payload }) => {
      const newUsers = [...state.brandUsers];
      newUsers.splice(payload, 1);
      state.brandUsers = newUsers;
    },

    removeBrandRequestUser: (state, { payload }) => {
      const newUsers = [...state.brandRequestedUsers];
      newUsers.splice(payload, 1);
      state.brandRequestedUsers = newUsers;
    },
  },
});

export const {
  setError,
  setUsers,
  setStyles,
  removeBrandUsers,
  setRequestedUsers,
  removeBrandRequestUser,
} = brandSlice.actions;

export const doGetBrand =
  (subdomain, cb = () => {}, shouldRetry) =>
  async () => {
    const { data, error } = await getBrand(subdomain, shouldRetry);
    cb(error, data);
  };

export const doUpdateColors = (id, payload) => async (dispatch) => {
  const { error } = await updateColors(id, payload);
  if (error) {
    dispatch(setError(error.message));
  }
};

export const doDeleteColors = (id, payload) => async (dispatch) => {
  const { error } = await deleteColors(id, payload);
  if (error) {
    dispatch(setError(error.message));
  }
};

export const doActivateUser =
  (bid, payload, cb = () => {}) =>
  async () => {
    const { error, data } = await activateUser(bid, payload);
    cb(error, data);
  };

export const doGetUsers = (id) => async (dispatch) => {
  const { data, error } = await getUsers(id);
  const { data: d } = await getUserRequest(id);

  if (error) {
    dispatch(setError(error.message));
    return;
  }
  dispatch(setUsers(data));
  dispatch(setRequestedUsers(d));
};

export const doDeleteBrandUser =
  (id, uid, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await deleteBrandUser(id, uid);
    dispatch(setError(error ? error.message : undefined));
    cb(error, data);
  };

export const doSendRequest =
  (bid, payload, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await userRequest(bid, payload);
    dispatch(setError(error ? error.message : undefined));
    cb(error, data);
  };

export const doAddUsers =
  (id, users, cb = () => {}) =>
  async () => {
    let error;
    const responses = await Promise.all(users.map((p) => addUser(id, p)));
    const errMessage = responses
      .filter((r) => r.error)
      .map((r) => r.error)
      .join(', ');
    if (errMessage.trim().match(/assoc/i))
      error = new Error(
        'One or more users are already associated with a brand'
      );
    else
      error = new Error('One or more users could not be added due to an error');

    // only pass successful response
    cb(
      error,
      responses.filter((r) => !r.error).map((r) => r.data)
    );
  };

export const updateStyles =
  (styles, cb = () => {}) =>
  async (dispatch) => {
    if (!styles) return;

    const sl = await Promise.all(
      ['large_headings', 'regular_headings', 'small_headings', 'body_text'].map(
        async (k) => {
          const o = {
            weight: 'normal',
            style: 'normal',
            color: '#000000',
            name: styles[k]?.font?.name?.split('.')[0],
          };

          // extract numeric char
          const variant = styles[k]?.font?.variant; // eslint-disable-line
          if (variant) {
            const w = variant.toString().replace(/[^0-9]/g, '');
            if (w.match(/[0-9]{2,}/)) o.weight = w;
            // extract alpha char
            const s = variant.toString().replace(/[^a-z]/gi, '');
            if (s.match(/[a-z]{2,}/i))
              o.style = s.replace(/regular/i, 'normal');
          }

          // if we has colors and shit
          // eslint-disable-next-line
          if (styles[k]?.color?.color) o.color = styles[k].color.color;

          // if we has fonts and sheyyyt
          // eslint-disable-next-line
          if (styles[k]?.font?.url) {
            let url = styles[k].font.url.replace('http:', 'https:');
            if (url.match(/googleapis/i))
              url = '/file?url=' + window.encodeURIComponent(url); // eslint-disable-line

            const { status, data } = await download(url);
            if (status !== 200) return;

            o.font = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(data);
            });
          }

          return o;
        }
      )
    );
    dispatch(setStyles(sl));
    cb(sl);
  };

export const doDeleteRequest =
  (id, email, cb = () => {}) =>
  async (dispatch) => {
    const { data, error } = await deleteUserRequest(id, { email });
    dispatch(setError(error));
    cb(error, data);
  };

export default brandSlice.reducer;
