import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Switch = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: ${({ size, height }) => height || (size === 'small' ? 30 : 45)}px;
  width: ${({ block, width }) =>
    block ? '100%' : `${Math.max(width, 200)}px`};
  background-color: #f0f0f0;
  border-radius: 3px;

  p {
    flex: 1;
    font-size: ${({ size }) => (size === 'small' ? 12 : 15)}px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 1;
    &.disabled {
      color: ${({ checked }) => (checked ? '#999999' : '#000000')};
    }
    &.enabled {
      color: ${({ checked }) => (checked ? '#000000' : '#999999')};
    }
  }
`;

const Knob = styled.div`
  background-color: #ffffff;
  width: 50%;
  height: 100%;
  position: absolute;
  ${({ checked }) =>
    checked &&
    `
  right: 0;
  `}
  box-sizing: border-box;
  border: ${({ size }) => (size === 'small' ? 3 : 5)}px solid #f0f0f0;
  border-radius: 2px;
  margin-bottom: 0 !important;
`;

export default ({
  size,
  width = 320,
  block,
  height,
  onChange,
  leftText = 'Disabled',
  rightText = 'Enabled',
  className,
  defaultValue,
}) => {
  const [checked, setChecked] = useState();

  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const val = !checked;
    setChecked(val);

    if (onChange) onChange(val);
  };

  useEffect(() => {
    if (defaultValue === undefined) return;
    setChecked(defaultValue);
  }, [defaultValue]); // eslint-disable-line

  return (
    <Switch
      checked={checked}
      size={size}
      block={block}
      width={width}
      height={height}
      className={className}
      onClick={toggle}
    >
      <Knob checked={checked} size={size} />
      <p className="disabled">{leftText}</p>
      <p className="enabled">{rightText}</p>
    </Switch>
  );
};
