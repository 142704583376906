import React, { useState } from 'react';
import styled from 'styled-components';

import { updateFonts, deleteFonts } from '../svc/brand';

import FontBrowser from './FontBrowser';
import { Spinner } from '../ui';

import SettingsFooter from './editor/SettingsFooter';

const Wrap = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 600px;

  .desc {
    width: 484px;
    margin-bottom: 24px;

    p {
      color: #444444;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 12px;
    }
  }

  main {
    margin-bottom: 32px;
    max-height: ${window.innerHeight - 252}px;
    padding: 2px;
  }
`;

export default ({
  fonts: initialFonts,
  onCancel = () => {},
  onConfirm = () => {},
  brandId,
}) => {
  const [fonts, setFonts] = useState([...initialFonts]);
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    await Promise.all([
      updateFonts(
        brandId,
        fonts.filter((c) => !initialFonts.find((ic) => c.url === ic.url))
      ),

      deleteFonts(
        brandId,
        initialFonts
          .filter((ic) => !fonts.find((c) => c.url === ic.url))
          .map((ic) => ic.url)
      ),
    ]);

    onConfirm(fonts);
    setLoading(false);
  };

  return (
    <Wrap>
      <Spinner spin={loading}>
        <main className="frame">
          <h3>Add fonts</h3>
          <div className="desc">
            <p>
              Add new fonts to your typography set. These fonts will be
              available to use throughout your BrandBox.
            </p>
            <p>
              Note: You can update the fonts anytime in Settings {'>'} Styles
            </p>
          </div>

          <FontBrowser fonts={fonts} onChange={setFonts} />
        </main>

        <SettingsFooter
          bordered
          showCancel
          showConfirm
          onCancel={onCancel}
          onConfirm={(() => {
            if (
              initialFonts.filter((ic) => !fonts.find((c) => c.url === ic.url))
                .length === 0 &&
              fonts.filter((c) => !initialFonts.find((ic) => c.url === ic.url))
                .length === 0
            )
              return undefined;
            return confirm;
          })()}
        />
      </Spinner>
    </Wrap>
  );
};
