/**
 * Base Component is a higher order component that abstracts redux store
 * interaction from the main component.
 *
 * Instead of having each component interact with the redux store, the base
 * comp will do that instead and just pass in the details to the main comp.
 *
 * Properties:
 * @type String Type of component to be render
 * @props Object Component properties
 */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  move,
  insertRow,
  updateItem,
  setSelected,
  setDragTarget,
  setDropTarget,
  insertSection,
  toggleEditMode,
  removeComponent,
  duplicateComponent,
} from '../../store/page';

import Row from './Row'; // eslint-disable-line
import Text from './Text';
import Color from './Color';
import Image from './Image';
import Section from './Section'; // eslint-disable-line
import Typography from './Typography';
import Divider from './Divider';
import Gradiant from './Gradiant';
import LogoSpacing from './LogoSpacing';
import LogoMinSize from './LogoMinSize';
import Video from './Video';
import Audio from './Audio';

import Insert from '../Insert';

import { Button, Icon } from '../../ui';

const Component = styled.div`
  position: relative;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  box-sizing: border-box;
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
  background-color: ${backgroundColor};
  `}

  ${({ label }) =>
    label.match(/section/i) &&
    `
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  `}

  padding: ${({ label, edit }) =>
    edit && !label.match(/section/i) ? '12px' : '0'};

  ${({ edit, label, selected, hovered }) =>
    edit &&
    `

  cursor: ${
    !selected && !label.match(/(section|row)/gi) ? 'pointer' : 'default'
  };

  ${
    hovered &&
    `
  outline: 1px solid #d2d2d2;
  `
  }

  h1,h2,h3,h4,h5,p {
    margin-bottom: 0;
  }
  `}

  ${({ selected, label }) =>
    selected &&
    `
    outline: 1px solid #000000 !important;
    ${
      !label.match(/section/i) &&
      `
    background-color: #ffffff;
    box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
    outline: 1px solid #FFFFFF !important;
    `
    }
    `}

    overflow: visible;
  .insert {
    position: absolute;
    bottom: ${({ label }) => (label.match(/section/i) ? -12 : 0)}px;
    left: 0;
    z-index: 1000;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  width: 100%;
  position: absolute;
  top: -24px;
  left: 0px;
  background-color: #000000;
  outline: 1px solid #000000;
  cursor: grab;

  p {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    color: #ffffff;
    padding-left: 12px;
    line-height: 0 !important;
    margin-bottom: 0 !important;
    flex: 1;
    text-transform: capitalize;
  }

  p:first-child {
    display: flex;
    align-items: center;
    svg {
      margin-right: 6px;
    }
  }

  p:last-child {
    text-align: right;
  }

  button {
    margin: 0;
    padding: 0;
  }

  svg {
    fill: #ffffff;
  }
`;

const SideCtl = styled.div`
  position: absolute;
  width: 32px;
  padding-bottom: 32px;
  right: -32px;
  top: -1px;
  visibility: ${({ hovered }) => (hovered ? 'visible' : 'hidden')};

  button {
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    display: block;
    background-color: #f0f0f0;
    border: 1px solid #d2d2d2;
    &:hover {
      background-color: #e2e2e2;
      &:after {
        content: attr(label);
        display: block;
        position: absolute;
        padding: 4px;
        width: 100px;
        top: 5px;
        right: -120px;
        background-color: #4b9aff;
        color: #ffffff;
        border-radius: 50px;
      }
    }
    svg {
      margin-left: 6px;
    }
    &.invert {
      background-color: #000000;
      svg {
        fill: #ffffff;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d2;
    }
  }
`;

const Drop = styled.div`
  height: 32px;
  border: 1px solid #000000;
  position: absolute;
  box-sizing: border-box;
  right: 12px;
  left: 12px;
  z-index: 9;

  &.down {
    bottom: -20px;
  }
  &.up {
    top: -20px;
  }
`;

const select = ({ page, user, global: { brand } }) => ({
  ...page,
  ...user,
  brand,
});

export default ({
  row,
  item,
  props = {},
  ccount,
  column,
  section,
  collapse,
  readOnly,
  isSingleRow,
  noSettings,
  backgroundColor,
}) => {
  const { type, columns = [] } = props;
  const isEmptyRow = (() => {
    if (type !== 'row') return false;
    return columns.length === 1 && columns[0].items.length === 0;
  })();

  const dispatch = useDispatch();
  const {
    selected,
    dropTarget,
    dragTarget,
    editMode: edit,
    newItem,
    brand: gbrand,
    user,
  } = useSelector(select);

  const brand = gbrand || user?.brand;

  const [hovered, setHovered] = useState(false);
  const [draggable, setDraggable] = useState(false);
  const [to, setTo] = useState();

  const compRef = useRef();

  const isDragTarget = (() => {
    if (!dragTarget) return false;
    return (
      dragTarget.section === section &&
      dragTarget.row === row &&
      dragTarget.column === column &&
      dragTarget.item === item
    );
  })();

  const isDropTarget = (() => {
    if (!dropTarget) return false;
    return (
      dropTarget.section === section &&
      dropTarget.row === row &&
      dropTarget.column === column &&
      dropTarget.item === item
    );
  })();

  const isSelected = (() => {
    if (!selected) return false;
    return (
      selected.section === section &&
      selected.row === row &&
      selected.column === column &&
      selected.item === item
    );
  })();

  const insert = (n) => {
    if (type === 'row') dispatch(insertRow({ section, row: row + 1, c: n }));
    else dispatch(insertSection({ section: section + 1, c: n }));
  };

  const click = (e) => {
    setHovered(false);
    // do nothing if view mode
    if (!edit) return false;

    if (!e.target.nodeName.match(/(label|input)/i)) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (isSelected) return;

    switch (type) {
      case 'section':
        dispatch(
          setSelected({
            type: type,
            section,
          })
        );
        localStorage.removeItem(`tooltip-section-${user.id}`, true);
        break;

      case 'row':
        dispatch(
          setSelected({
            type: type,
            section,
            row,
          })
        );
        localStorage.removeItem(`tooltip-row-${user.id}`, true);
        break;

      default:
        dispatch(
          setSelected({
            type: type,
            section,
            row,
            column,
            item,
          })
        );
        break;
    }
  };

  const over = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDropTarget && !noSettings) setHovered(true);
  };

  const out = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHovered(false);
  };

  // these two should suprese react warning about adding mouse over and out
  // to react comp
  const blur = () => undefined;
  const focus = () => undefined;

  const dupe = () => {
    dispatch(
      duplicateComponent({
        type: type,
        section,
        row,
        column,
        item,
      })
    );
  };

  const remove = () => {
    dispatch(
      removeComponent({
        type: type,
        section,
        row,
        column,
        item,
      })
    );
  };

  const up = () => {
    dispatch(
      move({
        up: true,
        row,
        type,
        section,
      })
    );
  };

  const down = () => {
    dispatch(
      move({
        row,
        type,
        section,
      })
    );
  };

  const dragstart = (e) => {
    // prevent textContent from being dragged
    if (
      e.target.wholeText ||
      e.target.nodeName.match(/img/i) ||
      e.target.nodeName.match(/A/i)
    )
      return e.preventDefault();

    e.stopPropagation();
    e.target.style.opacity = '0.3';

    document.body.addEventListener('keydown', function ku(k) {
      document.body.removeEventListener('keydown', ku);
      k.preventDefault();
      k.stopPropagation();
      if (e.key === 'Escape') {
        dispatch(setDropTarget(undefined));
        setDraggable(false);
        setHovered(false);
      }
    });

    if (!isDragTarget)
      dispatch(
        setDragTarget({
          row,
          type,
          item,
          column,
          section,
        })
      );
  };

  const dragend = (e) => {
    e.target.style.opacity = '1';
    setDraggable(false);
  };

  const drop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newItem) dispatch(move({ type: 'new' }));
    else dispatch(move());
  };

  const dragenter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (to) clearTimeout(to);

    if (e.target.classList?.contains('component')) {
      e.target.addEventListener('dragleave', function dl() {
        e.target.removeEventListener('dragleave', dl);
        dispatch(setDropTarget(undefined));
      });
    }

    const { top, height } = compRef.current?.getBoundingClientRect();
    const toph = top + height / 2;
    const position = e.clientY - toph > 0 ? 1 : 0; // 0 is up 1 is down

    setTo(
      setTimeout(() => {
        if (type.match(/(row|section)/i)) {
          dispatch(setDropTarget(undefined));
          return;
        }

        if (!isDragTarget && !isDropTarget) {
          dispatch(
            setDropTarget({
              row,
              type,
              item,
              column,
              section,
              position,
            })
          );
        }
      }, 100)
    );
  };

  const dragover = (e) => e.preventDefault();

  useEffect(() => {
    if (edit && compRef.current) {
      const copy = compRef.current;
      copy.addEventListener('dragstart', dragstart);
      copy.addEventListener('dragenter', dragenter);
      copy.addEventListener('dragover', dragover);
      copy.addEventListener('dragend', dragend);
      copy.addEventListener('drop', drop);

      return () => {
        copy.removeEventListener('dragstart', dragstart);
        copy.removeEventListener('dragenter', dragenter);
        copy.removeEventListener('dragover', dragover);
        copy.removeEventListener('dragend', dragend);
        copy.removeEventListener('drop', drop);
      };
    }
  });

  const onClickHolder = (e) => {
    e.preventDefault();
    dispatch(toggleEditMode());
    dispatch(
      setSelected({
        row,
        type,
        item,
        column,
        section,
      })
    );
  };

  const componentType = {
    color: 'color',
    text: 'text',
    typography: 'typography',
    divider: 'divider',
    gradient: 'gradient',
    welcomeintro: 'Welcome Intro',
    image: 'image',
    exclusionzone: 'Logo spacing',
    minimumsize: 'Minimum size',
  };

  return (
    <Component
      ref={compRef}
      edit={edit}
      label={type || ''}
      onBlur={blur}
      onClick={!type.match(/(row|section)/i) ? click : () => {}}
      onFocus={focus}
      hovered={!dragTarget && hovered}
      selected={isSelected && !type.match(/(row|section)/i)}
      className="component"
      onMouseOut={edit ? out : undefined}
      onMouseOver={edit ? over : undefined}
      backgroundColor={backgroundColor}
      draggable={draggable}
    >
      {isDropTarget && dropTarget.position === 0 && <Drop className="up" />}
      {isSelected && !type.match(/(row|section)/i) && (
        <Head
          onMouseDown={() => setDraggable(true)}
          onMouseUp={() => setDraggable(false)}
        >
          <p>
            <Icon name={type} />{' '}
            <span className="label">{componentType[type]}</span>
          </p>
          <p>
            <Button icon="duplicate" text onClick={dupe} />
            <Button icon="delete" text onClick={remove} />
          </p>
        </Head>
      )}
      {edit && type.match(/(row|section)/gi) && !noSettings && (
        <>
          <SideCtl hovered={hovered}>
            <Button
              label="Settings"
              text
              className="invert"
              icon="settings"
              onClick={click}
            />
            {!isSingleRow && (
              <>
                <Button
                  label="Move up"
                  text
                  icon="arrow-up-circle"
                  onClick={up}
                />
                <Button
                  label="Move down"
                  text
                  icon="arrow-down-circle"
                  onClick={down}
                />
              </>
            )}
            <Button label="Duplicate" text icon="duplicate" onClick={dupe} />
            {(!isSingleRow || !isEmptyRow) && (
              <Button label="Delete" text icon="delete" onClick={remove} />
            )}
          </SideCtl>
        </>
      )}
      {(() => {
        switch (type) {
          case 'row':
            return (
              <Row
                {...{
                  ...props,
                  readOnly,
                  row,
                  item,
                  edit,
                  column,
                  section,
                  newItem,
                  collapse,
                  dragTarget,
                  dropTarget,
                  isSelected,
                }}
              />
            );

          case 'text':
            return (
              <Text
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );

          case 'gradient':
            return (
              <Gradiant
                {...{
                  color: (() => {
                    const c = brand.colors.find(
                      (cc) => cc.id === props.id || cc.color === props.color
                    );
                    return {
                      ...props,
                      ...c,
                    };
                  })(),
                  secondaryColor: (() => {
                    const c = brand.colors.find(
                      (cc) =>
                        cc.id === props.secondaryId ||
                        cc.color === props.secondaryColor
                    );
                    return {
                      ...props,
                      ...c,
                    };
                  })(),
                  readOnly,
                  section,
                  row,
                  column,
                  item,
                }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
              />
            );

          case 'color':
            return (
              <Color
                {...{
                  color: (() => {
                    const c = brand.colors.find(
                      (cc) => cc.id === props.id || cc.color === props.color
                    );
                    return {
                      ...props,
                      ...c,
                    };
                  })(),
                  readOnly,
                  section,
                  row,
                  column,
                  item,
                }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
              />
            );

          case 'section':
            return (
              <>
                <Section
                  {...{ ...props, readOnly, section, row, column, item }}
                  edit={edit}
                  selected={isSelected}
                />
              </>
            );

          case 'image':
            return (
              <Image
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );

          case 'exclusionzone':
            return (
              <LogoSpacing
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );

          case 'minimumsize':
            return (
              <LogoMinSize
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );

          case 'typography':
            return (
              <Typography
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
              />
            );

          case 'divider':
            return (
              <Divider
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
              />
            );
          case 'welcomeintro':
            return (
              <Text
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                ccount={ccount}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );
          case 'video':
            return (
              <Video
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );
          case 'audio':
            return (
              <Audio
                {...{ ...props, readOnly, section, row, column, item }}
                edit={edit}
                selected={isSelected}
                onClickHolder={onClickHolder}
                changeHandler={(payload) => {
                  // deselected selected comp
                  dispatch(
                    updateItem({
                      section,
                      row,
                      column,
                      item,
                      props: payload,
                    })
                  );
                }}
              />
            );

          default:
            return <span />;
        }
      })()}
      {isDropTarget && dropTarget.position === 1 && <Drop className="down" />}
      {edit && !dragTarget && type.match(/(row|section)/) && !noSettings && (
        <Insert label="Add a row:" onClick={insert} />
      )}
    </Component>
  );
};
