import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import brandboxLogo from '../brandbox.svg';
import { doLogout, updateProfile } from '../store/user';
import { Button, Theme, ContextMenu, Icon, Modal } from '../ui';
import { toast } from '../ui/Toast';
import MainNav from './MainNav';

import Account from './Account';
import Expire from './Expire';
import VerifyPrompt from './VerifyPrompt';
import Subscription from './Subscription';

const { colors } = Theme;

const TrialExpired = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 380px;
  background-color: rgba(240, 240, 240, 0.8);
  z-index: 1;

  .expired {
    width: 740px;
    height: 240px;
    background-color: #ffffff;
    margin: 70px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
  }

  .title {
    height: 30px;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
  .desc {
    display: block;
    width: 100%;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444444;
    margin-bottom: 25px;
    span {
      display: block;
    }
  }
  .info {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: normal;
    color: #999999;
  }
  a {
    color: #000000;
    text-decoration: underline;
    font-weight: 500;
    border: 0;
  }
  button {
    height: 30px;
    text-transform: uppercase;
  }
`;

const Header = styled.header`
  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    z-index: 10005;
  `}
  width: 100%;
  ${({ brand, user }) =>
    brand &&
    `
  background-color: ${colors.body};
  border-bottom: 1px solid ${colors.default};
  height: ${user ? '110px' : 'auto'}`};
`;

const ExpiredWrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  width: 960px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  font-weight: 300;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  p,
  div,
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }

  a,
  a:hover {
    border: 0;
    padding: 0;
  }

  button {
    font-weight: normal;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    background: none;
    &:not(:last-child) {
      margin-right: 0;
    }
    &:active {
      top: 0;
    }
  }
`;

const HeaderStripe = styled.div`
  width: 100%;
  height: 30px;
  font-size: 12px;
  background-color: ${colors.head};

  & p {
    font-weight: 400;
    text-transform: capitalize;
  }

  .account {
    display: flex;
    align-items: center;
  }

  .plan {
    display: flex;
    background-color: ${colors.body};
    align-items: center;
    padding: 3px 7px;
    .big {
      margin-right: 6px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 3px;
    }
  }
`;

const Brand = styled(Container)`
  height: 80px;
`;

const BrandBox = styled.span`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background-color: ${({ color, brand }) => {
    let c = (() => {
      // if color exists then return first
      if (color) return color;
      // otherwise check if brand has default color
      // eslint-disable-next-line
      if (brand?.styles?.logo_color) return brand?.styles.logo_color.color;
      return 'd2d2d2';
    })();

    if (c.match(/^#?[0-9a-fA-F]{3,6}/) && !c.startsWith('#')) c = `#${c}`;
    return c;
  }};

  & img {
    width: 100%;
  }
`;

const BrandsWrap = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 2px;
  }
  a {
    font-size: 13px;
  }
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  margin-right: 6px;
  border-radius: 50px;
  padding: 5px 19px;
  font-weight: 400;
  background-color: ${colors.info};
  color: ${colors.white};
  height: 13px;
  font-size: 12px;
  & svg {
    margin-right: 6px;
    width: 12px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export default ({ fixed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user,
    path,
    brand: gbrand,
  } = useSelector(({ user: usr, global }) => ({
    ...usr,
    ...global,
  }));
  const brand = user?.brand || gbrand || {};
  const { requests: brandRequests, users } = brand;
  const [showModal, setShowModal] = useState();

  // brandColor
  const brandColor = brand?.colors?.find(
    (c) =>
      c.id === brand?.styles?.logo_color ||
      c.color === brand?.styles?.logo_color.color
  )?.color;

  const routes = [];

  const currentUser = users?.find((i) => i.id === user?.id);
  const owner = users?.find((i) => i.role === 'owner');

  const accountUpdate = (profile, isVerification) => {
    if (profile === 1) {
      toast('Success', 'You have successfully updated your password');
      setShowModal();
      return;
    }
    if (typeof profile === 'object') {
      dispatch(updateProfile(profile));
      if (isVerification)
        toast(
          'Complete account verification',
          'An email has been sent with instructions to complete verification and access all features'
        );
      else toast('Success', 'You have successfully updated your profile.');
    }
    setShowModal();
  };

  if (brand?.pages) {
    brand.pages.forEach((p) => {
      if (!p.slug.match(/^basic/i) && !user.verified) return;
      routes.push({
        name: p.name,
        route: `/${p.slug}`,
      });
    });
  }

  if (user || brand.access === 1) {
    routes.push(
      ...[
        { name: 'Media', route: '/media' },
        { name: 'Touchpoints', route: '/touchpoints' },
      ]
    );
  }

  return (
    <Header {...{ user, fixed, brand }}>
      {user && (
        <HeaderStripe>
          <Container>
            <div className="plan">
              {(() => {
                if (!user) return;
                if (user.brand?.premium || user.brand?.subscription)
                  return <></>;
                // eslint-disable-next-line
                const start = new Date(user.brand?.created_at).getTime();
                const elapse = Math.round((Date.now() - start) / 8.64e7);
                if (!user.brand?.subscription)
                  if (30 - elapse <= 0) return <>TRIAL HAS EXPIRED</>;
                return (
                  <>
                    <span className="big">{30 - elapse}</span> days left
                  </>
                );
              })()}
            </div>
            <div className="account">
              {!user.verified && (
                <Notice onClick={() => setShowModal('account')}>
                  <Icon color="#ffffff" name="alert-circle" />{' '}
                  {!user.last_name
                    ? 'Verify your account to access all features'
                    : 'Account pending verification'}
                </Notice>
              )}
              <ContextMenu
                icon={
                  <Icon
                    className={`brand-${user?.brand?.role}`}
                    name="user-circle"
                  />
                }
                items={[
                  {
                    label: 'Your account',
                    handler: () => {
                      setShowModal('account');
                    },
                    iconRight: user?.verified ? undefined : 'alert-circle',
                  },
                ]}
                footerIcon={<Icon name="logout" />}
                footerLabel="Log out"
                footerClickHandler={() => dispatch(doLogout())}
                label={user.first_name}
              />
            </div>
          </Container>
        </HeaderStripe>
      )}

      <Brand>
        <BrandsWrap>
          <BrandBox color="black">
            <Link to="/">
              <img src={brandboxLogo} alt="bb-logo" />
            </Link>
          </BrandBox>
          {brand && <BrandBox color={brandColor} />}
          <MainNav routes={routes} />
        </BrandsWrap>
        {user && user.brand?.role?.match(/(admin|owner)/i) && (
          <p style={{ display: 'flex' }}>
            {brandRequests.length !== 0 && (
              <Notice
                onClick={() => {
                  history.push('/settings/users');
                }}
              >
                <Icon color="#ffffff" name="alert-circle" />
                {`${brandRequests.length} ${
                  brandRequests.length === 1 ? 'request' : 'requests'
                }`}
              </Notice>
            )}
            <Button
              text
              iconRight="settings"
              size="small"
              onClick={() => {
                if (user.verified) {
                  history.push(
                    user?.brand?.online ? '/settings' : '/settings/advanced'
                  );
                } else {
                  setShowModal('verify');
                }
              }}
            />
          </p>
        )}
        {!user && path.match(/signup|reset-password/) && (
          <p>
            <small>
              <Button
                text
                icon="cross-sml"
                onClick={() => dispatch(doLogout())}
                style={{
                  padding: 0,
                }}
              >
                CANCEL
              </Button>
            </small>
          </p>
        )}
      </Brand>

      {(() => {
        if (!user) return;
        if (user.brand?.premium) return <></>;
        // eslint-disable-next-line
        const start = new Date(user.brand?.created_at).getTime();
        const elapse = Math.round((Date.now() - start) / 8.64e7);
        if (!user.brand?.subscription && 30 - elapse <= 0)
          return (
            <>
              <ExpiredWrap />
              <TrialExpired>
                <div className="expired">
                  <p className="title">Your trial has expired</p>
                  <p className="desc">
                    Update your subscription for full access to your BrandBox.
                    <span>
                      Get in touch with your project owner
                      <a href={`mailto:${owner?.email}`}>here</a>.
                    </span>
                  </p>
                  {currentUser?.role === 'owner' ? (
                    <Button
                      size="small"
                      onClick={() => {
                        setShowModal('subscribe');
                      }}
                    >
                      Upgrade to full subscription
                    </Button>
                  ) : (
                    <p className="info">
                      If your project owner is not available, you can contact
                      the BrandBox team{' '}
                      <a href="mailto:support@brandboxhq.com">here</a>.
                    </p>
                  )}
                </div>
              </TrialExpired>
            </>
          );
        return <></>;
      })()}
      {showModal === 'expire' && (
        <Modal visible>
          <Expire onCancel={setShowModal} />
        </Modal>
      )}
      {showModal === 'subscribe' && (
        <Modal visible>
          <Subscription
            onCancel={setShowModal}
            onConfirm={() => {
              window.location.href = '/';
            }}
          />
        </Modal>
      )}
      {showModal === 'account' && (
        <Modal visible>
          <Account onCancel={setShowModal} onUpdate={accountUpdate} />
        </Modal>
      )}
      {showModal === 'verify' && (
        <Modal visible>
          <VerifyPrompt
            onCancel={setShowModal}
            onConfirm={() => setShowModal('account')}
          />
        </Modal>
      )}
    </Header>
  );
};
