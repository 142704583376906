import React from 'react';
import styled from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';

const Spinner = styled.div`
  width: 100%;
  height: 100%;

  ${({ spin }) =>
    spin &&
    `
      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        z-index: 11;
        margin: -16px 0 0 -16px;
        animation: spin 0.5s linear infinite;
        opacity: 0.75;
        font-weight: bold;
      }

      &::after {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        background-color: white;
        color: #aaa;
        opacity: 0.8;
        width: 100%;
        height: 100%;
        z-index: 10;
        align-items: center;
        justify-content: center;
      }
    `}
`;

export default ({ spin, children }) => (
  <Spinner spin={spin}>
    {spin && <AiOutlineLoading />}
    {children}
  </Spinner>
);
