/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Modal, Button } from '../../ui';
import Placeholder from './PlaceHolder';
import MediaBrowser from '../MediaBrowser';

const Audio = styled.div`
  background-color: rgb(238, 238, 238);
  border: 1px solid #dddddd;
  max-height: 100%;
  max-width: 100%;
  padding: 2rem;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #000000;
  }

  button {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  svg {
    width: 28px;
    height: 28px;
    fill: #000000;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-apperance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    cursor: pointer;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0.15, #d2d2d2),
      color-stop(0.15, #d2d2d2)
    );
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border-color: transparent;
      background: #666666;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -1px;
    }
    &::-moz-range-thumb {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border-color: transparent;
      background: #666666;
      cursor: pointer;
      margin-top: -1px;
    }
    &::-ms-thumb {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      border-color: transparent;
      background: #666666;
      cursor: pointer;
      margin-top: -1px;
    }
  }
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  line-height: 1.5em;
}
  div {
    margin: 0;
    line-height: 1.5em;
    display: flex;
    justify-content: space-between;
  }
  span {
    font-size: 12px;
    color: #333333;
  }
`;

export default ({
  url = '',
  edit,
  selected,
  placeholder,
  onClickHolder,
  changeHandler = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef();
  const audioNode = audioRef?.current;

  useEffect(() => {
    if (url && audioNode) {
      audioNode.addEventListener('loadedmetadata', () => {
        setDuration(audioNode.duration);
        setCurrentTime(audioNode.currentTime);
      });
      audioNode.addEventListener('playing', () => {
        setInterval(() => {
          setCurrentTime(audioNode.currentTime);
        }, 10);
      });
      audioNode.addEventListener('ended', () => {
        setCurrentTime(0);
        setPaused(true);
      });
      return () => {
        setCurrentTime(0);
        setPaused(true);
        audioNode.currentTime = 0;
        audioNode.pause();
      };
    }
  }, [edit, url, audioNode]);

  if (!edit && !url && placeholder)
    return (
      <Placeholder
        placeholder={placeholder}
        height={368}
        onClick={onClickHolder}
      />
    );

  const togglePlay = () => {
    if (edit || !url) return;
    if (audioNode.paused) {
      audioNode.play();
      setPaused(false);
    } else {
      audioNode.pause();
      setPaused(true);
    }
  };
  const formatTime = (seconds) => {
    const min = Math.floor(Math.floor(Math.round(seconds, 0)) / 60);
    const sec = Math.floor(Math.round(seconds, 0)) - min * 60;

    return `${min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCurrentTime(value);
    audioNode.currentTime = value;
  };

  const setAudio = (sel) => {
    if (audioRef.current) audioRef.current.src = sel.urls.original;
    changeHandler({
      url: sel.urls.original,
    });
    setOpen(false);
  };

  return (
    <>
      <Audio
        paused={paused}
        edit={edit}
        url={url}
        onClick={() => selected && setOpen(!open)}
      >
        {url ? <audio ref={audioRef} src={url} /> : <span />}

        <Button
          onClick={togglePlay}
          size="small"
          icon={paused ? 'play' : 'pause'}
        />
        <Controls>
          <div>
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
          <input
            id="slide"
            type="range"
            min="0"
            max={duration}
            step=".01"
            value={currentTime}
            onChange={handleChange}
          />
        </Controls>
      </Audio>

      {selected && (
        <Modal visible={open}>
          <MediaBrowser
            type="audio"
            category="basics"
            onCancel={() => setOpen(false)}
            onConfirm={setAudio}
          />
        </Modal>
      )}
    </>
  );
};
